import React, { useState } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { getDateString, getDateTimeString } from '../../helpers/Date'

//state
//components
import SportIcon from '../icons/SportIcon';
//styles
import styles from './ReviewerCompletedRequest.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import card from '../../app-styles/Card.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import Icon from '../../icons/Icon';

function ReviewerCompletedRequest(props) {

  //props
  const request = props.request;
  //state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //router
  const history = useHistory();

  const cardClick = () => {
    history.push('/reviewer-replay/' + request._id);
  }

  const openMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen)
  }

  // const getMenuLinks = () => {
  //   switch (status) {
  //     case "awaiting-review":
  //       return (
  //         <>
  //           <div className={mjes.textLink}>Discard</div>
  //           <div className={mjes.textLink}>Report</div>
  //         </>
  //       )
  //     case "user-upload-error":
  //       return (
  //         <>
  //           <div className={mjes.textLink} >Report Error</div>
  //         </>
  //       )
  //     case "awaiting-user-transcode":
  //       return <></>
  //     case "user-transcode-error":
  //       return <></>
  //     default:
  //       return <></>
  //   }
  // }

  const getResponseTime = () => {
    const responseTime = Math.floor(request.responseTime);
    if (responseTime < 1)
      return "< 1 Hour"
    else if (responseTime === 1)
      return "1 Hour"
    else
      return responseTime + " Hours"
  }

  const getQuestionColor = () => {
    if(!request.question)
      return "gray"
    else {
      if(!request.response)
        return "#4353FF"
      else
        return "white"
    }
  }

  return (

    <div className={card.card} onClick={() => cardClick()} style={{ cursor: "pointer" }}>
      <div className={card.cardContent}>
        <div className={styles.topGrid}>
          <div className={rs.labelMediumLight}>{(request.userFirstName).substring(0, 25)}</div>
          <div className={styles.sportIconContainer}>
            {/* <SportIcon icon={request.sport} /> */}
          </div>
        </div>
        {/* <div className={styles.imageContainer}>
          <img src={request.reviewerImage} className={styles.image}></img>
        </div> */}
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Date:</div>
          <div className={mjes.text_s}>{getDateString(new Date(request.dateCreated))}</div>
        </div>
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Price:</div>
          <div className={mjes.text_s}>{"$" + request.originalBasePrice ? request.originalBasePrice : request.reviewerPrice}</div>
        </div>
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Session #:</div>
          <div className={mjes.text_s}>{request.repeatCount + 1}</div>
        </div>
        {/* <div>
          <div className={rs.labelSmallLight}>Specific Request:</div>
          <div className={mjes.text_s} style={{ height: "9rem" }}>
            {request.details === "" ?
              <div style={{ textAlign: "center" }}>- None Provided -</div> :
              <div>{request.details}</div>
            }
          </div>
        </div> */}
        <div className={styles.followupGrid}>
          <div className={rs.labelSmallLight}>Follow Up:</div>
          <div className={styles.followupGrid}>
            <div className={rs.labelSmallLight} style={{ color: getQuestionColor() }}>Question</div>
            <div className={rs.labelSmallLight} style={{ color: request.response ? "white" : "gray" }}>Answer</div>
          </div>
        </div>
        <div className={styles.statsGrid}>
          <div className={styles.statGrid}>
            <Icon icon="star" width="2rem" height="1.5rem" color="white" />
            <div className={rs.labelSmallLight}>{request.rating ? request.rating.rating : "-"}</div>
          </div>
          <div className={styles.statGrid}>
            <Icon icon="lightening" width="2rem" height="1.5rem" color="white" />
            <div className={rs.labelSmallLight}>{getResponseTime()}</div>
          </div>
        </div>
        <div className={card.statusContainer}>
          <div className={rs.labelSmallLight}>Click To View</div>
          {/* <div className={card.buttonContainer} onClick={openMenu}>
            <Icon icon="gear" width="1rem" height="1rem" color="white" />
          </div> */}
        </div>
      </div>
      {/* {isMenuOpen &&
        <div className={mjes.dropdownMenu}>
          <div>
            {getMenuLinks()}
          </div>
        </div>
      } */}
    </div>

    // <div className={card.clickableCard} onClick={cardClick}>
    //   <div className={card.cardContent}>
    //     <div className={styles.topGrid}>
    //       <div className={card.title}>{request.recipientName}</div>
    //       <div className={card.title}>{"$" + request.price}</div>
    //     </div>
    //     <div className={card.textGroup}>
    //       <div className={card.label}>Date:</div>
    //       <div className={card.text}>{new Date(request.createdDate).toDateString()}</div>
    //     </div>
    //     <div className={card.textGroup}>
    //       <div className={card.label}>Type:</div>
    //       <div className={card.text}>Performance Replay Analysis</div>
    //     </div>
    //     <div>
    //       <div className={card.label}>Specific Request:</div>
    //       <div className={card.text} style={{ height: "8rem" }}>
    //         {request.requestDescription === "" ?
    //           <div style={{ textAlign: "center" }}>- None Provided -</div> :
    //           <div>{request.requestDescription}</div>
    //         }
    //       </div>
    //     </div>
    //     <div className={card.metricsGrid}>
    //       <Icon icon="star" height="1.25rem" color="#fff769" />
    //       <div className={card.text}>{request.rating ? request.rating : "- none -"}</div>
    //     </div>
    //     <div className={card.metricsGrid}>
    //       <Icon icon="lightening" height="1.25rem" color="#9cedff" />
    //       <div className={card.text}>{getResponseTime()}</div>
    //     </div>
    //     <div className={card.statusContainer}>
    //       <div></div>
    //       <div className={card.status}>Click To View</div>
    //       <div className={card.buttonContainer} onClick={(e) => openMenu(e)}>
    //         <Icon icon="gear" width="1rem" height="1rem" color="white" />
    //       </div>
    //     </div>
    //   </div>
    //   {menuOpen &&
    //     <OutsideClickHandler onOutsideClick={() => outsideClick()} >
    //       <div className={styles.menu}>
    //         <div className={styles.menuItem}>Discard</div>
    //         <div className={styles.lastMenuItem}>Flag</div>
    //       </div>
    //     </OutsideClickHandler>
    //   }
    // </div >
    // <div className={card.clickableCard} onClick={cardClick}>
    //   <div className={card.cardContent} >
    //     <div className={styles.topGrid}>
    //       <img src={request.videoThumbnail} className={styles.image}></img>
    //       <div>
    //         <div className={layout.horizontalFlex}>
    //           <div className={styles.name}>{request.recipientName}</div>
    //           <div className={styles.price}>{"$" + request.price}</div>
    //         </div>
    //         <div className={styles.requestDate}>{"" + new Date(request.createdDate).toDateString()}</div>
    //         <div className={styles.ratingGrid}>
    //           <Icon icon="star" height="1.5rem" color="yellow" />
    //           <div>{request.rating ? request.rating : "no rating"}</div>
    //         </div>
    //         <div className={styles.ratingGrid}>
    //           <Icon icon="lightening" height="1.5rem" color="lightBlue" />
    //           <div>{Math.round(hours) + " hours"}</div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={card.statusContainer}>
    //       <div></div>
    //       <div className={card.status}>Click To View</div>
    //       <div className={card.buttonContainer} onClick={(e) => { e.stopPropagation(); }}>
    //         <Icon icon="gear" width="1rem" height="1rem" color="white" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );

}

export default ReviewerCompletedRequest;