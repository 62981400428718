import React from 'react';
import { CardElement} from '@stripe/react-stripe-js';
// import './Styles.css'
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "white",
      color: "#b4b3b3",
      fontSize: "16px",
      "::placeholder": {
        color: "#b4b3b3",
      },
    },
    invalid: {
      color: "#c24141",
      iconColor: "#df8b8b",
    },
  },
};

function CardSection() {
  return (
    <div>
      <div style={{ marginBottom: ".5rem" }}>Card: </div>
      <div style={{backgroundColor:"#191a1b", padding:".75rem"}}>
        <CardElement options={CARD_ELEMENT_OPTIONS}/>
      </div>
    </div>
  );
};
export default CardSection;