import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const ReviewerCompletedRequestsSlice = createSlice({
  name: 'reviewerCompletedRequests',
  initialState: {
    requests: null,
    status: "processing", //idle, fetching, success, error
    error: ""
  },
  reducers: {
    ReviewerCompletedRequestsProcessing: (state) => {
      state.status = "processing";
      state.error = "";
      state.requests = null;
    },
    ReviewerCompletedRequestsSuccess: (state, action) => {
      state.requests = action.payload.requests
      state.status = "success";
      state.error = "";
    },
    ReviewerCompletedRequestsError: (state, action) => {
      state.status = "error";
      state.requests = null;
      state.error = action.payload;
    }
  }
});

export const {
  ReviewerCompletedRequestsProcessing,
  ReviewerCompletedRequestsSuccess,
  ReviewerCompletedRequestsError
} = ReviewerCompletedRequestsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const FetchRequests = () => async dispatch => {

  dispatch(ReviewerCompletedRequestsProcessing());

  try {
    let response = await fetch(getEndpoint() +"request/get-reviewer-completed-requests", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ })
    });

    response = await response.json();
    if (response.success) {
      const requests = response.payload.requests
      dispatch(ReviewerCompletedRequestsSuccess({requests: requests}));
    }
    else {
      dispatch(ReviewerCompletedRequestsError(response.message));
    }
  }
  catch (error) {
    dispatch(ReviewerCompletedRequestsError(error));
  }
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectReviewerCompletedRequests = state => state.reviewerCompletedRequests;

export default ReviewerCompletedRequestsSlice.reducer;
