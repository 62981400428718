import React, { useState, useEffect, useCallback } from 'react';
//libraries
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validateImage } from '../../input-validation/InputValidation';
import { toast } from 'react-toastify';
import ReactModal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import ResizeImage from '../../helpers/ImageSize';
import getCroppedImg from '../../helpers/ImageCrop';
import Cropper from 'react-easy-crop';
//state
//components
//styles
import styles from './UpdateProfilePicture.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import Icon from '../../icons/Icon';

function UpdateProfilePicture(props) {

  //redux
  //image crop
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const [isCropOpen, setIsCropOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 400, y: 300 })
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [aspectRatio, setAspectRatio] = useState(4 / 3);

  //react-dropzone
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 1) {
      toast.error("Only accepts single files")
      return
    }

    const selectedFile = acceptedFiles[0];
    if (!validateImage(selectedFile)) {
      toast.error("Must be a png or jpeg")
      return
    }

    setSelectedImage(selectedFile);

    var reader = new FileReader();
    reader.onload = function (event) {
      setSelectedImageUrl(event.target.result);
      setIsCropOpen(true)
    };
    reader.readAsDataURL(selectedFile);

  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  //image cropper
  const onCropChange = crop => {
    setCrop(crop);
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  const onZoomChange = zoom => {
    setZoom(zoom)
  }

  const cancelCrop = () => {
    setIsCropOpen(false)
    if (!croppedImage) {
      setSelectedImage(null)
      setSelectedImageUrl(null)
    }
  }

  const cropPicture = async () => {
    try {
      const croppedImage = await getCroppedImg(selectedImageUrl, croppedAreaPixels);
      let blob = await fetch(croppedImage).then(r => r.blob());

      //resize
      const resizedImage = await ResizeImage(blob);
      setCroppedImage(resizedImage)

      var reader = new FileReader();
      reader.onload = function (event) {
        setCroppedImageUrl(event.target.result);
      };
      reader.readAsDataURL(resizedImage);
    } catch (e) {
      console.error(e)
    }
    setIsCropOpen(false);
  }

  const recrop = () => {
    setIsCropOpen(true)
  }

  const submitPicture = () => {
    props.submitPicture(croppedImage)
    props.close()
  }

  return (
    <div className={mjes.pageContainer}>
      <div>
        <div className={styles.header}>
          <div className={mjes.label} style={{ textAlign: "left" }}>Select a picture and crop it for your profile</div>
        </div>
        <div className={styles.dragZoneContainer} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={styles.dropZoneLabel}>
            <div>Click here or drag file</div>
          </div>
        </div>
        <div className={styles.imageArea} style={{ margin: "1rem 0" }}>
          {isCropOpen ?
            <div>
              <div className={styles.cropper}>
                <Cropper
                  image={selectedImageUrl}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspectRatio}
                  onCropChange={onCropChange}
                  onCropComplete={onCropComplete}
                  onZoomChange={onZoomChange}
                />
              </div>
              <div className={styles.buttonsContainer} style={{ margin: "1rem 0" }}>
                <div className={mjes.stretchButtonPrimary} onClick={() => cropPicture()}>Crop</div>
                <div className={mjes.stretchButtonSecondary} onClick={() => cancelCrop()}>Cancel</div>
              </div>
            </div> :
            <div>
              <div>
                {croppedImage &&
                  <div className={mjes.buttonPrimary} style={{ marginBottom: "1rem", maxWidth: "300px" }} onClick={() => recrop()}>Adjust Crop</div>
                }
              </div>
              <div style={{ textAlign: "center" }}>
                <img src={croppedImageUrl} style={{ margin: "auto" }} />
              </div>
            </div>
          }
        </div>
        <div style={{ marginTop: "1rem" }}>
          {(!isCropOpen && croppedImage) &&
            <div>
              <div className={mjes.stretchButtonSecondary} onClick={() => submitPicture()}>Submit Picture</div>
              <div className={mjes.labelError}>{props.error}</div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default UpdateProfilePicture