import React, { useState } from 'react'
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import Loader from '../loader/Loader'

const ContactUs = () => {

  const [status, setStatus] = useState("awaiting")
  const [email, setEmail] = useState("")
  const [supportRequest, setSupportRequest] = useState("")
  const [error, setError] = useState()

  const submitTicket = () => {
    if(email.length === 0 || supportRequest.length === 0 ){
      setError("Email and Support Request are both required")
      return
    }
  }

  const getTicketSubmission = () => {
    if (status === "awaiting") {
      return (
        <div>
          <div  style={{marginTop:"1rem"}}>
            <div className={mjes.label_s}>Email Address *</div>
            <input className={mjes.textInput} type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div style={{marginTop:"1rem"}}>
            <div className={mjes.label_s}>Support Request *</div>
            <textarea className={mjes.textAreaInput} value={supportRequest} onChange={(e) => setSupportRequest(e.target.value)} />
          </div>
          <div className={mjes.stretchButtonPrimary} style={{marginTop:".5rem"}}>Submit Ticket</div>
          <div className={mjes.labelError}>{error}</div>
        </div>
      )
    }
    else if (status === "processing") {
      return (
        <Loader text="Submitting Ticket" />
      )
    }
    else {
      return (
        <div className={mjes.text_l}>Thank you for submitting a ticket!</div>
      )
    }
  }

  return (
    <div style={{margin:"1rem"}}>
      <div style={{ maxWidth: "60rem", margin: "auto" }}>
        <div className={rs.h1MediumLight} style={{textAlign:"center"}}>Contact Us</div>
        <div style={{marginTop:"1.5rem"}}>
          <div className={rs.labelMediumLight}>Support</div>
          <div className={rs.labelSmallLight} style={{margin:".25rem 0"}}>support@thereplayapp.com</div>
          <div className={mjes.text_m}>
            Please send us an email regarding any issues or questions you may have about Replay.
            We are happy to hear from you and will
            do our best to get back to you as soon as possible.
          </div>
          {/* <div className={mjes.text_m}>
            Please fill out the form below to contact us regarding any issues or questions you may have about Replay.
            If you would prefer, you can email us at the support email listed above.  We are happy to hear from you and will
            do our best to get back to you as soon as possible.
          </div> */}
          <div>
            {/* {getTicketSubmission()} */}
          </div>
        </div>
        <div  style={{marginTop:"2.5rem"}}>
          <div className={rs.labelMediumLight}>Partnerships</div>
          <div className={rs.labelSmallLight} style={{margin:".25rem 0"}}>partners@thereplayapp.com</div>
          <div className={rs.textSmallLight}>
            For any inquiries about potential partnerships with Replay, please send us an email at the address listed above.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs