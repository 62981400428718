import React, { useEffect } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//state
import { FetchNotifications, selectNotifications } from '../../slices/NotificationSlice';
import { ResetNotificationCount, selectLogin } from '../../slices/LoginSlice';
//components
import Loader from '../loader/Loader'
//styles
import styles from './NotificationPage.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import modal from '../../app-styles/Modal.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'


function NotificationPage() {

  const dispatch = useDispatch()
  const history = useHistory()
  const login = useSelector(selectLogin)
  const notificationSlice = useSelector(selectNotifications)
  const notifications = notificationSlice.notifications
  const status = notificationSlice.status

  useEffect(() => {
    dispatch(FetchNotifications())
    dispatch(ResetNotificationCount())
  }, []);

  const clickHandler = (notification) => {
    switch (login.role) {
      case "user":
        if (notification.type === "user-request-submitted")
          return history.push('/profile/requests')
        else
          return history.push('/user-download/' + notification.content.requestId)
      case "reviewer":
        if (notification.type === "reviewer-request-ready")
          return history.push("/reviewer-profile/requests")
        else
          return history.push('/reviewer-replay/' + notification.content.requestId)
    }
  }

  const getFriendlyDate = (date) => {
    const dateObj = new Date(date)
    let formatter = Intl.DateTimeFormat(
      "default", // a locale name; "default" chooses automatically
      {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      }
    );

    try {
      return formatter.format(dateObj)
    }
    catch {
      return ""
    }
  }

  if (status === 'processing')
    return <Loader text="Loading Profile" />
  else if (status === 'error')
    return (
      <div>
        <div className={rs.h1MediumLight}>Messages</div>
        <div className={rs.textMediumLight}>Error retrieving notifications</div>
      </div>
    )

  return (
    <div>
      <div className={rs.h1MediumLight}>Notifications</div>
      <div className={mjes.pageContentContainer}>
        {notifications.length > 0 ?
          notifications.map((notification) => (
            <div key={notification._id} className={notification.isRead ? styles.notificationContainer : styles.newNotificationContainer} key={notification.dateCreated + notification.type}
              onClick={() => clickHandler(notification)}>
              <div className={rs.labelMediumLight}>{notification.friendlyName}</div>
              <div>{getFriendlyDate(notification.dateCreated)}</div>
              <div className={rs.textSmallLight}>{notification.message}</div>
            </div>
          )) :
          <div className={rs.textMediumLight}>You have no notifications.  Get started by finding a coach and submitting a video.</div>
        }
      </div>
    </div>
  );
}

export default NotificationPage;