import React, { useEffect } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
//state
import { selectReviewerActiveRequests, FetchRequests } from '../../slices/ReviewerActiveRequestsSlice'
//components
import ReviewerActiveRequest from '../reviewer-active-request/ReviewerActiveRequest'
import Loader from '../loader/Loader'
import Icon from '../../icons/Icon'
//styles
import styles from './ReviewerActiveRequests.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'

function ReviewerActiveRequests() {

  const dispatch = useDispatch()
  const reviewerActiveRequests = useSelector(selectReviewerActiveRequests)
  const requests = reviewerActiveRequests.requests
  const reviewer = reviewerActiveRequests.reviewer
  const status = reviewerActiveRequests.status
  const history = useHistory()

  const isSmall = useMediaQuery({ query: '(max-width: 760px)' })

  useEffect(() => {
    dispatch(FetchRequests(reviewerActiveRequests.page));
  }, []);

  const updateProfile = () => {
    history.push('/reviewer-profile/profile')
  }

  const onPracticeClick = () => {
    history.push('/practice-video/' + reviewer.sport)
  }

  if (status === 'processing')
    return <Loader text="Loading Requests" />
  else if (status === 'error')
    return <div>Error retrieving requests</div>

  return (
    <div>
      <div className={rs.h1MediumLight} >Active Requests</div>
      <div className={rs.labelMediumLight} style={{ color: "#ff9819" }}>Google Chrome Required!</div>
      <div>
        {!isSmall &&
          <div className={rs.buttonSecondary} onClick={() => onPracticeClick()} style={{width:"18rem", margin:"1rem 0"}}>
            <div className={rs.labelMediumLight}>Practice Video</div>
          </div>
        }
      </div>
      {requests.length > 0 ?
        <div>
          {requests.map(request => (
            <ReviewerActiveRequest key={request._id} request={request} />
          ))}
        </div> :
        <div className={layout.pageContent}>
          <div className={rs.textMediumLight} style={{ marginBottom: ".5rem" }}>You have no incoming requests</div>
        </div>}
    </div>
  );
}

export default ReviewerActiveRequests;