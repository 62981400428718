import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import SearchReviewer from '../search-reviewer/SearchReviewer';
import SearchReviewerMobile from '../search-reviewer/SearchReviewerMobile';
import styles from './SearchResultsSection.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'

function SearchResultsSection(props) {

  const title = props.title
  const result = props.result
  const bucket = props.bucket

  const isSmall = useMediaQuery({ query: '(max-width: 41rem)' })

  return (
    <div style={{ margin: "1rem .5rem 1rem .5rem" }}>
      <div style={{ margin: "auto", maxWidth: "100rem" }}>
        <div>
          <div className={rs.h1SmallLight} style={{ margin: "auto", fontSize: "1.25rem" }}>{title}</div>
        </div>
        <div className={isSmall ? styles.reviewersContainerMobile : styles.reviewersContainer}>
          {result.map((reviewer, i) => (
            isSmall ?
              <div style={{ width: "100%" }}>
                <SearchReviewerMobile key={reviewer._id} reviewer={reviewer} bucket={bucket} sport={props.sport} scrollY={props.scrollY} />
              </div> :
              <div style={{ width: "20rem" }}>
                <SearchReviewerMobile key={reviewer._id} reviewer={reviewer} bucket={bucket} sport={props.sport} scrollY={props.scrollY} />
              </div>

          ))}
        </div>
      </div>
    </div>
  );
}

export default SearchResultsSection;