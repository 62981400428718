import React, { useState, useEffect } from 'react';
//libraries
import { Link } from 'react-router-dom';
import { getDateString, getDateTimeString } from '../../helpers/Date'
//state
//components
import SportIcon from '../icons/SportIcon';
import Icon from '../../icons/Icon';
//styles
import styles from './UserActiveRequest.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import card from '../../app-styles/Card.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';

function UserActiveRequest(props) {

  const request = props.request;
  const status = request.status

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false)

  useEffect(() => {
  }, []);

  const viewReviewerProfile = () => {

  }

  const getFriendlyStatus = () => {
    switch (request.processStatus) {
      case "user-upload":
        return "Uploading Video"
      case "user-transcode":
        return "Preparing Video"
      case "reviewer-upload":
      case "reviewer-transcode":
      default:
        return "Awaiting Review"
    }
  }

  return (
    <div className={card.card} >
      <div className={card.cardContent}>
        <div className={styles.topGrid}>
          <div className={rs.labelMediumLight}>{(request.reviewerFirstName + " " + request.reviewerLastName).substring(0, 25)}</div>
          <div className={styles.sportIconContainer}>
            <SportIcon icon={request.sport} />
          </div>
        </div>
        {/* <div className={styles.imageContainer}>
          <img src={request.reviewerImage} className={styles.image}></img>
        </div> */}
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Date:</div>
          <div className={rs.textSmallLight}>{getDateString(new Date(request.dateCreated))}</div>
        </div>
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Session #:</div>
          <div className={rs.textSmallLight}>{request.repeatCount + 1}</div>
        </div>
        <div>
          <div className={rs.labelSmallLight}>Details:</div>
          <div className={rs.textSmallLight} style={{ height: "9rem" }}>
            {request.details === "" ?
              <div className={rs.textSmallLight} style={{ textAlign: "center" }}>- None Provided -</div> :
              <div className={rs.textSmallLight} style={{fontSize:".85rem"}}>{request.details}</div>
            }
          </div>
        </div>
        <div className={card.statusContainer}>
          <div className={rs.labelSmallLight} style={{ }}>{getFriendlyStatus()}</div>
          {/* <div className={card.buttonContainer} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Icon icon="gear" width="1rem" height="1rem" color="white" />
          </div> */}
        </div>
      </div>
    </div>
  );

}

export default UserActiveRequest;