import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import { toast } from 'react-toastify';

export const SignupSlice = createSlice({
  name: 'signup',
  initialState: {
    status: 'awaiting-input',
    error: ''
  },
  reducers: {
    Processing: (state) => {
      state.status = 'processing'
    },
    Success: (state) => {
      state.status = 'success';
    },
    Error: (state, action) => {
      state.status = 'awaiting-input'
      state.error = action.payload;
    },
    SignupReset: (state) => {
      state.status = 'awaiting-input'
    }
  },
});

export const { Processing, Success, Error, SignupReset } = SignupSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const UserSignup = (firstName, lastName, email, password, birthday, referralCode, allowPromos) => async dispatch => {

  dispatch(Processing()); //starting processing

  const signup = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    birthday: birthday,
    referralCode: referralCode,
    allowPromos: allowPromos
  }

  try {
    let response = await fetch(getEndpoint() + "signup/account", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(signup)
    });

    response = await response.json();
    if (response.success) {
      toast.success(firstName + " , Welcome to Replay!" )
      dispatch(Success());
    }
    else {
      console.log(response.message)
      dispatch(Error(response.message));
    }
  }
  catch (error) {
    console.log(error.toString());
    dispatch(Error(error));
  }
};

export const ReviewerSignup = (firstName, lastName, email, password, birthday, referralCode, athleticExp, coachingExp, achievements) => async dispatch => {

  dispatch(Processing()); //starting processing

  const signup = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    birthday: birthday,
    referralCode: referralCode,
    athleticExp: athleticExp,
    coachingExp: coachingExp,
    achievements: achievements
  }

  try {
    let response = await fetch(getEndpoint() + "signup/reviewer", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(signup)
    });

    response = await response.json();
    if (response.success) {
      toast.success(firstName + " , Your application has been recieved!" )
      dispatch(Success());
    }
    else {
      console.log(response.message)
      dispatch(Error(response.message));
    }
  }
  catch (error) {
    console.log(error.toString());
    dispatch(Error(error));
  }
};


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSignup = state => state.signup;

export default SignupSlice.reducer;
