import React, { useState, useEffect } from 'react';
//libraries
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import queryString from "query-string"
import MaskedInput from 'react-text-mask';
import { validateName, validateEmail, validatePassword, validateBirthday } from '../../input-validation/InputValidation';
//state
import { AddUser, ChangeUser, EditUser, selectLogin, DeleteUser } from '../../slices/LoginSlice';
//styles
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import styles from '../switch-user/SwitchUser.module.css'
import Icon from '../../icons/Icon';

const SwitchUser = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const login = useSelector(selectLogin)
  const query = queryString.parse(location.search);

  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [editUser, setEditUser] = useState(null)
  const [firstName, setFirstName] = useState("")
  const [firstNameStatus, setFirstNameStatus] = useState("awaiting")
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastName, setLastName] = useState("")
  const [lastNameStatus, setLastNameStatus] = useState("awaiting")
  const [lastNameError, setLastNameError] = useState(null)
  const [birthday, setBirthday] = useState("");
  const [birthdayStatus, setBirthdayStatus] = useState("awaiting");
  const [birthdayError, setBirthdayError] = useState("");
  const [gender, setGender] = useState("other")
  const [color, setColor] = useState(0)
  const [sports, setSports] = useState([])
  const [isAutoLogin, setIsAutoLogin] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null)
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false)

  const isSmall = useMediaQuery({ query: '(max-width: 44rem)' })

  useEffect(() => {
    setIsAutoLogin(login.autoLoginUserId !== null)
  }, [])

  const selectUser = (userId) => {
    dispatch(ChangeUser(userId, isAutoLogin))
    if (query.username)
      return history.push('/results/' + query.username + '/request')
    history.push('/browse')
  }

  const openAddUser = () => {
    setFirstName("")
    setLastName("")
    setBirthday("")
    setGender("other")
    setColor(1)
    setSports([])
    setIsAddUserOpen(true)
  }

  const addUser = () => {
    if (!areUserSettingsValid())
      return

    dispatch(AddUser(firstName, lastName, gender, color, sports, birthday))
    setIsAddUserOpen(false)
  }

  const submitEditUser = () => {
    // if (!areUserSettingsValid())
    //   return

    dispatch(EditUser(editUser._id, firstName, lastName, gender, color, sports))
    setIsEditUserOpen(false)
  }

  const openEditModal = (user) => {
    setEditUser(user)
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setBirthday(user.birthday)
    setGender(user.gender)
    setColor(user.color)
    setSports(user.sports)
    setIsEditUserOpen(true)
  }

  const openDeleteWarning = (user) => {
    setDeleteUser(user)
    setIsDeleteWarningOpen(true)
  }

  const confirmDeleteUser = () => {
    if (deleteUser) {
      dispatch(DeleteUser(deleteUser._id))
    }
    setIsDeleteWarningOpen(false)
  }

  const sportSelected = (sport) => {

    if (sports.includes(sport)) {
      setSports(sports.filter(s => s !== sport))
    }
    else {
      let updatedSports = [...sports, sport]
      setSports(updatedSports);
    }
  }

  const areUserSettingsValid = () => {
    if (firstName.length > 0 && lastName.length > 0 && birthdayStatus === "valid")
      return true
    return false
  }

  const updateBirthday = (birthday) => {
    setBirthday(birthday);

    const validation = validateBirthday(birthday);
    if (validation.isValid) {
      setBirthdayStatus("valid");
      setBirthdayError(null);

    }
    else {
      setBirthdayStatus("invalid")
      setBirthdayError(validation.error);
      return;
    }

    const birthdayDate = new Date(birthday);
    var ageDifMs = Date.now() - birthdayDate.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = ageDate.getUTCFullYear() - 1970
    if (age < 1) {
      setBirthdayStatus("error");
      setBirthdayError("Must be at least 1 years old")
    }
    else if (age >= 125) {
      setBirthdayStatus("error");
      setBirthdayError("Must be less than 125 years old")
    }
  }

  const getCardStyle = (userColor) => {
    switch (userColor) {
      case 0:
        return styles.nameCardBlue //"#147efb"
      case 1:
        return styles.nameCardPink //"#ff14af"
      case 2:
        return styles.nameCardGreen //"#11d465"
      case 3:
        return styles.nameCardPurple //"#b245e6"
      case 4:
        return styles.nameCardOrange //"#ff9819"
    }
  }

  const getColor = (userColor) => {
    switch (userColor) {
      case 0:
        return "#147efb"
      case 1:
        return "#ff14af"
      case 2:
        return "#11d465"
      case 3:
        return "#b245e6"
      case 4:
        return "#ff9819"
    }
  }

  if (isAddUserOpen)
    return (
      <div className={mjes.pageContentContainer}>
        <div className={styles.modalTitleGrid}>
          <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Add User</div>
          <div className={mjes.iconButton} style={{ fontWeight: "500", fontSize: "1.25rem" }} onClick={() => setIsAddUserOpen(false)}>
            <Icon icon="x" height="1.5rem" color="white" />
          </div>
        </div>
        <div className={mjes.columnGrid}>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>First Name *</div>
            <input className={firstNameStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              type='text' value={firstName} onChange={e => setFirstName(e.target.value)} maxLength="13" />
            <div className={mjes.labelError}>{firstNameError}</div>
          </div>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Last Name *</div>
            <input className={lastNameStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              type='text' value={lastName} onChange={e => setLastName(e.target.value)} maxLength="13" />
            <div className={mjes.labelError}>{lastNameError}</div>
          </div>
        </div>
        <div className={mjes.columnGrid}>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Birthday *</div>
            <MaskedInput className={birthdayStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              value={birthday} onChange={e => updateBirthday(e.target.value)}
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              guide={false} showMask={true} placeholder="12/06/1991" />
            <div className={mjes.labelError}>{birthdayError}</div>
          </div>
        </div>
        <div className={mjes.columnGrid}>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Gender</div>
            <div className={styles.genderContainer}>
              <div className={gender === "male" ? mjes.radioButtonSelected : mjes.radioButton} onClick={() => setGender("male")}>Male</div>
              <div className={gender === "female" ? mjes.radioButtonSelected : mjes.radioButton} onClick={() => setGender("female")}>Female</div>
              <div className={gender === "other" ? mjes.radioButtonSelected : mjes.radioButton} onClick={() => setGender("other")}>Other</div>
            </div>
          </div>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Color</div>
            <div className={styles.colorContainer}>
              <div className={color === 0 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(0)}
                style={{ backgroundColor: getColor(0) }}></div>
              <div className={color === 1 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(1)}
                style={{ backgroundColor: getColor(1) }}></div>
              <div className={color === 2 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(2)}
                style={{ backgroundColor: getColor(2) }}></div>
              <div className={color === 3 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(3)}
                style={{ backgroundColor: getColor(3) }}></div>
              <div className={color === 4 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(4)}
                style={{ backgroundColor: getColor(4) }}></div>
            </div>
          </div>
        </div>
        <div className={mjes.textGroupContainer}>
          <div className={rs.labelSmallLight}>Sports</div>
          <div className={mjes.columnGrid}>
            <div className={styles.sportsGrid}>
              <div className={sports.indexOf('basketball') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("basketball")}>Basketball</div>
              <div className={sports.indexOf('baseball') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("baseball")}>Baseball</div>
              <div className={sports.indexOf('football') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("football")}>Football</div>
            </div>
            <div className={styles.sportsGrid}>
              <div className={sports.indexOf('golf') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("golf")}>Golf</div>
              <div className={sports.indexOf('lacrosse') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("lacrosse")}>Lacrosse</div>
              <div className={sports.indexOf('gymnastics') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("gymnastics")}>Gymnastics</div>
            </div>
          </div>
        </div>
        <div className={areUserSettingsValid() ? rs.buttonSecondary : rs.buttonSecondary} style={{ cursor: areUserSettingsValid() ? "pointer" : "not-allowed" }} onClick={() => { addUser() }}>Save</div>
      </div>
    )
  else if (isEditUserOpen)
    return (
      <div className={mjes.pageContentContainer}>
        <div className={styles.modalTitleGrid}>
          <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Edit User</div>
          <div className={mjes.iconButton} style={{ fontWeight: "500", fontSize: "1.25rem" }} onClick={() => setIsEditUserOpen(false)}>
            <Icon icon="x" height="1.5rem" color="white" />
          </div>
        </div>
        <div className={mjes.columnGrid}>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Gender</div>
            <div className={styles.genderContainer}>
              <div className={gender === "male" ? mjes.radioButtonSelected : mjes.radioButton} onClick={() => setGender("male")}>Male</div>
              <div className={gender === "female" ? mjes.radioButtonSelected : mjes.radioButton} onClick={() => setGender("female")}>Female</div>
              <div className={gender === "other" ? mjes.radioButtonSelected : mjes.radioButton} onClick={() => setGender("other")}>Other</div>
            </div>
          </div>
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Color</div>
            <div className={styles.colorContainer}>
              <div className={color === 0 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(0)}
                style={{ backgroundColor: getColor(0) }}></div>
              <div className={color === 1 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(1)}
                style={{ backgroundColor: getColor(1) }}></div>
              <div className={color === 2 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(2)}
                style={{ backgroundColor: getColor(2) }}></div>
              <div className={color === 3 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(3)}
                style={{ backgroundColor: getColor(3) }}></div>
              <div className={color === 4 ? mjes.colorButtonSelected : mjes.colorButton} onClick={() => setColor(4)}
                style={{ backgroundColor: getColor(4) }}></div>
            </div>
          </div>
        </div>
        <div className={mjes.textGroupContainer}>
          <div className={rs.labelSmallLight}>Sports</div>
          <div className={mjes.columnGrid}>
            <div className={styles.sportsGrid}>
              <div className={sports.indexOf('basketball') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("basketball")}>Basketball</div>
              <div className={sports.indexOf('baseball') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("baseball")}>Baseball</div>
              <div className={sports.indexOf('football') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("football")}>Football</div>
            </div>
            <div className={styles.sportsGrid}>
              <div className={sports.indexOf('golf') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("golf")}>Golf</div>
              <div className={sports.indexOf('lacrosse') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("lacrosse")}>Lacrosse</div>
              <div className={sports.indexOf('gymnastics') > -1 ? mjes.radioButtonSelected : mjes.radioButton}
                onClick={() => sportSelected("gymnastics")}>Gymnastics</div>
            </div>
          </div>
        </div>
        <div>
          <div className={rs.buttonSecondary} onClick={() => { submitEditUser() }}>Save</div>
        </div>
      </div>
    )

  return (
    <div className={mjes.pageContentContainer}>
      <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Select User</div>
      <div className={rs.textSmallLight} style={{ textAlign: "center" }}>Choose the active user profile</div>
      <div className={styles.userGrid}>
        {login.users.map((user) => (
          <div className={styles.userCard} key={user._id}>
            <div className={styles.cardIconGrid}>
              <div></div>
              <div className={mjes.iconButton} onClick={() => openEditModal(user)} style={{ display: "inline-block" }}>
                <Icon icon="pencil" height="1rem" width="1rem" color="darkGray" />
              </div>
              {login.user._id !== user._id &&
                <div className={mjes.iconButton} onClick={() => openDeleteWarning(user)} style={{ display: "inline-block" }}>
                  <Icon icon="trashcan" height="1rem" width="1rem" color="darkGray" />
                </div>
              }
            </div>
            <div className={getCardStyle(user.color)} onClick={() => selectUser(user._id)}
              style={{ fontFamily: "bebas_neueregular", color: "black" }}>
              <div className={rs.h1LargeDark}>{user.firstName.substring(0, 1)}</div>
            </div>
            <div className={styles.nameGrid}>
              <div className={rs.labelMediumLight} style={{ textAlign: "center", marginTop: ".25rem" }}>{user.firstName}</div>
            </div>
          </div>
        ))}
        <div className={styles.userCard}>
          <div style={{ textAlign: "right" }}>
            <div className={mjes.iconButton} style={{ display: "inline-block" }}>
              <Icon icon="pencil" height="1rem" width="1rem" color="transparent" />
            </div>
          </div>
          <div className={styles.addCard} onClick={() => openAddUser()}>
            <div>+</div>
          </div>
          <div className={rs.labelMediumLight} style={{ textAlign: "center", marginTop: ".25rem" }}>Add User</div>
        </div>
      </div>
      {/* <div className={styles.autologinGrid}>
        <input className={styles.autologinCheckbox} type="checkbox" checked={isAutoLogin} onChange={(e) => setIsAutoLogin(e.target.checked)} />
        <div className={mjes.label}>Auto-login user</div>
      </div> */}
      <ReactModal className={mjes.modal} overlayClassName={mjes.overlay} isOpen={isDeleteWarningOpen} contentLabel="Discard Request" ariaHideApp={false}>
        <div>
          <div className={mjes.modalHeader}>
            <div className={rs.h1MediumLight}>Delete User</div>
            <div className={mjes.iconButton} onClick={() => setIsDeleteWarningOpen(false)}>
              <Icon icon="x" height="1rem" color="white" />
            </div>
          </div>
          <div>
            <div className={rs.textSmallLight}>Are you sure that you want to delete this user?  You will no longer have access to their videos or anything else associated with their profile.
              To continue, select "Delete" below
            </div>
          </div>
          <div className={rs.buttonSecondary} style={{ marginTop: ".5rem" }} onClick={() => confirmDeleteUser()}>Delete</div>
        </div>
      </ReactModal>
    </div>
  );
}

export default SwitchUser;