import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { getRequest } from '../../../requests/AdminRequests'
import mjes from '../../../mjes-styles/mjes.module.css'
import rs from '../../../styles/ReplayStyles.module.css'
import { getLocalTimeFromUTC } from '../../../helpers/Date'

const AdminRequest = () => {

  const [request, setRequest] = useState()
  const [userFiles, setUserFiles] = useState(null)
  const [reviewerVideoLink, setReviewerVideoLink] = useState(null)
  const [status, setStatus] = useState("processing")

  let { requestId } = useParams();
  const history = useHistory()

  useEffect(() => {
    loadRequest()
  }, [])

  const loadRequest = async () => {
    //
    const response = await getRequest(requestId)
    console.log(response)
    if (response.success) {
      setRequest(response.payload.request)
      setUserFiles(response.payload.requestFiles)
      setReviewerVideoLink(response.payload.reviewerVideoLink)
      setStatus("loaded")
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "#66ff00"
      case "unavailable":
        return "blue"
      case "suspended":
        return "red"
      case "setup":
        return "yellow"
    }
  }

  const settingGroup = {
    margin: "1rem 0"
  }
  const settingSection = {
    margin: ".5rem 0"
  }

  if (status === "processing")
    return (
      <div>Loading</div>
    )

  return (
    <div style={{ padding: ".5rem" }}>
      <div style={{ display: "flex", gap: "2rem" }}>
        <div className={mjes.buttonPrimary} style={{ maxWidth: "10rem", margin: "0" }} onClick={() => history.push('/admin-requests/' + request.reviewerId)}>Active Requests</div>
        <div className={mjes.buttonPrimary} style={{ maxWidth: "10rem", margin: "0" }} onClick={() => history.push('/admin-reviewer/' + request.reviewerId)}>Reviewer Page</div>
      </div>

      <div style={{ margin: "1rem 0" }}>
        <div>
          <div className={rs.h1MediumLight}>General Info</div>
          <div style={settingGroup}>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Request ID</div>
              <div className={rs.textSmallLight}>{request._id}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Coach</div>
              <div className={rs.textSmallLight}>{request.reviewerFirstName + " " + request.reviewerLastName}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>User</div>
              <div className={rs.textSmallLight}>{request.userFirstName + " " + request.userLastName}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Session</div>
              <div className={rs.textSmallLight}>{request.repeatCount + 1}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Public</div>
              <div className={rs.textSmallLight}>{request.isPublic.toString()}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>details</div>
              <div className={rs.textSmallLight}>{request.details}</div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: ".2rem solid gray", margin: ".5rem 0" }}></div>
        <div>
          <div className={rs.h1MediumLight}>Status</div>
          <div style={settingGroup}>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>General</div>
              <div className={rs.textSmallLight}>{request.status}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Process</div>
              <div className={rs.textSmallLight}>{request.processStatus}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Payment</div>
              <div className={rs.textSmallLight}>{request.paymentStatus}</div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: ".2rem solid gray", margin: ".5rem 0" }}></div>
        <div>
          <div className={rs.h1MediumLight}>Price</div>
          <div style={settingGroup}>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>List Price</div>
              <div className={rs.textSmallLight}>{"$ " + request.reviewerPrice}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Total Price</div>
              <div className={rs.textSmallLight}>{"$ " + request.totalPrice}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Service Fee</div>
              <div className={rs.textSmallLight}>{"$ " + request.serviceFee}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Reviewer Take Rate</div>
              <div className={rs.textSmallLight}>{"$ " + request.reviewerTakerate}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Reviewer Take</div>
              <div className={rs.textSmallLight}>{"$ " + request.reviewerPrice * .5}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Promo Code</div>
              <div className={rs.textSmallLight}>{request.promoCode ? request.promoCode : "no promo"}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Discount Rate</div>
              <div className={rs.textSmallLight}>{request.discount}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Supply Referral Code</div>
              <div className={rs.textSmallLight}>{request.supplyReferralCode ? request.supplyReferralCode : "no referral"}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Demand Referral Code</div>
              <div className={rs.textSmallLight}>{request.demandReferralCode ? request.demandReferralCode : "no referral"}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Stripe ID</div>
              <div className={rs.textSmallLight}>{request.paymentId}</div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: ".2rem solid gray", margin: ".5rem 0" }}></div>
        <div>
          <div className={rs.h1MediumLight}>Rating</div>
          <div style={settingGroup}>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Rating</div>
              <div className={rs.textSmallLight}>{request.rating ? request.rating.ratingValue : "no rating"}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Review</div>
              <div className={rs.textSmallLight}>{request.rating ? request.rating.review : "no review"}</div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: ".2rem solid gray", margin: ".5rem 0" }}></div>
        <div>
          <div className={rs.h1MediumLight}>Dates</div>
          <div style={settingGroup}>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Start</div>
              <div className={rs.textSmallLight}>{getLocalTimeFromUTC(request.dateCreated)}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Available to Coach</div>
              <div className={rs.textSmallLight}>{getLocalTimeFromUTC(request.dateAvailable)}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Due</div>
              <div className={rs.textSmallLight}>{getLocalTimeFromUTC(request.dateDue)}</div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Completed</div>
              <div className={rs.textSmallLight}>{getLocalTimeFromUTC(request.dateCompleted)}</div>
            </div>
          </div>
        </div>
        <div style={{ borderBottom: ".2rem solid gray", margin: ".5rem 0" }}></div>
        <div>
          <div className={rs.h1MediumLight}>Videos</div>
          <div style={settingGroup}>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Upload Videos</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {userFiles.map((file) =>
                  <div style={{ width: "18rem" }}>
                    <video width="100%" crossOrigin="anonymous" controls controlsList="nodownload" >
                      <source src={file.videoUrl} type="video/mp4" />
                    </video>
                  </div>
                )}
              </div>
            </div>
            <div className={settingSection}>
              <div className={rs.labelMediumLight}>Replay Video</div>
              <div>
                {reviewerVideoLink &&
                  <div style={{ width: "18rem" }}>
                    <video width="100%" crossOrigin="anonymous" controls controlsList="nodownload" >
                      <source src={reviewerVideoLink} type="video/mp4" />
                    </video>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminRequest