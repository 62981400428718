import React, { useState, useEffect, useCallback } from 'react'
import Icon from '../../icons/Icon'
import mjes from '../../mjes-styles/mjes.module.css'

const VideoNote = (props) => {

  const note = props.note
  const deleteNote = props.deleteNote

  const [isEdit, setIsEdit] = useState(false)
  const [text, setText] = useState("")

  useEffect(() => {
    console.log(note.text)
    setText(note.text)
    console.log(note.isNew)
    setIsEdit(note.isNew)
    return () => {
      window.removeEventListener("keydown", keyPressed, true);
    }
  }, [])

  const updateNote = async () => {
    console.log(note.id)
    console.log(note.text)
    console.log("updating note: " + note.id)
    await props.updateNote(note.id, text)
    setIsEdit(false)
  }

  const convertSecondsToTimecode = (time) => {
    const durationMs = time % 1;
    const durationFrames = Math.floor(durationMs * 1000 / 33);
    const totalSeconds = Math.floor(time);
    const durationMinutes = Math.floor(totalSeconds / 60);
    const durationSeconds = totalSeconds - (durationMinutes * 60);
    let frameString = durationFrames < 10 ? "0" + durationFrames : durationFrames;
    let secondString = durationSeconds < 10 ? "0" + durationSeconds : durationSeconds;
    let minuteString = durationMinutes < 10 ? "0" + durationMinutes : durationMinutes;
    return minuteString + ":" + secondString + ":" + frameString;
  }

  const onFocus = () => {
    props.onFocus()
  }

  const onLoseFocus = () => {
    console.log("losing focus")
    props.onLoseFocus()
  }

  const keyPressed = async (event) => {
    console.log("note key pressed")
    console.log(event.key)
    switch (event.key) {
      case "Enter":
        //event.preventDefault();
        await updateNote()
        props.onLoseFocus()
    }
  }

  console.log(isEdit)

  const editMode = () => {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className={mjes.label_s} style={{ flexGrow: 1 }}>{convertSecondsToTimecode(note.time)}</div>
          <div onClick={() => updateNote()} style={{ margin: " 0 .25rem" }}>
            <Icon icon="checkmark" color="lightgreen" height="1rem" />
          </div>
          <div onClick={() => deleteNote(note.id)} style={{ margin: " 0 .25rem" }}>
            <Icon icon="x" color="red" height="1rem" />
          </div>
        </div>
        <textarea className={mjes.textInput} style={{ fontSize: "1rem" }} type="text" value={text} onChange={(e) => setText(e.target.value)}
          onFocus={() => onFocus()} onBlur={() => onLoseFocus()} autoFocus onKeyDown={(e) => keyPressed(e)} />
      </div>
    )
  }

  const displayMode = () => {
    return (
      <div onClick={() => props.noteOnClick(note)} style={{ cursor: "pointer" }}>
        <div style={{ display: "flex" }}>
          <div className={mjes.label_s} style={{ flexGrow: 1 }}>{convertSecondsToTimecode(note.time)}</div>
          <div onClick={() => setIsEdit(true)} style={{ margin: " 0 .25rem" }}>
            <Icon icon="pencil" color="lightyellow" height="1rem" />
          </div>
          <div onClick={() => deleteNote(note.id)} style={{ margin: " 0 .25rem" }}>
            <Icon icon="x" color="red" height="1rem" />
          </div>
        </div>
        <div className={mjes.text_s} style={{ wordWrap: "break-word" }}>{text}</div>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: "#1a1a1a", padding: ".5rem", margin: ".5rem 0" }}>
      {isEdit ?
        editMode() :
        displayMode()
      }
    </div>
  )

}

export default VideoNote