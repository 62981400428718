import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import { validateName, 
  validateEmail, 
  validatePassword, 
  validatePrice, 
  validateImage } from '../input-validation/InputValidation';

export const ReviewerProfileSlice = createSlice({
  name: 'reviewerProfile',
  initialState: {
    reviewer: null,
    imageUrl: "",
    status: "processing", //idle, fetching, success, error, uploading-video    
    error: "",
    pictureStatus: "idle",
    pictureError: null,
    contentStatus: "idle",
    contentError: null,
  },
  reducers: {
    ReviewerProfileProcessing: (state) => {
      state.status = "processing";
      state.error = "";
    },
    ReviewerProfileSuccess: (state, action) => {
      state.reviewer = action.payload.reviewer;
      state.imageUrl = action.payload.imageUrl;
      state.status = "success";
      state.error = "";
    },
    ReviewerUpdate: (state, action) => {
      state.reviewer = action.payload;
      state.status = "success";
      state.error = "";
    },
    ReviewerProfileError: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    },
    PictureProcessing: (state) => {
      state.pictureStatus = "processing";
      state.pictureError = "";
    },
    PictureSuccess: (state, action) => {
      state.imageUrl = action.payload.imageUrl;
      state.pictureStatus = "success";
      state.pictureError = "";
    },
    PictureError: (state, action) => {
      state.pictureStatus = "error";
      state.pictureError = action.payload;
    },
    PictureReset: (state, action) => {
      state.pictureStatus = "idle";
    },
    ContentProcessing: (state) => {
      state.contentStatus = "processing";
      state.contentError = "";
    },
    ContentSuccess: (state, action) => {
      state.reviewer = action.payload
      state.contentStatus = "success";
      state.contentError = "";
    },
    ContentError: (state, action) => {
      state.contentStatus = "error";
      state.contentError = action.payload;
    },
    ContentReset: (state, action) => {
      state.contentStatus = "idle";
    }
  }
});

export const {
  ReviewerProfileProcessing,
  ReviewerProfileSuccess,
  ReviewerUpdate,
  ReviewerProfileError,
  PictureProcessing, PictureSuccess, PictureError, PictureReset,
  ContentProcessing, ContentSuccess, ContentError, ContentReset
} = ReviewerProfileSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const FetchReviewerProfile = (reviewerId) => async dispatch => {

  dispatch(ReviewerProfileProcessing());

  try {
    let response = await fetch(getEndpoint() +"reviewer/get-reviewer", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ reviewerId: reviewerId })
    });

    response = await response.json();
    if (response.success) {
      const reviewer = response.payload.reviewer;
      const imageUrl = response.payload.imageUrl;
      dispatch(ReviewerProfileSuccess({reviewer:reviewer, imageUrl:imageUrl}));
    }
    else {
      dispatch(ReviewerProfileError(response.message));
    }
  }
  catch (error) {
    dispatch(ReviewerProfileError(error));
  }
}

export const UpdateProfilePicture = (image) => async dispatch => {

  dispatch(PictureProcessing());

  if(!validateImage(image)) {
    dispatch(PictureError('Must submit a profile image'));
    return;
  }

  try {
    let response = await fetch(getEndpoint() +"reviewer/update-profile-picture", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({})
    });

    response = await response.json();
    if (response.success) {
      const imageUrl = response.payload.imageUrl;
      const uploadData = response.payload.uploadData;

      //upload image to s3 bucket
      let form = new FormData();
      Object.keys(uploadData.fields).forEach(key => form.append(key, uploadData.fields[key]));
      form.append('file', image);
      await fetch(uploadData.url, { method: 'POST', body: form });

      dispatch(PictureSuccess({imageUrl:imageUrl}));
    }
    else {
      console.log(response)
      dispatch(PictureError(response.message));
    }
  }
  catch (error) {
    console.log(error)
    dispatch(PictureError(error));
  }
}

export const UpdateProfileContent = (price, title, aboutMe, feature1, feature2, feature3, videoGuidelines) => async dispatch => {

  dispatch(ContentProcessing());

  try {
    let response = await fetch(getEndpoint() +"reviewer/update-profile-content", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ 
        price: price,
        title: title,
        aboutMe: aboutMe,
        feature1: feature1,
        feature2: feature2,
        feature3: feature3,
        videoGuidelines: videoGuidelines 
      })
    });

    response = await response.json();
    if (response.success) {
      const reviewer = response.payload.reviewer;
      dispatch(ContentSuccess(reviewer));
    }
    else {
      console.log(response.message)
      toast.error(response.message)
      dispatch(ContentError(response.message));
    }
  }
  catch (error) {
    console.log(error)
    toast.error(error)
    dispatch(ContentError(error));
  }
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectReviewerProfile = state => state.reviewerProfile;

export default ReviewerProfileSlice.reducer;
