import React, { useState } from "react"
import { useHistory } from "react-router";
import { Logout } from "../../slices/LoginSlice";
import { useDispatch } from "react-redux";
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './NavMenu.module.css'
import SportsIcon from '@mui/icons-material/Sports';
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ApprovalIcon from '@mui/icons-material/Approval';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';

const NavMenu = (props) => {

  const isLoggedIn = props.isLoggedIn
  const user = props.user
  const role = props.role
  const status = props.status
  const firstName = props.firstName
  const isMenuOpen = props.isMenuOpen
  const setIsMenuOpen = props.setIsMenuOpen
  const history = useHistory()

  const outsideClick = () => {
    //props.setIsMenuOpen(false)
  }

  const getMenu = () => {
    if (isLoggedIn) {
      if (role === "user") {
        return (<UserMenu setIsMenuOpen={setIsMenuOpen} user={user} />)
      }
      else if (role === "reviewer")
        return (<ReviewerMenu setIsMenuOpen={setIsMenuOpen} status={status} const firstName={firstName} />)
      else if (role === "admin")
        return (<AdminMenu setIsMenuOpen={setIsMenuOpen} />)
    }
    else {
      //return (<div></div>)
      return (<GuestMenu setIsMenuOpen={setIsMenuOpen} />)
    }
  }

  if (props.isMenuOpen) {
    return (
      <OutsideClickHandler onOutsideClick={() => outsideClick()} >
        {getMenu()}
      </OutsideClickHandler>
    )
  }
  else {
    return <div></div>
  }
}

export default NavMenu

const GuestMenu = (props) => {
  const setIsMenuOpen = props.setIsMenuOpen
  const history = useHistory()

  return (
    <div className={styles.navMenu}>
      <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
        <SportsIcon style={{ color: "white" }} />
        <div onClick={() => {
          history.push('/golf')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Find A Coach</div>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
        <HomeIcon style={{ color: "white" }} />
        <div onClick={() => {
          history.push('/')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Home</div>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
        <ContactSupportIcon style={{ color: "white" }} />
        <div onClick={() => {
          history.push('/contact-us')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Contact Us</div>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
        <ApprovalIcon style={{ color: "white" }} />
        <div onClick={() => {
          history.push('/signup-reviewer')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Apply To Coach</div>
        </div>
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", justifyContent: "center", marginTop: "1rem" }}>
        <div className={styles.actionButtons} onClick={() => {
          history.push('/login')
          setIsMenuOpen(false)
        }}>Login</div>
        <div className={styles.actionButtons} onClick={() => {
          history.push('/signup-user')
          setIsMenuOpen(false)
        }}>Sign Up</div>
      </div>

    </div>
  )
}

const UserMenu = (props) => {

  const setIsMenuOpen = props.setIsMenuOpen
  const user = props.user
  const history = useHistory()
  const dispatch = useDispatch();

  const getColor = () => {
    switch (user.color) {
      case 0:
        return "#147efb" //blue
      case 1:
        return "#ff14af" //pink
      case 2:
        return "#11d465" //green
      case 3:
        return "#b245e6" //purple
      case 4:
        return "#ff9819" //orange
    }
  }

  return (
    <div className={styles.navMenu}>
      <div style={{ display: "grid", gridTemplateColumns: "3rem 1fr", gap: ".5rem", margin: ".5rem", alignItems: "center" }}>
        <div className={styles.userSquare} style={{ backgroundColor: getColor() }}>
        </div>
        <div className={styles.userName}>{user.firstName}</div>
      </div>
      <div style={{ marginLeft: "3.5rem" }}>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <OndemandVideoIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/profile/requests')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Requests</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <SlowMotionVideoIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/profile/videos')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Replays</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <NotificationsIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/profile/messages')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Notifications</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <PersonIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/switch-user')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Switch User</div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <SportsIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/golf')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Find A Coach</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <HomeIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Home</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <ContactSupportIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/contact-us')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Contact Us</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <ApprovalIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/signup-reviewer')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Apply To Coach</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", justifyContent: "center", marginTop: "1rem" }}>
          <div></div>
          <div className={styles.actionButtons} onClick={() => {
            dispatch(Logout())
            setIsMenuOpen(false)
          }}>Log Out</div>
        </div>
      </div>
    </div>
  )
}

const ReviewerMenu = (props) => {
  const setIsMenuOpen = props.setIsMenuOpen
  const status = props.status
  const firstName = props.firstName
  const history = useHistory()
  const dispatch = useDispatch();

  const getColor = () => {
    return "#147efb" //blue
  }

  const getReviewerLinks = () => {
    switch (status) {
      case "application":
        return (
          <div>
            <div onClick={() => {
              history.push('/application-status')
              setIsMenuOpen(false)
            }}>
              <div className={styles.navItem}>Application</div>
            </div>
          </div>
        )
      case "setup":
        return (
          <div>
            <div onClick={() => {
              history.push('/setup-profile')
              setIsMenuOpen(false)
            }}>
              <div className={styles.navItem}>Setup Account</div>
            </div>
          </div>
        )
      case "setup":
        return (
          <div>
            <div onClick={() => {
              history.push('/setup-picture')
              setIsMenuOpen(false)
            }}>
              <div className={styles.navItem}>Setup Account</div>
            </div>
          </div>
        )
      case "setup":
        return (
          <div>
            <div onClick={() => {
              history.push('/setup-payment')
              setIsMenuOpen(false)
            }}>
              <div className={styles.navItem}>Setup Account</div>
            </div>
          </div>
        )
      default:
        return (
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
              <OndemandVideoIcon style={{ color: "white" }} />
              <div onClick={() => {
                history.push('/reviewer-profile/requests')
                setIsMenuOpen(false)
              }}>
                <div className={styles.navItem}>Requests</div>
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
              <SlowMotionVideoIcon style={{ color: "white" }} />
              <div onClick={() => {
                history.push('/reviewer-profile/videos')
                setIsMenuOpen(false)
              }}>
                <div className={styles.navItem}>Replays</div>
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
              <NotificationsIcon style={{ color: "white" }} />
              <div onClick={() => {
                history.push('/reviewer-profile/messages')
                setIsMenuOpen(false)
              }}>
                <div className={styles.navItem}>Notifications</div>
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
              <PersonIcon style={{ color: "white" }} />
              <div onClick={() => {
                history.push('/reviewer-profile/profile')
                setIsMenuOpen(false)
              }}>
                <div className={styles.navItem}>Profile</div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <div className={styles.navMenu}>
      <div style={{ display: "grid", gridTemplateColumns: "3rem 1fr", gap: ".5rem", margin: ".5rem", alignItems: "center" }}>
        <div className={styles.userSquare} style={{ backgroundColor: getColor() }}>
        </div>
        <div className={styles.userName}>{firstName}</div>
      </div>
      <div style={{ marginLeft: "3.5rem" }}>
        {getReviewerLinks()}
      </div>
      <div style={{ marginTop: "2rem" }}>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <SportsIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/golf')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Find A Coach</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <HomeIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Home</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <ContactSupportIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/contact-us')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Contact Us</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
          <ApprovalIcon style={{ color: "white" }} />
          <div onClick={() => {
            history.push('/signup-reviewer')
            setIsMenuOpen(false)
          }}>
            <div className={styles.navItem}>Apply To Coach</div>
          </div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", justifyContent: "center", marginTop: "1rem" }}>
          <div></div>
          <div className={styles.actionButtons} onClick={() => {
            dispatch(Logout())
            setIsMenuOpen(false)
          }}>Log Out</div>
        </div>
      </div>
    </div>
  )
}

const AdminMenu = (props) => {
  const setIsMenuOpen = props.setIsMenuOpen
  const history = useHistory()
  const dispatch = useDispatch();

  const getColor = () => {
    return "#ff9819" //orange
  }

  return (
    <div className={styles.navMenu}>
      <div style={{ display: "grid", gridTemplateColumns: "3rem 1fr", gap: ".5rem", margin: ".5rem", alignItems: "center" }}>
        <div className={styles.userSquare} style={{ backgroundColor: getColor() }}>
        </div>
        <div className={styles.userName}>Admin</div>
      </div>
      <div style={{ marginLeft: "3.5rem" }}>
        <div onClick={() => {
          history.push('/reviewer-applications')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Applications</div>
        </div>
        <div onClick={() => {
          history.push('/admin-reviewers')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Coaches</div>
        </div>
        <div onClick={() => {
          history.push('/admin-requests')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Requests</div>
        </div>
        <div onClick={() => {
          history.push('/create-coach')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Create Coach</div>
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <div onClick={() => {
          history.push('/golf')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Find A Coach</div>
        </div>
        <div onClick={() => {
          history.push('/home')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Home</div>
        </div>
        <div onClick={() => {
          history.push('/contact-us')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Contact Us</div>
        </div>
        <div onClick={() => {
          history.push('/signup-reviewer')
          setIsMenuOpen(false)
        }}>
          <div className={styles.navItem}>Apply To Coach</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", justifyContent: "center", marginTop: "1rem" }}>
          <div></div>
          <div className={styles.actionButtons} onClick={() => {
            dispatch(Logout())
            setIsMenuOpen(false)
          }}>Log Out</div>
        </div>
      </div>
    </div>
  )
}