import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const requestBetaAccess = async (email) => {
  let response = await fetch(getEndpoint() + "beta/beta-signup", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      email: email
    })
  });

  response = await response.json();
  return response
}