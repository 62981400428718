import React, { useState, useEffect } from 'react'
import { requestBetaAccess } from '../../requests/BetaRequests'
import { useMediaQuery } from 'react-responsive';
import mjes from '../../mjes-styles/mjes.module.css'
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import rs from '../../styles/ReplayStyles.module.css'


const BetaSignup = (props) => {

  const [email, setEmail] = useState("")
  const [honeypotPassword, setHoneypotPassword] = useState("")
  const [status, setStatus] = useState("awaiting")
  const [error, setError] = useState(null)

  const isSmall = true //useMediaQuery({ query: '(max-width: 28rem)' })

  useEffect(() => {
    const signupEmail = localStorage.beta_signup_email;
    if (signupEmail) {
      setEmail(signupEmail)
      setStatus("returning")
    }
  }, [])

  const submit = async () => {

    setStatus("processing")
    setError(null)

    if (honeypotPassword !== "") {
      console.log("ROBOT ALERT")
      setStatus("awaiting")
      setError("spam signup detected")
      return
    }

    try {
      const response = await requestBetaAccess(email)
      if (response.success) {
        localStorage.setItem('beta_signup_email', email);
        setStatus("success")
        //ReactPixel.trackCustom("email-signup", {})
        ReactGA.event({
          category: 'User Events',
          action: 'email_signup',
          label: 'Email Signup'
        });

        TagManager.dataLayer({
          dataLayer: {
            event: "email_signup"
          }
        })
        props.acceptEmailSignup()
        return
      }
      else {
        setStatus("awaiting")
        setError(response.message)
        return
      }
    }
    catch (err) {
      setStatus("awaiting")
      setError(err.message)
      return
    }
  }

  const getSignupForm = () => {

    if (status === "success") {
      return (
        <></>
      )
    }
    else if (status === "returning")
      return (
        <></>
      )
    else {
      return (
        <div style={{ maxWidth: "40rem", margin: "auto" }}>
          {isSmall ?
            <div>
              <div className={rs.labelMediumLight}>Be The First To Know!</div>
              <div className={rs.textSmallLight} style={{margin:".25rem 0 0 0 "}}>Get access to exclusive deals</div>
              <div style={{ display: "flex", alignItems:"center" }}>
                <input className={mjes.textInput} style={{ marginRight: ".5rem", marginTop: ".25rem", fontSize: "1rem" }} type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <div className={rs.buttonSecondary} style={{ padding: ".4rem 1rem" }} onClick={() => submit()}>
                  <svg viewBox="0 0 24 24" width="20" height="20" strokeWidth="2" fill="none" stroke="white">
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </div>
              </div>
              <input type="text" name="password" style={{ display: "none" }} tabIndex="-1" autoComplete="off" value={honeypotPassword} onChange={(e) => setHoneypotPassword(e.target.value)} />
              <div className={mjes.text_xs} style={{ margin: ".25rem 0" }}>
                <div>emails subject to <span style={{ cursor: "pointer" }} onClick={() => window.open('https://www.thereplayapp.com/privacy-policy')}><u>Privacy Policy</u></span></div>
              </div>
            </div> :
            <div style={{ display: "flex" }}>
              <input className={mjes.textInput} style={{ marginRight: ".5rem" }} type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <input type="text" name="password" style={{ display: "none" }} tabIndex="-1" autoComplete="off" value={honeypotPassword} onChange={(e) => setHoneypotPassword(e.target.value)} />
              <div className={mjes.buttonPrimary} style={{ width: "10rem" }} onClick={() => submit()} >Learn More</div>
            </div>
          }
          <div className={mjes.labelError} >{error}</div>
        </div>
      )
    }

  }

  return getSignupForm()
}

export default BetaSignup