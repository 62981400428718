import React, { useEffect, useRef, useState, useCallback } from 'react';
//libraries
import { useHistory } from 'react-router-dom'
import { getDimensions } from '../../helpers/Dimensions';
import { useViewport } from '../../hooks/useViewport';
import { useInterval } from '../../hooks/useInterval';
import ReactTooltip from 'react-tooltip';
import ReactModal from 'react-modal'
import { v4 as uuidv4 } from 'uuid';
//state
//components
import IconButton from '../../icons/IconButton';
//images
import Logo from '../../images/logo/Replay_Logo.png'
//styles
import styles from './CreateVideo.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import layout from '../../app-styles/Layout.module.css'
import elements from '../../app-styles/Elements.module.css'
import Icon from '../../icons/Icon';
import { toast } from 'react-toastify';
import VideoNote from '../video-note/VideoNote';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpIcon from '@mui/icons-material/Help';


function CreateVideo(props) {

  const request = props.request
  const requestFiles = props.requestFiles
  const history = useHistory()

  const source1 = requestFiles.find(rf => rf.position === 1)
  const source2 = requestFiles.find(rf => rf.position === 2)
  const source3 = requestFiles.find(rf => rf.position === 3)
  const source4 = requestFiles.find(rf => rf.position === 4)

  //state
  const [isVideo1Loaded, setIsVideo1Loaded] = useState(false);
  const [isVideo2Loaded, setIsVideo2Loaded] = useState(false);
  const [isVideo3Loaded, setIsVideo3Loaded] = useState(false);
  const [isVideo4Loaded, setIsVideo4Loaded] = useState(false);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [video1Duration, setVideo1Duration] = useState(null);
  const [video2Duration, setVideo2Duration] = useState(null);
  const [video3Duration, setVideo3Duration] = useState(null);
  const [video4Duration, setVideo4Duration] = useState(null);
  const [isWebcamLoaded, setIsWebcamLoaded] = useState(true);
  const [activeTool, setActiveTool] = useState('line');
  const [activeToolStatus, setActiveToolStatus] = useState('awaiting');
  const [drawMousePosition, setDrawMousePosition] = useState(null);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [isFrameScrubbing, setIsFrameScrubbing] = useState(false);
  const [frameScrubStartX, setFrameScrubStartX] = useState(null);
  const [frameScrubStartDuration, setFrameScrubStartDuration] = useState(null);
  const [frameOffset, setFrameOffset] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [color, setColor] = useState('green');
  const [recDuration, setRecDuration] = useState(0);
  const [recStartDate, setRecStartDate] = useState(null);
  const [isFirstWarning, setIsFirstWarning] = useState(false)
  const [isSecondWarning, setIsSecondWarning] = useState(false)
  const [cameraPosition, setCameraPosition] = useState('top-left');
  const [speed, setSpeed] = useState(1);
  const [webcamLoaded, setWebcamLoaded] = useState(false);
  const [isRequestDescOpen, setIsRequestDescOpen] = useState(true)
  const [notes, setNotes] = useState([])
  const [selectedClip, setSelectedClip] = useState(1)
  const selectedClipRef = useRef(selectedClip)

  //get viewport
  const { width, height } = useViewport();

  //refs
  const previewRef = useRef(null);
  const recordingRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const scrubRef = useRef(null);
  const drawingRef = useRef(null);
  const webcamRef = useRef(null);
  const frameScrubRef = useRef(null);
  const stageDrawingRef = useRef(null);
  const laserRef = useRef(null);
  const logoRef = useRef(null)

  const getActiveClipRef = () => {
    switch (selectedClipRef.current) {
      case 1:
        return videoRef1
      case 2:
        return videoRef2
      case 3:
        return videoRef3
      case 4:
        return videoRef4
    }
  }
  const videoRef = getActiveClipRef()

  //calculate canvas dimensions
  const remPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const headerHeight = 2 * remPx;
  const topBarHeight = 1.5 * remPx;
  const bottomBarHeight = 4 * remPx;
  const leftBarWidth = 9 * remPx;
  const rightBarWidth = 9 * remPx;
  const availHeight = height - headerHeight - topBarHeight - bottomBarHeight;
  const availWidth = width - leftBarWidth - rightBarWidth;
  const canvasDimensions = getDimensions(availHeight, availWidth);
  //const frameScrubWidth = canvasDimensions.width;
  const scrubWidth = canvasDimensions.width - 3 * remPx;
  const scrubHeight = 2.5 * remPx;
  const frameScrubWidth = scrubWidth;

  useEffect(() => {
    window.addEventListener("mousemove", globalMouseMove);
    window.addEventListener("mouseup", globalMouseUp);
    //window.addEventListener("keydown", keyPressed, true);
    let stream = null;

    (async () => {
      //load webcam
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        webcamRef.current.srcObject = stream;
        webcamRef.current.onloadedmetadata = function (e) {
          setWebcamLoaded(true);
          webcamRef.current.play();
        };
      } catch (err) {
        /* handle the error */
      }
    })();

    return () => {
      window.removeEventListener("mousemove", globalMouseMove);
      window.removeEventListener("mouseup", globalMouseUp);
      window.removeEventListener("keydown", keyPressed, true);
      try {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      catch { }
    };
  }, []);

  useEffect(() => {
    loadNotes()
  }, [])

  useInterval(() => {
    update();
  }, 1000.00 / 30.00);

  const update = () => {
    if (!getIsVideoLoaded())
      return

    //get video play status
    if (videoRef.paused)
      setIsVideoPlaying(false);

    if (isRecording) {
      const recDur = (Date.now() - recStartDate) / 1000
      if (recDur >= 60 * 10) {
        mediaRecorder.stop()
        return
      }
      else if (recDur >= 60 * 9.5) {
        if (!isSecondWarning)
          setIsSecondWarning(true)
      }
      else if (recDur >= 60 * 9) {
        if (!isFirstWarning)
          setIsFirstWarning(true)
      }
      setRecDuration(Date.now() - recStartDate);
    }
    //get dom elements
    const video = videoRef.current;
    const preview = previewRef.current;
    const recording = recordingRef.current;
    const drawing = drawingRef.current;
    const stageDrawing = stageDrawingRef.current;
    const laser = laserRef.current;
    const webcam = webcamRef.current;
    const previewContext = preview.getContext('2d');
    const recordingContext = recording.getContext('2d');

    //draw recording context
    recordingContext.clearRect(0, 0, 1080, 1920);
    if (cameraPosition === "full") {
      recordingContext.fillRect(0, 0, 1080, 1920);
      recordingContext.font = "32px Arial white";
      recordingContext.fillText("The Replay App", 50, 50);
    }
    else
      recordingContext.drawImage(video, 0, 0, 1080, 1920);

    const webcamWidth = webcam.videoWidth;
    const webcamHeight = webcam.videoHeight;
    const effectiveWidth = 540;
    const effectiveHeight = effectiveWidth * webcamHeight / webcamWidth;
    switch (cameraPosition) {
      case 'top-left':
        recordingContext.drawImage(webcam, 0, 0, effectiveWidth, effectiveHeight);
        break;
      case 'top-right':
        recordingContext.drawImage(webcam, 1080 - effectiveWidth, 0, effectiveWidth, effectiveHeight);
        break;
      case 'bottom-left':
        recordingContext.drawImage(webcam, 0, 1920 - effectiveHeight, effectiveWidth, effectiveHeight);
        break;
      case 'bottom-right':
        recordingContext.drawImage(webcam, 1080 - effectiveWidth, 1920 - effectiveHeight, effectiveWidth, effectiveHeight);
        break;
      case 'full':
        const fullWidth = 1080;
        const fullHeight = fullWidth * webcamHeight / webcamWidth;
        recordingContext.drawImage(webcam, 0, 360, 1080, fullHeight);
        break;
      case 'hide':
        break;
    }

    //draw laser pointer
    switch (activeTool) {
      case "laser":
        if (activeToolStatus === "drawing") {
          const laserContext = laser.getContext('2d');
          laserContext.clearRect(0, 0, 1080, 1920);
          const startX = drawMousePosition.x * 1080 / canvasDimensions.width;
          const startY = drawMousePosition.y * 1920 / canvasDimensions.height;
          drawLaser(laserContext, startX, startY, 10);
          recordingContext.drawImage(laser, 0, 0, 1080, 1920);
        }
    }

    //draw to record
    recordingContext.drawImage(drawing, 0, 0, 1080, 1920);
    recordingContext.drawImage(stageDrawing, 0, 0, 1080, 1920);


    //add watermark
    recordingContext.drawImage(logoRef.current, 0, 1800, 300, 150);


    //draw preview context
    previewContext.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);
    //draw watermark
    previewContext.drawImage(recording, 0, 0, canvasDimensions.width, canvasDimensions.height);
    const videoPosition = video.currentTime;
    drawScrub(videoPosition);
    //drawFrameScrub(frameOffset);
  }

  const getIsVideoLoaded = () => {
    switch (selectedClip) {
      case 1:
        return isVideo1Loaded
      case 2:
        return isVideo2Loaded
      case 3:
        return isVideo3Loaded
      case 4:
        return isVideo4Loaded
    }
  }

  const getColor = () => {
    switch (color) {
      case "blue":
        return "#4353FF"
      case "pink":
        return "#ff14af"
      case "green":
        return "#CEFD14"
      case "red":
        return "#F21D2F"
      case "yellow":
        return "#EEF345"
    }
  }

  //keybinds
  const keyPressed = useCallback((event) => {

    event.preventDefault();

    const selectedVideoRef = getActiveClipRef()

    switch (event.code) {
      case "Space":
        if (selectedVideoRef.current.paused)
          playVideo();
        else
          pauseVideo();
        break;
      case "Escape":
        clearDrawing();
        break;
      case "Digit1":
        changeTool('laser')
        break;
      case "Digit2":
        changeTool('draw')
        break;
      case "Digit3":
        changeTool('line')
        break;
      case "Digit4":
        changeTool('circle')
        break;
      case "Digit5":
        changeTool('arrow')
        break;
      case "ArrowLeft":
      case "KeyA":
        moveFrameBack();
        break;
      case "KeyD":
      case "ArrowRight":
        moveFrameForward();
        break;
      case "KeyQ":
        moveSecondBack();
        break;
      case "KeyE":
        moveSecondForward();
        break;
      case "KeyQ":
        moveSecondBack();
        break;
      case "KeyE":
        moveSecondForward();
        break;
    }
  }, [])


  const onClipSelected = (clipNumber) => {
    pauseVideo()
    selectedClipRef.current = clipNumber
    setSelectedClip(clipNumber)
  }

  //video events
  const videoLoaded1 = (e) => {
    videoRef1.current.currentTime = 0.01; //get video to render first frame to canvas
    videoRef1.current.currentTime = 0;
    setIsVideo1Loaded(true);
  }

  const videoLoaded2 = (e) => {
    videoRef2.current.currentTime = 0.01; //get video to render first frame to canvas
    videoRef2.current.currentTime = 0;
    setIsVideo2Loaded(true);
  }

  const videoLoaded3 = (e) => {
    videoRef3.current.currentTime = 0.01; //get video to render first frame to canvas
    videoRef3.current.currentTime = 0;
    setIsVideo3Loaded(true);
  }

  const videoLoaded4 = (e) => {
    videoRef4.current.currentTime = 0.01; //get video to render first frame to canvas
    videoRef4.current.currentTime = 0;
    setIsVideo4Loaded(true);
  }

  const video1MetadataLoaded = (e) => {
    setVideo1Duration(videoRef1.current.duration)
  }

  const video2MetadataLoaded = (e) => {
    setVideo2Duration(videoRef2.current.duration)
  }

  const video3MetadataLoaded = (e) => {
    setVideo3Duration(videoRef3.current.duration)
  }

  const video4MetadataLoaded = (e) => {
    setVideo4Duration(videoRef4.current.duration)
  }

  const playVideo = (e) => {
    const vRef = getActiveClipRef()
    vRef.current.play();
  }

  const pauseVideo = (e) => {
    const vRef = getActiveClipRef()
    vRef.current.pause();
  }

  const videoPlaying = (e) => {
    setIsVideoPlaying(true);
  }

  const videoPaused = (e) => {
    setIsVideoPlaying(false);
  }

  const getActiveClipDuration = () => {
    switch (selectedClipRef.current) {
      case 1:
        return video1Duration
      case 2:
        return video2Duration
      case 3:
        return video3Duration
      case 4:
        return video4Duration
    }
  }

  //recording
  async function startRecording() {

    //setCameraPosition("full")

    const recording = recordingRef.current;
    var videoStream = recording.captureStream(30.00);

    const stream = webcamRef.current.srcObject;  //await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    videoStream.addTrack(stream.getAudioTracks()[0])

    setIsRecording(true);
    setRecStartDate(Date.now());

    const startDate = Date.now()

    const data = [];

    var options = {
      //audioBitsPerSecond: 128000,
      videoBitsPerSecond: 5000000,
      //mimeType: "video/webm; codecs=vp9"
      mimeType: 'video/webm;codecs=h264'
    }
    const recorder = new MediaRecorder(videoStream, options);

    recorder.ondataavailable = function (e) {
      data.push(e.data);
    };
    recorder.onstop = function (e) {
      var blob = new Blob(data, { 'type': 'video/webm' });
      blob.lastModifiedDate = new Date();
      blob.name = "file-name";
      var encodedURL = URL.createObjectURL(blob);
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });

      const recordingLength = (Date.now() - startDate) / 1000
      props.videoFinished(blob, encodedURL, recordingLength);
    };
    recorder.start(30);
    setMediaRecorder(recorder);
  }

  function stopRecording() {
    setIsRecording(false);
    mediaRecorder.stop();
  }

  //draw events
  const previewMouseDown = (e) => {
    switch (activeTool) {
      case 'laser':
        setActiveToolStatus('drawing')
        setDrawMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        break;
      case 'draw':
        setActiveToolStatus('drawing')
        setDrawMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        break;
      case 'line':
        setActiveToolStatus('drawing')
        setDrawMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        break;
      case 'circle':
        setActiveToolStatus('drawing')
        setDrawMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        break;
    }
  }

  const previewMouseMove = (e) => {
    switch (activeTool) {
      case 'laser':
        if (activeToolStatus === 'drawing') {
          setDrawMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        }
        break;
      case 'draw':
        if (activeToolStatus === 'drawing') {
          const drawingContext = drawingRef.current.getContext('2d');
          const startX = drawMousePosition.x * 1080 / canvasDimensions.width;
          const startY = drawMousePosition.y * 1920 / canvasDimensions.height;
          const endX = e.nativeEvent.offsetX * 1080 / canvasDimensions.width;
          const endY = e.nativeEvent.offsetY * 1920 / canvasDimensions.height;
          drawLine(drawingContext, startX, startY, endX, endY);
          setDrawMousePosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        }
        break;
      case 'line':
        if (activeToolStatus === 'drawing') {
          const stageDrawingContext = stageDrawingRef.current.getContext('2d');
          const startX = drawMousePosition.x * 1080 / canvasDimensions.width;
          const startY = drawMousePosition.y * 1920 / canvasDimensions.height;
          const endX = e.nativeEvent.offsetX * 1080 / canvasDimensions.width;
          const endY = e.nativeEvent.offsetY * 1920 / canvasDimensions.height;
          clearStageDrawing()
          drawLine(stageDrawingContext, startX, startY, endX, endY);
        }
        break;
      case 'circle':
        if (activeToolStatus === 'drawing') {
          const stageDrawingContext = stageDrawingRef.current.getContext('2d');
          const startX = drawMousePosition.x * 1080 / canvasDimensions.width;
          const startY = drawMousePosition.y * 1920 / canvasDimensions.height;
          const endX = e.nativeEvent.offsetX * 1080 / canvasDimensions.width;
          const endY = e.nativeEvent.offsetY * 1920 / canvasDimensions.height;
          clearStageDrawing()
          drawCircle(stageDrawingContext, startX, startY, endX, endY);
        }
        break;
    }
  }

  const previewMouseUp = (e) => {
    switch (activeTool) {
      case 'laser':
        setActiveToolStatus('awaiting');
        setDrawMousePosition(null);
        break;
      case 'draw':
        setActiveToolStatus('awaiting');
        setDrawMousePosition(null);
        break;
      case 'line':
        if (activeToolStatus === 'drawing') {
          const drawingContext = drawingRef.current.getContext('2d');
          const startX = drawMousePosition.x * 1080 / canvasDimensions.width;
          const startY = drawMousePosition.y * 1920 / canvasDimensions.height;
          const endX = e.nativeEvent.offsetX * 1080 / canvasDimensions.width;
          const endY = e.nativeEvent.offsetY * 1920 / canvasDimensions.height;
          clearStageDrawing()
          console.log("End - Drawing Line")
          drawLine(drawingContext, startX, startY, endX, endY);
          setActiveToolStatus('awaiting');
          setDrawMousePosition(null);
        }
        break;
      case 'circle':
        if (activeToolStatus === 'drawing') {
          const drawingContext = drawingRef.current.getContext('2d');
          const startX = drawMousePosition.x * 1080 / canvasDimensions.width;
          const startY = drawMousePosition.y * 1920 / canvasDimensions.height;
          const endX = e.nativeEvent.offsetX * 1080 / canvasDimensions.width;
          const endY = e.nativeEvent.offsetY * 1920 / canvasDimensions.height;
          clearStageDrawing()
          drawCircle(drawingContext, startX, startY, endX, endY);
          setActiveToolStatus('awaiting');
          setDrawMousePosition(null);
        }
        break;
    }
  }

  const previewMouseLeave = (e) => {
    switch (activeTool) {
      case 'laser':
        setActiveToolStatus('awaiting');
        setDrawMousePosition(null);
        break;
      case 'draw':
        setActiveToolStatus('awaiting');
        setDrawMousePosition(null);
        clearStageDrawing()
        break;
      case 'line':
        setDrawMousePosition(null);
        setActiveToolStatus('awaiting');
        clearStageDrawing()
        break;
      case 'circle':
        setDrawMousePosition(null);
        setActiveToolStatus('awaiting');
        clearStageDrawing()
        break;
    }
  }

  const drawLine = (context, x1, y1, x2, y2) => {

    context.beginPath();
    context.strokeStyle = getColor();
    context.lineWidth = 8;
    context.lineCap = "round";
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.stroke();
    context.closePath();
  }

  const drawCircle = (context, x1, y1, x2, y2) => {

    var a = x1 - x2;
    var b = y1 - y2;
    var c = Math.sqrt(a * a + b * b);

    context.beginPath();
    context.arc((x2 + x1) / 2, (y2 + y1) / 2, c / 2, 0, 2 * Math.PI, false);
    context.lineWidth = 8;
    context.strokeStyle = getColor();
    context.stroke();
  }

  const drawLaser = (context, x, y, radius) => {
    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI, false);
    context.fillStyle = 'red';
    context.fill();
  }




  //window mousemove events
  const globalMouseMove = (e) => {
    // const position = { x: e.clientX, y: e.clientY };
    // console.log(isScrubbing)
    // if (isScrubbing) {
    //   console.log(isScrubbing)
    //   const percentX =  position.x - width/2 - (canvasDimensions.width/2 - 2*remPx);
    //   console.log(percentX)
    //   videoRef.current.currentTime = percentX * videoDuration;
    // }
  }

  const globalMouseUp = (e) => {

  }

  //scrub events
  const drawScrub = (videoPosition) => {

    const videoDuration = getActiveClipDuration()

    const scrubContext = scrubRef.current.getContext('2d');
    const width = scrubWidth;
    const height = scrubHeight;
    const percentFill = videoPosition / videoDuration;

    scrubContext.beginPath();
    scrubContext.fillStyle = '#191a1b ';
    scrubContext.rect(0, 0, width, height);
    scrubContext.fill();

    scrubContext.beginPath();
    scrubContext.fillStyle = '#4353FF';
    scrubContext.rect(0, 0, width * percentFill, height / 1);
    scrubContext.fill();

    scrubContext.beginPath();
    scrubContext.fillStyle = '#147efb';
    scrubContext.rect(width * percentFill - .25 * remPx, 0, .5 * remPx, height);
    scrubContext.fill();

    //calc duration as min/sec/frames
    const durationMs = videoDuration % 1;
    const durationFrames = Math.floor(durationMs * 1000 / 33);
    const totalSeconds = Math.floor(videoDuration);
    const durationMinutes = Math.floor(totalSeconds / 60);
    const durationSeconds = totalSeconds - (durationMinutes * 60);
    let frameString = durationFrames < 10 ? "0" + durationFrames : durationFrames;
    let secondString = durationSeconds < 10 ? "0" + durationSeconds : durationSeconds;
    let minuteString = durationMinutes < 10 ? "0" + durationMinutes : durationMinutes;
    const durationString = minuteString + ":" + secondString + ":" + frameString;

    const currentMs = videoPosition % 1;
    const currentFrames = Math.floor(currentMs * 1000 / 33);
    const totalCurrentSeconds = Math.floor(videoPosition)
    const currentMinutes = Math.floor(totalCurrentSeconds / 60);
    const currentSeconds = totalCurrentSeconds - (currentMinutes * 60);
    frameString = currentFrames < 10 ? "0" + currentFrames : currentFrames;
    secondString = currentSeconds < 10 ? "0" + currentSeconds : currentSeconds;
    minuteString = currentMinutes < 10 ? "0" + currentMinutes : currentMinutes;
    const currentString = minuteString + ":" + secondString + ":" + frameString;


    scrubContext.font = "16px Arial";
    scrubContext.fillStyle = 'white';
    scrubContext.fillText(currentString + " / " + durationString, width - 9 * remPx, height - height / 2.5);

  }

  const drawFrameScrub = () => {
    const scubContext = frameScrubRef.current.getContext('2d');
    const width = frameScrubWidth;
    const height = scrubHeight;

    scubContext.beginPath();
    scubContext.fillStyle = '#191a1b ';
    scubContext.rect(0, 0, width, height * 2);
    scubContext.fill();

    //draw frames
    const pxPerFrame = frameScrubWidth / 30;
    const videoDuration = videoRef.current.currentTime;

    for (let i = 0; i < 31; i++) {
      scubContext.beginPath();
      scubContext.fillStyle = '#4A91B5';
      //line height
      const baseLineHeight = height * .4;
      const maxExtraHeight = baseLineHeight * .75;
      const lineOffset = Math.abs(i - 15) / 15;
      const lineHeight = baseLineHeight + maxExtraHeight - maxExtraHeight * lineOffset;
      const lineWidth = .15 * remPx;
      const startOffset = (pxPerFrame * i - lineWidth / 2) + frameOffset % pxPerFrame;
      scubContext.rect(startOffset, height * 1.5 - lineHeight, lineWidth, lineHeight);
      scubContext.fill();
    }

    scubContext.beginPath();
    scubContext.fillStyle = '#4A91B5';
    const lineWidth = .25 * remPx;
    scubContext.rect(width / 2 - lineWidth, 0, lineWidth, height / 2);
    scubContext.fill();
  }

  function scrubMouseDown(e) {
    setIsScrubbing(true);
    videoRef.current.pause();
    const x = e.nativeEvent.offsetX;
    const percentX = x / scrubWidth;
    videoRef.current.currentTime = percentX * getActiveClipDuration();
  }

  function scrubMouseUp(e) {
    setIsScrubbing(false);
  }

  function scrubMouseMove(e) {
    if (!isScrubbing)
      return;

    const x = e.nativeEvent.offsetX;
    const percentX = x / scrubWidth;
    videoRef.current.currentTime = percentX * getActiveClipDuration();
  }

  function scrubMouseLeave() {
    setIsScrubbing(false);
  }

  function frameScrubMouseDown(e) {
    setIsFrameScrubbing(true);
    setFrameScrubStartDuration(videoRef.current.currentTime);
    setFrameScrubStartX(e.nativeEvent.offsetX);
    videoRef.current.pause();
  }

  function frameScrubMouseUp(e) {
    setIsFrameScrubbing(false);
    setFrameScrubStartDuration(null);
    setFrameScrubStartX(null);
  }

  function frameScrubMouseMove(e) {

    const videoDuration = getActiveClipDuration()

    if (!isFrameScrubbing)
      return;

    const pxPerFrame = frameScrubWidth / 30;
    const currentX = e.nativeEvent.offsetX;
    const xOffset = currentX - frameScrubStartX;
    setFrameOffset(xOffset);
    let newDuration = frameScrubStartDuration + (Math.floor(xOffset / pxPerFrame) * .03333333);
    if (newDuration < 0) {
      setFrameScrubStartDuration(0);
      setFrameScrubStartX(currentX);
      newDuration = 0;
    }
    else if (newDuration > videoDuration) {
      setFrameScrubStartDuration(videoDuration);
      setFrameScrubStartX(currentX);
      newDuration = videoDuration;
    }

    videoRef.current.currentTime = newDuration;
  }

  function frameScrubMouseLeave(e) {
    setIsFrameScrubbing(false);
    setFrameScrubStartX(null);
    setFrameScrubStartDuration(null);
  }

  //tool events
  const changeTool = (tool) => {
    setDrawMousePosition(null);
    setActiveToolStatus('awaiting');
    setActiveTool(tool);
  }

  const clearDrawing = () => {
    drawingRef.current.getContext('2d').clearRect(0, 0, 1080, 1920);
  }

  const clearStageDrawing = () => {
    stageDrawingRef.current.getContext('2d').clearRect(0, 0, 1080, 1920);
  }

  //speed events
  const changeSpeed = (speed) => {
    setSpeed(speed);
    videoRef.current.playbackRate = speed;
  }

  //frame events
  const moveFrameForward = () => {
    const vRef = getActiveClipRef()
    const currentTime = vRef.current.currentTime;
    let desiredTime = currentTime + .033;
    //if (desiredTime > videoDuration) desiredTime = videoDuration;
    vRef.current.currentTime = desiredTime;
  }

  const moveFrameBack = () => {
    const vRef = getActiveClipRef()
    const currentTime = vRef.current.currentTime;
    let desiredTime = currentTime - .033;
    //if (desiredTime < 0) desiredTime = 0;
    vRef.current.currentTime = desiredTime;
  }

  //seconds events
  const moveSecondForward = () => {
    const currentTime = videoRef.current.currentTime;
    let desiredTime = currentTime + 1;
    //if (desiredTime > videoDuration) desiredTime = videoDuration;
    videoRef.current.currentTime = desiredTime;
  }

  const moveSecondBack = () => {
    const currentTime = videoRef.current.currentTime;
    let desiredTime = currentTime - 1;
    //if (desiredTime < 0) desiredTime = 0;
    videoRef.current.currentTime = desiredTime;
  }

  const loadNotes = async () => {
    const existingNotes = await props.getNotes()
    //const startingNotes = request.videoNotes ? [...request.videoNotes] : []
    for (let i = 0; i < existingNotes.length; i++) {
      existingNotes[i] = {
        ...existingNotes[i],
        isNew: false
      }
    }
    setNotes(existingNotes)
  }

  const addNote = async () => {
    const videoTime = videoRef.current.currentTime

    const newNotes = [...notes]
    const newNote = {
      id: uuidv4(),
      time: videoTime,
      clip: selectedClip,
      text: "",
      isNew: true
    }

    await newNotes.push(newNote)

    newNotes.sort((a, b) => a.time - b.time)

    props.updateNotes(request._id, newNotes)
    setNotes(newNotes)
  }

  const deleteNote = async (noteId) => {
    const newNoteList = notes.filter(n => n.id !== noteId)
    await props.updateNotes(request._id, newNoteList)
    setNotes(newNoteList)
  }

  const updateNote = async (noteId, text) => {
    const existingNotes = [...notes]
    const index = existingNotes.findIndex(n => n.id === noteId)
    existingNotes[index] = { ...existingNotes[index], text: text, isNew: false }


    await props.updateNotes(request._id, existingNotes)
    setNotes(existingNotes)
  }

  const noteOnClick = (note) => {
    onClipSelected(note.clip)
    const clipRef = getActiveClipRef()
    clipRef.current.currentTime = note.time
  }

  const onNoteFocus = () => {
    window.removeEventListener("keydown", keyPressed, true);
  }

  const onNoteLoseFocus = () => {
    window.addEventListener("keydown", keyPressed, true);
  }

  const closeInfoPopup = () => {
    setIsRequestDescOpen(false)
    window.addEventListener("keydown", keyPressed, true);
  }

  const openInfoPopup = () => {
    setIsRequestDescOpen(true)
    window.removeEventListener("keydown", keyPressed, true);
  }

  //display helpers
  const getRecordDurationDisplay = () => {
    if (isRecording) {
      const sec = Math.floor(recDuration / 1000);
      const min = Math.floor(sec / 60);

      const seconds = (sec - min * 60)
      return min + ":" + (seconds < 10 ? "0" + seconds : seconds);
    }
  }

  const getRecordingButton = () => {
    if (!webcamLoaded) {
      return (
        <div>
          <div className={styles.loader}></div>
          <div className={mjes.text_s} style={{ textAlign: 'center', marginTop: ".25rem" }}>Loading Webcam</div>
        </div>
      );
    }

    return isRecording ?
      <div className={styles.stopButton} onClick={() => stopRecording()}
        data-tip="Stop Recording" data-for="right" data-iscapture="true">
        <div className={rs.labelSmallLight}>Stop</div>
      </div> :
      <div className={styles.recordButton} onClick={() => startRecording()}
        data-tip="Start Recording" data-for="right" data-iscapture="true">
        <div className={rs.labelSmallLight}>Rec</div>
      </div>
  }

  const getRecordingWarning = () => {
    if (isSecondWarning)
      return <div className={rs.labelSmallLight} style={{ color: "yellow", marginTop: ".25rem", minHeight: "2.5rem" }}>{"< 30 Seconds"}</div>
    else if (isFirstWarning)
      return <div className={rs.labelSmallLight} style={{ color: "yellow", marginTop: ".25rem", minHeight: "2.5rem" }}>{"< 1 Minute"}</div>
    else
      return <div className={rs.labelSmallLight} style={{ color: "yellow", marginTop: ".25rem", minHeight: "2.5rem" }}></div>
  }


  return (
    <div className={styles.createVideo}>
      <div className={styles.topRow} >
        {/* <div className={mjes.breadcrumbs} style={{ justifySelf: "left" }}>
          <div className={mjes.textLinkPrimary} onClick={() => history.push('/reviewer-profile/requests')}>{"Requests"}</div>
          <div style={{ margin: "0 .5rem" }}>
            <Icon icon="right" height=".75rem" color="gray" />
          </div>
          <div  className={rs.labelSmallLight}>{request.userFirstName}</div>
        </div> */}
        <div className={rs.buttonIcon} onClick={() => history.push('/reviewer-profile/requests')} >
          <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
        </div>
        <div className={styles.nameGrid} style={{ width: canvasDimensions.width }}>
          <div style={{ display: "flex", alignItems: 'center' }}>
            <div className={rs.labelMediumLight}>Athlete:</div>
            <div className={rs.textMediumLight} style={{ marginLeft: ".5rem" }}>{request.userFirstName}</div>
          </div>

        </div>
        <div style={{ marginLeft: "auto" }}>
          <div onClick={() => openInfoPopup()} >
            <HelpIcon style={{ fontSize: "2rem" }} />
          </div>
        </div>
        {/* <div className={mjes.label_m} style={{ color: "#ff9819"  }}>Google Chrome Required!</div> */}
      </div>
      <div className={styles.middleRow}>
        <div className={styles.leftColumn}>
          {getRecordingButton()}
          <div className={mjes.pageLabel} style={{ color: "red", marginTop: ".25rem", minHeight: "4rem", textAlign: "center" }}>
            {getRecordDurationDisplay()}
          </div>
          {getRecordingWarning()}
          <div style={{ marginTop: "4rem" }}>
            <div className={rs.labelSmallLight} style={{ marginTop: "2rem" }}>Webcam</div>
            <div className={styles.colors}>
              <div className={cameraPosition === "top-left" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setCameraPosition('top-left')} icon="box"
                data-tip="Top Left" data-for="right" data-iscapture="true">
                <Icon icon="topLeft" width="1rem" height="1rem" color="#F7F7F2" />
              </div>
              <div className={cameraPosition === "top-right" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setCameraPosition('top-right')} icon="box"
                data-tip="Top Right" data-for="right" data-iscapture="true">
                <Icon icon="topRight" width="1rem" height="1rem" color="#F7F7F2" />
              </div>
              <div className={cameraPosition === "full" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setCameraPosition('full')} icon="expand"
                data-tip="Maximize" data-for="right" data-iscapture="true">
                <Icon icon="expand" width="1rem" height="1rem" color="#F7F7F2" />
              </div>
              <div className={cameraPosition === "bottom-left" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setCameraPosition('bottom-left')} icon="box"
                data-tip="Bottom Left" data-for="right" data-iscapture="true">
                <Icon icon="bottomLeft" width="1rem" height="1rem" color="#F7F7F2" />
              </div>
              <div className={cameraPosition === "bottom-right" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setCameraPosition('bottom-right')} icon="box"
                data-tip="Bottom Right" data-for="right" data-iscapture="true">
                <Icon icon="bottomRight" width="1rem" height="1rem" color="#F7F7F2" />
              </div>

              {/* <div className={cameraPosition === "hide" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setCameraPosition('hide')} icon="contract"
                data-tip="Hide" data-for="right" data-iscapture="true">
                <Icon icon="contract" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div> */}
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div className={rs.labelSmallLight}>Slow Motion</div>
            <div className={styles.colors}>
              <div className={speed === 1 ? styles.selectedSquareButton : styles.squareButton} onClick={() => changeSpeed(1)}
                data-tip="1x Speed" data-for="left" data-iscapture="true">
                <div className={rs.labelSmallLight}>1</div>
              </div>
              <div className={speed === .5 ? styles.selectedSquareButton : styles.squareButton} onClick={() => changeSpeed(.5)}
                data-tip="1/2x Speed" data-for="left" data-iscapture="true">
                <div className={rs.labelSmallLight}>.5</div>
              </div>
              <div className={speed === .25 ? styles.selectedSquareButton : styles.squareButton} onClick={() => changeSpeed(.25)}
                data-tip="1/4x Speed" data-for="left" data-iscapture="true">
                <div className={rs.labelSmallLight}>.25</div>
              </div>
              {/* <div className={speed === .1 ?  styles.selectedSquareButton : styles.squareButton} onClick={() => changeSpeed(.1)}
                data-tip="V - 1/10x Speed" data-for="left" data-iscapture="true">
                <div className={speed === .1 ? styles.speedSelected : styles.speedNotSelected}></div>
                <div>1/10x</div>
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <canvas ref={previewRef} width={canvasDimensions.width} height={canvasDimensions.height}
            onMouseDown={previewMouseDown} onMouseMove={previewMouseMove}
            onMouseUp={previewMouseUp} onMouseLeave={previewMouseLeave} style={{ cursor: "pointer" }} />
          <div className={styles.scrubControls} style={{ width: canvasDimensions.width }}>
            <div className={styles.playButton}>
              {isVideoPlaying ?
                <div className={styles.squareButton} style={{ backgroundColor: "#4353FF" }}
                  onClick={pauseVideo} >
                  <Icon icon="pause" width="1.5rem" height="1.5rem" color="#EAEAEA" />
                </div> :
                <div className={styles.squareButton} style={{ backgroundColor: "#4353FF" }}
                  onClick={playVideo} >
                  <Icon icon="play" width="1.5rem" height="1.5rem" color="#EAEAEA" />
                </div>
              }
            </div>
            <canvas ref={scrubRef} width={scrubWidth} height={scrubHeight} style={{ marginLeft: ".5rem" }}
              onMouseDown={scrubMouseDown} onMouseUp={scrubMouseUp} onMouseMove={scrubMouseMove} onMouseLeave={scrubMouseLeave} />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div>
            <div className={rs.labelSmallLight}>Colors</div>
            <div className={styles.colors}>
              <div className={color === "green" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setColor('green')}
                data-tip="Green" data-for="left" data-iscapture="true">
                <Icon icon="box" width="1.25rem" height="1.25rem" color="#CEFD14" />
              </div>
              <div className={color === "blue" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setColor('blue')}
                data-tip="Blue" data-for="left" data-iscapture="true">
                <Icon icon="box" width="1.25rem" height="1.25rem" color="#4353FF" />
              </div>
              {/* <div className={color === "red" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setColor('red')}
                data-tip="Red" data-for="left" data-iscapture="true">
                <Icon icon="box" width="1.25rem" height="1.25rem" color="#F21D2F" />
              </div> */}

              {/* <div className={color === "yellow" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setColor('yellow')}
                data-tip="Yellow" data-for="left" data-iscapture="true">
                <Icon icon="box" width="1.25rem" height="1.25rem" color="#EEF345" />
              </div> */}
              <div className={color === "pink" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => setColor('pink')}
                data-tip="Pink" data-for="left" data-iscapture="true">
                <Icon icon="box" width="1.25rem" height="1.25rem" color="#ff14af" />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div className={rs.labelSmallLight}>Tools</div>
            <div className={styles.colors}>
              <div className={activeTool === "laser" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => changeTool('laser')} data-tip="1 - laser" data-for="left" data-iscapture="true">
                <Icon icon="dot" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
              <div className={activeTool === "draw" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => changeTool('draw')} data-tip="2 - Draw" data-for="left" data-iscapture="true">
                <Icon icon="pencil" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
              <div className={activeTool === "line" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => changeTool('line')} data-tip="3 - Line" data-for="left" data-iscapture="true">
                <Icon icon="line" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
              <div className={activeTool === "circle" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => changeTool('circle')} data-tip="4 - circle" data-for="left" data-iscapture="true">
                <Icon icon="circle" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
              {/* <div className={activeTool === "arrow" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => changeTool('circle')} data-tip="5 - arrow" data-for="left" data-iscapture="true">
                <Icon icon="line" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div> */}
              {/* <div className={activeTool === "arrow" ? styles.selectedSquareButton : styles.squareButton}
                onClick={() => changeTool('draw')}>
                <Icon icon="pencil" width="1.5rem" height="1.5rem" color="black" />
              </div> */}
            </div>
            <div className={styles.clearButton} style={{ marginTop: ".5rem" }} onClick={() => clearDrawing()} data-tip="ESC - Clear" data-for="left" data-iscapture="true">
              <div className={rs.labelSmallLight}>Clear</div>
            </div>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div className={rs.labelSmallLight}>Frames</div>
            {/* <div className={mjes.label}>-/+ 1 frame</div> */}
            <div className={styles.colors}>
              <div className={styles.squareButton} onClick={() => moveFrameBack()}
                data-tip="A or Left Arrow - Back One Frame" data-for="left" data-iscapture="true">
                <Icon icon="minus" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
              <div className={styles.squareButton} onClick={() => moveFrameForward()}
                data-tip="D or Right Arrow - Forward One Frame" data-for="left" data-iscapture="true">
                <Icon icon="plus" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
            </div>
          </div>
          {/* <div style={{ marginTop: "1rem" }}>
            <div className={rs.labelSmallLight}>Seconds</div>
            <div className={mjes.label}>-/+ 1 second</div>
            <div className={styles.colors}>
              <div className={styles.squareButton} onClick={() => moveSecondBack()}
                data-tip="Q - Back One Second" data-for="left" data-iscapture="true">
                <Icon icon="minus" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
              <div className={styles.squareButton} onClick={() => moveSecondForward()}
                data-tip="E - Forward One Second " data-for="left" data-iscapture="true">
                <Icon icon="plus" width="1.25rem" height="1.25rem" color="#F7F7F2" />
              </div>
            </div>
          </div> */}
        </div>

        <div style={{ flexGrow: "1", margin: "0 1rem", maxWidth: "20rem" }}>
          <div style={{ margin: "0 0 1rem 0" }}>
            <div>
              <div className={rs.labelSmallLight} style={{ flexGrow: "1" }}>Videos</div>
            </div>
            <div className={rs.textSmallLight}>Select the video you would like to watch.  Users can submit up to 4 videos.</div>
            <div clipNumber={rs.labelSmallLight} style={{ display: "flex", gap: "1rem", marginTop: ".5rem" }}>
              {request.userFileUploadCount > 0 &&
                <div style={{ background: selectedClip === 1 ? "#4353FF" : "#7db2e2", width: "3rem", height: "3rem", display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center', borderRadius: "50%", cursor: "pointer" }} onClick={() => onClipSelected(1)}>1</div>
              }
              {request.userFileUploadCount > 1 &&
                <div style={{ background: selectedClip === 2 ? "#4353FF" : "#7db2e2", width: "3rem", height: "3rem", display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center', borderRadius: "50%", cursor: "pointer" }} onClick={() => onClipSelected(2)}>2</div>
              }
              {request.userFileUploadCount > 2 &&
                <div style={{ background: selectedClip === 3 ? "#4353FF" : "#7db2e2", width: "3rem", height: "3rem", display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center', borderRadius: "50%", cursor: "pointer" }} onClick={() => onClipSelected(3)}>3</div>
              }
              {request.userFileUploadCount > 3 &&
                <div style={{ background: selectedClip === 4 ? "#4353FF" : "#7db2e2", width: "3rem", height: "3rem", display: "flex", alignItems: "center", justifyContent: "center", textAlign: 'center', borderRadius: "50%", cursor: "pointer" }} onClick={() => onClipSelected(4)}>4</div>
              }
            </div>
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <div className={rs.labelSmallLight} style={{ flexGrow: "1" }}>Bookmarks/Notes</div>
              <div onClick={() => addNote()} style={{ cursor: "pointer" }}>
                <Icon icon="plus" height="1rem" color="lightgreen" />
              </div>
            </div>
            <div className={rs.textSmallLight}>Create bookmarks at different times in the video and click them to jump to that spot</div>
            <div style={{ overflow: "auto", maxHeight: availHeight - 20 * remPx }}>
              <div style={{ backgroundColor: "#1a1a1a", padding: ".5rem", margin: ".5rem 0", borderRadius: ".5rem", cursor: "pointer" }} onClick={() => setCameraPosition('full')}>
                <div className={rs.labelSmallLight}>Intro (Full Screen)</div>
                <div className={rs.textSmallLight}>Smile, Self-Introduction, Roadmap</div>
              </div>
              {notes.filter(n => n.clip === selectedClip).map((note) => (
                <VideoNote key={note.id} note={note} deleteNote={deleteNote} updateNote={updateNote} noteOnClick={noteOnClick} onFocus={onNoteFocus} onLoseFocus={onNoteLoseFocus} />
              ))}
              {/* <div style={{ backgroundColor: "#333333", padding: ".5rem", margin: ".5rem 0", cursor: "pointer" }} onClick={() => setCameraPosition('full')}>
                <div className={mjes.label_s}>Outro (Full Screen)</div>
                <div className={mjes.text_s}>Thank You!, Recap (drills), Followup Plan</div>
              </div> */}
            </div>
          </div>

        </div>

      </div>
      <div className={styles.bottomRow}>
        {/* <div className={styles.scrubControls} style={{ width: canvasDimensions.width }}>
          <div className={styles.playButton}>
            {isVideoPlaying ?
              <div className={styles.squareButton} style={{ backgroundColor: "#147efb" }}
                onClick={pauseVideo} >
                <Icon icon="pause" width="1.5rem" height="1.5rem" color="#F7F7F2" />
              </div> :
              <div className={styles.squareButton} style={{ backgroundColor: "#147efb" }}
                onClick={playVideo} >
                <Icon icon="play" width="1.5rem" height="1.5rem" color="#F7F7F2" />
              </div>
            }
          </div>
          <canvas ref={scrubRef} width={scrubWidth} height={scrubHeight} style={{ marginLeft: ".5rem" }}
            onMouseDown={scrubMouseDown} onMouseUp={scrubMouseUp} onMouseMove={scrubMouseMove} onMouseLeave={scrubMouseLeave} />
        </div> */}
        {/* <div className={styles.frameScrubControls} >
          <canvas ref={frameScrubRef} width={frameScrubWidth} height={scrubHeight * 1.5}
            onMouseDown={frameScrubMouseDown} onMouseUp={frameScrubMouseUp} onMouseMove={frameScrubMouseMove} onMouseLeave={frameScrubMouseLeave} />
        </div> */}
      </div>
      <ReactTooltip
        id="right"
        place="right"
        type="dark"
        effect="float"
        multiline={true}
      />
      <ReactTooltip
        id="left"
        place="left"
        type="dark"
        effect="float"
        multiline={true}
      />
      <div className={styles.hiddenElements}>
        {request.userFileUploadCount > 0 &&
          <video key={source1.fileId} ref={videoRef1} width="1080" height="1920" controls muted
            onLoadedMetadata={video1MetadataLoaded} onLoadedData={videoLoaded1} onPlaying={videoPlaying} onPause={videoPaused}
            src={source1.videoUrl} crossOrigin="anonymous" preload="auto" />
        }
        {request.userFileUploadCount > 1 &&
          <video key={source2.fileId} ref={videoRef2} width="1080" height="1920" controls muted
            onLoadedMetadata={video2MetadataLoaded} onLoadedData={videoLoaded2} onPlaying={videoPlaying} onPause={videoPaused}
            src={source2.videoUrl} crossOrigin="anonymous" preload="auto" />
        }
        {request.userFileUploadCount > 2 &&
          <video key={source3.fileId} ref={videoRef3} width="1080" height="1920" controls muted
            onLoadedMetadata={video3MetadataLoaded} onLoadedData={videoLoaded3} onPlaying={videoPlaying} onPause={videoPaused}
            src={source3.videoUrl} crossOrigin="anonymous" preload="auto" />
        }
        {request.userFileUploadCount > 3 &&
          <video key={source4.fileId} ref={videoRef4} width="1080" height="1920" controls muted
            onLoadedMetadata={video4MetadataLoaded} onLoadedData={videoLoaded4} onPlaying={videoPlaying} onPause={videoPaused}
            src={source4.videoUrl} crossOrigin="anonymous" preload="auto" />
        }
        <video ref={webcamRef} muted crossOrigin="anonymous" />
        <canvas ref={recordingRef} width="1080" height="1920" />
        <canvas ref={drawingRef} width="1080" height="1920" />
        <canvas ref={stageDrawingRef} width="1080" height="1920" />
        <canvas ref={laserRef} width="1080" height="1920" />
        <img ref={logoRef} src={Logo} style={{ width: "300px" }} />
      </div>
      <ReactModal className={mjes.modal} overlayClassName={mjes.overlay} isOpen={isRequestDescOpen} contentLabel="Request Description" ariaHideApp={false}>
        <div>
          <div className={mjes.modalHeader}>
            <div className={rs.h1SmallLight}>Replay Video Recorder</div>
          </div>
          <div>
            {/* <div style={{ display: "flex", alignItems: "center" }}> */}
            <div>
              <div className={rs.labelSmallLight}>Athlete Name</div>
              <div className={rs.textSmallLight} style={{ marginTop: ".25rem", marginLeft: "0rem" }}>{request.userFirstName}</div>
            </div>
            <div style={{ marginTop: ".5rem" }}>
              <div className={rs.labelSmallLight}>Request Details</div>
              <div className={rs.textSmallLight} style={{ marginTop: ".25rem" }}>{request.details ? request.details : "No Details Provided"}</div>
            </div>
          </div>
          <div style={{ margin: "1rem 0" }}>
            <div style={{ display: "flex" }}>
              <div className={rs.labelSmallLight}>Requirements</div>
              <div className={rs.labelSmallLight} style={{ color: "orange", marginLeft: ".5rem" }}>Must Read!</div>
            </div>
            <div className={rs.textSmallLight}>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".5rem" }}>
                <div >1.</div>
                <div><u style={{ color: "orange" }}>Google Chrome</u> on a <u style={{ color: "orange" }}>PC/Mac/Laptop</u> is required for recording</div>
              </div>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".25rem" }}>
                <div >2.</div>
                <div>Maximum video length is <u style={{ color: "orange" }}>10 minutes</u> (Video will automatically stop recording at that time)</div>
              </div>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".25rem" }}>
                <div>3.</div>
                <div>{"Intro/Outro - Use Full Screen"}</div>
              </div>
            </div>
          </div>
          {/* <div style={{ margin: "1rem 0" }}>
            <div>
              <div className={rs.labelSmallLight}>Lesson Expectations</div>
            </div>
            <div className={rs.textSmallLight}>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".5rem" }}>
                <div >1.</div>
                <div>
                  <div>Full Screen Intro and Outro</div>
                  <div style={{ fontSize: ".9rem" }}>Talk directly to the athlete by name</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".25rem" }}>
                <div >2.</div>
                <div>
                  <div>Analyze the athlete's videos</div>
                  <div style={{ fontSize: ".9rem" }}>What is the athlete doing right and wrong?</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".25rem" }}>
                <div >3.</div>
                <div>
                  <div>Provide instruction for what the athlete is doing wrong</div>
                  <div style={{ fontSize: ".9rem" }}>What is the right way and what can they do to fix it? (drills, tips, etc)</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "1rem", marginTop: ".25rem" }}>
                <div>4.</div>
                <div>
                  <div>Encourage a follow-up transaction</div>
                  <div style={{ fontSize: ".9rem" }}>What do you plan to review in the next video? What/when should they submit the next request?</div>
                </div>
              </div>
            </div> *
          </div> */}
          <div className={rs.buttonSecondary} style={{ margin: ".5rem 0" }} onClick={() => closeInfoPopup()}>
            <div className={rs.labelLargeLight}>Continue to Recording</div>
          </div>
        </div>
      </ReactModal>
    </div >
  );
}

export default CreateVideo;