import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';


export const createReviewer = async (firstName, lastName, email, phone, password, sport, birthday, referralCode) => {
  let response = await fetch(getEndpoint() + "administrator/create-reviewer", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      phone,
      password,
      sport,
      birthday,
      referralCode
    })
  });

  response = await response.json();
  return response
}

export const getReviewers = async () => {
  let response = await fetch(getEndpoint() + "administrator/get-reviewers", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
    })
  });

  response = await response.json();
  return response
}

export const getReviewer = async (reviewerId) => {
  let response = await fetch(getEndpoint() + "administrator/get-reviewer", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      reviewerId: reviewerId
    })
  });

  response = await response.json();
  return response
}

export const getRequest = async (requestId) => {
  let response = await fetch(getEndpoint() + "administrator/get-request", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId: requestId
    })
  });

  response = await response.json();
  return response
}

export const getActiveRequests = async () => {
  let response = await fetch(getEndpoint() + "administrator/get-active-requests", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
    })
  });

  response = await response.json();
  return response
}

export const getStats = async () => {
  let response = await fetch(getEndpoint() + "administrator/get-stats", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
    })
  });

  response = await response.json();
  return response
}

export const createContent = async (requestId, title, description) => {
  let response = await fetch(getEndpoint() + "administrator/create-content", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId,
      title,
      description
    })
  });

  response = await response.json();
  return response
}