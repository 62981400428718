import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validateName, validateEmail, validatePassword, validateBirthday, validateText } from '../../input-validation/InputValidation';
import queryString from "query-string"

import { Helmet } from 'react-helmet';
//state
import { ReviewerSignup, selectLogin, LoginReset } from '../../slices/LoginSlice'
//components
import MaskedInput from 'react-text-mask';
import Loader from '../loader/Loader';
//styles
import styles from './SignupUserReviewer.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'

function SignupReviewer() {

  //redux
  const login = useSelector(selectLogin);
  const dispatch = useDispatch();
  const location = useLocation()
  const query = queryString.parse(location.search);

  //state
  const [firstName, setFirstName] = useState("")
  const [firstNameStatus, setFirstNameStatus] = useState("awaiting")
  const [firstNameError, setFirstNameError] = useState("")
  const [lastName, setLastName] = useState("")
  const [lastNameStatus, setLastNameStatus] = useState("awaiting")
  const [lastNameError, setLastNameError] = useState("")
  const [email, setEmail] = useState("")
  const [emailStatus, setEmailStatus] = useState("awaiting")
  const [emailError, setEmailError] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [passwordStatus, setPasswordStatus] = useState("awaiting")
  const [passwordError, setPasswordError] = useState("")
  const [birthday, setBirthday] = useState("")
  const [birthdayStatus, setBirthdayStatus] = useState("awaiting")
  const [birthdayError, setBirthdayError] = useState("")
  const [referralCode, setReferralCode] = useState(null)
  const [referralCodeStatus, setReferralCodeStatus] = useState("success")
  const [referralCodeError, setReferralCodeEddor] = useState("")
  const [referralCodeEnabled, setReferralCodeEnabled] = useState(true)
  const [sport, setSport] = useState("basketball")
  const [athleticExperience, setAthleticExperience] = useState("")
  const [athleticExperienceStatus, setAthleticExperienceStatus] = useState("awaiting")
  const [athleticExperienceError, setAthleticExperienceError] = useState("")
  const [coachingExperience, setCoachingExperience] = useState("")
  const [coachingExperienceStatus, setCoachingExperienceStatus] = useState("awaiting")
  const [coachingExperienceError, setCoachingExperienceError] = useState("")
  const [achievements, setAchievements] = useState("")
  const [socialLinks, setSocialLinks] = useState("")


  useEffect(() => {
    dispatch(LoginReset())
    const referralCode = localStorage.referralCode
    if (query.ref && query.ref.length > 0) {
      setReferralCodeEnabled(false)
      setReferralCode(query.ref)
    }
    else if (referralCode) {
      setReferralCodeEnabled(false)
      setReferralCode(referralCode)
    }
  }, []);

  const submitSignup = () => {
    dispatch(ReviewerSignup(firstName, lastName, email, phone, password, birthday, referralCode, sport,
      athleticExperience, coachingExperience, achievements, socialLinks));
  }

  const canSubmit = () => {
    return (firstNameStatus === "valid" && lastNameStatus === "valid" && emailStatus === "valid" &&
      passwordStatus === "valid" && birthdayStatus === "valid");
  }

  const updateFirstName = (firstName) => {
    setFirstName(firstName);

    const validation = validateName(firstName);
    if (validation.isValid) {
      setFirstNameStatus("valid");
      setFirstNameError(null);
    }
    else {
      setFirstNameStatus("invalid")
      setFirstNameError(validation.error);
    }
  }

  const updateLastName = (lastName) => {
    setLastName(lastName);

    const validation = validateName(lastName);
    if (validation.isValid) {
      setLastNameStatus("valid");
      setLastNameError(null);
    }
    else {
      setLastNameStatus("invalid")
      setLastNameError(validation.error);
    }
  }

  const updateEmail = (email) => {
    setEmail(email);

    const validation = validateEmail(email);
    if (validation.isValid) {
      setEmailStatus("valid");
      setEmailError(null);
    }
    else {
      setEmailStatus("invalid")
      setEmailError(validation.error);
    }
  }

  const updatePassword = (password) => {
    setPassword(password);

    const validation = validatePassword(password);
    if (validation.isValid) {
      setPasswordStatus("valid");
      setPasswordError(null);
    }
    else {
      setPasswordStatus("invalid")
      setPasswordError(validation.error);
    }
  }

  const updateBirthday = (birthday) => {
    setBirthday(birthday);

    const validation = validateBirthday(birthday);
    if (validation.isValid) {
      setBirthdayStatus("valid");
      setBirthdayError(null);
    }
    else {
      setBirthdayStatus("invalid")
      setBirthdayError(validation.error);
      return;
    }

    const birthdayDate = new Date(birthday);
    var ageDifMs = Date.now() - birthdayDate.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < 18 && age > 120) {
      setBirthdayStatus("error");
      setBirthdayError("Must be at least 18 years old")
    }
  }

  const updateAthleticExperience = (experience) => {
    setAthleticExperience(experience);

    const validation = validateText(experience, 750);
    if (validation.isValid) {
      setAthleticExperienceStatus("valid");
      setAthleticExperienceError(null);
    }
    else {
      setAthleticExperienceStatus("invalid")
      setAthleticExperienceError(validation.error);
    }
  }

  const updateCoachingExperience = (experience) => {
    setCoachingExperience(experience);

    const validation = validateText(experience, 255);
    if (validation.isValid) {
      setCoachingExperienceStatus("valid");
      setCoachingExperienceError(null);
    }
    else {
      setCoachingExperienceStatus("invalid")
      setCoachingExperienceError(validation.error);
    }
  }

  if (login.isLoggedIn)
    return <Redirect to="/verify-account" />
  else if (login.isLoggingIn)
    return <Loader />

  return (
    <div className={mjes.pageContainer}>
      <Helmet>
        <title>Coach Application | The Replay App - Video Coaching From Professional Athletes</title>
        <meta name="description" content="Apply to become a coach on The Replay App. Get 1-1 video coaching from professional and college athletes.  Choose from a selection of over 50 professional and college athletes.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
      </Helmet>
      <div className={mjes.pageContentContainer}>
        <div className={rs.h1MediumLight} style={{ textAlign: "center", margin: "1rem 0" }}>Apply to Coach</div>
        {/* <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Sign Up</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumber}>2</div>
            <div className={mjes.label}>Verify Email</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumber}>3</div>
            <div className={mjes.label}>Replay Will Contact You</div>
          </div>
        </div> */}
        <div style={{ margin: "1.5rem 0" }}>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>First Name *</div>
              <input className={firstNameStatus === "invalid" ? rs.textInputError : rs.textInput}
                type='text' value={firstName} onChange={e => updateFirstName(e.target.value)} maxLength="13" />
              <div className={mjes.labelError}>{firstNameError}</div>
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Last Name *</div>
              <input className={lastNameStatus === "invalid" ? rs.textInputError : rs.textInput}
                type='text' value={lastName} onChange={e => updateLastName(e.target.value)} maxLength="13" />
              <div className={mjes.labelError}>{lastNameError}</div>
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Email *</div>
              <input className={emailStatus === "invalid" ? rs.textInputError : rs.textInput}
                type='text' value={email} onChange={e => updateEmail(e.target.value)} maxLength="320" />
              <div className={mjes.labelError}>{emailError}</div>
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Password *</div>
              <input className={passwordStatus === "invalid" ? rs.textInputError : rs.textInput}
                value={password} onChange={e => updatePassword(e.target.value)} maxLength="13" />
              <div className={mjes.labelError}>{passwordError}</div>
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Birthday *</div>
              <MaskedInput className={birthdayStatus === "invalid" ? rs.textInputError : rs.textInput}
                value={birthday} onChange={e => updateBirthday(e.target.value)}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                guide={false} showMask={true} placeholder="12/06/1991" />
              <div className={mjes.labelError}>{birthdayError}</div>
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Sport *</div>
              <select className={rs.textInput} value={sport} onChange={e => setSport(e.target.value)}>
                <option value="basketball">Basketball</option>
                <option value="baseball">Baseball/Softball</option>
                <option value="football">Football</option>
                <option value="golf">Golf</option>
                <option value="lacrosse">Lacrosse</option>
                <option value="gymnastics">Gymnastics</option>
              </select>
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Phone</div>
              <input className={rs.textInput} placeholder="(999) 999-9999"
                type='text' value={phone} onChange={e => setPhone(e.target.value)} maxLength="20" />
            </div>
            {referralCodeEnabled &&
              <div className={mjes.textGroupContainer}>
                <div className={rs.labelSmallLight}>Referral Code (optional)</div>
                <input className={rs.textInput} type='text'
                  value={referralCode} onChange={e => setReferralCode(e.target.value)} />
              </div>
            }
          </div>
          <div className={mjes.textGroupContainer} style={{ marginTop: "1.5rem" }}>
            <div className={rs.labelSmallLight}>Athletic Exp/Coaching Exp/ Achievements (optional)</div>
            <textarea className={rs.textAreaInput} value={athleticExperience} onChange={e => updateAthleticExperience(e.target.value)} maxLength="750" />
            {/* <div className={mjes.labelError}>{athleticExperienceError}</div> */}
            <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + athleticExperience.length + "/750"}</div>
          </div>
          {/* <div>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Coaching Experience (optional)</div>
              <textarea className={mjes.textAreaInput} value={coachingExperience} onChange={e => updateCoachingExperience(e.target.value)} maxLength="255" />
              <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + coachingExperience.length + "/255"}</div>
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Achievements (optional)</div>
              <textarea className={mjes.textAreaInput} value={achievements} onChange={e => setAchievements(e.target.value)} maxLength="255" />
              <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + achievements.length + "/255"}</div>
            </div>
          </div> */}
          <div className={mjes.textGroupContainer}>
            <div className={rs.labelSmallLight}>Social Media Links (optional)</div>
            <textarea className={rs.textAreaInput} value={socialLinks} onChange={e => setSocialLinks(e.target.value)} maxLength="255" />
            <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + socialLinks.length + "/255"}</div>
          </div>
          <div className={styles.buttonMargin}>
            {canSubmit() ?
              <div className={rs.buttonSecondary} onClick={() => submitSignup()}>
                <div className={rs.buttonTextLargeLight}>Sign Up</div>
              </div> :
              <div className={rs.buttonSecondary}>
                <div className={rs.buttonTextLargeLight}>Sign Up</div>
              </div>
            }
          </div>
          <div className={rs.textSmallLight} style={{ margin: ".5rem 0" }}>
            By creating an account you agree to Replay's <span style={{ cursor: "pointer", color: "white" }} onClick={() => window.open('https://www.thereplayapp.com/terms-of-service')}><u>Terms of Service</u></span> and <span style={{ cursor: "pointer", color: "white" }} onClick={() => window.open('https://www.thereplayapp.com/privacy-policy')}><u>Privacy Policy</u></span>
          </div>
          <div className={mjes.labelError} style={{ margin: ".5rem 0" }}>{login.error}</div>
        </div>
      </div>
    </div>
  );
}

export default SignupReviewer