import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from '../../icons/Icon';
import star from '../../images/profile/star.png'
import reviews from '../../images/profile/reviews.png'
import timer from '../../images/profile/timer.png'
import styles from './SearchReviewer.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import BaseballIcon from '../../images/sports-filter/baseball_glow.png';
import BasketballIcon from '../../images/sports-filter/basketball_glow.png';
import FootballIcon from '../../images/sports-filter/football_glow.png';
import GolfIcon from '../../images/sports-filter/golf_glow.png';
import LacrosseIcon from '../../images/sports-filter/lacrosse_glow.png';
import GymnasticsIcon from '../../images/sports-filter/gymnastics_glow.png';
import rs from '../../styles/ReplayStyles.module.css'


function SearchReviewer(props) {

  const reviewer = props.reviewer;
  const bucket = props.bucket;
  const history = useHistory();
  const scrollY = props.scrollY
  const sport = props.sport

  const imageUrl = "https://" + bucket + ".s3.amazonaws.com/" + reviewer.imageFilename;

  function mentorClick() {
    const path = '/results/' + reviewer.username + "?sport="+sport + "&scrollY=" + scrollY;
    history.push(path);
  }

  const getSportsIcon = () => {
    switch (reviewer.sport) {
      case "baseball": return BaseballIcon;
      case "basketball": return BasketballIcon;
      case "football": return FootballIcon;
      case "golf": return GolfIcon;
      case "lacrosse": return LacrosseIcon;
      case "gymnastics": return GymnasticsIcon;
    }
  }

  const getAverageResponseTime = () => {
    const responseTime = Math.floor(reviewer.totalResponseTime / reviewer.totalReviewCount);
    if (reviewer.totalReviewCount === 0)
      return "-"
    else if (responseTime < 1) {
      return "< 1 Hour";
    }
    else if (responseTime === 1) {
      return "1 Hour";
    }
    else if (responseTime < 24) {
      return responseTime + " Hours"
    }
    else {
      const days = Math.floor(responseTime / 24);
      return days === 1 ? days + " Day" : days + " Days";
    }
  }

  const getTotalReviews = () => {
    //return 0 + " Replays"
    return reviewer.totalReviewCount + " Replays";
  }

  return (
    <div className={styles.container} onClick={() => mentorClick()}>
      <div className={styles.nameGrid}>
        <div>
          <div className={rs.h1M}>{(reviewer.firstName + " " + reviewer.lastName)}</div>
          <div className={rs.labelM}>{reviewer.title}</div>
        </div>
        <img src={getSportsIcon()} style={{ height: "4rem", alignSelf: "start" }} />
      </div>
      <div className={styles.cardGrid} style={{ margin: ".5rem 0 0 0" }}>
        <div className={styles.profileImageContainer}>
          <img src={imageUrl} className={styles.image} />
        </div>
        <div style={{ marginTop: ".25rem" }}>
          <div className={styles.experience}>
            <div style={{ marginTop: ".25rem" }}>&#8226;</div>
            <div className={rs.textM} style={{lineHeight:"1rem"}}>{reviewer.feature1}</div>
          </div>
          <div className={styles.experience}>
            <div style={{ marginTop: ".25rem" }}>&#8226;</div>
            <div className={rs.textM} style={{lineHeight:"1rem"}}>{reviewer.feature2}</div>
          </div>
          <div className={styles.experience}>
            <div style={{ marginTop: ".25rem" }}>&#8226;</div>
            <div className={rs.textM} style={{lineHeight:"1rem"}}>{reviewer.feature3}</div>
          </div>
        </div>
      </div>
      <div className={rs.labelM} style={{color:"#777777", textAlign:"right"}}>{"$" + reviewer.price}</div>
      {/* <div>
        <div className={styles.statsGrid}>
          <div className={styles.ratingGrid} >
            <Icon icon="star" height="1.75rem" color="white" />
            <div className={mjes.text_m}>{reviewer.totalRatingCount > 0 ? (reviewer.totalRatingValue / reviewer.totalRatingCount).toFixed(1) : "-"}</div>
          </div>
          <div className={styles.ratingGrid}>
            <Icon icon="lightening" height="1.75rem" color="white" />
            <div className={mjes.text_m}>{getAverageResponseTime()}</div>
          </div>
          <div className={styles.ratingGrid}>
            <Icon icon="checkmark" height="1.75rem" color="white" />
            <div className={mjes.text_m}>{getTotalReviews()}</div>
          </div>
        </div>
      </div> */}
      <div>
        {/* <div className={rs.textS} style={{ margin: ".5rem auto 0 0", width:"12rem" }}>Click to View Coach Profile</div> */}
      </div>
    </div>
    //   <div className={styles.sportIconContainer}>
    //   <img src={getSportsIcon()} className={styles.sportIcon} />
    // </div>
  )
}

export default SearchReviewer;