import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';
import { toast } from 'react-toastify';

import {
  validateVideo
} from '../input-validation/InputValidation';

export const ReviewVideoSlice = createSlice({
  name: 'reviewVideo',
  initialState: {
    status: 'awaiting-input',
    error: ''
  },
  reducers: {
    ReviewVideoProcessing: (state) => {
      state.status = 'processing';
      state.error = '';
    },
    ReviewVideoSuccess: (state) => {
      state.status = 'success';
    },
    ReviewVideoError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
    ReviewVideoReset: (state) => {
      state.status = 'awaiting-input';
      state.error = '';
    },
    ReviewVideoReject: (state) => {
      state.status = 'reject';
      state.error = '';
    }
  },
});

export const { ReviewVideoProcessing, ReviewVideoSuccess, ReviewVideoError, ReviewVideoReset, ReviewVideoReject } = ReviewVideoSlice.actions;


export const SubmitCompletedVideo = (requestId, videoFile, takeaways) => async dispatch => {

  dispatch(ReviewVideoProcessing());

  //validate inputs
  if (!requestId) {
    dispatch(ReviewVideoError("Cannot find request"));
    return;
  }
  if (!validateVideo(videoFile)) {
    dispatch(ReviewVideoError("Invalid Video"));
    return;
  }

  try {
    let requestResponse = await fetch(getEndpoint() + "request/submit-completed-video", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        requestId: requestId,
        takeaways: takeaways
      })
    });

    const request = await requestResponse.json();
    if (!request.success) {
      dispatch(ReviewVideoError(request.message));
      return;
    }

    //get presigned URL
    const uploadData = request.payload.uploadData;

    try {
      //submit to s3 storage
      let form = new FormData();
      Object.keys(uploadData.fields).forEach(key => form.append(key, uploadData.fields[key]));
      form.append('file', videoFile);

      await fetch(uploadData.url, { method: 'POST', body: form });
    }
    catch (error) {
      //alert that upload had an error
      await fetch(getEndpoint() + "request/reviewer-upload-error", {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify({ requestId: requestId })
      });
      toast.error("Error Uploading Video. Try Again")
      return
    }

    await fetch(getEndpoint() + "request/reviewer-upload-success", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ requestId: requestId })
    });

    dispatch(ReviewVideoSuccess());
  }
  catch (error) {
    console.log(error);
    dispatch(ReviewVideoError(error.toString()));
    return;
  }
};

export const RejectRequest = (requestId) => async dispatch => {

  dispatch(ReviewVideoProcessing());

  //validate inputs
  if (!requestId) {
    dispatch(ReviewVideoError("Cannot find request"));
    return;
  }

  try {
    let requestResponse = await fetch(getEndpoint() + "request/reviewer-reject-request", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId: requestId })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(ReviewVideoReject());
      return;
    }

    dispatch(ReviewVideoError(request.message));
    return;

  }
  catch (error) {
    dispatch(ReviewVideoError(error));
    return;
  }
}


export const selectReviewVideo = state => state.reviewVideo;

export default ReviewVideoSlice.reducer;
