import React from 'react';
//libraries
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
//state
//components
import DropdownMenuAdmin from '../dropdown-menu-admin/DropdownMenuAdmin';
//images
import Logo from '../../images/logo/Replay_Logo.png'
//styles
import styles from './Header.module.css'
import mjes from '../../mjes-styles/mjes.module.css';

function HeaderAdmin(props) {

  //router
  const history = useHistory();

  //media queries
  const isSmall = useMediaQuery({ query: '(max-width: 40rem)' })

  return (
    <div className={styles.header}>
      <div className={styles.headerGrid}>
        <div className={styles.logo} onClick={() => history.push("/browse/all")}>
          <img className={styles.logoImg} src={Logo} />
        </div>
        <div className={styles.headerLinksAdmin}>
        </div>
        <div className={styles.buttonContainer}>
          <div></div>
          <DropdownMenuAdmin />
        </div>
      </div>
    </div>
  );
}

export default HeaderAdmin;