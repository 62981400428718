import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const CreateVideoSlice = createSlice({
  name: 'createVideo',
  initialState: {
    request: null,
    requestFiles: "",
    status: 'processing',
    error: ''
  },
  reducers: {
    CreateVideoProcessing: (state) => {
      state.status = 'processing';
      state.request = null;
      state.error = '';
    },
    CreateVideoSuccess: (state, action) => {
      state.request = action.payload.request;
      state.requestFiles = action.payload.requestFiles;
      state.status = 'success';
    },
    CreateVideoError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
      state.request = null;
    },
    CreateVideoReset: (state) => {
      state.status = 'processing';
      state.error = '';
      state.request = null;
    }
  },
});

export const { CreateVideoProcessing, CreateVideoSuccess, CreateVideoError, CreateVideoReset } = CreateVideoSlice.actions;


export const FetchRequest = (requestId) => async dispatch => {

  dispatch(CreateVideoProcessing());

  //validate inputs
  if (!requestId) {
    dispatch(CreateVideoError("Cannot find request"));
    return;
  }

  try {
    let requestResponse = await fetch(getEndpoint() + "request/get-request-user-video", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId: requestId })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(CreateVideoSuccess({request: request.payload.request, requestFiles: request.payload.requestFiles}));
      return;
    }
    dispatch(CreateVideoError(request.message));
  }
  catch (error) {
    console.log(error);
    dispatch(CreateVideoError(error.toString()));
    return;
  }
};


export const selectCreateVideo = state => state.createVideo;

export default CreateVideoSlice.reducer;
