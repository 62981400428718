import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/fonts.css'
import App from './components/app/App';
import store from './store/store';
import { ViewportProvider } from './providers/ViewportProvider';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '@stripe/stripe-js';
import TagManager from 'react-gtm-module'


//Initialize Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-WTHKZ6L'
}

//Initialize Stripe
let publickey
const mode = process.env.NODE_ENV;
switch (mode) {
  case "development":
    publickey = process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY
    //TagManager.initialize(tagManagerArgs)
    break
  case "production":
    publickey = process.env.REACT_APP_PRODUCTION_STRIPE_PUBLIC_KEY
    //TagManager.initialize(tagManagerArgs)
    break
  default:
    publickey = process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY
}
const stripePromise = loadStripe(publickey);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ViewportProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ViewportProvider>
      </Provider>
    </Elements>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
