import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import { toast } from 'react-toastify';
import { getAuthHeaders } from '../helpers/Authentication';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'


export const LoginSlice = createSlice({
  name: 'login',
  initialState: {
    //login
    isLoggedIn: false,
    accountId: null,
    role: null,
    notificationCount: 0,
    isLoggingIn: true,
    error: "",
    //user login
    user: null,
    users: null,
    autoLoginUserId: null,
    //reviewer login
    firstName: null,
    //signup
    //changing user
    isChangingUser: false,
    //updating user
    isEditingUser: false,
    //verify account
    isVerifyingAccount: false,
    //add user
    isAddingUser: false,
  },
  reducers: {
    LoginProcessing: (state, action) => {
      state.isLoggedIn = false
      state.accountId = null
      state.status = null
      state.role = null
      state.notificationCount = 0
      state.isEmailVerified = false
      state.isLoggingIn = true
      state.error = ""
      state.user = null
      state.users = null
      state.autoLoginUserId = false
      state.firstName = null
      state.setupStatus = null
      state.applicationStatus = null
    },
    LoginUserSuccess: (state, action) => {
      state.isLoggedIn = true
      state.accountId = action.payload.accountId
      state.status = action.payload.status
      state.role = action.payload.role
      state.notificationCount = action.payload.notificationCount
      state.isEmailVerified = action.payload.isEmailVerified
      state.isLoggingIn = false
      state.error = ""

      state.user = action.payload.user;
      state.users = action.payload.users;
      state.autoLoginUserId = action.payload.autoLoginUserId
    },
    LoginReviewerSuccess: (state, action) => {
      state.isLoggedIn = true
      state.accountId = action.payload.accountId
      state.status = action.payload.status
      state.role = action.payload.role
      state.notificationCount = action.payload.notificationCount
      state.isEmailVerified = action.payload.isEmailVerified
      state.isLoggingIn = false
      state.error = ""
      state.firstName = action.payload.firstName
      state.setupStatus = action.payload.setupStatus
      state.applicationStatus = action.payload.applicationStatus
    },
    LoginAdminSuccess: (state, action) => {
      state.isLoggedIn = true
      state.accountId = action.payload.accountId
      state.status = action.payload.status
      state.role = action.payload.role
      state.isEmailVerified = true
      state.isLoggingIn = false
      state.error = ""
    },
    LoginError: (state, action) => {
      state.isLoggedIn = false
      state.accountId = null
      state.status = null
      state.role = null
      state.notificationCount = 0
      state.isEmailVerified = false
      state.isLoggingIn = false
      state.error = action.payload.error

      state.user = null
      state.users = null
      state.autoLoginUser = false
      state.firstName = null
      state.setupStatus = null
      state.applicationStatus = null
    },
    LoginReset: (state, action) => {
      state.isLoggedIn = false
      state.accountId = null
      state.status = null
      state.role = null
      state.notificationCount = 0
      state.isEmailVerified = false
      state.isLoggingIn = false
      state.error = ""

      state.user = null
      state.users = null
      state.autoLoginUserId = false
      state.firstName = null
      state.setupStatus = null
      state.applicationStatus = null
    },
    ChangeUserProcessing: (state, action) => {
      state.isChangingUser = true;
    },
    ChangeUserSuccess: (state, action) => {
      state.user = action.payload.user;
      state.isChangingUser = false;
      state.autoLoginUserId = action.payload.autoLoginUserId
      state.notificationCount = action.payload.notificationCount
    },
    ChangeUserError: (state, action) => {
      state.isChangingUser = false;
    },
    AddUserProcessing: (state, action) => {
      state.isAddingUser = true;
    },
    AddUserSuccess: (state, action) => {
      state.users = action.payload.users;
      state.isAddingUser = false;
    },
    AddUserError: (state, action) => {
      state.isAddingUser = false;
    },
    EditUserProcessing: (state, action) => {
      state.isEditingUser = true
    },
    EditUserSuccess: (state, action) => {
      state.users = action.payload.users;
      state.isEditingUser = false
      const user = action.payload.user
      if (user._id === state.user._id)
        state.user = user
    },
    EditUserError: (state, action) => {
      state.isEditingUser = false
    },
    VerifyAccountProcessing: (state, action) => {
      state.isVerifyingAccount = true
    },
    VerifyAccountSuccess: (state, action) => {
      state.isEmailVerified = action.payload.isEmailVerified;
      state.isVerifyingAccount = false
    },
    VerifyAccountError: (state, action) => {
      state.isVerifyingAccount = false
    },
    EditReviewerStatus: (state, action) => {
      state.status = action.payload.status
      state.setupStatus = action.payload.setupStatus
    },
    ResetNotificationCount: (state) => {
      state.notificationCount = 0
    },
    LogoutSuccess: (state, action) => {
      state.isLoggedIn = false
      state.accountId = null
      state.status = null
      state.role = null
      state.notificationCount = 0
      state.isEmailVerified = false
      state.isLoggingIn = false
      state.error = ""

      state.user = null
      state.users = null
      state.autoLoginUserId = null
      state.firstName = null
    }
  }
});

export const {
  LoginProcessing, LoginUserSuccess, LoginReviewerSuccess, LoginAdminSuccess, LoginError, LoginReset,
  ChangeUserProcessing, ChangeUserSuccess, ChangeUserError,
  AddUserProcessing, AddUserSuccess, AddUserError,
  EditUserProcessing, EditUserSuccess, EditUserError,
  VerifyAccountProcessing, VerifyAccountSuccess, VerifyAccountError,
  EditReviewerStatus, ResetNotificationCount,
  LogoutSuccess } = LoginSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const LoginAccount = (email, password) => async dispatch => {

  dispatch(LoginProcessing());

  //remove token from storage
  localStorage.removeItem('account_token');

  const credentials = {
    email: email,
    password: password
  }

  try {
    let response = await fetch(getEndpoint() + "authentication/login", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials)
    });

    response = await response.json();
    if (response.success) {
      const accountId = response.payload.accountId
      const status = response.payload.status
      const role = response.payload.role
      const notificationCount = response.payload.notificationCount
      const isEmailVerified = response.payload.isEmailVerified
      const referralCode = response.payload.referralCode
      const token = response.payload.token;
      //set token in local storage
      localStorage.setItem('account_token', token);
      //set user
      ReactGA.set({ userId: accountId });

      if (role === "user") {
        const user = response.payload.user
        const users = response.payload.users
        const autoLoginUserId = response.payload.autoLoginUserId
        return dispatch(LoginUserSuccess({
          accountId: accountId,
          status: status,
          role: role,
          notificationCount: notificationCount,
          user: user,
          users: users,
          isEmailVerified: isEmailVerified,
          referralCode: referralCode,
          autoLoginUserId: autoLoginUserId,
          token: token
        }));
      }
      else if (role === "reviewer") {
        const firstName = response.payload.firstName
        const status = response.payload.status
        const setupStatus = response.payload.setupStatus
        const applicationStatus = response.payload.applicationStatus
        return dispatch(LoginReviewerSuccess({
          accountId: accountId,
          status: status,
          setupStatus: setupStatus,
          applicationStatus: applicationStatus,
          role: role,
          notificationCount: notificationCount,
          firstName: firstName,
          isEmailVerified: isEmailVerified,
          token: token
        }));
      }
      else if (role === "admin") {
        return dispatch(LoginAdminSuccess({
          accountId: accountId,
          status: status,
          role: role,
          token: token
        }));
      }
      dispatch(LoginError("Error with account - contact support"));
    }
    else {
      dispatch(LoginError({ error: response.message }));
    }
  }
  catch (error) {
    dispatch(LoginError(error));
  }
};

export const LoginWithToken = (token) => async dispatch => {

  dispatch(LoginProcessing());

  //remove token from storage
  try {
    let response = await fetch(getEndpoint() + "authentication/login-with-token", {
      method: "POST",
      headers: getAuthHeaders()
    });

    response = await response.json();
    localStorage.removeItem('account_token');
    if (response.success) {
      const accountId = response.payload.accountId
      const status = response.payload.status
      const role = response.payload.role
      const notificationCount = response.payload.notificationCount
      const isEmailVerified = response.payload.isEmailVerified
      const referralCode = response.payload.referralCode
      const token = response.payload.token;
      localStorage.setItem('account_token', token);
      //set user
      ReactGA.set({ userId: accountId });

      if (role === "user") {
        const user = response.payload.user
        const users = response.payload.users
        const autoLoginUserId = response.payload.autoLoginUserId
        return dispatch(LoginUserSuccess({
          accountId: accountId,
          status: status,
          role: role,
          notificationCount: notificationCount,
          user: user,
          users: users,
          isEmailVerified: isEmailVerified,
          referralCode: referralCode,
          autoLoginUserId: autoLoginUserId,
          token: token
        }));
      }
      else if (role === "reviewer") {
        const firstName = response.payload.firstName
        const status = response.payload.status
        const setupStatus = response.payload.setupStatus
        const applicationStatus = response.payload.applicationStatus
        return dispatch(LoginReviewerSuccess({
          accountId: accountId,
          status: status,
          setupStatus: setupStatus,
          applicationStatus: applicationStatus,
          role: role,
          notificationCount: notificationCount,
          firstName: firstName,
          isEmailVerified: isEmailVerified,
          token: token
        }));
      }
      else if (role === "admin") {
        return dispatch(LoginAdminSuccess({
          accountId: accountId,
          status: status,
          role: role,
          token: token
        }));
      }
      dispatch(LoginError("Error with account - contact support"));
    }
    else {
      dispatch(LoginError(response.message));
    }
  }
  catch (error) {
    console.log(error)
    localStorage.removeItem('account_token');
    dispatch(LoginError(error));
  }
};

export const UserSignup = (firstName, lastName, email, password, birthday, referralCode, allowPromos) => async dispatch => {

  dispatch(LoginProcessing());

  //remove token from storage
  localStorage.removeItem('account_token');

  const signup = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    birthday: birthday,
    referralCode: referralCode,
    allowPromos: allowPromos
  }

  try {
    let response = await fetch(getEndpoint() + "signup/account", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(signup)
    });

    response = await response.json();
    if (response.success) {
      const accountId = response.payload.accountId
      const role = response.payload.role
      const isEmailVerified = response.payload.isEmailVerified
      const referralCode = response.payload.referralCode
      const token = response.payload.token;
      const user = response.payload.user
      const users = response.payload.users
      const autoLoginUserId = response.payload.autoLoginUserId

      localStorage.setItem('account_token', token);
      //set user
      ReactGA.set({ userId: accountId });
      ReactGA.event({
        category: 'User Events',
        action: 'user_signup',
        label: 'User Signup'
      });
      //fb pixel event
      //ReactPixel.trackCustom("user-signup", {})
      //dataLayer.push({'event': 'user_signup'})
      TagManager.dataLayer({
        dataLayer: {
          event: "user_signup"
        }
      })


      return dispatch(LoginUserSuccess({
        accountId: accountId,
        role: role,
        notificationCount: 0,
        user: user,
        users: users,
        isEmailVerified: isEmailVerified,
        referralCode: referralCode,
        autoLoginUserId: autoLoginUserId,
        token: token
      }));
    }
    else {
      console.log(response.message)
      dispatch(LoginError({ error: response.message }));
    }
  }
  catch (error) {
    console.log(error)
    dispatch(LoginError({ error: error }));
  }
};

export const ReviewerSignup = (firstName, lastName, email, phone, password, birthday, referralCode, sport,
  athleticExperience, coachingExperience, achievements, socialLinks) => async dispatch => {

    dispatch(LoginProcessing());

    //remove token from storage
    localStorage.removeItem('account_token');

    const signup = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password,
      birthday: birthday,
      referralCode: referralCode,
      sport: sport,
      athleticExperience: athleticExperience,
      coachingExperience: coachingExperience,
      achievements: achievements,
      socialLinks: socialLinks,
    }

    try {
      let response = await fetch(getEndpoint() + "signup/reviewer", {
        method: "POST",
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(signup)
      });

      response = await response.json();
      if (response.success) {
        const accountId = response.payload.accountId
        const role = response.payload.role
        const isEmailVerified = response.payload.isEmailVerified
        const referralCode = response.payload.referralCode
        const firstName = response.payload.firstName
        const status = response.payload.status
        const setupStatus = response.payload.setupStatus
        const applicationStatus = response.payload.applicationStatus
        const token = response.payload.token

        localStorage.setItem('account_token', token);
        return dispatch(LoginReviewerSuccess({
          accountId: accountId,
          status: status,
          setupStatus: setupStatus,
          applicationStatus: applicationStatus,
          role: role,
          notificationCount: 0,
          isEmailVerified: isEmailVerified,
          referralCode: referralCode,
          firstName: firstName,
          token: token
        }));
      }
      else {
        console.log(response.message)
        dispatch(LoginError({ error: response.message }));
      }
    }
    catch (error) {
      console.log(error)
      dispatch(LoginError({ error: error }));
    }
  };

export const ChangeUser = (userId, isAutoLogin) => async dispatch => {

  dispatch(ChangeUserProcessing());

  try {
    let response = await fetch(getEndpoint() + "authentication/change-user", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        userId: userId,
        isAutoLogin: isAutoLogin
      })
    });

    response = await response.json()
    localStorage.removeItem('account_token')
    if (response.success) {
      const user = response.payload.user
      const autoLoginUserId = response.payload.autoLoginUserId
      const newToken = response.payload.token
      const notificationCount = response.payload.notificationCount
      localStorage.setItem('account_token', newToken)

      dispatch(ChangeUserSuccess({
        user: user,
        autoLoginUserId: autoLoginUserId,
        notificationCount: notificationCount
      }));
    }
    else {
      dispatch(ChangeUserError(response.message));
    }
  }
  catch (error) {
    localStorage.removeItem('account_token');
    dispatch(ChangeUserError(error));
  }
};

export const AddUser = (firstName, lastName, gender, color, sports, birthday) => async dispatch => {

  dispatch(AddUserProcessing());

  try {
    let response = await fetch(getEndpoint() + "account/add-user", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        firstName,
        lastName,
        gender,
        color,
        sports,
        birthday
      })
    });

    response = await response.json();
    if (response.success) {
      const users = response.payload.users;
      dispatch(AddUserSuccess({
        users: users
      }));
    }
    else {
      toast.error(response.message)
      dispatch(AddUserError(response.message));
    }
  }
  catch (error) {
    dispatch(AddUserError(error));
  }
};

export const EditUser = (userId, firstName, lastName, gender, color, sports) => async dispatch => {

  dispatch(AddUserProcessing());

  try {
    let response = await fetch(getEndpoint() + "account/update-user", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        userId,
        firstName,
        lastName,
        gender,
        color,
        sports
      })
    });

    response = await response.json();
    if (response.success) {
      const users = response.payload.users;
      const user = response.payload.user;
      dispatch(EditUserSuccess({
        users: users,
        user: user
      }));
    }
    else {
      dispatch(AddUserError(response.message));
    }
  }
  catch (error) {
    dispatch(AddUserError(error));
  }
};

export const DeleteUser = (userId) => async dispatch => {

  dispatch(EditUserProcessing());

  try {
    let response = await fetch(getEndpoint() + "account/delete-user", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        userId
      })
    });

    response = await response.json();
    if (response.success) {
      const users = response.payload.users;
      const user = response.payload.user;
      dispatch(EditUserSuccess({
        users: users,
        user: user
      }));
    }
    else {
      console.log(response.message)
      toast.error('Could not delete user')
      dispatch(EditUserError(response.message));
    }
  }
  catch (error) {
    console.log(error)
    toast.error('Could not delete user')
    dispatch(EditUserError(error));
  }
};

export const VerifyAccountEmail = (verificationCode) => async dispatch => {

  dispatch(VerifyAccountProcessing());

  try {
    let response = await fetch(getEndpoint() + "authentication/verify-account-email", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        verificationCode: verificationCode
      })
    });

    response = await response.json();
    if (response.success) {
      const isEmailVerified = response.payload.isEmailVerified;
      toast.success("Email Verified!")
      dispatch(VerifyAccountSuccess({ isEmailVerified: isEmailVerified }));
    }
    else {
      toast.error("Invalid Verification Code")
      dispatch(VerifyAccountError(response.message));
    }
  }
  catch (error) {
    toast.error("Invalid Verification Code")
    dispatch(VerifyAccountError(error));
  }
};

export const ResendEmailVerification = () => async dispatch => {

  try {
    let response = await fetch(getEndpoint() + "authentication/send-email-verification", {
      method: "POST",
      headers: getAuthHeaders()
    });

    response = await response.json();
    if (response.success) {
      //toast notification
      toast.success("Email Verification Sent!")
    }
    else {
      //toast notification
      toast.error("Could Not Send Email")
    }
  }
  catch (error) {
    toast.error("Could Not Send Email")
  }
};

export const Logout = () => async dispatch => {

  try {
    localStorage.removeItem('account_token');
  }
  catch (error) {
  }
  dispatch(LogoutSuccess());
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectLogin = state => state.login;

export default LoginSlice.reducer;
