import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import {
  validateImage
} from '../input-validation/InputValidation';

export const ReviewerDashboardSlice = createSlice({
  name: 'reviewerDashboard',
  initialState: {
    reviewer: null,
    activeRequests: null,
    notifications: null,
    completedRequests: null,
    status: "processing", //idle, fetching, success, error, uploading-video
    error: ""
  },
  reducers: {
    Loading: (state) => {
      state.status = "processing";
      state.error = "";
    },
    UpdateDashboard: (state, action) => {
      state.reviewer = action.payload.reviewer;
      state.activeRequests = action.payload.activeRequests;
      state.notifications = action.payload.notifications;
      state.completedRequests = action.payload.completedRequests;
      state.status = "success";
      state.error = "";
    },
    Error: (state, action) => {
      state.status = "error";
      state.error = action.payload;
    }
  }
});

export const {
  Loading,
  UpdateDashboard,
  Error
} = ReviewerDashboardSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const FetchDashboard = (reviewerId) => async dispatch => {

  dispatch(Loading());

  try {
    let response = await fetch(getEndpoint() + "reviewer/get-dashboard", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ reviewerId: reviewerId })
    });

    response = await response.json();
    if (response.success) {
      const reviewer = response.payload.reviewer;
      const activeRequests = response.payload.activeRequests;
      const notifications = response.payload.notifications;
      const completedRequests = response.payload.completedRequests;

      dispatch(UpdateDashboard({
        reviewer: reviewer,
        activeRequests: activeRequests,
        notifications: notifications,
        completedRequests: completedRequests
      }));
    }
    else {
      dispatch(Error(response.message));
    }
  }
  catch (error) {
    dispatch(Error(error));
  }
}


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectReviewerDashboard = state => state.reviewerDashboard;

export default ReviewerDashboardSlice.reducer;
