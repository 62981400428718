import React, { useState, useEffect, useReducer } from 'react';
//libraries
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
//state
import { LoginAccount, LoginProcessing, selectLogin } from '../../slices/LoginSlice';
//components
//styles
import styles from './ApplicationStatus.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import Loader from '../loader/Loader';

function ApplicationStatus() {

  //redux
  const login = useSelector(selectLogin);
  const dispatch = useDispatch();
  //router
  const history = useHistory();

  const getAppStatus = () => {
    switch (login.applicationStatus) {
      case "pending":
        return "Awaiting Review"
      case "rejected":
        return "Declined"
    }
  }

  if (login.isLoggingIn)
    return <Loader />

  if (login.status !== "application")
    return <Redirect to="/" />

  return (
    <div className={mjes.pageContainer}>
      <div className={mjes.pageContentContainer}>
        <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Application Status</div>
        <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Sign Up</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>2</div>
            <div className={mjes.label}>Verify Email</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>3</div>
            <div className={mjes.label}>Replay Will Contact You</div>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <div className={mjes.contentLabel}>Your coaching application is currently</div>
          <div className={mjes.featuredTextSecondary}>{getAppStatus()}</div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          {login.applicationStatus === "pending" &&
            <div>
              <div style={{margin:"1rem 0"}}>
                <div className={mjes.label}>If Selected:</div>
                <div style={{margin:".25rem 0"}}>1. You will receive an email from Replay letting you know that you have been selected for the next round of onboarding</div>
                <div style={{margin:".5rem 0"}}>2. Replay will arrange a time with you for a remote video call where you have the opportunity to meet some of our team and ask any questions</div>
                <div style={{margin:".5rem 0"}}>3. If there is a good fit, we will email you a link to fill out a background check that Replay will pay for - We take working with young athletes very seriously</div>
                <div style={{margin:".5rem 0"}}>4. Upon passing the background check, you will be able to sign into your Replay account and setup your public facing profile.  Replay encourages you to work with our team to build the optimal profile</div>
              </div>
              <div style={{margin:"2rem 0"}}>
                <div className={mjes.label}>If Not Selected:</div>
                <div style={{margin:".25rem 0"}}>1. You will receive an email from Replay letting you know that you have not been selected for the next round of onboarding</div>
              </div>
              <div style={{margin:"2rem 0"}}>
                <div className={mjes.label}>If Status Has Not Changed:</div>
                <div style={{margin:".25rem 0"}}>1. Replay is very deliberate with selecting the master coaches that will be allowed on the platform</div>
                <div style={{margin:".25rem 0"}}>2. Replay balances supply and demand to ensure that each selected coach has available demand so you may be waitlisted until demand grows</div>
              </div>
            </div>            
          }
        </div>
      </div>
    </div >
  );
}

export default ApplicationStatus;
