import React, { useEffect, useState } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal'
//state
import { FetchReviewerProfile, selectReviewerProfile, UpdateProfilePicture, UpdateProfileContent } from '../../slices/ReviewerProfileSlice';
import { ResetNotificationCount, selectLogin } from '../../slices/LoginSlice';
//components
import Loader from '../loader/Loader'
import ProfilePicture from '../update-profile-picture/UpdateProfilePicture';
import ProfileContent from '../update-profile-content/UpdateProfileContent';
//styles
import styles from './ReviewerProfile.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import Icon from '../../icons/Icon';
import ReviewerStatusEditor from '../reviewer-components/reviewer-status-editor/ReviewerStatusEditor';


function ReviewerProfile() {

  const [isEditPictureOpen, setIsEditPictureOpen] = useState(false)
  const [isEditContentOpen, setIsEditContentOpen] = useState(false)
  const [isEditStatusOpen, setIsEditStatusOpen] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const login = useSelector(selectLogin)
  const reviewerProfile = useSelector(selectReviewerProfile)
  const reviewer = reviewerProfile.reviewer
  const imageUrl = reviewerProfile.imageUrl
  const status = reviewerProfile.status
  const pictureStatus = reviewerProfile.pictureStatus
  const contentStatus = reviewerProfile.contentStatus

  
  useEffect(() => {
    dispatch(FetchReviewerProfile(login.accountId))
  }, []);

  const submitPicture = (image) => {
    dispatch(UpdateProfilePicture(image))
  }

  const submitContent = (price, title, aboutMe, feature1, feature2, feature3, videoGuidelines) => {
    setIsEditContentOpen(false);
    dispatch(UpdateProfileContent(price, title, aboutMe, feature1, feature2, feature3, videoGuidelines))
  }

  const closeEditPicture = () => {
    setIsEditPictureOpen(false)
  }

  const closeEditContent = () => {
    setIsEditContentOpen(false)
  }

  const getProfilePicture = () => {
    if (pictureStatus === "processing")
      return <Loader text="Uploading profile picture" />

    if (!isEditPictureOpen)
      return (
        <div>
          <div className={styles.headerGrid}>
            <div className={rs.labelMediumLight} style={{textAlign:"left", margin:"2rem 0 1rem 0"}}>Picture</div>
            <div className={mjes.iconButton} onClick={() => setIsEditPictureOpen(true)}>
              <Icon icon="gear" width="1.5rem" height="1.5rem" color="white" />
            </div>
          </div>
          <div>
            <img src={imageUrl} />
          </div>
        </div>
      )
    else
      return (
        <div>
          <div className={styles.headerGrid}>
            <div className={rs.labelMediumLight} style={{textAlign:"left", margin:"2rem 0 1rem 0"}}>Picture</div>
            <div className={mjes.iconButton} onClick={() => setIsEditPictureOpen(false)}>
              <Icon icon="x" width="1.5rem" height="1.5rem" color="white" />
            </div>
          </div>
          <div>
            <ProfilePicture submitPicture={submitPicture} close={closeEditPicture} error={reviewerProfile.pictureError} />
          </div>
        </div>
      )
  }

  const getProfileContent = () => {
    if (contentStatus === "processing")
      return <Loader text="Updating profile content" />

    if (!isEditContentOpen)
      return (
        <div>
          <div className={styles.headerGrid}>
            <div className={rs.labelMediumLight} style={{margin:"2rem 0 1rem 0", textAlign:"left"}}>Content</div>
            <div className={mjes.iconButton} onClick={() => setIsEditContentOpen(true)}>
              <Icon icon="gear" width="1.5rem" height="1.5rem" color="white" />
            </div>
          </div>
          <div>
            {/* <div className={styles.doubleColumn} >
              <div style={{ margin:".5rem 0 0 0" }}>
                <div className={rs.labelSmallLight}>First Name</div>
                <div className={rs.textSmallLight}>{reviewer.firstName}</div>
              </div>
              <div style={{ margin:".5rem 0 0 0" }}>
                <div className={rs.labelSmallLight}>Last Name</div>
                <div className={rs.textSmallLight}>{reviewer.lastName}</div>
              </div>
            </div> */}
            <div style={{ margin:".5rem 0 0 0" }}>
              <div className={rs.labelSmallLight}>Price</div>
              <div className={rs.textSmallLight}>{"$ " + reviewer.price}</div>
            </div>
            <div style={{ margin:".5rem 0 0 0" }}>
              <div className={rs.labelSmallLight}>Title</div>
              <div className={rs.textSmallLight}>{reviewer.title}</div>
            </div>
            <div style={{ margin:".5rem 0 0 0" }}>
              <div className={rs.labelSmallLight}>Featured Experience</div>
              <div>
                <div className={styles.featuredGrid}>
                  <div>-</div>
                  <div className={rs.textSmallLight}>{reviewer.feature1}</div>
                </div>
                <div className={styles.featuredGrid}>
                  <div>-</div>
                  <div className={rs.textSmallLight}>{reviewer.feature2}</div>
                </div>
                <div className={styles.featuredGrid}>
                  <div>-</div>
                  <div className={rs.textSmallLight}>{reviewer.feature3}</div>
                </div>
              </div>
            </div>
            <div style={{ margin:".5rem 0 0 0" }}>
              <div className={rs.labelSmallLight}>About Me</div>
              <div className={rs.textSmallLight} style={{ whiteSpace: "pre-wrap" }}>{reviewer.aboutMe}</div>
            </div>
            <div style={{ margin:".5rem 0 0 0" }}>
              <div className={rs.labelSmallLight}>Video Guidelines</div>
              <div className={rs.textSmallLight} style={{ whiteSpace: "pre-wrap" }}>{reviewer.videoGuidelines}</div>
            </div>
          </div>
        </div>
      )
    else
      return (
        <div>
          <div className={styles.headerGrid}>
            <div className={rs.labelMediumLight}>Content</div>
            <div className={mjes.iconButton} onClick={() => setIsEditContentOpen(false)}>
              <Icon icon="x" width="1.5rem" height="1.5rem" color="white" />
            </div>
          </div>
          <div>
            <ProfileContent submitContent={submitContent} reviewer={reviewer} />
          </div>
        </div>
      )
  }

  if (status === 'processing')
    return <Loader text="Loading Profile" />
  else if (status === 'error')
    return (
      <div>
        <div className={mjes.pageLabel}>Profile</div>
        <div className={mjes.pageContentContainer} >
          <div className={mjes.contentLabel}>Error retrieving profile</div>
        </div>
      </div>
    )

  return (
    <div>
      <div className={rs.h1MediumLight}>Profile</div>
      <div className={mjes.pageContentContainer} style={{ marginBottom: "2rem" }}>
        <div>
          <ReviewerStatusEditor status={reviewer.status} />
        </div>
        <div>
          {getProfilePicture()}
        </div>
        <div style={{ marginTop: "2rem" }}>
          {getProfileContent()}
        </div>
      </div>
      {/* <ReactModal className={mjes.modal} overlayClassName={mjes.overlay} isOpen={isEditPictureOpen} contentLabel="Edit Picture" ariaHideApp={false}>
        <ProfilePicture submitPicture={submitPicture} close={closeEditPicture} error={reviewerProfile.pictureError} />
      </ReactModal>
      <ReactModal className={mjes.modal} overlayClassName={mjes.overlay} isOpen={isEditContentModalOpen} contentLabel="Edit Content" ariaHideApp={false}>
        <ProfileContent submitContent={submitContent} close={closeEditContent} reviewer={reviewer} />
      </ReactModal> */}
    </div>
  );
}

export default ReviewerProfile;