import React, { useEffect } from 'react';
//libraries
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//state
import { selectUser } from '../../slices/UserSlice';
import { selectNotifications, FetchNotifications } from '../../slices/NotificationSlice';
//components
//styles
import styles from './UserNotifications.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import layout from '../../app-styles/Layout.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';

function UserNotifications() {

  //redux
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const notifications = useSelector(selectNotifications);
  const page = notifications.page;
  const pages = notifications.pages;
  //router
  const history = useHistory();


  useEffect(() => {
    dispatch(FetchNotifications(user._id, page, 20));
  }, []);


  if (notifications.status === 'processing')
    return <div>Loading Notifications</div>
  else if (notifications.status === 'error')
    return <div>Something went wrong.  Try reloading the page</div>

  return (
    <div className={layout.pageContainer}>
      <div className={rs.h1MediumLight}>Notifications</div>
      <div className={layout.pageContent}>
        {notifications.results.length > 0 ?
          notifications.results.map(notification => (
            <div className={styles.notification} key={notification.dateCreated + notification.type}>
              <div className={styles.notificationType}>{notification.friendlyName}</div>
              <div className={styles.notificationText}>{notification.message}</div>
            </div>
          )) :
          <div className={layout.pageContent}>
            <div className={mjes.contentText}>You have no notifications</div>
          </div>}
      </div>
    </div>
  );
}

export default UserNotifications;