import React from 'react'
import mjes from '../../mjes-styles/mjes.module.css'


const Loader = (props) => {

  const message = props.message
  const submessage = props.submessage

  return (
    <div className={mjes.spinLoaderContainer}>
      <div className={mjes.spinLoader}></div>
      <div className={mjes.contentLabel}>{message}</div>
      <div className={mjes.label_s}>{submessage}</div>
    </div>
  )
}

export default Loader