import React, { useRef } from "react"
import SearchReviewerMobile from "../search-reviewer/SearchReviewerMobile"
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import rs from '../../styles/ReplayStyles.module.css'
import { useViewport } from "../../hooks/useViewport"
import { useHistory } from "react-router"

const SearchResultsAll = (props) => {

  const results = props.results

  return (
    <div>
      {results.map((result) =>
        <div>
          <ResultSection result={result} bucket={props.bucket} />
        </div>
      )}
    </div>
  )
}

export default SearchResultsAll


const ResultSection = (props) => {

  const result = props.result
  const imageBucket = props.bucket
  const title = result.title
  const reviewers = result.reviewers
  const { width, height } = useViewport();
  const scrollRef = useRef()
  const history = useHistory()
  const hasSeeMore = props.hasSeeMores

  const onTitleClick = () => {
    if (result.sport === "all") return
    if (result.sport === 'golf')
      return history.push('/golf/')
    history.push('/browse/' + result.sport)
  }

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  }

  return (
    <div style={{ padding: "1rem .5rem", maxWidth: "100rem", margin: "0 auto" }}>
      <div style={{ display: "flex", margin: "0rem 0", alignItems: "center" }}>
        <div className={rs.h1SmallLight} style={{ flexGrow: "1", cursor: "pointer", fontSize: "1.25rem" }} onClick={() => onTitleClick()}>{title}</div>
        <div className={rs.labelM} onClick={() => scroll(-150)} style={{ cursor: "pointer" }} >
          <ArrowBackIos />
        </div>
        <div style={{ width: ".5rem" }}></div>
        <div className={rs.labelM} onClick={() => scroll(150)} style={{ cursor: "pointer" }} >
          <ArrowForwardIos />
        </div>
      </div>
      <div style={{}}>
        <div ref={scrollRef} style={{ display: "flex", overflow: width <= 768 ? "auto" : "hidden" }}>
          {reviewers && reviewers.map((reviewer) =>
            <>
              <div style={{ marginRight: "1rem", minWidth: "18rem", width: "18rem" }}>
                <SearchReviewerMobile reviewer={reviewer} bucket={imageBucket} sport="golf" scrollY={0} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}