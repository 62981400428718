import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    user: {},
    loggedIn: false,
    token: {},
    isFetching: false,
    betaLoggedIn: false,
    error: "",
    status: "success"
  },
  reducers: {
    FetchUser: (state, action) => {
      state.isFetching = true;
    },
    UpdateUserLogin: (state, action) => {
      state.user = action.payload.user;
      state.loggedIn = true;
      state.isFetching = false;
      state.error = "";
    },
    UpdateUserError: (state, action) => {
      state.user = {};
      state.loggedIn = false;
      state.isFetching = false;
      state.error = action.payload;
    },
    UpdateUserLogout: (state, action) => {
      state.user = {};
      state.loggedIn = false;
      state.isFetching = false;
      state.error = "";
    },
    BetaLogin: (state) => {
      state.betaLoggedIn = true;
    },
    BetaLogout: (state) => {
      state.betaLoggedIn = false;
    },
    UpdatingUserInformation: (state, action) => {
      state.status = "processing";
    },
    UpdateUserInformation: (state, action) => {
      state.status = "success";
      state.user = action.payload.user;
    },
    UpdateUserInformationError: (state, action) => {
      state.status = "error";
      state.user = {};
    }
  },
});

export const { FetchUser, UpdateUserLogin, UpdateUserError, UpdateUserLogout, BetaLogin, BetaLogout,
  UpdatingUserInformation, UpdateUserInformation, UpdateUserInformationError } = UserSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const UserLogin = (email, password) => async dispatch => {

  dispatch(FetchUser());

  const userCredentials = {
    email: email,
    password: password
  }

  try {
    let response = await fetch(getEndpoint() + "api/Login", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(userCredentials)
    });

    response = await response.json();
    if (response.success) {
      const user = response.payload;
      const token = response.token;
      localStorage.setItem('user', JSON.stringify(user));
      dispatch(UpdateUserLogin({ user: user }));
    }
    else {
      dispatch(UpdateUserError(response.message));
    }
  }
  catch (error) {
    dispatch(UpdateUserError(error));
  }
};

export const UserLogout = () => async dispatch => {

  try {
    localStorage.removeItem('user');
    //mark user as logged out 
  }
  catch (error) {
  }
  dispatch(UpdateUserLogout());
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUserSlice = state => state.user;
export const selectUser = state => state.user.user;
export const selectLoggedIn = state => state.user.loggedIn;
export const selectError = state => state.user.error;
export const selectIsFetching = state => state.user.isFetching;
export const selectBetaLoggedIn = state => state.user.betaLoggedIn;

export default UserSlice.reducer;
