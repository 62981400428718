import React, { useState } from 'react'
//libraries
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive';
import { validateEmail } from '../../input-validation/InputValidation';
import { useViewport } from '../../hooks/useViewport';
import { Helmet } from "react-helmet"
//state
import { selectLogin } from '../../slices/LoginSlice'
//images
import IPhone from '../../images/assets/Mack-Iphone.png'
import Step1 from '../../images/assets/step1.png'
import Step2 from '../../images/assets/step2.png'
import Step3 from '../../images/assets/step3.png'
//styles
import styles from './HomePage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import FeaturedReplays from './FeaturedReplays';
import FeaturedCoaches from './FeaturedCoaches';
import PickYourSport from './PickYourSport';
import BetaSignup2 from '../beta-signup/BetaSignupNoHeader';

const HomePage = () => {

  const history = useHistory();
  const login = useSelector(selectLogin)
  const isSmall = useMediaQuery({ query: '(max-width: 40rem)' })

  const [email, setEmail] = useState("")
  const [emailStatus, setEmailStatus] = useState("awaiting")
  const [emailError, setEmailError] = useState()
  const [honeypotPassword, setHoneypotPassword] = useState("")

  const { width, height } = useViewport();
  let screenSize;
  if (width <= 480)
    screenSize = "small"
  else if (width <= 768)
    screenSize = "medium"
  else
    screenSize = "large"

  const updateEmail = (email) => {
    setEmail(email);
    const validation = validateEmail(email);
    if (validation.isValid) {
      setEmailStatus("valid");
      setEmailError(null);
    }
    else {
      setEmailStatus("invalid")
      setEmailError(validation.error);
    }
  }

  const smallJumbo = () => {
    return (
      <div style={{ margin: "1rem 0" }} >
        <div>
          <div>
            <div className={rs.h1S} style={{ textAlign: "center", letterSpacing: ".05rem" }}>UP YOUR <span style={{ color: "white" }}>GAME</span>. FRAME BY <span style={{ color: "white" }}>Frame.</span></div>
          </div>
          <div style={{ margin: ".75rem 0", maxWidth: "30rem", textAlign: "center" }}>
            <div className={rs.textM} style={{fontSize:"1.3rem", textAlign:"center"}}>{"Play like pro & college athletes by sending them your sports clips to get 1-on-1 private coaching videos"}</div>
          </div>
          <div>
            <BetaSignup2 />
          </div>
        </div>
      </div >
    )
  }

  const mediumJumbo = () => {
    return (
      <div>
        <div>
          <div>
            <div className={rs.h1M} style={{ textAlign: "center" }}>UP YOUR <span style={{ color: "white" }}>GAME</span>, FRAME BY <span style={{ color: "white" }}>Frame</span></div>
          </div>
          <div style={{ margin: ".25rem auto", maxWidth: "25rem", textAlign: "center" }}>
            <div className={rs.textM} style={{fontSize:"1.3rem"}} >{"Play like pro & college athletes by sending them your sports clips to get 1-on-1 private coaching videos"}</div>
          </div>
          <div>
            <BetaSignup2 />
          </div>
        </div>
      </div>
    )
  }

  const largeJumbo = () => {
    return (
      <div style={{ margin: "1rem 0 0 0" }}>
        <div>
          <div>
            <div className={rs.h1XL} style={{ textAlign: "center" }}>UP YOUR <span style={{ color: "white" }}>GAME</span>, FRAME BY <span style={{ color: "white" }}>Frame</span></div>
          </div>
          <div style={{ margin: ".5rem auto", maxWidth: "40rem", textAlign: "center" }}>
            <div className={rs.textL} >{"Play like pro & college athletes by sending them your sports clips to get 1-on-1 private coaching videos"}</div>
          </div>
          <div>
            <BetaSignup2 />
          </div>
        </div>
      </div>
    )
  }

  const getJumbo = () => {
    switch (screenSize) {
      case "small":
        return smallJumbo()
      case "medium":
        return mediumJumbo()
      default:
        return largeJumbo()
    }
  }

  return (
    <div style={{ margin: "0 .5rem" }}>
      <div style={{ margin: "0 auto", maxWidth: "70rem" }}>
        <Helmet>
          <title>The Replay App | Video Coaching From Professional Athletes</title>
          <meta name="description" content="Get 1-1 video coaching from professional and college athletes.  Upload a video and get back a Replay video review from a pro using high tech video analysis software.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
        </Helmet>
        <div>
          {getJumbo()}
        </div>
        <div style={{ marginTop: "1rem" }} >
          <FeaturedReplays />
        </div>
        <div style={{ margin: "2rem 0" }}>
          <FeaturedCoaches />
        </div>
        <div style={{ margin: "3rem 0 0rem 0" }}>
          <PickYourSport />
        </div>
        <div style={{ height: ".1rem", backgroundColor: "#ff14af", maxWidth: "70rem", margin: "4rem auto 3rem auto" }}></div>
        <div>
          <div className={mjes.pageContentContainer} style={{ margin: "2rem auto", textAlign: "center" }}>
            <div className={rs.h1M}>How Replay Works</div>
          </div>
          <div style={{ margin: "0 auto 4rem auto" }}>
            <div className={styles.howitworks}>
              <div style={{ maxWidth: "300px" }}>
                <img src={Step1} style={{ width: "100%" }} />
                <div className={mjes.pageLabel} style={{ margin: "-2rem -1rem 0 0" }}>1</div>
                <div className={mjes.label_l}>{"Choose Coach"}</div>
                <div className={mjes.text_m}>{"Request coaching from your favorite pro or college athlete (background check verified)"}</div>
              </div>
              <div style={{ maxWidth: "300px", boxSizing: "border-box" }}>
                <img src={Step2} style={{ width: "100%" }} />
                <div className={mjes.pageLabel} style={{ margin: "-2rem -1rem 0 0" }}>2</div>
                <div className={mjes.label_l}>{"Upload Video"}</div>
                <div className={mjes.text_m}>{"Send them up to 60 sec. of your own clips (from your camera roll)"}</div>
              </div>
              <div style={{ maxWidth: "300px" }}>
                <img src={Step3} style={{ width: "100%" }} />
                <div className={mjes.pageLabel} style={{ margin: "-2rem -1rem 0 0" }}>3</div>
                <div className={mjes.label_l}>{"Get Feedback"}</div>
                <div className={mjes.text_m}>{"Get a personalized Replay video from them (up to 5-10 min)"}</div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", maxWidth: "40rem", margin: "2rem auto" }}>
            <video width="100%" playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/how-replay-works.png">
              <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/DemandDemo3.mp4"} />
            </video>
          </div>
        </div>
        <div style={{ height: ".1rem", backgroundColor: "#ff14af", maxWidth: "70rem", margin: "3rem auto 3rem auto" }}></div>
        <div>
          <div className={mjes.pageContentContainer} style={{ margin: "2rem auto", textAlign: "center" }}>
            <div className={rs.h1M}>Replay Pro Edition</div>
            <div className={rs.textM}>Pros give you an inside look into game footage and highlights</div>
          </div>
          <div style={{ margin: "0 auto 4rem auto" }}>
            <div className={styles.howitworks}>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Carson Jacobs"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Giselle Juarez breaks down her final 4 pitches to win 2021 NCAA WCWS"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/3.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/giselle-juarez-4-pitches.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Carson Jacobs"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Michael Sowers breaks down his first PLL goal"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/5.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/michael-sowers-first-goal.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Carson Jacobs"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Carson breaks down how Tiger Woods hit 1 of the most famous shots in golf history"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/6.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/carson-jacobs-tiger.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Patrick Benzan"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Pat breaks down the play that won Game 5 of the NBA Finals"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/2.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/patrick-benzan-game-5.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Golf Replay"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Ty breaks down a Shohei Ohtani ankle breaking slider"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/1.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/ty-hensley-shohota.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Gracie Kramer"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Gracie breaks down how Jade Carey won gold in Tokyo"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/9.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/gracie-kramer-gold-medal.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Mollie Korth"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Mack breaks down Mac Jones' first NFL pass"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/4.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/mack-trey-lance.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Golf Replay"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Scott breaks down shots from Sabbatini's silver in Tokyo"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/8.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/scott-pechacek-analysis.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Mollie Korth"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Mollie breaks down how MyKayla Skinner won silver in Tokyo"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/7.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/mollie-korth-silver-medal.mp4"} />
                </video>
              </div>
              <div style={{ maxWidth: "300px", marginBottom: "2rem" }}>
                {/* <div className={mjes.label_l}>{"Golf Replay"}</div> */}
                {/* <div className={mjes.text_m} style={{ height: "4rem" }}>{"Ty breaks down a Shohei Ohtani ankle breaking slider"}</div> */}
                <video style={{ maxWidth: "18rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/brad-smith.png">
                  <source src={"https://d2vtlih1ycguzs.cloudfront.net/home-page/brad-smith-breakdown.mp4"} />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage