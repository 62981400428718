import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { getReviewer } from '../../../requests/AdminRequests'
import mjes from '../../../mjes-styles/mjes.module.css'

const AdminReviewerPage = () => {

  const [reviewer, setReviewer] = useState()
  const [requests, setRequests] = useState()
  const [status, setStatus] = useState("processing")

  let { reviewerId } = useParams();
  const history = useHistory()

  useEffect(() => {
    loadReviewer()
  }, [])

  const loadReviewer = async () => {
    //
    const response = await getReviewer(reviewerId)
    if (response.success) {
      setReviewer(response.payload.reviewer)
      setRequests(response.payload.requests)
      console.log(response.payload.requests)
      setStatus("loaded")
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "#66ff00"
      case "unavailable":
        return "blue"
      case "suspended":
        return "red"
      case "setup":
        return "yellow"
    }
  }

  if (status === "processing")
    return (
      <div>Loading</div>
    )

  return (
    <div style={{ padding: ".5rem" }}>
      <div className={mjes.buttonPrimary} style={{ maxWidth: "10rem", margin: "0" }} onClick={() => history.push('/admin-reviewers')}>Back</div>
      <div style={{ margin: "1rem 0" }}>
        <div style={{ maxWidth: "18rem", display: "flex", alignItems: "center" }}>
          <div className={mjes.label_m} style={{ marginRight: "2rem" }}>{reviewer.firstName + " " + reviewer.lastName}</div>
          <div className={mjes.label_m} style={{ color: getStatusColor(reviewer.status) }}>{reviewer.status}</div>
        </div>
        <div style={{ marginTop: ".25rem", display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "18rem" }}>
            <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom: ".5rem" }}>Requests</div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Total - </div>
              <div className={mjes.text_s}>{reviewer.totalRequestCount} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Active - </div>
              <div className={mjes.text_s}>{reviewer.totalRequestCount - reviewer.totalDiscardCount - reviewer.totalReviewCount - reviewer.totalExpiredCount} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Completed - </div>
              <div className={mjes.text_s}>{reviewer.totalReviewCount} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Discarded - </div>
              <div className={mjes.text_s}>{reviewer.totalDiscardCount} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Expired - </div>
              <div className={mjes.text_s}>{reviewer.totalExpiredCount} </div>
            </div>
          </div>
          <div style={{ width: "18rem" }}>
            <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom: ".5rem" }}>Stats</div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Total Revenue - </div>
              <div className={mjes.text_s}>{"$ " + reviewer.totalRevenue} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Avg Rating - </div>
              <div className={mjes.text_s}>{reviewer.totalRatingValue / reviewer.totalRatingCount + " stars"} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Avg Time - </div>
              <div className={mjes.text_s}>{(reviewer.totalResponseTime / reviewer.totalReviewCount).toFixed(2) + " hrs"} </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: "2em 0" }}>
        {requests.map((request) =>
          <div style={{ backgroundColor: "#333333", margin: ".5rem 0", padding: ".5rem", cursor: "pointer" }} onClick={() => history.push('/admin-request/' + request._id)}>
            <div className={mjes.text_m}>{"Id: " + request._id}</div>
            <div className={mjes.text_m}>{"Status: " + request.status}</div>
            <div className={mjes.text_m}>{"Process: " + request.processStatus}</div>
            <div className={mjes.text_m}>{"Payment: " + request.paymentStatus}</div>
            <div className={mjes.text_m}>{"Due: " + new Date(request.dateDue)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminReviewerPage