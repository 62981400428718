import React, { useState } from 'react';
//libraries
import { getDimensions } from '../../helpers/Dimensions';
import { useViewport } from '../../hooks/useViewport';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom'
//state
//components
import Icon from '../../icons/Icon'
//styles
import styles from './ReviewVideo.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import buttons from '../../app-styles/Buttons.module.css'
import modal from '../../app-styles/Modal.module.css'

function ReviewVideo(props) {

  const request = props.request
  const history = useHistory()

  //state
  const [keyTakeaways, setKeyTakeaways] = useState("");
  const [showTakeawayError, setShowTakeawayError] = useState(false);
  const [isRejectMenuOpen, setIsRejectMenuOpen] = useState(false)

  //get viewport
  const { width, height } = useViewport();

  //calculate canvas dimensions
  const remPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const headerHeight = 0 //3.5 * remPx;
  const topBarHeight = 0//3.5 * remPx;
  const bottomBarHeight = 0 //5 * remPx;
  const leftBarWidth = 1.5 * remPx //5 * remPx;
  const rightBarWidth = 1.5 * remPx//5 * remPx;
  const availHeight = height - headerHeight - topBarHeight - bottomBarHeight - 8 * remPx;
  const availWidth = width - leftBarWidth - rightBarWidth;
  const canvasDimensions = getDimensions(availHeight, availWidth);
  const scrubWidth = canvasDimensions.width - 2.5 * remPx;
  const scrubHeight = 2 * remPx;
  const isTwoColumns = width > (canvasDimensions.width * 2 + 3 * remPx)

  //duration
  const validDuration = props.duration > 1 && props.duration < 60 * 11

  function accept() {
    props.uploadVideo(keyTakeaways);
  }

  function reject() {
    props.reject();
  }

  if (isRejectMenuOpen) {
    return (
      <div className={mjes.pageContentContainer} style={{ marginTop: "5rem" }}>
        <div className={styles.modalTitleGrid}>
          <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Delete Video</div>
          <div className={mjes.iconButton} style={{ fontWeight: "500", fontSize: "1.25rem" }} onClick={() => setIsRejectMenuOpen(false)}>
            <Icon icon="x" height="1.25rem" color="white" />
          </div>
        </div>
        <div className={rs.textSmallLight} style={{ marginBottom: "1rem" }}>
          Are you sure you want to delete this video?  You will not be able to recover this video once deleted.
        </div>
        <div className={rs.buttonPrimary} onClick={() => reject()}>Confirm Delete</div>
      </div>
    )
  }

  return (
    <div className={styles.reviewVideo}>
      <div>
        <div className={mjes.breadcrumbs} style={{ justifySelf: "left" }}>
          <div className={mjes.textLinkPrimary} onClick={() => history.push('/reviewer-profile/requests')}>{"Replays"}</div>
          <div style={{ margin: "0 .5rem" }}>
            <Icon icon="right" height=".75rem" color="gray" />
          </div>
          <div>{request.userFirstName }</div>
        </div>
      </div>
      <div style={{textAlign:"center"}}>
        <div className={mjes.label_l}>DO NOT LEAVE OR REFRESH THE PAGE!!!</div>
        <div className={mjes.text_m} style={{color:"yellow"}}>Doing so will delete your video</div>
      </div>
      {/* <div style={{ textAlign: "center" }}>
        <div className={mjes.pageLabel}>{"Replay For " + request.userFirstName}</div>
      </div> */}
      <div className={isTwoColumns ? styles.mainGrid : null} style={{ marginTop: "1rem", marginRight: "1rem" }}>
        <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
          <video width={canvasDimensions.width} height={canvasDimensions.height} crossOrigin="anonymous" controls >
            <source src={props.videoUrl} type="video/mp4" />
          </video>
        </div>
        <div style={{maxWidth:"45rem"}}>
          <div>
            <div className={rs.labelSmallLight}>Athlete:</div>
            <div className={rs.textSmallLight}>{request.userFirstName}</div>
          </div>
          <div style={{ margin: "1rem 0" }}>
            <div className={rs.labelSmallLight}>Request Details:</div>
            <div className={rs.textSmallLight}>{request.details? request.details : "No Details Provided"}</div>
          </div>
          <div style={{ margin: "1rem 0" }}>
            {!validDuration && 
              // <div>
              //   <div className={mjes.label_l}>Game Plan (optional)</div>
              //   <div className={mjes.text_s} >Provide some key takeways from the lesson - What should they focus on and how can they improve before sending another request</div>
              //   <div className={mjes.subText} style={{ textAlign: "right" }}>{keyTakeaways.length + "/500"}</div>
              //   <textarea className={mjes.textAreaInput} value={keyTakeaways} onChange={e => setKeyTakeaways(e.target.value)} maxLength="500" />
              // </div> :
              < div className={mjes.labelError} style={{ fontSize: "1.25rem" }}>Video must be between 1 second and 10 minutes</div>
            }
          </div>
          <div className={styles.buttonGrid}>
            {(validDuration && props.mode !== "practice") ?
              <div className={rs.buttonSecondary} onClick={() => accept()}>
                <div className={rs.labelLargeLight}>Accept</div>
              </div> :
              <div></div>
            }
            <div></div>
            <div className={rs.buttonPrimary} style={{}} onClick={() => setIsRejectMenuOpen(true)}>
              <Icon icon="trashcan" width="1rem" color="white" />
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ textAlign: "right" }}>
        <div className={mjes.linkButton} style={{ margin: ".5rem" }} onClick={() => { history.push('/reviewer-profile') }}>{"Exit >"}</div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.headerGrid}>
          <div className={mjes.contentLabel}>Athlete: </div>
          <div>{request.userFirstName}</div>
        </div>
        <div className={styles.headerGrid} style={{ marginBottom: "1rem" }}>
          <div className={mjes.contentLabel}>Details: </div>
          <div>{request.details}</div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <video width={canvasDimensions.width} height={canvasDimensions.height} crossOrigin="anonymous" controls>
          <source src={props.videoUrl} type="video/mp4" />
        </video>
      </div>
      <div className={styles.bottomContainer}>
        {validDuration ?
          <div>
            <div className={mjes.label}>Key Takeaways (Optional)</div>
            <div className={mjes.labelDescription} >Provide some key takeways from the lesson - What should they focus on and how can they improve before sending another request</div>
            <textArea className={mjes.textAreaInput} value={keyTakeaways} onChange={e => setKeyTakeaways(e.target.value)} maxLength="255" />
            <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + keyTakeaways.length + "/255"}</div>
          </div> :
          < div className={mjes.labelError} style={{fontSize:"1.25rem"}}>Video must be between 3 and 5 minutes</div>
        }
        <div className={styles.buttonGrid}>
          <div></div>
          {validDuration &&
            <div className={mjes.buttonPrimary} onClick={() => accept()}>Accept</div>
          }
          <div className={mjes.buttonPrimary} style={{ backgroundColor: "#d44848" }} onClick={() => reject()}>Discard</div>
        </div>
        <div>
        </div>
      </div> */}
      {/* <ReactModal className={modal.modal} overlayClassName={modal.overlay} isOpen={showTakeawayError} contentLabel="Price" ariaHideApp={false}>
        <div>
          <div className={styles.modalLabel}>Takeaways Input Error</div>
          <div>Key Takeaways section cannot be empty</div>
          <div style={{ textAlign: "right" }}>
            <div className={buttons.button} onClick={() => setShowTakeawayError(false)}>Okay</div>
          </div>
        </div>
      </ReactModal> */}
    </div >
  );

}

export default ReviewVideo;