import React, { useState, useEffect } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import ReactModal from 'react-modal'
import { useMediaQuery } from 'react-responsive';
import { getDateString, getDateTimeString } from '../../helpers/Date'
//state
import { DiscardRequest, selectReviewerActiveRequests } from '../../slices/ReviewerActiveRequestsSlice'
import { ReviewVideoReset } from '../../slices/ReviewVideoSlice'
//components
import SportIcon from '../icons/SportIcon';
import Icon from '../../icons/Icon';
//styles
import styles from './ReviewerActiveRequest.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import card from '../../app-styles/Card.module.css';
import ReviewVideo from '../review-video/ReviewVideo';
import rs from '../../styles/ReplayStyles.module.css'
import { toast } from 'react-toastify';

function ReviewerActiveRequest(props) {

  const request = props.request;
  const history = useHistory()
  const dispatch = useDispatch()
  const reviewerActiveRequests = useSelector(selectReviewerActiveRequests)
  const status = request.status
  const processStatus = request.processStatus

  const [hoursRemaining, setHoursRemaining] = useState()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDiscardOpen, setIsDiscardOpen] = useState(false)
  const [isReportOpen, setIsReportOpen] = useState(false)

  const [discardReason, setDiscardReason] = useState("bad-video-quality")
  const [discardReasonCustom, setDiscardReasonCustom] = useState("")
  // const [reportReason, setReportReason] = useState("inappropriate")
  // const [reportReasonCustom, setReportReasonCustom] = useState("")

  //media queries
  const isSmall = useMediaQuery({ query: '(max-width: 760px)' })

  useEffect(() => {
    const hours = Math.floor((new Date(request.dateDue) - new Date()) / 36e5)
    setHoursRemaining(hours)
  }, []);

  const viewReviewerProfile = () => {

  }

  const reviewVideo = () => {
    if (isSmall) {
      toast.error("Screen is too small to complete replays")
      return
    }

    if (processStatus === "review" || processStatus === "reviewer-upload-error" || processStatus === "reviewer-transcode-error") {
      dispatch(ReviewVideoReset())
      history.push('/review-video/' + request._id)
    }
    else {
      toast.info("Replay is busy processing")
    }
  }

  const discard = () => {
    dispatch(DiscardRequest(request._id, discardReason, discardReasonCustom))
  }

  // const openReportMenu = (e) => {
  //   e.stopPropagation();
  //   setReportReason("inappropriate")
  //   setIsReportOpen(!isMenuOpen)
  // }

  const openDiscardMenu = (e) => {
    e.stopPropagation();
    setDiscardReason("bad-video-quality")
    setDiscardReasonCustom("")
    setIsDiscardOpen(!isMenuOpen)
  }

  const getSessionNumber = () => {
    if (request.repeatCount === 0)
      return "1 - New Client"
    else
      return request.repeatCount + 1
  }

  const getDisplayTimeRemaining = () => {
    if (hoursRemaining >= 24) {
      if (hoursRemaining >= 48)
        return Math.floor(hoursRemaining / 24) + " Days"
      else
        return "1 Day"

    }
    else if (hoursRemaining > 1) {
      return hoursRemaining + " Hours"
    }
    else if (hoursRemaining === 1)
      return "1 Hour"
    else {
      return "< 1 Hour"
    }
  }

  const getFriendlyStatus = () => {
    switch (processStatus) {
      case "reviewer-upload":
        return "Uploading Video"
      case "reviewer-upload-error":
        return "Upload Error"
      case "reviewer-transcode":
        return "Preparing Video"
      case "reviewer-transcode-error":
        return "Video Error"
      default:
        return "Click To Review"
    }
  }

  return (
    <div style={{ display: "inline-block" }}>
      <div className={card.card} onClick={() => reviewVideo()} style={{ cursor: "pointer", border: ".15rem solid #4353FF" }}>
        <div className={card.cardContent}>
          <div className={styles.topGrid}>
            <div className={rs.labelMediumLight}>{(request.userFirstName).substring(0, 25)}</div>
          </div>
          <div className={card.textGroup}>
            <div className={rs.labelSmallLight}>Date:</div>
            <div className={rs.textSmallLight}>{getDateString(new Date(request.dateCreated))}</div>
          </div>
          <div className={card.textGroup}>
            <div className={rs.labelSmallLight}>Due:</div>
            <div className={rs.textSmallLight} style={{ color: hoursRemaining < 24 ? "#ff9819" : "" }}>{getDisplayTimeRemaining()}</div>
          </div>
          <div className={card.textGroup}>
            <div className={rs.labelSmallLight}>Session #:</div>
            <div className={rs.textSmallLight}>{getSessionNumber()}</div>
          </div>
          <div>
            <div className={rs.labelSmallLight}>Details:</div>
            <div className={rs.textSmallLight} style={{ height: "9rem" }}>
              {request.details === "" ?
                <div style={{ textAlign: "center" }}>- None Provided -</div> :
                <div style={{fontSize:".85rem"}}>{request.details}</div>
              }
            </div>
          </div>
          <div className={card.statusContainer}>
            <div className={rs.labelSmallLight}>{getFriendlyStatus()}</div>
            {/* <div className={mjes.iconButton} onClick={(e) => openReportMenu(e)}>
              <Icon icon="flag" width="1rem" height="1rem" color="white" />
            </div> */}
            {/* <div className={mjes.iconButton} onClick={(e) => openDiscardMenu(e)}>
              <Icon icon="trashcan" width="1rem" height="1rem" color="white" />
            </div> */}
          </div>
        </div>
      </div>
      <ReactModal className={mjes.modal} overlayClassName={mjes.overlay} isOpen={isDiscardOpen} contentLabel="Discard Request" ariaHideApp={false}>
        <div>
          <div className={mjes.modalHeader}>
            <div className={rs.h1MediumLight} style={{ marginBottom: "1rem" }}>Discard Request</div>
            <div className={mjes.iconButton} onClick={() => setIsDiscardOpen(false)}>
              <Icon icon="x" height="1rem" color="white" />
            </div>
          </div>
          <div>
            <div className={mjes.labelGrid}>
              <div className={rs.labelSmallLight}>Reason:</div>
              <select className={rs.textSmallDark} value={discardReason} onChange={(e) => setDiscardReason(e.target.value)}>
                <option value="bad-video-quality">Bad Video Quality</option>
                <option value="bad-video-content">Poor Video Content</option>
                <option value="outside-coaches-specialty">Outside of my Specialty</option>
                <option value="outside-coaches-sport">Wrong Sport</option>
                <option value="limited-time">Limited Time</option>
                <option value="inappropriate">Inappropriate Content</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
          <div>
            {reviewerActiveRequests.discardStatus === "processing" ?
              <div className={rs.buttonSecondary} style={{ margin: "1rem 0" }}>
                <div className={rs.labelMediumLight}>Discarding ...</div>
              </div> :
              <div className={rs.buttonSecondary} onClick={() => discard()} style={{ margin: "1rem 0" }}>
                <div className={rs.labelMediumLight}>Discard</div>
              </div>
            }
          </div>
          <div style={{ marginTop: ".25rem" }}>* Discarding a request cannot be undone</div>
        </div>
      </ReactModal>
    </div>
  );

}

export default ReviewerActiveRequest;