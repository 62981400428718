import React, { useEffect } from 'react';
//libraries
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//state
import { selectLogin } from '../../slices/LoginSlice';
import { selectUserActiveRequests, FetchRequests } from '../../slices/UserActiveRequestsSlice';
//components
import UserActiveRequest from '../user-active-request/UserActiveRequest';
//styles
import styles from './UserActiveRequests.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import Loader from '../loader/Loader';
import rs from '../../styles/ReplayStyles.module.css'


function UserActiveRequests() {

  const dispatch = useDispatch();
  const login = useSelector(selectLogin);
  const userActiveRequests = useSelector(selectUserActiveRequests);
  const requests = userActiveRequests.requests;
  const status = userActiveRequests.status;
  const history = useHistory();

  useEffect(() => {
    dispatch(FetchRequests(userActiveRequests.page));
  }, []);

  const pageBack = () => {
    if (userActiveRequests.page > 1)
      dispatch(FetchRequests(userActiveRequests.page - 1));
  }

  const pageForward = () => {
    if (userActiveRequests.page < userActiveRequests.totalPages)
      dispatch(FetchRequests(userActiveRequests.page + 1));
  }

  const findCoach = () => {
    history.push('/browse')
  }

  if (status === 'processing')
    return <Loader text="Loading Requests" />
  else if (status === 'error')
    return <div>Error retrieving requests</div>

  return (
    <div>
      <div className={rs.h1MediumLight}>Active Requests</div>
      {requests.length > 0 ?
        <div>
          {requests.map(request => (
            <UserActiveRequest key={request._id} request={request} />
          ))}
        </div> :
        <div className={layout.pageContent}>
          <div className={mjes.contentText}>You have not submitted and requests.  Find the right coach for you to get started!</div>
          <div className={rs.buttonSecondary} onClick={() => findCoach()}>
            <div className={rs.labelLargeLight}>Find a Coach</div>
          </div>
        </div>}
    </div>
  );
}

export default UserActiveRequests;