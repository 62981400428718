import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const RatingSlice = createSlice({
  name: 'rating',
  initialState: {
    status: 'processing',
    rating: null,
    error: ''
  },
  reducers: {
    RatingProcessing: (state) => {
      state.status = 'processing';
      state.error = '';
    },
    RatingComplete: (state, action) => {
      state.rating = action.payload;
      state.status = 'complete';
    },
    RatingAwaitingInput: (state) => {
      state.rating = null;
      state.status = 'awaiting-input'
    },
    RatingError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
      state.rating = null;
    },
    RatingReset: (state) => {
      state.status = 'processing';
      state.rating = null;
      state.error = '';
    }
  }
});

export const {
  RatingProcessing, RatingComplete,
  RatingAwaitingInput, RatingError, RatingReset } = RatingSlice.actions;


export const FetchRating = (requestId) => async dispatch => {

  dispatch(RatingProcessing());

  try {
    let requestResponse = await fetch(getEndpoint() +"rating/get-rating-by-request", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId: requestId })
    });

    const request = await requestResponse.json();
    if (request.success) {
      const rating = request.payload;
      if (rating)
        dispatch(RatingComplete(rating));
      else
        dispatch(RatingAwaitingInput());
    }
    else
      dispatch(RatingError(request.message));
  }
  catch (error) {
    console.log(error);
    dispatch(RatingError(error.toString()));
    return;
  }
};

export const SubmitRating = (requestId, reviewerId, requesterId, rating, review, isPrivate) => async dispatch => {

  dispatch(RatingProcessing());

  try {
    let requestResponse = await fetch(getEndpoint() +"rating/submit-rating", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        requestId: requestId,
        reviewerId: reviewerId,
        requesterId: requesterId,
        rating: rating,
        review: review,
        isPrivate: isPrivate
      })
    });

    const request = await requestResponse.json();
    if (request.success) {
      const rating = request.payload;
      dispatch(RatingComplete(rating));
    }
    else
      dispatch(RatingError(request.message));
  }
  catch (error) {
    console.log(error);
    dispatch(RatingError(error.toString()));
    return;
  }
};

export const UpdateRating = (ratingId, starRating, review) => async dispatch => {

  dispatch(RatingProcessing());

  try {
    let requestResponse = await fetch(getEndpoint() +"rating/update-rating", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ratingId: ratingId,
        starRating: starRating,
        review: review
      })
    });

    const request = await requestResponse.json();
    if (request.success) {
      const rating = request.payload;
      dispatch(RatingComplete(rating));
    }
    else
      dispatch(RatingError(request.message));
  }
  catch (error) {
    console.log(error);
    dispatch(RatingError(error.toString()));
    return;
  }
};


export const selectRating = state => state.rating;

export default RatingSlice.reducer;
