import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const NotificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    status: "processing",
    notifications: [],
    error: ""
  },
  reducers: {
    NotificationsProcessing: (state) => {
      state.status = "processing";
      state.notifications = [];
    },
    NotificationsSuccess: (state, action) => {
      state.status = "success";
      state.notifications = action.payload.notifications;
    },
    NotificationsError: (state, action) => {
      state.status = "error";
      state.error = action.payload;
      state.notifications = [];
    }
  },
});

export const { NotificationsProcessing, NotificationsSuccess, NotificationsError } = NotificationSlice.actions;

export const FetchNotifications = () => async dispatch => {

  dispatch(NotificationsProcessing());

  try {
    let response = await fetch(getEndpoint() + "notification/get-notifications", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({})
    });

    response = await response.json();
    if (response.success) {
      dispatch(NotificationsSuccess({
        notifications: response.payload
      }));
    }
    else {
      dispatch(NotificationsError(response.message));
    }
  }
  catch (error) {
    dispatch(NotificationsError(error));
  }
};

export const selectNotifications = state => state.notifications;

export default NotificationSlice.reducer;
