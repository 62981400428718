import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const submitRequest = async (reviewerId, details, isPublic, price, totalPrice, serviceFee, promoCode, videoFiles) => {
  let response = await fetch(getEndpoint() + "request/submit-request", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      reviewerId: reviewerId,
      details: details,
      isPublic: isPublic,
      price: price,
      totalPrice: totalPrice,
      serviceFee: serviceFee,
      promoCode: promoCode,
      uploadFiles: videoFiles
    })
  });

  response = await response.json();
  return response
}

export const getRequestInformation = async (username, promoCode) => {
  let response = await fetch(getEndpoint() + "request/get-request-information", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      reviewerUsername: username,
      promoCode: promoCode
    })
  });

  response = await response.json();
  return response
}

export const uploadError = async (requestId) => {
  let response = await fetch(getEndpoint() + "request/user-upload-error", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId: requestId
    })
  });

  response = await response.json();
  return response
}

export const uploadSuccess = async (requestId) => {
  let response = await fetch(getEndpoint() + "request/user-upload-success", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId: requestId
    })
  });

  response = await response.json();
  return response
}

export const paymentError = async (requestId) => {
  let response = await fetch(getEndpoint() + "request/user-payment-error", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId: requestId
    })
  });

  response = await response.json();
  return response
}