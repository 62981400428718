import React, { useRef } from "react";
import { useHistory } from "react-router";
import BaseballIcon from '../../images/sports-filter/baseball_glow.png';
import BasketballIcon from '../../images/sports-filter/basketball_glow.png';
import FootballIcon from '../../images/sports-filter/football_glow.png';
import GolfIcon from '../../images/sports-filter/golf_glow.png';
import LacrosseIcon from '../../images/sports-filter/lacrosse_glow.png';
import GymnasticsIcon from '../../images/sports-filter/gymnastics_glow.png';
import rs from '../../styles/ReplayStyles.module.css'
import styles from './PickYourSport.module.css'

const PickYourSport = () => {

  const history = useHistory()

  const iconWrapperStyle = {
    marginRight: "1rem",
    cursor: "pointer"
  }

  const iconStyle = {
    width: "6rem",
    height: "6rem"

  }

  return (
    <div style={{ padding: "2rem 1rem"}}>
      <div style={{ textAlign: "center", margin: "0rem 0" }}>
        <div className={rs.h1MediumLight}>Start Getting Better</div>
        <div className={rs.textMediumLight}>Pick your sport</div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", margin: "2rem 0" }}>
        <div className={styles.sportFilter} style={iconWrapperStyle} onClick={() => history.push('/browse/golf')}>
          <img style={iconStyle} src={GolfIcon} />
        </div>
        <div className={styles.sportFilter} style={iconWrapperStyle} onClick={() => history.push('/browse/baseball')}>
          <img style={iconStyle} src={BaseballIcon} />
        </div>
        <div className={styles.sportFilter} style={iconWrapperStyle} onClick={() => history.push('/browse/lacrosse')}>
          <img style={iconStyle} src={LacrosseIcon} />
        </div>
        <div className={styles.sportFilter} style={iconWrapperStyle} onClick={() => history.push('/browse/gymnastics')}>
          <img style={iconStyle} src={GymnasticsIcon} />
        </div>
        <div className={styles.sportFilter} style={iconWrapperStyle} onClick={() => history.push('/browse/basketball')}>
          <img style={iconStyle} src={BasketballIcon} />
        </div>
        <div className={styles.sportFilter} style={iconWrapperStyle} onClick={() => history.push('/browse/football')}>
          <img style={iconStyle} src={FootballIcon} />
        </div>
      </div>
    </div >
  )
}

export default PickYourSport