import React, { useState, useRef } from "react"
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import rs from '../../styles/ReplayStyles.module.css'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Icon } from "@mui/material";
import { useHistory } from "react-router";

const ExampleReplays = (props) => {

	const screenSize = props.screenSize
	const scrollRef = useRef()

	const scroll = (scrollOffset) => {
		scrollRef.current.scrollLeft += scrollOffset;
	}

	return (
		<div style={{ padding: "1rem 1rem 2rem 1rem", borderRadius: "0rem", backgroundColor: "#F3F3F3" }}>
			{/* <div style={{ backgroundColor: "#E4E4E4", padding: "1rem", margin: "1rem 0", borderRadius: ".5rem" }}>
				<div className={rs.h1MediumDark} style={{ textAlign: "center" }}>What is a <span style={{ color: "#147efb" }}>Replay?</span></div>
				<div className={rs.textMediumDark} style={{ textAlign: "left", maxWidth: "35rem", margin: ".5rem auto" }}>
					A Replay is a 10 minute video lesson recorded by your coach tailored entirely to your swing.
				</div>				
			</div> */}
			<div style={{ maxWidth: "100rem", margin: "auto" }}>
				<div style={{ display: "flex", margin: "0rem auto", alignItems: "center" }}>
					<div className={rs.h1SmallDark} style={{ flexGrow: "1", cursor: "pointer", fontSize: "1.25rem" }}>{"Recent Replays"}</div>
					<div className={rs.labelMediumDark} onClick={() => scroll(-150)} style={{ cursor: "pointer" }} >
						<ArrowBackIos />
					</div>
					<div style={{ width: ".5rem" }}></div>
					<div className={rs.labelMediumDark} onClick={() => scroll(150)} style={{ cursor: "pointer" }} >
						<ArrowForwardIos />
					</div>
				</div>
				<div style={{ marginTop: ".5rem" }}>
					<div ref={scrollRef} style={{ display: "flex", overflow: screenSize === "small" ? "auto" : "hidden" }}>
						<div style={{ marginRight: "1rem", minWidth: "18rem", width: "18rem" }}>
							<ExampleReplay reviewerName={"Colby Huffman"} reviewerUsername={"colbyhuffman"}
								videoUrl={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/colby-bright.mp4"}
								imgUrl={"https://i.vimeocdn.com/video/1482323498-27f4b83f72e242b65a5dd32eac86dd96712a72e6f6bfefb90f0605f8d2f1e01e-d?mw=253&mh=450"} />
						</div>
						<div style={{ marginRight: "1rem", minWidth: "18rem", width: "18rem" }}>
							<ExampleReplay reviewerName={"Sean Lanyi"} reviewerUsername={"seanlanyi"}
								videoUrl={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/sean-jonathan.mp4"}
								imgUrl={"https://i.vimeocdn.com/video/1482306687-b6245b0f11ea82b4f9ce0237cd8785124bc00467ab4ab67ab1c3fb7b401f8c4e-d?mw=253&mh=450"} />
						</div>
						<div style={{ marginRight: "1rem", minWidth: "18rem", width: "18rem" }}>
							<ExampleReplay reviewerName={"Bryan Gathright"} reviewerUsername={"bryangathright"}
								videoUrl={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/bryan-ryan.mp4"}
								imgUrl={"https://i.vimeocdn.com/video/1503609419-9a7b15f03ee9698b828d1722bd86b6463e1b9bace4457bc1372cd14e8081be2f-d?mw=253&mh=450"} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}

export default ExampleReplays


const ExampleReplay = (props) => {

	const imgUrl = props.imgUrl
	const videoUrl = props.videoUrl
	const reviewerName = props.reviewerName
	const reviewerUsername = props.reviewerUsername

	const history = useHistory()

	const [showVideo, setShowVideo] = useState(false)


	const onReviewerClick = () => {
		const url = "/results/" + reviewerUsername
		history.push(url)
	}

	return (
		<div>
			<div style={{ width: "18rem", borderRadius: ".5rem" }}>
				{showVideo ?
					<video style={{ width: "100%", borderRadius: ".5rem" }} controls controlsList="nodownload" preload="metadata">
						<source src={videoUrl + "#t=0.1"} />
					</video> :
					<div style={{ display: "grid" }}>
						<img style={{ width: "100%", borderRadius: ".5rem", gridRow: "1", gridColumn: "1" }} src={imgUrl} onClick={() => setShowVideo(true)} />
						<div style={{ justifySelf: "center", alignSelf: "center", gridRow: "1", gridColumn: "1" }} onClick={() => setShowVideo(true)}>
							<PlayCircleIcon fontSize="large" />
						</div>
					</div>
				}
			</div>
			<div className={rs.labelMediumDark} style={{ marginTop: ".5rem" }} onClick={() => { onReviewerClick() }}>{reviewerName}</div>
		</div>
	)
}

