import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const ReviewerActiveRequestsSlice = createSlice({
  name: 'reviewerActiveRequests',
  initialState: {
    requests: null,
    reviewer: null,
    status: "processing", //idle, fetching, success, error
    error: "",
    discardStatus: "idle",
    reportStatus: "idle"
  },
  reducers: {
    ReviewerActiveRequestsProcessing: (state) => {
      state.status = "processing";
      state.error = "";
    },
    ReviewerActiveRequestsSuccess: (state, action) => {
      state.requests = action.payload.requests
      state.reviewer = action.payload.reviewer
      state.status = "success";
      state.error = "";
    },
    ReviewerActiveRequestsError: (state, action) => {
      state.status = "error";
      state.requests = null;
      state.error = action.payload;
    },
    DiscardProcessing: (state, action) => {
      state.discardStatus = "processing"
    },
    DiscardSuccess: (state, action) => {
      state.discardStatus = "success"
      const index = state.requests.findIndex(r => r._id === action.payload.requestId)
      if (index != -1) {
        const requests = state.requests
        requests.splice(index, 1)
        state.requests = requests
      }
    },
    DiscardError: (state, action) => {
      state.discardStatus = "error"
    },
    DiscardReset: (state, action) => {
      state.discardStatus = "idle"
    }
  }
});

export const {
  ReviewerActiveRequestsProcessing,
  ReviewerActiveRequestsSuccess,
  ReviewerActiveRequestsError,
  DiscardSuccess, DiscardProcessing, DiscardError, DiscardReset
} = ReviewerActiveRequestsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const FetchRequests = () => async dispatch => {

  dispatch(ReviewerActiveRequestsProcessing());

  try {
    let response = await fetch(getEndpoint() + "request/get-reviewer-active-requests", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({})
    });

    response = await response.json();
    if (response.success) {
      const requests = response.payload.requests
      const reviewer = response.payload.reviewer
      dispatch(ReviewerActiveRequestsSuccess({ requests: requests, reviewer: reviewer }));
    }
    else {
      dispatch(ReviewerActiveRequestsError(response.message));
    }
  }
  catch (error) {
    dispatch(ReviewerActiveRequestsError(error));
  }
}

export const DiscardRequest = (requestId, discardReason, discardReasonCustom) => async dispatch => {

  dispatch(DiscardProcessing());

  try {
    let response = await fetch(getEndpoint() + "request/reviewer-discard", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        requestId: requestId,
        discardReason: discardReason,
        discardReasonCustom: discardReasonCustom
      })
    });

    response = await response.json();
    if (response.success) {
      dispatch(DiscardSuccess({ requestId: requestId }));
    }
    else {
      dispatch(DiscardError(response.message));
    }
  }
  catch (error) {
    dispatch(DiscardError(error));
  }
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectReviewerActiveRequests = state => state.reviewerActiveRequests;

export default ReviewerActiveRequestsSlice.reducer;
