import React, { useState, useEffect } from 'react';
import styles from './ReviewerStatusEditor.module.css'
import mjes from '../../../mjes-styles/mjes.module.css'
import rs from '../../../styles/ReplayStyles.module.css'
import Icon from '../../../icons/Icon';
import { updateStatus } from '../../../requests/ReviewerRequests';

const ReviewerStatusEditor = (props) => {

  const [currentReviewerStatus, setCurrentReviewerStatus] = useState(props.status)
  const [selectedReviewerStatus, setSelectedReviewerStatus] = useState(props.status)
  const [isEditing, setIsEditing] = useState(false)
  const [canUpdate, setCanUpdate] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
  }, [])

  const onEditClick = () => {
    setSelectedReviewerStatus(currentReviewerStatus)
    setIsEditing(true)
    setCanUpdate(false)
    setError(null)
  }

  const onCloseEditClick = () => {
    setIsEditing(false)
  }

  const onStatusButtonClick = (status) => {
    setSelectedReviewerStatus(status)
    if (status !== currentReviewerStatus && (status === "active" || status === "unavailable")) {
      setCanUpdate(true)
    }
    else
      setCanUpdate(false)
  }

  const onUpdateStatusClick = async () => {
    try {
      const response = await updateStatus(selectedReviewerStatus)
      if (response.success) {
        setCurrentReviewerStatus(response.payload.status)
        setIsEditing(false)
      }
      else {
        setError(response.message)
      }
    }
    catch (err) {
      setError(err)
    }
  }

  const getFriendlyStatus = () => {
    switch (currentReviewerStatus) {
      case "active":
        return "Available"
      case "unavailable":
        return "Unavailable"
      case "payment_unavailable":
        return "Update Payment Account"
      case "suspended":
        return "Suspended"
      default:
        return "Status Unknown - Contact Replay"
    }
  }

  const getButtonStyle = (status) => {
    if (currentReviewerStatus !== "active" && currentReviewerStatus !== "unavailable") {
      return styles.toggleButtonDisabled
    }

    if (selectedReviewerStatus === status)
      return styles.toggleButtonSelected
    else
      return styles.toggleButton
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className={rs.labelMediumLight} style={{ flexGrow: "1" }}>Status</div>
        <div>
          {isEditing ?
            <div className={mjes.iconButton} onClick={() => onCloseEditClick()}>
              <Icon icon="x" width="1.5rem" color="white" />
            </div> :
            <div className={mjes.iconButton} onClick={() => onEditClick()}>
              <Icon icon="gear" width="1.5rem" color="white" />
            </div>
          }
        </div>
      </div>
      <div>
        {isEditing ?
          <div>
            <div className={styles.toggleButtons}>
              <div className={getButtonStyle("active")}
                onClick={() => onStatusButtonClick("active")}>available</div>
              <div className={getButtonStyle("unavailable")}
                onClick={() => onStatusButtonClick("unavailable")}>unavailable</div>
            </div>
            <div>
              {canUpdate &&
                <div className={mjes.stretchButtonSecondary} onClick={() => onUpdateStatusClick()}>Submit Change</div>
              }
            </div>
          </div> :
          <div>
            <div className={mjes.text_l} style={{ color: currentReviewerStatus === "active" ? "#147efb" : "#ff14af" , textAlign:"center"}}><b>{getFriendlyStatus().toUpperCase()}</b></div>
          </div>
        }
      </div>
    </div >
  )

}

export default ReviewerStatusEditor