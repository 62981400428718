import React from 'react'
import mjes from '../../mjes-styles/mjes.module.css'

const Faq = () => {

  return (
    <div>
      <div style={{ maxWidth: "60rem", margin: "auto" }}>
        <div className={mjes.label_xl}>FAQ</div>
        <div>Updated: 5/25/2021</div>

        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>What is Replay</div>
          <div className={mjes.text_m}>
            We’re a video coaching marketplace where rising athletes can get personalized video feedback from the pros. Send your sports video clips to pro {"&"} college athletes/coaches (collectively “Master Coaches”) through The Replay App and get personalized video feedback.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How does Replay work?</div>
          <div className={mjes.text_m}>
            <div>Replay’s no hassle technology platform makes the process for both rising athletes and Master Coaches extremely easy. Here is how you can get your own Replay Video:</div>
            <div style={{ marginLeft: "2rem", marginTop: '1rem' }}>
              <div>1) <b>Choose</b> a Master Coach from Replay’s marketplace. Access any of the pro {"&"} college athletes/coaches (background check verified) in our video coaching marketplace</div>
              <div>2) <b>Upload</b> your video to request video feedback and performance analysis from your Master Coach. Please give your Master Coach some detail on who you are (e.g., age or experience level, what you’re working on, and how you’d like to improve if there’s anything specific that you’re focused on).</div>
              <div>3) <b>Receive</b> personalized feedback video from your Master Coach including specific focus areas and next steps. Develop your skills with your Master Coach’s specialized expertise and feedback.</div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>Who are Replay’s Master Coaches?</div>
          <div className={mjes.text_m}>
            Our Master Coaches include men and women who have reached the highest levels of their respective sports. Our pro {"&"} college athletes/coaches come from all around the country and provide the most personalized, high quality, and authentic private coaching experiences in the world.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>Who can use Replay?</div>
          <div className={mjes.text_m}>
            Replay is for any athlete looking to improve their game and get personalized video feedback from professional and collegiate athletes/coaches. We’re continuing to grow our community of top athletes and coaches but if you don’t see a specific Master Coach for your sport or if there are no Master Coaches for your sport please email us at info@TheReplayApp.com and we’ll work with you to provide the best experience that we can offer.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How do I create an account?</div>
          <div className={mjes.text_m}>
            If you don’t have a Replay account yet, go to TheReplayApp.com and click Sign Up.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How do I share my Replay Video?</div>
          <div className={mjes.text_m}>
            Once the Master Coach has completed your review you will receive an email notification and see the new Replay Video within your ‘Replays’ tab. Click on the Replay and make sure sharing is enabled. When sharing is enabled you can share the video through the link found within the Replay Report with anyone at any time. Simply click the “Copy Share Link” button and paste the link wherever you want to share it.          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>Do I need a Replay Account?</div>
          <div className={mjes.text_m}>
            It’s free, fast, and easy to sign up. You don’t need an account to browse our website or watch a Replay Video from someone who decides to share one of theirs with you, but you do need to sign up in order to request a Replay Video.          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>What videos should I upload to my Master Coach?</div>
          <div className={mjes.text_m}>
            Your videos should contain any action, movement, technique, drill, (shot, swing, throw, etc.) that your Master Coach will be able to analyze, breakdown, and review. You can upload existing videos or record new videos that follow the video guidelines outlined in the Master Coach’s profile.
          </div>
          <div style={{ margin: "1rem 0rem 0 2rem" }}>
            <div className={mjes.label_m}>Best Practices:</div>
            <div className={mjes.text_m}>
              <div>1) The video should clearly show the athlete’s full range of motion.</div>
              <div>2) Portrait mode video is preferred for best results.  Landscape video is acceptable but will not fill the entire screen.</div>
              <div>3) Include 2-5 reps (see ‘Video Guidelines’ in your Master Coaches profile).</div>
              <div>4) Keep your video uploads to no more than 60 seconds (see ‘How long should my uploads be?’ for help with shortening your videos to meet this requirement)</div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How should I film my clips for Replay?</div>
          <div className={mjes.text_m}>
            Film the videos in portrait mode with a smart device.  Use whatever method of recording that allows you to capture the athlete's full range of motion.  Defer to Master Coaches ‘Video Guidelines’ in their profile.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How long should my uploads be?</div>
          <div className={mjes.text_m}>
            Upload a few reps or up to 60 seconds of good quality video with visibility of the athlete’s entire motion.  You can optionally splice together videos to create one video that meets the upload requirements. We recommend using Adobe Premiere Rush (IOS and Android) which is a free mobile app.
          </div>
          <div style={{ margin: "1rem 0rem 0 2rem" }}>
            <div className={mjes.label_m}>Adobe Premiere Rush Instructions:</div>
            <div className={mjes.text_m}>
              <div>1) Create a free account and log in.</div>
              <div>2) Choose the 9:16 video output setting (Portrait Mode).</div>
              <div>3) Select your video clips and shorten them as needed.</div>
              <div>4) Download the video by selecting the export button (Top right hand corner) and tap “export” to save it to your camera roll</div>
              <div>
                <a href="https://apps.apple.com/us/app/adobe-premiere-rush-for-video/id1188753863">Apple App Store Link</a>
              </div>
              <div>
                <a href={"https://play.google.com/store/apps/details?id=com.adobe.premiererush.videoeditor&hl=en_US&gl=US"}>Google Play Store Link</a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How much does it cost for a Replay Video?</div>
          <div className={mjes.text_m}>
            Each pro athlete or Master Coach on the Replay marketplace sets their own price.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How do I pay the Master Coach?</div>
          <div className={mjes.text_m}>
            When you submit a request to a Master Coach, you pay directly through the website using a debit or credit card.
          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>What do I need to request a Replay?</div>
          <div className={mjes.text_m}>
            You will need a recording device or existing video, and an internet enabled device to upload the video to your Master Coach. For best results use videos recorded in portrait mode (phone held vertically).          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>Are Master Coaches on the Replay Marketplace well vetted?</div>
          <div className={mjes.text_m}>
            Absolutely! We realize that trust and safety is paramount and it’s really important to us. Replay is extremely thorough in our vetting process. We make sure each and every Master Coach on the marketplace goes through our rigorous evaluation process and only qualified, high quality pro athletes and coaches are selected. Each Master Coach has also completed a verified background check through checkr.com.          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>After my first Replay Video, what’s next?</div>
          <div className={mjes.text_m}>
            After you have uploaded your video and your Master Coach has successfully reviewed and completed your Replay Video please be sure to focus on the personalized action items they plan out for you to develop your skills. Typically our Master Coaches will suggest action items including drills for you to work on and rising athletes will submit follow up videos so they can analyze your progress on skills you’re working on together while also focusing on other areas of your game to develop.        </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>What if I have questions about my Master Coach’s Review?</div>
          <div className={mjes.text_m}>
            Questions? Great! No form of learning, improving, and growing can be done without asking questions. Once you have studied your Replay Video you will have an opportunity to ask your Master Coach any question(s) relative to your personalized video. Please leave our Master Coaches the necessary time to respond.          </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>Why are some Master Coaches listed as “Unavailable”?</div>
          <div className={mjes.text_m}>
            Please understand that the first priority of every Master Coach is to give you 100% of the time and attention your game deserves. However, many Replay Master Coaches are also competing at the highest levels as pro & college athletes/coaches. Don’t worry though, we’ll let you know once they are available again.            </div>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div className={mjes.label_m}>How do I report any concerns I have with my Replay Video?</div>
          <div className={mjes.text_m}>
            If you believe that a Replay Video violates our Terms of Service, please send our team a message at support@thereplayapp.com
          </div>
        </div>
      </div >
    </div>
  )
}
export default Faq