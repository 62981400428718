import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const SelectionSlice = createSlice({
  name: 'selection',
  initialState: {
    reviewer: null,
    imageUrl: "",
    price: null,
    totalPrice: null,
    serviceFee: null,
    status: 'processing',
    error: '',
    ratings: [],
    ratingsStatus: 'processing'
  },
  reducers: {
    SelectionProcessing: (state) => {
      state.status = 'processing';
      state.reviewer = null;
      state.imageUrl = "";
      state.price = null
      state.totalPrice = null 
      state.serviceFee = null
    },
    SelectionSuccess: (state, action) => {
      state.status = 'success';
      state.reviewer = action.payload.reviewer;
      state.imageUrl = action.payload.imageUrl;
      state.price = action.payload.price
      state.totalPrice = action.payload.totalPrice 
      state.serviceFee = action.payload.serviceFee
    },
    SelectionError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
      state.reviewer = null;
      state.imageUrl = "";
      state.price = null
      state.totalPrice = null 
      state.serviceFee = null
    },
    SelectionReset: (state) => {
      state.status = 'processing';
      state.reviewer = null;
      state.imageUrl = "";
      state.price = null
      state.totalPrice = null 
      state.serviceFee = null
    },
    RatingsProcessing: (state) => {
      state.ratingsStatus = 'processing';
      state.ratings = null;
    },
    RatingsSuccess: (state, action) => {
      state.ratingsStatus = 'success';
      state.ratings = action.payload.ratings;
    },
    RatingsError: (state, action) => {
      state.ratingsStatus = 'error';
    },
    RatingsReset: (state) => {
      state.ratingsStatus = 'processing';
      state.ratings = [];
    }
  },
});

export const { 
  SelectionProcessing, SelectionSuccess, SelectionError, SelectionReset,
  RatingsProcessing, RatingsSuccess, RatingsError, RatingsReset 
} = SelectionSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const SelectionByUsername = (username) => async dispatch => {

    dispatch(SelectionProcessing()); //starting processing

    try {
      let response = await fetch(getEndpoint() +"reviewer/get-reviewer-by-username", {
        method: "POST",
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify({username})
      });

      response = await response.json();
      if (response.success) {
        dispatch(SelectionSuccess({
          reviewer: response.payload.reviewer, 
          imageUrl: response.payload.imageUrl,
          price: response.payload.price,
          totalPrice: response.payload.totalPrice,
          serviceFee: response.payload.serviceFee
        }));
      }
      else {
        console.log(response.message)
        dispatch(SelectionError(response.message));
      }
    }
    catch (error) {
      console.log(error);
      dispatch(SelectionError(error));
    }
  };

  // export const GetRatingsByUsername = (username) => async dispatch => {

  //   dispatch(SelectionProcessing()); //starting processing

  //   try {
  //     let response = await fetch(getEndpoint() +"reviewer/get-reviewer-by-username", {
  //       method: "POST",
  //       headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  //       body: JSON.stringify({username})
  //     });

  //     response = await response.json();
  //     if (response.success) {
  //       console.log(response.payload)
  //       dispatch(SelectionSuccess({reviewer: response.payload.reviewer, bucket: response.payload.bucket}));
  //     }
  //     else {
  //       console.log(response.message)
  //       dispatch(SelectionError(response.message));
  //     }
  //   }
  //   catch (error) {
  //     console.log(error);
  //     dispatch(SelectionError(error));
  //   }
  // };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSelection = state => state.selection;

export default SelectionSlice.reducer;
