import { configureStore } from '@reduxjs/toolkit';
import UserSlice from '../slices/UserSlice';
import SearchSlice from '../slices/SearchSlice';
import SignupSlice from '../slices/SignupSlice';
import SelectionSlice from '../slices/SelectionSlice';
import RequestSlice from '../slices/RequestSlice';
import ReviewerProfileSlice from '../slices/ReviewerProfileSlice';
import ReviewerActiveRequestsSlice from '../slices/ReviewerActiveRequestsSlice';
import UserActiveRequestsSlice from '../slices/UserActiveRequestsSlice';
import CreateVideoSlice from '../slices/CreateVideoSlice';
import UserCompletedRequestsSlice from '../slices/UserCompletedRequestsSlice';
import ReviewerCompletedRequestsSlice from '../slices/ReviewerCompletedRequestsSlice';
import UserDownloadVideoSlice from '../slices/UserDownloadVideoSlice';
import ReviewerDownloadVideoSlice from '../slices/ReviewerDownloadVideoSlice';
import ReviewVideoSlice from '../slices/ReviewVideoSlice';
import RatingSlice from '../slices/RatingSlice';
import NotificationSlice from '../slices/NotificationSlice';
import ReviewerDashboardSlice from '../slices/ReviewerDashboardSlice';
import LoginSlice from '../slices/LoginSlice';
import ReviewApplicationSlice from '../slices/ReviewApplicationSlice';
import ReviewerSetupSlice from '../slices/ReviewerSetupSlice';

export default configureStore({
  reducer: {
    user: UserSlice,
    login: LoginSlice,
    search: SearchSlice,
    reviewerProfile: ReviewerProfileSlice,
    signup: SignupSlice,
    selection: SelectionSlice,
    request: RequestSlice,
    userCompletedRequests: UserCompletedRequestsSlice,
    userActiveRequests: UserActiveRequestsSlice,
    reviewerActiveRequests: ReviewerActiveRequestsSlice,
    createVideo: CreateVideoSlice,
    reviewVideo: ReviewVideoSlice,
    userDownloadVideo: UserDownloadVideoSlice,
    reviewerCompletedRequests: ReviewerCompletedRequestsSlice,
    rating: RatingSlice,
    reviewerDownloadVideo: ReviewerDownloadVideoSlice,
    notifications: NotificationSlice,
    reviewerDashboard: ReviewerDashboardSlice,
    reviewApplication: ReviewApplicationSlice,
    reviewerSetup: ReviewerSetupSlice
  }
});
