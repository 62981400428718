import React, { useEffect, useState } from 'react';
//libraries
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//state
import { selectCreateVideo, FetchRequest, CreateVideoReset } from '../../slices/CreateVideoSlice';
import { selectReviewVideo, SubmitCompletedVideo, ReviewVideoReset } from '../../slices/ReviewVideoSlice';
//components
import CreateVideo from '../create-video/CreateVideo';
import ReviewVideo from '../review-video/ReviewVideo';
import Loader from '../loader/Loader'
//styles
import styles from './CreateVideoPage.module.css';
import elements from '../../app-styles/Elements.module.css';
import { getVideoNotes, updateVideoNotes } from '../../requests/VideoNoteRequests';

function CreateVideoPage() {


  //params
  const params = useParams();
  const requestId = params.requestId;

  //redux
  const dispatch = useDispatch();
  const createVideo = useSelector(selectCreateVideo);
  const reviewVideo = useSelector(selectReviewVideo);
  const reviewStatus = reviewVideo.status;
  const request = createVideo.request;
  const requestFiles = createVideo.requestFiles
  const requestStatus = createVideo.status;

  //state
  const [reviewState, setReviewState] = useState("create");
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [duration, setDuration] = useState(0)

  useEffect(() => {
    dispatch(FetchRequest(requestId));
  }, []);

  function videoFinished(file, videoUrl, duration) {
    setReviewState("review");
    setVideoUrl(videoUrl);
    setVideoFile(file);
    setDuration(duration)
  }

  function reject() {
    setReviewState("create");
    setVideoUrl(null);
    setVideoFile(null);
  }

  function uploadVideo(keyTakeaways) {
    dispatch(SubmitCompletedVideo(request._id, videoFile, keyTakeaways));
  }

  const getNotes = async () => {
    const response = await getVideoNotes(requestId)
    console.log(response)
    if(response.success)
      return response.payload.notes
    else
      return []

  }

  // const updateNotes = async () => {
  //   console.log("updateVideoNotes")
  //   await updateVideoNotes()
  // }

  if (requestStatus === "processing")
    return <div>Loading...</div>
  else if (requestStatus === "error")
    return <div>{createVideo.error}</div>
  else if (requestStatus === "reject") {
    return <Redirect to="/reviewer-profile/requests" />
  }

  if (request.processStatus !== "review" && request.processStatus !== "reviewer-upload-error" && request.processStatus !== "reviewer-transcode-error")
    return <Redirect to="/reviewer-profile/requests" />

  if (reviewStatus === "processing") {
    return (
      <Loader message="DO NOT CLOSE OR REFRESH PAGE" submessage="Video may take a couple minutes to upload" />
    )
  }
  else if (reviewStatus === "error")
    return <div>Error Uploading Video</div>
  else if (reviewStatus === "success") {
    return <Redirect to="/reviewer-profile/requests" />
  }

  return (
    <div className={styles.container}>
      {reviewState === "create"
        ? <CreateVideo videoFinished={videoFinished} requestFiles={requestFiles} request={request} updateNotes={updateVideoNotes} getNotes={getNotes}></CreateVideo>
        : <ReviewVideo videoUrl={videoUrl} reject={reject} uploadVideo={uploadVideo} request={request} duration={duration}></ReviewVideo>
      }
    </div>
  );
}

export default CreateVideoPage;