import React, { useEffect } from 'react';
//libraries
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//state
import { selectUser } from '../../slices/UserSlice';
import { selectUserCompletedRequests, FetchRequests } from '../../slices/UserCompletedRequestsSlice';
//components
import UserCompletedRequest from '../user-completed-request/UserCompletedRequest';
import Loader from '../loader/Loader';
//styles
import styles from './UserCompletedRequests.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import rs from '../../styles/ReplayStyles.module.css'


function UserCompletedRequests() {

  const dispatch = useDispatch();
  const requestSlice = useSelector(selectUserCompletedRequests);
  const requests = requestSlice.requests;
  const status = requestSlice.status;
  const page = requestSlice.page
  const totalPages = requestSlice.totalPages
  const history = useHistory();

  useEffect(() => {
    dispatch(FetchRequests(page));
  }, []);

  const pageBack = () => {
    if (page > 1)
      dispatch(FetchRequests(page - 1));
  }

  const pageForward = () => {
    if (page < totalPages)
      dispatch(FetchRequests(page + 1));
  }

  const findCoach = () => {
    history.push('/')
  }

  if (status === 'processing')
    return <Loader text="Loading Requests" />
  else if (status === 'error')
    return <div>Error retrieving requests</div>

  return (
    <div>
      <div className={rs.h1MediumLight}>Replays</div>
      {requests.length > 0 ?
        <div>
          {requests.map(request => (
            <UserCompletedRequest key={request._id} request={request} />
          ))}
        </div> :
        <div className={layout.pageContent}>
          <div className={mjes.contentText}>You currently have no active requests.  Find the master coach that will take your game to the next level!</div>
          <div className={mjes.stretchButtonSecondary} onClick={() => findCoach()}>Find a Coach</div>
        </div>}
    </div>
  );
}

export default UserCompletedRequests;