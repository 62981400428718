import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validateName, validateEmail, validatePassword, validateBirthday } from '../../input-validation/InputValidation';
import queryString from "query-string"
import { Helmet } from 'react-helmet';
//state
import { UserSignup, selectLogin, LoginReset } from '../../slices/LoginSlice'
//components
import MaskedInput from 'react-text-mask';
import Loader from '../loader/Loader';
//styles
import styles from './SignupUser.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'

function SignupUser() {

  //redux
  const login = useSelector(selectLogin);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const query = queryString.parse(location.search);

  //state
  const [firstName, setFirstName] = useState("");
  const [firstNameStatus, setFirstNameStatus] = useState("awaiting");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameStatus, setLastNameStatus] = useState("awaiting");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState("awaiting");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStatus, setPasswordStatus] = useState("awaiting");
  const [passwordError, setPasswordError] = useState("");
  const [birthday, setBirthday] = useState("");
  const [birthdayStatus, setBirthdayStatus] = useState("awaiting");
  const [birthdayError, setBirthdayError] = useState("");
  const [referralCode, setReferralCode] = useState(null);
  const [referralCodeStatus, setReferralCodeStatus] = useState("success");
  const [referralCodeError, setReferralCodeError] = useState("");
  const [referralCodeEnabled, setReferralCodeEnabled] = useState(true)
  const [allowPromos, setAllowPromos] = useState(true);


  useEffect(() => {
    dispatch(LoginReset())
    const referralCode = localStorage.referralCode
    if (query.ref && query.ref.length > 0) {
      setReferralCodeEnabled(false)
      setReferralCode(query.ref)
    }
    else if (referralCode) {
      setReferralCodeEnabled(false)
      setReferralCode(referralCode)
    }
  }, []);

  const submitSignup = () => {
    dispatch(UserSignup(firstName, lastName, email, password, birthday, referralCode, allowPromos));
  }

  const canSubmit = () => {
    return (firstNameStatus === "valid" && lastNameStatus === "valid" && emailStatus === "valid" &&
      passwordStatus === "valid" && birthdayStatus === "valid");
  }

  const updateFirstName = (firstName) => {
    setFirstName(firstName);

    const validation = validateName(firstName);
    if (validation.isValid) {
      setFirstNameStatus("valid");
      setFirstNameError(null);
    }
    else {
      setFirstNameStatus("invalid")
      setFirstNameError(validation.error);
    }
  }

  const updateLastName = (lastName) => {
    setLastName(lastName);

    const validation = validateName(lastName);
    if (validation.isValid) {
      setLastNameStatus("valid");
      setLastNameError(null);
    }
    else {
      setLastNameStatus("invalid")
      setLastNameError(validation.error);
    }
  }

  const updateEmail = (email) => {
    setEmail(email);

    const validation = validateEmail(email);
    if (validation.isValid) {
      setEmailStatus("valid");
      setEmailError(null);
    }
    else {
      setEmailStatus("invalid")
      setEmailError(validation.error);
    }
  }

  const updatePassword = (password) => {
    setPassword(password);

    const validation = validatePassword(password);
    if (validation.isValid) {
      setPasswordStatus("valid");
      setPasswordError(null);
    }
    else {
      setPasswordStatus("invalid")
      setPasswordError(validation.error);
    }
  }

  const updateBirthday = (birthday) => {
    setBirthday(birthday);

    const validation = validateBirthday(birthday);
    if (validation.isValid) {
      setBirthdayStatus("valid");
      setBirthdayError(null);

    }
    else {
      setBirthdayStatus("invalid")
      setBirthdayError(validation.error);
      return;
    }

    const birthdayDate = new Date(birthday);
    var ageDifMs = Date.now() - birthdayDate.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < 13) {
      setBirthdayStatus("error");
      setBirthdayError("Must be at least 13 years old")
    }
  }

  if (login.isLoggedIn) {
    console.log(query)
    if (query.username)
      return <Redirect to={"/verify-account?username=" + query.username} />
    else if (query.trial)
      return <Redirect to={"/verify-account?trial=true"} />
    else
      return <Redirect to="/verify-account" />
  }
  else if (login.isLoggingIn)
    return <Loader message="Creating Account" />


  return (
    <div className={mjes.pageContainer}>
      <Helmet>
        <title>Signup | The Replay App - Video Coaching From Professional Athletes</title>
        <meta name="description" content="Sign up for a free account on The Replay App. Get 1-1 video coaching from professional and college athletes.  Choose from a selection of over 50 professional and college athletes.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
      </Helmet>
      <div className={mjes.pageContentContainer}>
        {/* <div style={{ marginBottom: "0rem" }}>
          <BetaSignup />
        </div> */}
        {/* <div style={{ margin: "2rem 0 4rem 0" }}>
          <div className={mjes.text_m}>
            Replay is currently in early access mode. Here's what you need to do: (1) submit your email above to request early access and join the waitlist; (2) Once you've been taken off the waitlist, we'll send you an email letting you know that you're eligible to sign up. You'll then sign up with that same email and be able to request personalized video feedback from any pro {"&"} college athletes/coaches on our marketplace. If you're interested in receiving priority early access, email us at info@thereplayapp.com.
          </div>
        </div> */}
        <div className={rs.h1MediumLight} style={{ textAlign: "center", margin: "1rem 0" }}>Sign Up</div>
        {/* <div className={rs.textM} style={{ textAlign: "center", margin: "0rem auto 1rem auto", maxWidth: "26rem" }}>{"Send your sports clips to any pro or college athlete and get private coaching videos"}</div> */}
        {/* <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Sign Up</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={styles.stepNumber}>2</div>
            <div className={mjes.label}>Verify Email</div>
          </div>
        </div> */}
        {/* <div className={mjes.contentLabel}>Connect with master coaches</div> */}
        <div >
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>First Name</div>
              <input className={firstNameStatus === "invalid" ? rs.textInputError : rs.textInput}
                type='text' value={firstName} onChange={e => updateFirstName(e.target.value)} maxLength="15" />
              <div className={mjes.labelError}>{firstNameError}</div>
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Last Name</div>
              <input className={lastNameStatus === "invalid" ? rs.textInputError : rs.textInput}
                type='text' value={lastName} onChange={e => updateLastName(e.target.value)} maxLength="15" />
              <div className={mjes.labelError}>{lastNameError}</div>
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Email</div>
              <input className={emailStatus === "invalid" ? rs.textInputError : rs.textInput}
                type='text' value={email} onChange={e => updateEmail(e.target.value)} maxLength="320" />
              <div className={mjes.labelError}>{emailError}</div>
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Password</div>
              <input className={passwordStatus === "invalid" ? rs.textInputError : rs.textInput}
                value={password} onChange={e => updatePassword(e.target.value)} maxLength="13" type="password" />
              <div className={mjes.labelError}>{passwordError}</div>
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={rs.labelSmallLight}>Birthday</div>
              <MaskedInput className={birthdayStatus === "invalid" ? rs.textInputError : rs.textInput}
                value={birthday} onChange={e => updateBirthday(e.target.value)}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                guide={false} showMask={true} placeholder="12/06/1991" />
              <div className={mjes.labelError}>{birthdayError}</div>
            </div>
            {referralCodeEnabled &&
              <div className={mjes.textGroupContainer}>
                <div className={rs.labelSmallLight}>Referral Code (optional)</div>
                <input className={rs.textInput} type='text'
                  value={referralCode} onChange={e => setReferralCode(e.target.value)} />
              </div>
            }
          </div>
          <div className={styles.buttonMargin}>
            {canSubmit() ?
              <div className={rs.buttonSecondary} onClick={() => submitSignup()}>
                <div className={rs.buttonTextLargeLight}>Sign Up</div>
              </div> :
              <div className={rs.buttonSecondary} style={{ cursor: "not-allowed" }} >
                <div className={rs.buttonTextLargeLight}>Sign Up</div>
              </div>
            }
          </div>
          <div className={rs.textSmallLight} style={{ margin: ".5rem 0" }}>
            By creating an account you agree to Replay's <span style={{ cursor: "pointer", color: "white" }} onClick={() => window.open('https://www.thereplayapp.com/terms-of-service')}><u>Terms of Service</u></span> and <span style={{ cursor: "pointer", color: "white" }} onClick={() => window.open('https://www.thereplayapp.com/privacy-policy')}><u>Privacy Policy</u></span>
          </div>
          <div className={mjes.labelError} style={{ margin: ".5rem 0" }}>{login.error}</div>
        </div>
        {/* <div style={{ margin: "2rem 0" }}>
          <div className={rs.textSmallLight}>
            Replay allows you to add more users to your account after sign up (Family/Teammates)
          </div>
        </div> */}
        {/* <div className={styles.coachSignupGrid} style={{ marginTop: "2rem" }}>
          <div className={rs.labelS}>Have an account?</div>
          <div className={rs.buttonPrimary} style={{width:"10rem"}} onClick={() => history.push('/login')}>Log In</div>
        </div>
        <div className={styles.coachSignupGrid} style={{ marginTop: ".5rem" }}>
          <div className={rs.labelS}>Applying to Coach?</div>
          <div className={rs.buttonTertiary} style={{width:"10rem"}} onClick={() => history.push('/signup-reviewer')}>Apply</div>
        </div> */}
        <div style={{ marginTop: "1rem", display: "flex", flexWrap: "wrap", alignItems: "center", gap: ".5rem" }}>
          <div className={rs.textSmallLight}>Have an account?</div>
          <div className={rs.labelSmallLight} style={{ width: "8rem", cursor: "pointer" }} onClick={() => history.push('/login')}>Login</div>
        </div>
        <div style={{ marginTop: ".5rem", display: "flex", flexWrap: "wrap", alignItems: "center", gap: ".5rem" }}>
          <div className={rs.textSmallLight}>Want to Coach?</div>
          <div className={rs.labelSmallLight} style={{ width: "8rem", cursor: "pointer" }} onClick={() => history.push('/login')}>Apply</div>
        </div>
        {/* <div style={{ display: "flex" }}>
          <div className={rs.buttonPrimary} style={{ width: "6rem", marginRight: ".5rem" }} onClick={() => history.push('/login')}>Login</div>
          <div className={rs.buttonTertiary} style={{ width: "10rem" }} onClick={() => history.push('/signup-reviewer')}>Apply to Coach</div>
        </div> */}
      </div>
    </div>
  );
}

export default SignupUser