import validator from 'validator';

export const validateName = (name) => {
  let isValid;
  let error = "";
  if (name.length > 15) {
    isValid = false;
    error = "Name must be less than 16 characters"
  }
  else if (name.length <= 0) {
    isValid = false;
    error = "Name must be at least 1 character"
  }
  else {
    isValid = true;
  }

  return {
    isValid,
    error
  }
}

export const validateText = (text, maxLength) => {
  let isValid;
  let error = "";
  if (text.length > maxLength) {
    isValid = false;
    error = "Must be less than " + (maxLength + 1) + " characters"
  }
  else if (text.length <= 0) {
    isValid = false;
    error = "Must be at least 1 character"
  }
  else {
    isValid = true;
  }

  return {
    isValid,
    error
  }
}

export const validateEmail = (email) => {
  let isValid;
  let error = "";
  if (validator.isEmail(email))
    isValid = true;
  else {
    isValid = false;
    error = "Must be a valid email"
  }

  return { isValid, error }
}

export const validatePassword = (password) => {
  let isValid;
  let error = "";
  if (password.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/) !== -1)
    isValid = true;
  else {
    isValid = false;
    error = "Must contain at least 1 uppercase and 1 number";
  }

  if (password.length < 8 || password.length > 20) {
    isValid = false;
    error = "Must be between 8 and 20 characters";
  }

  return { isValid, error }
}

export const validateBirthday = (birthday) => {
  let isValid;
  let error = "";

  const birthdayDate = new Date(birthday)

  if (birthdayDate instanceof Date && !isNaN(birthdayDate) && birthday.length === 10)
    isValid = true;
  else {
    isValid = false;
    error = "Must be a valid date (mm/dd/yyyy)";
  }

  return { isValid, error }

}

export const validatePrice = (price) => {
  let isValid;
  let error = "";
  if (price < 39) {
    isValid = false;
    error = "Must be at least $39";
  }
  else if (price > 100000) {
    isValid = false;
    error = "Must be less than $100,000";
  }
  else {
    isValid = true
    error = ""
  }
  return { isValid, error }
}

export const validateImage = (image) => {
  if (!image)
    return false;
  if (image.type !== 'image/png' && image.type !== 'image/jpeg')
    return false;
  return true;
}

export const validateVideo = (video) => {

  let isValid = true
  let error = "";

  if (!video) {
    isValid = false;
    error = "Must select a video"
  }
  else if (video.type !== 'video/mp4' && video.type !== 'video/webm' && video.type !== 'video/oog' && video.type !== 'video/quicktime') {
    isValid = false
    error = "Must be an mp4, webm, oog, or mov file"
  }

  return { isValid, error};
}

export const validateInt = (value) => {
  let isValid;
  let error = "";

  isValid = !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))
  if (!isValid)
    error = "Must be a valid whole number"

  return { isValid, error }
}