import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//state
import { selectLogin } from '../../slices/LoginSlice'
//hooks
//components
//styles
import styles from './ReviewerPayments.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import { checkAccountStatus } from '../../requests/PaymentRequests';
import Loader from '../loader/Loader';
import rs from '../../styles/ReplayStyles.module.css'

function ReviewerPayments() {

  //redux
  const login = useSelector(selectLogin);
  const dispatch = useDispatch();
  const history = useHistory()
  //state

  useEffect(() => {

  }, []);

  const submitPayment = () => {
    history.push('/payment-redirect')
  }

  return (
    <div>
      <div className={rs.h1MediumLight}>Payments</div>
      <div className={mjes.pageContentContainer}>
        <div className={rs.labelMediumLight}>Click to visit the payment dashboard</div>
        <div style={{ marginTop: "1rem" }}>
          <div className={rs.buttonSecondary} onClick={() => submitPayment()}>
            <div className={rs.labelLargeLight}>Access Payments Account</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewerPayments