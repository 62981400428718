import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useRouteMatch, useParams, useHistory } from 'react-router-dom';
//state
//hooks
import { useViewport } from '../../hooks/useViewport';
//components
import UserActiveRequests from '../user-active-requests/UserActiveRequests';
import UserCompletedRequests from '../user-completed-requests/UserCompletedRequests';
import UserProfile from '../user-profile/UserProfile';
//images
import Request from '../../images/profile-icons/request.png'
import Replay from '../../images/profile-icons/replay.png'
import Notification from '../../images/profile-icons/notification.png'
import Profile from '../../images/profile-icons/profile.png'
import Users from '../../images/profile-icons/users.png'
import SportsIcon from '@mui/icons-material/Sports';
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ApprovalIcon from '@mui/icons-material/Approval';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
//styles
import styles from './UserPage.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import mjes from '../../mjes-styles/mjes.module.css';
import NotificationPage from '../notification-page/NotificationPage';

function UserPage(props) {

  //state
  //viewport
  const { width, height } = useViewport();
  const isSmall = width < 768
  //router
  const { page } = useParams();
  const history = useHistory();

  useEffect(() => {

  }, [])

  const getActiveComponent = () => {
    switch (page) {
      case "requests":
        return <UserActiveRequests />
      case "videos":
        return <UserCompletedRequests />
      case "messages":
        return <NotificationPage />
      default:
        return <UserActiveRequests />;
    }
  }

  const getActiveTab = () => {
    switch (page) {
      case "requests":
      case "videos":
      case "messages":
        return page;
      default:
        return "requests";
    }
  }

  const getNavMenu = () => {
    return (
      <div>
        <div className={rs.darkbackground}>
          <div className={mjes.navMenuItems}>
            <div className={getActiveTab() === "requests" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/profile/requests')}>
              <OndemandVideoIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Requests</div>
            </div>
            <div className={getActiveTab() === "videos" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/profile/videos')}>
              <SlowMotionVideoIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Replays</div>
            </div>
            <div className={getActiveTab() === "messages" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/profile/messages')}>
              <NotificationsIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Notifications</div>
            </div>
            {/* <div className={getActiveTab() === "account" ? mjes.navMenuInavMenuItemSelectedtem : mjes.navMenuItem}>Account</div> */}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {!isSmall &&
        <div>{getNavMenu()}</div>
      }
      <div className={mjes.page}>{getActiveComponent()}</div>
    </div>
  );
}

export default UserPage;