import React, { useState, useEffect } from 'react';
//libraries
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validateName, validateEmail, validatePassword, validateBirthday, validateText } from '../../../input-validation/InputValidation';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
//state
//components
import MaskedInput from 'react-text-mask';
import Loader from '../../loader/Loader';
//styles
import mjes from '../../../mjes-styles/mjes.module.css';
import { createReviewer } from '../../../requests/AdminRequests';

const AdminCreateReviewer = () => {

  //state
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [birthday, setBirthday] = useState("")
  const [sport, setSport] = useState("basketball")
  const [error, setError] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
  }, []);

  const submitSignup = async () => {

    setIsSubmitting(true)

    if (!canSubmit()) {
      setIsSubmitting(false)
      return
    }

    try {
      const response = await createReviewer(firstName, lastName, email, phone, password, sport, birthday, "")
      if (response.success) {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhone("")
        setPassword("")
        setBirthday("")
        setSport("")
        setError("")
        toast.success("Coach Created")
      }
    }
    catch (err) {
      setError(err)
    }

    setIsSubmitting(false)
  }

  const canSubmit = () => {
    //validation
    const firstNameValidation = validateName(firstName)
    if (!firstNameValidation.isValid) {
      setError(firstNameValidation.error)
      return false
    }

    const lastNameValidation = validateName(lastName)
    if (!lastNameValidation.isValid) {
      setError(lastNameValidation.error)
      return false
    }

    const emailValidation = validateEmail(email)
    if (!emailValidation.isValid) {
      setError(emailValidation.error)
      return false
    }

    const passwordValidation = validatePassword(password)
    if (!passwordValidation.isValid) {
      setError(passwordValidation.error)
      return false
    }

    const birthdayValidation = validateBirthday(birthday);
    if (!birthdayValidation.isValid) {
      setError(birthdayValidation.error)
      return false
    }

    const birthdayDate = new Date(birthday);
    var ageDifMs = Date.now() - birthdayDate.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age < 18 && age > 120) {
      setError("Must be at least 18 years old");
      return false
    }

    return true
  }


  return (
    <div className={mjes.pageContainer}>
      <Helmet>
        <title>Create Coach | The Replay App - Video Coaching From Professional Athletes</title>
        <meta name="description" content="Apply to become a coach on The Replay App. Get 1-1 video coaching from professional and college athletes.  Choose from a selection of over 50 professional and college athletes.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
      </Helmet>
      <div className={mjes.pageContentContainer}>
        <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Create A Coach</div>
        <div style={{ margin: "1.5rem 0" }}>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>First Name *</div>
              <input className={mjes.textInput}
                type='text' value={firstName} onChange={e => setFirstName(e.target.value)} maxLength="13" />
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Last Name *</div>
              <input className={mjes.textInput}
                type='text' value={lastName} onChange={e => setLastName(e.target.value)} maxLength="13" />
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Email *</div>
              <input className={mjes.textInput}
                type='text' value={email} onChange={e => setEmail(e.target.value)} maxLength="320" />
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Password *</div>
              <input className={mjes.textInput}
                value={password} onChange={e => setPassword(e.target.value)} maxLength="13" />
            </div>
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Birthday *</div>
              <MaskedInput className={mjes.textInput}
                value={birthday} onChange={e => setBirthday(e.target.value)}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                guide={false} showMask={true} placeholder="12/06/1991" />
            </div>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Sport *</div>
              <select className={mjes.textInput} value={sport} onChange={e => setSport(e.target.value)}>
                <option value="basketball">Basketball</option>
                <option value="baseball">Baseball/Softball</option>
                <option value="football">Football</option>
                <option value="golf">Golf</option>
                <option value="lacrosse">Lacrosse</option>
                <option value="gymnastics">Gymnastics</option>
              </select>
            </div>
            {/* <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Referral Code (optional)</div>
              <input className={mjes.textInput} type='text'
                value={referralCode} onChange={e => setReferralCode(e.target.value)} />
            </div> */}
          </div>
          <div className={mjes.columnGrid}>
            <div className={mjes.textGroupContainer}>
              <div className={mjes.label}>Phone</div>
              <input className={mjes.textInput} placeholder="(999) 999-9999"
                type='text' value={phone} onChange={e => setPhone(e.target.value)} maxLength="20" />
            </div>
            <div></div>
          </div>
          <div>
            {!isSubmitting ?
              <div className={mjes.stretchButtonSecondary} onClick={() => submitSignup()}>Create</div> :
              <div></div>
            }
          </div>
          <div className={mjes.labelError} style={{ margin: ".5rem 0" }}>{error}</div>
        </div>
      </div>
    </div>
  );
}

export default AdminCreateReviewer