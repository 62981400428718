import React from 'react';

//styles
import styles from './SportIcon.module.css'
import AllIcon from '../../images/sports-filter/all.png';
import BaseballIcon from '../../images/sports-filter/baseball.png';
import BasketballIcon from '../../images/sports-filter/basketball.png';
import FootballIcon from '../../images/sports-filter/football.png';
import GolfIcon from '../../images/sports-filter/golf.png';
import LacrosseIcon from '../../images/sports-filter/lacrosse.png';
import GymnasticsIcon from '../../images/sports-filter/gymnastics.png';


const SportIcon = (props) => {
  const icon = props.icon;

  const getImageSource = () => {
    switch (icon) {
      case "all":
        return AllIcon;
      case "baseball":
        return BaseballIcon;
      case "basketball":
        return BasketballIcon;
      case "football":
        return FootballIcon;
      case "golf":
        return GolfIcon;
      case "lacrosse":
        return LacrosseIcon
      case "gymnastics":
        return GymnasticsIcon

    }
  }

  return (
    <img className={styles.icon} src={getImageSource()} />
  );

}

export default SportIcon;