import React, { useEffect, useState } from 'react';
//libraries
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import queryString from "query-string"
import { Helmet } from 'react-helmet';
import { ArrowBackIos, ArrowForwardIos, Upload } from '@mui/icons-material'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import PersonIcon from '@mui/icons-material/Person';
//state
import { selectSelection, SelectionByUsername } from '../../slices/SelectionSlice';
import { selectLogin } from '../../slices/LoginSlice';
//components
import Icon from '../../icons/Icon';
import Loader from '../loader/Loader'
import { toast } from 'react-toastify';
import Step1 from '../../images/assets/step1.png'
import Step2 from '../../images/assets/step2.png'
import Step3 from '../../images/assets/step3.png'
//styles
import styles from './SearchReviewerPage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import BaseballIcon from '../../images/sports-filter/baseball_glow.png';
import BasketballIcon from '../../images/sports-filter/basketball_glow.png';
import FootballIcon from '../../images/sports-filter/football_glow.png';
import GolfIcon from '../../images/sports-filter/golf_glow.png';
import LacrosseIcon from '../../images/sports-filter/lacrosse_glow.png';
import GymnasticsIcon from '../../images/sports-filter/gymnastics_glow.png';
import ReviewerRating from '../reviewer-rating/ReviewerRating';
import ReplayDescription from '../replay-description/ReplayDescription';
import IosShareIcon from '@mui/icons-material/IosShare';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function SearchReviewerPage() {

  const [seeMoreRatings, setSeeMoreRatings] = useState(false)
  const [howItWorksExpanded, setHowItWorksExpanded] = useState(true)

  //query params
  const location = useLocation()
  const query = queryString.parse(location.search);

  //redux
  const dispatch = useDispatch()
  const history = useHistory()
  const login = useSelector(selectLogin)
  const selection = useSelector(selectSelection)
  const reviewer = selection.reviewer
  const status = selection.status

  //router
  let { username } = useParams();

  //media queries
  const isSmall = useMediaQuery({ query: '(max-width: 40rem)' })

  //metatags  

  useEffect(() => {
    dispatch(SelectionByUsername(username));
  }, []);

  const requestReview = () => {
    if (!login.isLoggedIn)
      return history.push({ pathname: '/signup-user', search: '?username=' + reviewer.username })

    history.push('/results/' + reviewer.username + '/request')
  }

  const onBackPressed = () => {
    console.log(reviewer.sport)
    if (reviewer.sport === "golf") {
      if (query.scrollY)
        return history.push('/golf?scrollY=' + query.scrollY)
      else
        return history.push('/golf?scrollY=0')
    }
    else
      return history.push('/browse/' + reviewer.sport + "?scrollY=" + query.scrollY ? query.scrollY : 0)
  }

  const openMembershipPage = () => {
    const url = "https://p2.thereplayapp.com/unlimited"
    window.open(url, 'blank')
  }

  const getSportsIcon = () => {
    switch (reviewer.sport) {
      case "baseball": return BaseballIcon;
      case "basketball": return BasketballIcon;
      case "football": return FootballIcon;
      case "golf": return GolfIcon;
      case "lacrosse": return LacrosseIcon
      case "gymnastics": return GymnasticsIcon;
    }
  }

  const webshareOnClick = async () => {

    // const link = 'https://www.thereplayapp.com/results/' + reviewer.username
    // toast.info("Link Copied")
    // navigator.clipboard.writeText(link)
    const shareData = {
      title: 'Check Out This Coach On The Replay App',
      text: reviewer.firstName + " " + reviewer.lastName,
      url: 'https://www.thereplayapp.com/results/' + reviewer.username
    }

    try {
      if (navigator.share) {
        await navigator.share(shareData)
      }
      else {
        const link = 'https://www.thereplayapp.com/results/' + reviewer.username
        toast.info("Link Copied")
        navigator.clipboard.writeText(link)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getSubmitButton = () => {
    if (reviewer.status === "unavailable") {
      return (
        <div className={rs.buttonSecondary}>
          <div className={rs.buttonTextLargeLight}>Unavailable</div>
        </div>
      )
    }
    else if (reviewer.status === "active") {
      if (login.isLoggedIn && login.role !== 'user') {
        return (
          <div className={rs.buttonSecondary} >
            <div className={rs.buttonTextLargeLight}>Log in as User</div>
          </div>
        )
      }
      else {
        return (
          <div className={rs.buttonSecondary} onClick={() => requestReview()}>
            <div className={rs.buttonTextLargeLight}>{"Request Replay"}</div>
          </div>
        )
      }
    }
    else {
      return (
        <div className={rs.buttonSecondary} style={{
          margin: "2rem 0", height: "4rem", fontSize: "1.75rem",
          fontFamily: "bebas_neueregular", letterSpacing: ".1rem"
        }}>
          Currently Unavailable
        </div>
      )
    }
  }

  const getAverageResponseTime = () => {
    const responseTime = Math.floor(reviewer.totalResponseTime / reviewer.totalReviewCount);
    if (reviewer.totalReviewCount === 0)
      return "-"
    else if (responseTime < 1) {
      return "< 1 Hour";
    }
    else if (responseTime === 1) {
      return "1 Hour";
    }
    else if (responseTime < 24) {
      return responseTime + " Hours"
    }
    else {
      const days = Math.floor(responseTime / 24);
      return days === 1 ? days + " Day" : days + " Days";
    }
  }

  const getTotalReviews = () => {
    //return 0 + " Replays"
    return reviewer.totalReviewCount + " Replays";
  }

  const getReviewDisplayOption = () => {
    if (reviewer.last25Ratings.length > 2) {
      return seeMoreRatings ?
        <div>
          <div className={mjes.textLink} onClick={() => setSeeMoreRatings(false)}>See Less Ratings</div>
        </div> :
        <div>
          <div className={mjes.textLink} onClick={() => setSeeMoreRatings(true)}>See More Ratings</div>
        </div>
    }
    else
      return <div></div>
  }

  const doubleColumnHeader = () => {
    return (
      <div className={styles.topContainerDoubleColumn}>
        <div className={styles.nameGrid}>
          <div>
            <div className={rs.h1MediumLight}>{name}</div>
            <div className={rs.textMediumLight}>{reviewer.title}</div>
          </div>
          <img src={getSportsIcon()} className={styles.sportIcon} />
        </div>
        <div className={styles.cardGrid}>
          <div className={styles.profileImageContainer} style={{ margin: "1rem 0" }}>
            <img src={selection.imageUrl} className={styles.image} />
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <div className={styles.experience}>
              <div style={{ marginTop: ".25rem" }}>&#8226;</div>
              <div className={rs.textSmallLight}>{reviewer.feature1}</div>
            </div>
            <div className={styles.experience}>
              <div style={{ marginTop: ".25rem" }}>&#8226;</div>
              <div className={rs.textSmallLight}>{reviewer.feature2}</div>
            </div>
            <div className={styles.experience}>
              <div style={{ marginTop: ".25rem" }}>&#8226;</div>
              <div className={rs.textSmallLight}>{reviewer.feature3}</div>
            </div>
          </div>
        </div>

      </div>
    );
  }

  const singleColumnHeader = () => {
    return (
      <div className={styles.topContainerSingleColumn}>
        <div className={styles.nameGrid}>
          <div className={rs.h1LargeLight}>{name}</div>
          <img src={getSportsIcon()} className={styles.sportIconSmall} />
        </div>
        <div className={rs.textLargeLight}>{reviewer.title}</div>
        <div>
          <div className={styles.profileImageContainer} style={{ margin: "1rem 0" }}>
            <img src={selection.imageUrl} className={styles.image} />
          </div>
        </div>
        <div style={{ marginTop: ".5rem" }}>
          <div className={styles.experience}>
            <div style={{ marginTop: ".15rem" }}>&#8226;</div>
            <div className={rs.textSmallLight}>{reviewer.feature1}</div>
          </div>
          <div className={styles.experience}>
            <div style={{ marginTop: ".15rem" }}>&#8226;</div>
            <div className={rs.textSmallLight}>{reviewer.feature2}</div>
          </div>
          <div className={styles.experience}>
            <div style={{ marginTop: ".15rem" }}>&#8226;</div>
            <div className={rs.textSmallLight}>{reviewer.feature3}</div>
          </div>
        </div>
      </div>
    );
  }

  if (status === "processing")
    return <Loader text="Loading Coach" />
  else if (status === "error")
    return <div className={layout.pageContainer}>Could Not Find User</div>

  const name = reviewer.firstName + " " + reviewer.lastName

  const metaTitle = name + " | The Replay App Professional " + reviewer.sport.charAt(0).toUpperCase() + reviewer.sport.slice(1) + " Coach"
  const metaDescription = "Get 1-1 video coaching from " + name + ".  Upload you sports clips and get a personalized Replay video review.  " + reviewer.sport.charAt(0).toUpperCase() + reviewer.sport.slice(1) + " video coaching"
  //console.log(metaTitle)
  //console.log(metaDescription)


  return (
    <div className={mjes.pageContainer}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      {/* <div style={{ display: 'flex', alignItems: "center", marginLeft: "1rem" }}>
        <div className={rs.textLink} onClick={() => onBackPressed()}>{"Coaches"}</div>
        <div style={{ margin: "0 .25rem" }}>
          <ArrowForwardIos style={{ fontSize: "1rem" }} />
        </div>
        <div className={rs.labelXS}>{reviewer.firstName + " " + reviewer.lastName}</div>
      </div> */}
      <div className={mjes.pageContentContainer} style={{ margin: "1rem auto" }} >
        {/* <div className={styles.topIconGrid}>
          <div></div>
          <div className={mjes.iconButton}>
            <Icon icon="heart" height="1.75rem" color="white" />
          </div>
          <div className={mjes.iconButton}>
            <Icon icon="share" height="1.75rem" color="white" />
          </div>
        </div> */}
        <div style={{ margin: ".5rem 0", display: "flex" }}>
          <div className={rs.buttonIcon} onClick={() => onBackPressed()} >
            <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
          </div>
          <div style={{ flexGrow: "1" }}></div>
          <div className={rs.buttonIcon} style={{}} onClick={() => webshareOnClick()}>
            <IosShareIcon style={{ fontSize: "1.5rem" }} />
          </div>
        </div>
        {isSmall ? singleColumnHeader() : doubleColumnHeader()}
        {/* <div>
          {isSmall ?
            <div className={styles.statsGrid}>
              <div className={styles.mobileRatingGrid} >
                <Icon icon="star" height="1.25rem" color="white" />
                <div className={mjes.text_m}>{reviewer.totalRatingCount > 0 ? (reviewer.totalRatingValue / reviewer.totalRatingCount).toFixed(1) : "-"}</div>
              </div>
              <div className={styles.mobileRatingGrid}>
                <Icon icon="lightening" height="1.25rem" color="white" />
                <div className={mjes.text_m}>{getAverageResponseTime()}</div>
              </div>
              <div className={styles.mobileRatingGrid}>
                <Icon icon="checkmark" height="1.25rem" color="white" />
                <div className={mjes.text_m}>{getTotalReviews()}</div>
              </div>
            </div> :
            <div className={styles.statsGrid}>
              <div className={styles.ratingGrid} >
                <Icon icon="star" height="1.75rem" color="white" />
                <div className={mjes.text_m}>{reviewer.totalRatingCount > 0 ? (reviewer.totalRatingValue / reviewer.totalRatingCount).toFixed(1) : "-"}</div>
              </div>
              <div className={styles.ratingGrid}>
                <Icon icon="lightening" height="1.75rem" color="white" />
                <div className={mjes.text_m}>{getAverageResponseTime()}</div>
              </div>
              <div className={styles.ratingGrid}>
                <Icon icon="checkmark" height="1.75rem" color="white" />
                <div className={mjes.text_m}>{getTotalReviews()}</div>
              </div>
            </div>
          } 
        </div> */}
        <div>
          {getSubmitButton()}
        </div>
        <div style={{ margin: ".75rem 0 0 0" }}>
          <div style={{ display: "flex", gap: '.5rem' }}>
            <div style={{ fontWeight: "bold", fontSize: "1rem", color: "#c4c4c4" }}>Save with Lesson Packages</div>
            <div style={{ color: "#4353FF", fontSize: ".95rem", cursor: "pointer", display: "inline-block" }} onClick={() => openMembershipPage()}>See Details</div>
          </div>
        </div>
        <div style={{ margin: "2rem 0" }}>
          {/* <div className={mjes.pageContentContainer} style={{ margin: ".5rem auto 0 auto", textAlign: "center" }}>
            <div className={rs.labelXS} style={{ color: "#147efb", cursor: "pointer" }} onClick={() => setHowItWorksExpanded(!howItWorksExpanded)}>How Does Replay Work?</div>
          </div> */}
          <ReplayDescription />
        </div>
        <div style={{ margin: ".5rem 0 2rem 0" }}>
          <div className={rs.labelMediumLight} style={{ margin: "0 0 .5rem 0" }}>About Me:</div>
          <div className={rs.textSmallLight} style={{ whiteSpace: "pre-wrap" }}>{reviewer.aboutMe}</div>
        </div>
        <div style={{ margin: "2rem 0 1rem 0" }}>
          <div className={rs.labelMediumLight} style={{ margin: ".5rem 0" }}>Submission Guidelines:</div>
          <div className={rs.textSmallLight} style={{ whiteSpace: "pre-wrap" }}>{reviewer.videoGuidelines}</div>
        </div>
        {reviewer.last25Ratings.length > 0 &&
          <div style={{ margin: "2rem 0 1rem 0" }} >
            <div className={styles.reviewLabelGrid}>
              <div className={rs.labelMediumLight} style={{ margin: ".5rem 0" }}>Reviews:</div>
            </div>
            <div>
              {reviewer.last25Ratings.map(rating => (
                <ReviewerRating rating={rating} />
              ))
              }
            </div>
          </div>
        }

      </div>
    </div >
  );
}

export default SearchReviewerPage;