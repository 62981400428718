import React from 'react'
//libraries
import { useSelector } from 'react-redux';
import { Redirect, useLocation, Route } from 'react-router-dom';
//state
import { selectLogin } from '../slices/LoginSlice';
//components
import Loader from '../components/loader/Loader'

const ReviewerProtectedRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const pathname = location.pathname
  const login = useSelector(selectLogin);

  //redirect if reviewer is not logged in
  if ((!login.isLoggedIn || login.role !== "reviewer") && !login.isLoggingIn) {
    return <Redirect to="/login" />
  }

  //if logged in
  if (login.isLoggedIn) {
    //is email verified
    if (!login.isEmailVerified)
      return <Redirect to="/verify-account" />

    //redirect to application status
    if (login.status === "application") {
      return <Redirect to="/application-status" />
    }

    if (login.status === "setup") {
      if (login.setupStatus === "profile" && pathname !== "/setup-profile")
        return <Redirect to="/setup-profile" />
      else if (login.setupStatus === "payment" && pathname !== "/setup-payment")
        return <Redirect to="/setup-payment" />
      else if (login.setupStatus === "picture" && pathname !== "/setup-picture")
        return <Redirect to="/setup-picture" />
    }
    else if (login.status !== "setup" && (pathname === "/setup-profile" || pathname === "/setup-payment" || pathname === "/setup-picture"))
      return <Redirect to="/reviewer-profile" />
  }

  if (login.isLoggingIn) {
    return <Loader message="Logging In" />
  }

  return <Route {...rest} render={
    props => <Component {...rest} {...props} />
  } />

}


export default ReviewerProtectedRoute;