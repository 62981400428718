import React, { useEffect } from 'react';
//libraries
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
//state
import { UserLogout, selectLoggedIn } from '../../slices/UserSlice';
//components
//styles
import styles from "./Logout.module.css"

function Logout(props) {

  const loggedIn = useSelector(selectLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserLogout());
  }, []);

  if(!loggedIn) { 
    return <Redirect to="/login" />
  }
  else {
    return <div></div>
  }

}
  
export default Logout;
  