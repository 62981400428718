import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectReviewApplication, FetchApplications, AcceptApplication, RejectApplication } from '../../slices/ReviewApplicationSlice'
import Loader from '../loader/Loader'
import styles from './ReviewerApplications.module.css'
import mjes from '../../mjes-styles/mjes.module.css'

const ReviewerApplications = () => {

  const dispatch = useDispatch()
  const reviewApplication = useSelector(selectReviewApplication)

  useEffect(() => {
    dispatch(FetchApplications(reviewApplication.filter, 1, 10))
  }, [])

  const pageUp = () => {
    const newPage = reviewApplication.page + 1;
    if(newPage > reviewApplication.totalPages)
      return;
    dispatch(FetchApplications(reviewApplication.filter, newPage, 10))
  }

  const pageDown = () => {
    const newPage = reviewApplication.page - 1;
    if(newPage < 1)
      return;
    dispatch(FetchApplications(reviewApplication.filter, newPage, 10))
  }

  const acceptApplication = (appId) => {
    dispatch(AcceptApplication(appId))
  }

  const rejectApplication = (appId) => {
    dispatch(RejectApplication(appId))
  }

  if (reviewApplication.status === "processing")
    return (
      <Loader text="Loading Applications" />
    )

  return (
    <div style={{margin:"1rem"}}>
      <div className={mjes.pageLabel}>Reviewer Applications</div>
      <div>
        {reviewApplication.applications.map(app => (
          <div className={styles.application}>
            <div className={mjes.contentLabel} style={{textAlign:"center"}}>{app.firstName + " " + app.lastName}</div>
            <div className={mjes.contentLabel} style={{textAlign:"center"}}>{app.email + " " + app.phone}</div>
            <div>
              <div className={mjes.label}>Sport</div>
              <div className={styles.text}>{app.sport}</div>
            </div>
            <div>
              <div className={mjes.label}>Athletic Experience</div>
              <div className={styles.text}>{app.athleticExperience}</div>
            </div>
            <div>
              <div className={mjes.label}>Coaching Experience</div>
              <div className={styles.text}>{app.coachingExperience}</div>
            </div>
            <div>
              <div className={mjes.label}>Achievements</div>
              <div className={styles.text}>{app.achievements}</div>
            </div>
            <div>
              <div className={mjes.label}>Social Links</div>
              <div className={styles.text}>{app.socialLinks}</div>
            </div>
            <div className={styles.buttons}>
              <div className={mjes.stretchButtonPrimary} onClick={()=>acceptApplication(app._id)}>Accept</div>
              <div className={mjes.stretchButtonSecondary} onClick={()=>dispatch(rejectApplication(app._id))}>Reject</div>
            </div>
          </div>
        ))}
      </div>
    </div >
  )
}

export default ReviewerApplications