export const getDateTimeString = (date) => {
  const d = date.getDate()
  const m = date.getMonth() + 1
  const y = date.getFullYear()
  let hr = date.getHours()
  let min = date.getMinutes()
  if (min < 10)
    min = "0" + min
  let sec = date.getSeconds()
  if (sec < 10)
    sec = "0" + sec
  const isAM = hr < 12
  if (!isAM)
    hr = hr - 12
  if (hr === 0)
    hr = 12
  const dateString = m + "/" + d + "/" + y + " " + hr + ":" + min + ":" + sec + " " + (isAM ? "am" : "pm")
  return dateString
}

export const getDateString = (date) => {
  const d = date.getDate()
  const m = date.getMonth() + 1
  const y = date.getFullYear()
  const dateString = m + "/" + d + "/" + y
  return dateString
}

export const getLocalTimeFromUTC = (utc) => {
  const myDate = new Date(utc).toLocaleDateString('en-US');
  // myDate is 12/28/2021
  const myTime = new Date(utc).toLocaleTimeString('en-US');
  const localTimeString = myDate + " " + myTime
  return localTimeString
}