import React from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import PersonIcon from '@mui/icons-material/Person';
import rs from '../../styles/ReplayStyles.module.css'

const ReplayDescription = () => {

  const iconStyles = {
    textAlign: "center",
    flexGrow: 1
  }

  return (
    <div className={rs.labelXS} style={{ margin: ".5rem auto", maxWidth: "100%", background: "#1a1a1a", display: "flex", padding: ".5rem", borderRadius: "1rem" }}>
      <div style={iconStyles}>
        <div>
          <PersonIcon fontSize="large" />
        </div>
        <div className={rs.labelSmallLight}>Choose</div>
        <div className={rs.labelSmallLight}>a Coach</div>
      </div>
      <div style={iconStyles}>
        <div>
          <FileUploadIcon fontSize="large" />
        </div>
        <div className={rs.labelSmallLight}>Upload</div>
        <div className={rs.labelSmallLight}>Videos</div>
      </div>
      <div style={iconStyles}>
        <div>
          <SlowMotionVideoIcon fontSize="large" />
        </div>
        <div className={rs.labelSmallLight}>Get Replay</div>
        <div className={rs.labelSmallLight}>Video</div>
      </div>
    </div>
  )
}

export default ReplayDescription