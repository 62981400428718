import React, { useState, useEffect } from 'react'
//libraries
import { useHistory } from 'react-router-dom'
//helpers
import { validateEmail } from '../../input-validation/InputValidation'
//styles
import styles from './ForgotPassword.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import { getEndpoint } from '../../helpers/ServiceEndpoint'
import { getAuthHeaders } from '../../helpers/Authentication'

const ForgotPassword = () => {

  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('idle')
  const [error, setError] = useState()

  const history = useHistory()

  useEffect(() => {

  }, [])

  const onSubmitHandler = async () => {
    setStatus("processing")

    const validation = validateEmail(email);
    if (!validation.isValid) {
      setError("Enter a valid email")
      setStatus("idle")
    }

    //make request
    try {
      let response = await fetch(getEndpoint() + "authentication/send-forgot-password-link", {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email })
      });

      response = await response.json();
      if (response.success) {
        setStatus("success")
      }
      else {
        setError(response.message)
        setStatus("idle")
      }
    }
    catch (error) {
      setError("Error connecting to server")
      setStatus('idle')
    }
  }

  const onLoginHandler = () => {
    history.push("/login")
  }

  if (status === "success")
    return (
      <div className={mjes.pageContainer}>
        <div className={mjes.pageContentContainer}>
          <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Password Reset Link Sent</div>
          <div className={mjes.contentLabel} style={{ textAlign: "center", margin: "1rem 0" }}>An email was sent to the address you provided.  Click the link and follow the steps to reset your email</div>
          <div className={mjes.stretchButtonPrimary} style={{ margin: ".5rem 0" }} onClick={() => onLoginHandler()}>Login</div>
        </div>
      </div>
    )

  return (
    <div className={mjes.pageContainer}>
      <div className={mjes.pageContentContainer}>
        <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Forgot Your Password</div>
        <div className={styles.settingContainer}>
          <div className={mjes.label}>Email:</div>
          <input className={mjes.textInput} type="text" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className={mjes.stretchButtonPrimary} style={{ margin: ".5rem 0" }} onClick={() => onSubmitHandler()}>Request Password Update</div>
        <div className={mjes.labelError}>{error}</div>
      </div>
    </div >
  )

}

export default ForgotPassword