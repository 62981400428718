import React, { useRef } from "react";
import StarIcon from '@mui/icons-material/Star';
import rs from '../../styles/ReplayStyles.module.css'

const Testimonials = (props) => {

  let screenSize = props.screenSize ? props.screenSize : "large" //default to large if not specified

  const getGridStyle = () => {

    if (screenSize === "small" || screenSize === "medium") {
      return {
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "auto"
      }
    }
    else {
      return {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        margin: "auto"
      }
    }
  }


  return (
    <div style={{ padding: "2rem 0rem 3rem 0rem", margin: "auto", backgroundColor: "#F4F4F4" }}>
      <div style={{ margin: "0 auto 2rem auto", textAlign: "center" }}>
        <div className={rs.h1MediumDark} style={{ lineHeight: "3rem" }}>Reviews</div>
      </div>
      <div style={getGridStyle()}>
        <Testimonial
          text="I was just guessing why my ball slices sometimes. He asked me some questions to further diagnose the issue and then provided some drills for me to work on. It's funny after I took his advice I was consistently hitting lower draws with my driver, which I've never done before."
          name="Matt R."
        />
        <Testimonial
          text="Thanks for the super helpful review! Very clear instructions. Can't wait to get to the range and try out the suggestions."
          name="Matt C."
        />
        <Testimonial
          text={"Detailed explanation of swing fix and easy to understand. Strongly recommend."}
          name="Brandan T"
        />
        <Testimonial
          text="Awesome reply to my video! Made an effort to get the video back to me as soon as possible even though he was in Mexico! Super helpful advice, definitely something I would never have noticed about my swing if I were looking at it. Thanks Sean!"
          name="Caden W."
        />
        <Testimonial
          text="Great feedback. Have already made a ton of progress in my swing. Love the demo and the comprehensive analysis... Also incredibly easy to use and feedback was provided back to me in less than 48 hours. Would recommend to all!"
          name="Jacob K."
        />
        <Testimonial
          text="Wonderful teacher with an abundance of knowledge. The entire process is seamless and everything went smooth."
          name="Brett U."
        />
        <Testimonial
          text="Great feedback to help improve my swing! Very happy with Nick's analysis and the drills he suggested."
          name="Michael A."
        />

        <Testimonial
          text="Amazing instruction, simple yet effective, thanks."
          name="Armaan K."
        />
      </div>
    </div>
  )
}

export default Testimonials

const Testimonial = (props) => {

  return (
    <div style={{ margin: ".5rem 1rem", padding: "1rem", borderRadius: ".5rem", minWidth: "18rem", backgroundColor: "white" }}>
      <div className={rs.labelSmallDark}>{props.name}</div>
      <div style={{ display: "flex", alignItems: "end", margin: ".5rem 0" }}>
        <div>
          <StarIcon style={{ color: "#FFAC33" }} />
          <StarIcon style={{ color: "#FFAC33" }} />
          <StarIcon style={{ color: "#FFAC33" }} />
          <StarIcon style={{ color: "#FFAC33" }} />
          <StarIcon style={{ color: "#FFAC33" }} />
        </div>
      </div>
      <div className={rs.textSmallDark}>{'"' + props.text + '"'}</div>
    </div>
  )
}