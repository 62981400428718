import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { getReviewers } from '../../../requests/AdminRequests'
import mjes from '../../../mjes-styles/mjes.module.css'

const AdminReviewers = () => {

  const [reviewers, setReviewers] = useState()
  const [status, setStatus] = useState("processing")

  const history = useHistory()

  useEffect(() => {
    loadReviewers()
  }, [])

  const loadReviewers = async () => {
    //
    const response = await getReviewers()
    if (response.success) {
      setReviewers(response.payload.reviewers)
      setStatus("loaded")
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "#66ff00"
      case "unavailable":
        return "blue"
      case "suspended":
        return "red"
      case "setup":
        return "yellow"
    }
  }

  if (status === "processing")
    return (
      <div>Loading</div>
    )

  return (
    <div>
      <div className={mjes.label_l} style={{ marginLeft: "1rem" }}>Reviewers</div>
      <div>
        {reviewers.map((reviewer) =>
          <div style={{ backgroundColor: "#333333", padding: ".5rem", margin: ".5rem", cursor: "pointer" }} onClick={() => history.push('/admin-reviewer/' + reviewer._id)}>
            <div style={{ maxWidth: "18rem", display: "flex", alignItems: "center" }}>
              <div className={mjes.label_m} style={{ marginRight: "2rem" }}>{reviewer.firstName + " " + reviewer.lastName}</div>
              <div className={mjes.label_m} style={{ color: getStatusColor(reviewer.status) }}>{reviewer.status}</div>
            </div>
            <div style={{ marginTop: ".25rem", display: "flex", flexWrap: "wrap" }}>
              <div style={{ width: "18rem" }}>
                <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom:".5rem"  }}>Requests</div>
                <div style={{ display: "flex" }}>
                  <div className={mjes.text_s} style={{ width: "10rem" }}>Total - </div>
                  <div className={mjes.text_s}>{reviewer.totalRequestCount} </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={mjes.text_s} style={{ width: "10rem" }}>Active - </div>
                  <div className={mjes.text_s}>{reviewer.totalRequestCount - reviewer.totalDiscardCount - reviewer.totalReviewCount - reviewer.totalExpiredCount} </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={mjes.text_s} style={{ width: "10rem" }}>Completed - </div>
                  <div className={mjes.text_s}>{reviewer.totalReviewCount} </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={mjes.text_s} style={{ width: "10rem" }}>Discarded - </div>
                  <div className={mjes.text_s}>{reviewer.totalDiscardCount} </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className={mjes.text_s} style={{ width: "10rem" }}>Expired - </div>
                  <div className={mjes.text_s}>{reviewer.totalExpiredCount} </div>
                </div>
              </div>
              <div style={{ width: "18rem" }}>
                <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom:".5rem"  }}>Stats</div>
                <div style={{ display: "flex" }}>
                  <div className={mjes.text_s} style={{ width: "10rem" }}>Total Revenue - </div>
                  <div className={mjes.text_s}>{reviewer.totalRevenue} </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminReviewers