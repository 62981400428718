import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const UserActiveRequestsSlice = createSlice({
  name: 'userCompletedRequests',
  initialState: {
    requests: null,
    status: "processing", //idle, fetching, success, error
    error: ""
  },
  reducers: {
    UserActiveRequestsProcessing: (state) => {
      state.status = "processing"
      state.error = ""
      state.requests = null
    },
    UserActiveRequestsSuccess: (state, action) => {
      state.requests = action.payload.requests
      state.status = "success"
      state.error = ""
    },
    UserActiveRequestsError: (state, action) => {
      state.status = "error"
      state.requests = null
      state.error = action.payload;
    }
  }
});

export const {
  UserActiveRequestsProcessing,
  UserActiveRequestsSuccess,
  UserActiveRequestsError
} = UserActiveRequestsSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const FetchRequests = () => async dispatch => {

  dispatch(UserActiveRequestsProcessing());

  try {
    let response = await fetch(getEndpoint() +"request/get-user-active-requests", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ })
    });

    response = await response.json();
    if (response.success) {
      const requests = response.payload.requests
      dispatch(UserActiveRequestsSuccess({requests: requests}))
    }
    else {
      console.log(response.message)
      dispatch(UserActiveRequestsError(response.message));
    }
  }
  catch (error) {
    console.log(error)
    dispatch(UserActiveRequestsError(error));
  }
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUserActiveRequests = state => state.userActiveRequests;

export default UserActiveRequestsSlice.reducer;
