import React, { useState, useEffect } from 'react';
//libraries
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from "query-string"
//state
import { LoginProcessing, ResendEmailVerification, selectLogin, VerifyAccountEmail } from '../../slices/LoginSlice';
//styles
import mjes from '../../mjes-styles/mjes.module.css';
import styles from '../verify-account/VerifyAccount.module.css';

const VerifyAccount = () => {

  const dispatch = useDispatch()
  const location = useLocation()
  const login = useSelector(selectLogin)
  const query = queryString.parse(location.search);

  const [code, setCode] = useState("");

  const resendVerificationCode = () => {
    dispatch(ResendEmailVerification())
  }

  const verifyAccountEmail = () => {
    dispatch(VerifyAccountEmail(code))
  }

  const getSteps = () => {
    if (login.role === 'user')
      return (
        <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Sign Up</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>2</div>
            <div className={mjes.label}>Verify Email</div>
          </div>
        </div>
      )
    else if (login.role === "reviewer")
      return (
        <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Sign Up</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>2</div>
            <div className={mjes.label}>Verify Email</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumber}>3</div>
            <div className={mjes.label}>Replay Will Contact You</div>
          </div>
        </div>
      )
  }

  if (!login.isLoggedIn) {
    return <Redirect to="/login" />
  }

  if (login.isEmailVerified) {
    if (login.role === "user") {
      if (query.trial) {
        const url = "https://buy.stripe.com/28ocNe4Xhbl86c07st"
        window.open(url, '_self')
      }
      else {
        return <Redirect to={'/switch-user' + location.search} />
      }
    }
    else
      return <Redirect to='/application-status' />
  }

  return (
    <div className={mjes.pageContentContainer}>
      <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Please Verify Your Email</div>
      {getSteps()}
      <div style={{ marginTop: "2rem" }}>
        <div className={mjes.contentLabel}>A verification code has been sent to your email</div>
        <div className={styles.codeGrid}>
          <input className={mjes.textInput} type="text" value={code} onChange={(e) => setCode(e.target.value)} />
          <div className={mjes.buttonPrimary} onClick={() => verifyAccountEmail()}>Apply Code</div>
        </div>
      </div>
      <div className={styles.resendGrid}>
        <div className={mjes.label_s} style={{ marginRight: ".5rem" }}>Did not recieve a code?</div>
        <div className={mjes.textLink} onClick={() => resendVerificationCode()}>Resend</div>
      </div>
      <div>
        <div className={mjes.label_m} style={{ marginTop: "2rem" }}>Warning:</div>
        <div className={mjes.text_m}>
          Verify your email now to enable password recovery.  If you do not verify your email and you forget your password, you will be unable to access your account
        </div>
      </div>
    </div>
  );
}

export default VerifyAccount;