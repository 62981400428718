import React, { useEffect, useState, useCallback } from 'react';
//libraries
import { Link, useParams, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { useMediaQuery } from 'react-responsive';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import ReactGA from "react-ga4";
//state
//import { selectSelection, SelectionByUsername } from '../../slices/SelectionSlice';
//import { selectRequest, SubmitRequest, RequestReset } from '../../slices/RequestSlice';
import { selectLogin } from '../../slices/LoginSlice';
//components
import Loader from '../loader/Loader'
import StripeCard from '../stripe-card/StripeCard'
//requests
import { paymentError, submitRequest, uploadError, uploadSuccess, getRequestInformation } from '../../requests/RequestRequests'
//styles
import styles from './RequestReview.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import { validateImage, validateVideo } from '../../input-validation/InputValidation';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import UserUpload from './UserUpload';

function RequestReview() {

  const login = useSelector(selectLogin);
  const stripe = useStripe();
  const elements = useElements();
  let { username } = useParams();
  const history = useHistory();

  const [requestInfoStatus, setRequestInfoStatus] = useState("fetching")
  const [submitStatus, setSubmitStatus] = useState("awaiting")
  const [error, setError] = useState(null)
  const [details, setDetails] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [videoFile1, setVideoFile1] = useState(null);
  const [videoFile2, setVideoFile2] = useState(null);
  const [videoFile3, setVideoFile3] = useState(null);
  const [videoFile4, setVideoFile4] = useState(null);
  const [videoFile1Error, setVideoFile1Error] = useState(null)
  const [videoFile2Error, setVideoFile2Error] = useState(null)
  const [videoFile3Error, setVideoFile3Error] = useState(null)
  const [videoFile4Error, setVideoFile4Error] = useState(null)

  const [requestType, setRequestType] = useState()
  const [reviewer, setReviewer] = useState()
  const [price, setPrice] = useState()
  const [totalPrice, setTotalPrice] = useState()
  const [serviceFee, setServiceFee] = useState()
  const [promoCode, setPromoCode] = useState(null)
  const [userEnteredPromoCode, setUserEnteredPromoCode] = useState("")
  const [promoCodeStatus, setPromoCodeStatus] = useState("awaiting")
  const [promoCodeError, setPromoCodeError] = useState(null)
  const [originalPrice, setOriginalPrice] = useState()
  const [originalTotalPrice, setOriginalTotalPrice] = useState()
  const [originalServiceFee, setOriginalServiceFee] = useState()
  const [reviewerImageUrl, setReviewerImageUrl] = useState(null)

  //sizing
  const isSmall = useMediaQuery({ query: '(max-width: 760px)' })

  useEffect(() => {
    //dispatch(SelectionByUsername(username)); //initialize reviewer
    getRequestInfo()
  }, []);

  const getRequestInfo = async () => {
    setRequestInfoStatus("fetching")
    try {
      const res = await getRequestInformation(username, "")
      if (res.success) {
        const requestInfo = res.payload
        setRequestType(requestInfo.requestType)
        setReviewer(requestInfo.reviewer)
        setReviewerImageUrl(requestInfo.reviewerImageUrl)
        setPrice(requestInfo.basePrice)
        setOriginalPrice(requestInfo.originalBasePrice)
        setTotalPrice(requestInfo.totalPrice)
        setOriginalTotalPrice(requestInfo.originalTotalPrice)
        setServiceFee(requestInfo.serviceFee)
        setOriginalServiceFee(requestInfo.originalServiceFee)
        if (requestInfo.promoCode) setPromoCode(requestInfo.promoCode)
        setRequestInfoStatus("success")
      }
      else {
        setRequestInfoStatus("error")
      }
    }
    catch (err) {
      setRequestInfoStatus("error")
    }
  }

  const testPromoCode = async () => {
    setPromoCodeStatus("fetching")
    try {
      const res = await getRequestInformation(username, userEnteredPromoCode)
      if (res.success) {
        const requestInfo = res.payload
        setRequestType(requestInfo.requestType)
        setPrice(requestInfo.basePrice)
        setOriginalPrice(requestInfo.originalBasePrice)
        setTotalPrice(requestInfo.totalPrice)
        setOriginalTotalPrice(requestInfo.originalTotalPrice)
        setServiceFee(requestInfo.serviceFee)
        setOriginalServiceFee(requestInfo.originalServiceFee)
        if (requestInfo.promoCode) {
          setPromoCode(requestInfo.promoCode)

          if (requestInfo.requestType === "unlimited")
            setPromoCodeError("Unlimited Access Enabled")
          else
            setPromoCodeError(null)
        }
        else {
          setPromoCode(null)
          setPromoCodeError("Invalid Promo Code")
          setPromoCodeStatus("awaiting")
        }
      }
      else {
        setPromoCodeError(res.message)
        setPromoCodeStatus("awaiting")
      }
    }
    catch (err) {
      setPromoCodeStatus("awaiting")
    }
  }

  const onSubmit = async () => {
    setSubmitStatus("processing_payment")
    const reviewerId = reviewer._id

    const videoFiles = getVideoFileList()
    if (videoFiles.length < 1) {
      setSubmitStatus("error")
      setError("Select at least 1 video file to upload")
      return
    }
    try {
      const res = await submitRequest(reviewerId, details, isPublic, price, totalPrice, serviceFee, promoCode, videoFiles)
      if (!res.success) {
        setSubmitStatus("error")
        setError(res.message)
        return
      }
      const request = res.payload.request
      const requestType = request.type
      const fileUploads = res.payload.fileUploads
      //const uploadData = res.payload.uploadData
      const paymentClientSecret = res.payload.paymentClientSecret
      //const cardObj = elements.getElement(CardElement)


      if (requestType === "standard") {
        //process payment
        const result = await stripe.confirmCardPayment(paymentClientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        });

        if (result.error) {
          setSubmitStatus("error")
          setError(result.error.message)
          await paymentError(request._id)
          return
        }

        if (result.paymentIntent.status !== "requires_capture") {
          setSubmitStatus("error")
          setError("Something went wrong with the payment")
          await paymentError(request._id)
          return
        }
      }

      setSubmitStatus("processing_video")

      //upload video
      for (let i = 0; i < videoFiles.length; i++) {
        const videoFile = videoFiles[i]
        const fileUpload = fileUploads.find(fu => fu.position === videoFile.position)
        console.log("uploading - ")
        console.log(fileUpload)
        console.log(videoFile)
        try {
          await uploadVideoFile(videoFile.videoFile, fileUpload.uploadData)
        }
        catch (e) {
          //alert that upload had an error
          await uploadError(request._id)
          setSubmitStatus("error")
          setError(e.message)
          return
        }
      }

      try {
        ReactGA.event({
          category: 'User Events',
          action: 'user_transaction',
          label: 'User Transaction'
        });
      }
      catch (err) {

      }

      //upload successfully finished
      await uploadSuccess(request._id)
      toast.success("Request Submitted")
      setSubmitStatus("success")
    }
    catch (err) {
      setSubmitStatus("error")
      setError(err.message)
      return
    }
  }

  const uploadVideoFile = async (videoFile, uploadData) => {
    //submit to s3 storage
    let form = new FormData();
    Object.keys(uploadData.fields).forEach(key => form.append(key, uploadData.fields[key]));
    form.append('file', videoFile);
    await fetch(uploadData.url, { method: 'POST', body: form });

  }

  const getVideoFileList = () => {
    const videoFiles = []
    let position = 1
    if (videoFile1) {
      const file = {
        position: position,
        description: "",
        videoFile: videoFile1
      }
      videoFiles.push(file)
      position = position + 1
    }

    if (videoFile2) {
      const file = {
        position: position,
        description: "",
        videoFile: videoFile2
      }
      videoFiles.push(file)
      position = position + 1
    }

    if (videoFile3) {
      const file = {
        position: position,
        description: "",
        videoFile: videoFile3
      }
      videoFiles.push(file)
      position = position + 1
    }

    if (videoFile4) {
      const file = {
        position: position,
        description: "",
        videoFile: videoFile4
      }
      videoFiles.push(file)
      position = position + 1
    }

    return videoFiles
  }

  const onVideoFile1Changed = (e) => {
    if (e.target.files.length !== 1) {
      setVideoFile1(null)
      return
    }

    const file = e.target.files[0]
    setVideoFile1(file)

    if (file.size > 400000000) {
      setVideoFile1(null)
      setVideoFile1Error("Must be less than 400MB in size")
      return
    }
    setVideoFile1Error(null)
  }

  const onVideoFile2Changed = (e) => {
    if (e.target.files.length !== 1) {
      setVideoFile2(null)
      return
    }

    const file = e.target.files[0]
    setVideoFile2(file)

    if (file.size > 400000000) {
      setVideoFile2(null)
      setVideoFile2Error("Must be less than 400MB in size")
      return
    }
    setVideoFile2Error(null)
  }

  const onVideoFile3Changed = (e) => {
    if (e.target.files.length !== 1) {
      setVideoFile3(null)
      return
    }

    const file = e.target.files[0]
    setVideoFile3(file)

    if (file.size > 400000000) {
      setVideoFile3(null)
      setVideoFile3Error("Must be less than 400MB in size")
      return
    }
    setVideoFile3Error(null)
  }

  const onVideoFile4Changed = (e) => {
    if (e.target.files.length !== 1) {
      setVideoFile4(null)
      return
    }

    const file = e.target.files[0]
    setVideoFile4(file)

    if (file.size > 400000000) {
      setVideoFile4(null)
      setVideoFile4Error("Must be less than 400MB in size")
      return
    }
    setVideoFile4Error(null)
  }

  const canSubmit = () => {
    //validate stripe
    if (!stripe || !elements)
      return false

    if (!videoFile1 && !videoFile2 && !videoFile3 && !videoFile4)
      return false

    return true
  }

  const switchUser = () => {
    history.push('/switch-user?username=' + reviewer.username)

  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "white",
        color: "#b4b3b3",
        fontSize: "16px",
        "::placeholder": {
          color: "#b4b3b3",
        },
      },
      invalid: {
        color: "#c24141",
        iconColor: "#df8b8b",
      },
    },
  };

  const getSubmitButton = () => {
    if (submitStatus === "processing_payment")
      return (
        <div className={rs.buttonSecondary}
          style={{ cursor: "not-allowed" }}>
          <div className={rs.buttonTextLargeLight}>Submitting</div>
        </div>
      )
    //return <Loader />

    if (canSubmit()) {
      return (
        <div className={rs.buttonSecondary} onClick={() => onSubmit()}>
          <div className={rs.buttonTextLargeLight}>Request Now</div>
        </div>
      )
    }
    else {
      return (
        <div className={rs.buttonSecondary}
          style={{ cursor: "not-allowed" }}>
          <div className={rs.buttonTextLargeLight}>Request Now</div></div>
      )
    }
  }

  const getCoachSection = () => {
    if (isSmall) {
      return (
        <div>
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <img className={styles.coachImage} src={reviewerImageUrl} />
          </div>
          <div style={{ margin: "1rem", textAlign: "left" }}>
            <div className={mjes.text_l} style={{ textAlign: "center", fontSize: "1.5rem", color: "white" }}>{reviewer.firstName + " " + reviewer.lastName}</div>
            <div className={mjes.text_l} style={{ textAlign: "center", fontSize: "1.25rem" }}>{reviewer.title}</div>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className={styles.coachGrid}>
          <div>
            <img className={styles.coachImage} src={reviewerImageUrl} />
          </div>
          <div style={{ textAlign: "center" }}>
            <div style={{ margin: "1rem", textAlign: "left" }}>
              <div className={mjes.text_l} style={{ textAlign: "center", fontSize: "1.5rem", color: "white" }}>{reviewer.firstName + " " + reviewer.lastName}</div>
              <div className={mjes.text_l} style={{ textAlign: "center", fontSize: "1.25rem" }}>{reviewer.title}</div>
            </div>
          </div>
        </div>
      )
    }
  }

  if (login.role !== "user") {
    history.push("/") //only let users transact
  }

  if (submitStatus == "success") {

    history.push("/profile"); //send to user profile requests
  }
  else if (submitStatus === "processing_video") {
    return <Loader message="DO NOT CLOSE OR REFRESH PAGE" submessage="Video may take a couple minutes to upload" />
  }

  if (requestInfoStatus === "fetching")
    return <Loader message="Loading..." />
  else if (requestInfoStatus === "error")
    return <div className={styles.pageContainer}>Could Not Find A Coach</div>

  return (
    <div className={mjes.pageContainer}>
      <div className={mjes.pageContentContainer}>
        <div>
          <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Request a Replay Video</div>
          {getCoachSection()}
        </div>
        <div style={{ marginTop: "1rem" }}>
          <div className={styles.stepSectionLabel}>
            <div className={styles.stepNumber}>1</div>
            <div style={{ marginLeft: "1rem" }}>
              <div className={rs.labelMediumLight} >Athlete</div>
              <div className={rs.textSmallLight}>Who is this Replay Video for?</div>
            </div>
          </div>
          <div style={{ marginLeft: "2rem", marginTop: "1rem" }}>
            <div className={mjes.text_l} style={{ marginTop: "1rem", textAlign: "center", fontSize: "1.5rem", color: "white" }}>{login.user.firstName}</div>
            <div className={mjes.text_m} style={{ color: "#147efb", cursor: "pointer", textAlign: "center" }} onClick={() => { switchUser() }} >(Wrong athlete? Switch User)</div>
          </div>
        </div>
        <div style={{ marginTop: "2.5rem" }}>
          <div className={styles.stepSectionLabel}>
            <div className={styles.stepNumber}>2</div>
            <div style={{ marginLeft: "1rem" }}>
              <div className={rs.labelMediumLight} >Video Uploads</div>
              <div className={rs.textSmallLight}>{"Select up to 4 video clips ( 2 minutes max )"}</div>

            </div>
          </div>
          <div style={{ marginLeft: "2rem", marginTop: "1rem" }}>
            <UserUpload number={1} videoFile={videoFile1} setVideoFile={setVideoFile1} onVideoFileChanged={onVideoFile1Changed} videoError={videoFile1Error} />
            <UserUpload number={2} videoFile={videoFile2} setVideoFile={setVideoFile2} onVideoFileChanged={onVideoFile2Changed} videoError={videoFile2Error} />
            <UserUpload number={3} videoFile={videoFile3} setVideoFile={setVideoFile3} onVideoFileChanged={onVideoFile3Changed} videoError={videoFile3Error} />
            <UserUpload number={4} videoFile={videoFile4} setVideoFile={setVideoFile4} onVideoFileChanged={onVideoFile4Changed} videoError={videoFile4Error} />

            {/* <div style={{ marginBottom: "1rem" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={rs.text_m} style={{ width: "2rem" }}>1:</div>
                {videoFile1 ?
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexGrow: "1", maxWidth: "20rem" }}>{videoFile1.name}</div>
                    <CancelIcon style={{ color: "white", cursor: "pointer" }} onClick={() => setVideoFile1(null)} />
                  </div> :
                  <label className={rs.buttonPrimary}>
                    <input type="file" style={{ fontSize: "1.15rem", width: "16rem", display: "none" }} onChange={onVideoFile1Changed} accept="video/mp4, video/quicktime" />
                    <div>Select Video</div>
                  </label>
                }
              </div>
              <div className={mjes.labelError} style={{ marginTop: ".25rem" }}>{videoFile1Error}</div>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={rs.text_m} style={{ width: "2rem" }}>1:</div>
                {videoFile2 ?
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexGrow: "1", maxWidth: "20rem" }}>{videoFile2.name}</div>
                    <CancelIcon style={{ color: "white", cursor: "pointer" }} onClick={() => setVideoFile2(null)} />
                  </div> :
                  <label className={rs.buttonPrimary}>
                    <input type="file" style={{ fontSize: "1.15rem", width: "16rem", display: "none" }} onChange={onVideoFile2Changed} accept="video/mp4, video/quicktime" />
                    <div>Select Video</div>
                  </label>
                }
              </div>
              <div className={mjes.labelError} style={{ marginTop: ".25rem" }}>{videoFile2Error}</div>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={rs.text_m} style={{ width: "2rem" }}>1:</div>
                {videoFile3 ?
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexGrow: "1", maxWidth: "20rem" }}>{videoFile3.name}</div>
                    <CancelIcon style={{ color: "white", cursor: "pointer" }} onClick={() => setVideoFile3(null)} />
                  </div> :
                  <label className={rs.buttonPrimary}>
                    <input type="file" style={{ fontSize: "1.15rem", width: "16rem", display: "none" }} onChange={onVideoFile3Changed} accept="video/mp4, video/quicktime" />
                    <div>Select Video</div>
                  </label>
                }
              </div>
              <div className={mjes.labelError} style={{ marginTop: ".25rem" }}>{videoFile3Error}</div>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={rs.text_m} style={{ width: "2rem" }}>1:</div>
                {videoFile4 ?
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flexGrow: "1", maxWidth: "20rem" }}>{videoFile4.name}</div>
                    <CancelIcon style={{ color: "white", cursor: "pointer" }} onClick={() => setVideoFile4(null)} />
                  </div> :
                  <label className={rs.buttonPrimary}>
                    <input type="file" style={{ fontSize: "1.15rem", width: "16rem", display: "none" }} onChange={onVideoFile4Changed} accept="video/mp4, video/quicktime" />
                    <div>Select Video</div>
                  </label>
                }
              </div>
              <div className={mjes.labelError} style={{ marginTop: ".25rem" }}>{videoFile4Error}</div>
            </div> */}

            {/* <div>
              <div className={mjes.text_m}>Video Requirements:</div>
              <div style={{ margin: ".25rem 0rem 1rem 1rem" }}>
                <div className={mjes.text_s}>- Portrait mode for best results (optional)</div>
                <div className={mjes.text_s}>- Up to 60 seconds</div>
                <div className={mjes.text_s}>- IOS or Android compatible video (up to 400MB)</div>
                <div className={mjes.text_s}>- All audio will be muted</div>
              </div>
            </div> */}
          </div>
        </div>
        <div style={{ marginTop: "2.5rem" }}>
          <div className={styles.stepSectionLabel}>
            <div>
              <div className={styles.stepNumber}>3</div>
            </div>
            <div style={{ marginLeft: "1rem" }}>
              <div className={rs.labelMediumLight}>Details (optional)</div>
              <div className={rs.textSmallLight}>Provide details to your coach (focus areas, problems, skill level, restrictions)</div>
            </div>
          </div>
          <textarea className={mjes.textAreaInput} value={details} onChange={e => setDetails(e.target.value)} maxLength="255"
            placeholder="" />
          <div className={mjes.subText} style={{ textAlign: "right" }}>{details.length + "/255"}</div>
        </div>

        {/* <div className={styles.dragZoneContainer} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={styles.dropZoneLabel}>
            {videoFile ?
              <div>{videoFile.name}</div> :
              <div>Drop Video Or Click To Select</div>
            }
          </div>
        </div> */}
        <div style={{ marginTop: "1.5rem" }}>
          <div className={styles.stepSectionLabel}>
            <div className={styles.stepNumber}>4</div>
            <div style={{ marginLeft: "1rem" }}>
              <div className={rs.labelMediumLight}>Payment</div>
            </div>
          </div>
          {requestType === "standard" &&
            <div style={{ margin: "1rem 0rem 0rem 0rem" }}>
              <div>
                <div className={rs.labelSmallLight} style={{ marginBottom: ".5rem" }}>Credit / Debit Card </div>
                <div style={{ backgroundColor: "#191a1b", padding: ".75rem" }}>
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                </div>
              </div>
            </div>
          }
          <div>
            {requestType === "standard" &&
              <div style={{ margin: "1rem auto 0 auto", maxWidth: "16rem" }}>
                <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
                  <div className={rs.labelSmallLight}>Replay Video:</div>
                  <div className={rs.labelSmallLight}>{"$" + originalPrice.toFixed(2)}</div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
                  <div className={rs.labelSmallLight}>Service Fee:</div>
                  <div className={rs.labelSmallLight}>{"$" + originalServiceFee.toFixed(2)}</div>
                </div>
                <div style={{ borderBottom: ".1rem gray solid", margin: ".25rem 1.5rem 0 0" }}></div>
                <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
                  <div className={rs.labelSmallLight}>Total Price:</div>
                  <div className={rs.labelSmallLight} style={{ color: "white" }}>
                    {promoCode ?
                      <s>{"$" + originalTotalPrice.toFixed(2)}</s> :
                      <b>{"$" + originalTotalPrice.toFixed(2)}</b>
                    }
                  </div>
                </div>
                {promoCode &&
                  <>
                    <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
                      <div className={rs.labelSmallLight}>Discount:</div>
                      <div className={rs.labelSmallLight} style={{ color: "green" }}>{promoCode.discount * 100 + "%"}</div>
                    </div>
                    <div style={{ borderBottom: ".1rem gray solid", margin: ".25rem 1.5rem 0 0" }}></div>
                    <div style={{ display: "grid", gridTemplateColumns: "10rem 1fr" }}>
                      <div className={rs.labelSmallLight} >New Price:</div>
                      <div className={rs.labelSmallLight} style={{ color: "white" }}><b>{"$" + totalPrice.toFixed(2)}</b></div>
                    </div>
                  </>
                }
              </div>
            }
          </div>
          <div style={{ margin: "1rem 0rem 0rem 0rem" }}>
            <div className={rs.labelSmallLight}>Replay Code (Optional)</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input className={rs.textInput} value={userEnteredPromoCode} onChange={(e) => setUserEnteredPromoCode(e.target.value)} style={{ color: "white", maxWidth: "16rem" }} placeholder="Enter Code" />
              <div className={rs.buttonSecondary} style={{ marginLeft: ".5rem" }} onClick={() => testPromoCode()}>
                <div className={rs.buttonTextMediumLight}>Apply</div>
              </div>
            </div>
            <div>{promoCodeError}</div>
          </div>
        </div>
        <div className={styles.buttonMargin}>
          <div className={styles.checkboxGrid} style={{ marginTop: "2.5rem" }}>
            <input className={mjes.checkbox} style={{ height: "1rem", width: "1rem" }} type="checkbox" checked={isPublic} onChange={(e) => setIsPublic(!isPublic)} />
            <div className={rs.textSmallLight} style={{ margin: ".5rem 0" }}>Allow use in marketing, promotion, and coach profiles - <span onClick={() => window.open("https://www.thereplayapp.com/terms-of-service", '_blank')} style={{ cursor: "pointer", color: "white" }}><u>Terms of Service</u></span></div>
          </div>
          <div style={{ marginTop: ".25rem" }}>
            {getSubmitButton()}
          </div>
        </div>
        <div className={mjes.labelError}>
          {error}
        </div>
        <div className={rs.textSmallLight} style={{ margin: ".5rem 0" }}>
          By requesting a review you agree to Replay's <span style={{ cursor: "pointer", color: "white" }} onClick={() => window.open('https://www.thereplayapp.com/terms-of-service')}><u>Terms of Service</u></span> and <span style={{ cursor: "pointer", color: "white" }} onClick={() => window.open('https://www.thereplayapp.com/privacy-policy')}><u>Privacy Policy</u></span>
        </div>
      </div>
    </div >
  );
}

export default RequestReview;