import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const getShareRequest = async (requestId) => {
  let response = await fetch(getEndpoint() + "request/get-share-request", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({requestId: requestId})
  });

  response = await response.json();
  return response
}
