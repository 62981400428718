import React, { useEffect, useState, useRef } from "react";
import { getFeaturedReviewers } from "../../requests/SearchRequests";
import { useViewport } from '../../hooks/useViewport';
import SearchResultsAll from '../search/SearchResultsAll'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import SearchReviewerMobile from "../search-reviewer/SearchReviewerMobile";
import rs from '../../styles/ReplayStyles.module.css'

const FeaturedCoaches = (props) => {

  const [results, setResults] = useState([])
  const [imageBucket, setImageBucket] = useState(null)
  const scrollRef = useRef(null)
  const { width, height } = useViewport();

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  }

  useEffect(() => {
    loadCoaches()
  }, [])

  const loadCoaches = async () => {
    try {
      const response = await getFeaturedReviewers()
      if (response.success) {
        setImageBucket(response.payload.bucket)
        setResults(response.payload.results)
      }
    }
    catch (err) {

    }
  }

  return (
    <div style={{ padding: "2rem 1rem"}}>
      <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Replay Sports</div>
      <SearchResultsAll results={results} bucket={imageBucket} />
    </div>
  )

  // return (
  //   <div style={{padding: "2rem 1rem"}}>
  //     <div style={{ display: "flex", margin: "1rem 0", alignItems:"center" }}>
  //       <div className={rs.h1MediumLight} style={{ flexGrow: "1" }}>Featured Coaches</div>
  //       <div className={rs.labelM} onClick={() => scroll(-150)} style={{ cursor: "pointer" }} >
  //         <ArrowBackIos />
  //       </div>
  //       <div style={{ width: ".5rem" }}></div>
  //       <div className={rs.labelM} onClick={() => scroll(150)} style={{ cursor: "pointer" }} >
  //         <ArrowForwardIos />
  //       </div>
  //     </div>
  //     <div style={{}}>
  //       <div ref={scrollRef} style={{ display: "flex", overflow: width <= 768 ? "auto" : "hidden" }}>
  //         {reviewers && reviewers.map((reviewer) =>
  //           <>
  //             <div style={{ marginRight: "1rem", minWidth: "18rem" }}>
  //               <SearchReviewerMobile reviewer={reviewer} bucket={imageBucket} sport="all" scrollY={0} />
  //             </div>
  //           </>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default FeaturedCoaches