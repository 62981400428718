import React, { useRef } from "react";
import { useViewport } from '../../hooks/useViewport';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import rs from '../../styles/ReplayStyles.module.css'

const FeaturedReplays = () => {

  const scrollRef = useRef(null)
  const { width, height } = useViewport();


  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  }

  if (width <= 768) { //mobile and tablet sizes
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div className={rs.labelM} style={{ flexGrow: "1" }}></div>
          <div className={rs.labelM} onClick={() => scroll(-192)} style={{ cursor: "pointer" }} >
            <ArrowBackIos />
          </div>
          <div style={{ width: ".5rem" }}></div>
          <div className={rs.labelM} onClick={() => scroll(192)} style={{ cursor: "pointer" }}>
            <ArrowForwardIos />
          </div>
        </div>
        <div ref={scrollRef} style={{ display: "flex", overflow: "auto" }}>
          <div style={{ marginRight: "1rem" }}>
            <video style={{ maxWidth: "12rem", margin: "0rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/mack_chase.png">
              <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/mack_chase.mp4"} />
            </video>
          </div>
          <div style={{ marginRight: "1rem" }}>
            <video style={{ maxWidth: "12rem", margin: "0rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/carson_gabe.png">
              <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/carson_gabe.mp4"} />
            </video>
          </div>
          <div style={{ marginRight: "1rem" }}>
            <video style={{ maxWidth: "12rem", margin: "0rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/dustin_chase.png">
              <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/dustin_chase.mp4"} />
            </video>
          </div>
          <div>
            <video style={{ maxWidth: "12rem", margin: "0rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/alex_quinn.png">
              <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/alex_quinn.mp4"} />
            </video>
          </div>
        </div>
      </div >
    )
  }
  else if (width < 1024) {
  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className={rs.labelM} style={{ flexGrow: "1" }}></div>
        <div className={rs.labelM} onClick={() => scroll(-150)} style={{ cursor: "pointer" }} >
          <ArrowBackIos />
        </div>
        <div style={{ width: ".5rem" }}></div>
        <div className={rs.labelM} onClick={() => scroll(150)} style={{ cursor: "pointer" }}>
          <ArrowForwardIos />
        </div>
      </div>
      <div ref={scrollRef} style={{ display: "flex", overflow: "hidden" }}>
        <div style={{ marginRight: "1rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/mack_chase.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/mack_chase.mp4"} />
          </video>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/carson_gabe.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/carson_gabe.mp4"} />
          </video>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/dustin_chase.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/dustin_chase.mp4"} />
          </video>
        </div>
        <div style={{ marginRight: "1rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/alex_quinn.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/alex_quinn.mp4"} />
          </video>
        </div>
      </div>
    </div >
  )
}
else {
  return (
    <div>
      {/* <div style={{ display: "flex" }}>
        <div className={rs.labelM} style={{ flexGrow: "1" }}></div>
        <div className={rs.labelM} onClick={() => scroll(-150)} style={{ cursor: "pointer" }} >
          <ArrowBackIos />
        </div>
        <div style={{ width: ".5rem" }}></div>
        <div className={rs.labelM} onClick={() => scroll(150)} style={{ cursor: "pointer" }}>
          <ArrowForwardIos />
        </div>
      </div> */}
      <div ref={scrollRef} style={{ display: "flex", overflow: "hidden", justifyContent:"center" }}>
        <div style={{ marginRight: "2rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/mack_chase.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/mack_chase.mp4"} />
          </video>
        </div>
        <div style={{ marginRight: "2rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/carson_gabe.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/carson_gabe.mp4"} />
          </video>
        </div>
        <div style={{ marginRight: "2rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/dustin_chase.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/dustin_chase.mp4"} />
          </video>
        </div>
        <div style={{ marginRight: "2rem" }}>
          <video style={{ maxWidth: "14rem", margin: "1rem 0" }} playsInline controls preload="metadata" poster="https://d2vtlih1ycguzs.cloudfront.net/video-thumbnails/alex_quinn.png">
            <source src={"https://d2vtlih1ycguzs.cloudfront.net/shared-replays/alex_quinn.mp4"} />
          </video>
        </div>
      </div>
    </div >
  )
}
}

export default FeaturedReplays