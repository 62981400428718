import React from "react";
import { useHistory } from "react-router";
import AllIcon from '../../images/sports-filter/all_glow.png';
import BaseballIcon from '../../images/sports-filter/baseball_glow.png';
import BasketballIcon from '../../images/sports-filter/basketball_glow.png';
import FootballIcon from '../../images/sports-filter/football_glow.png';
import GolfIcon from '../../images/sports-filter/golf_glow.png';
import LacrosseIcon from '../../images/sports-filter/lacrosse_glow.png';
import GymnasticsIcon from '../../images/sports-filter/gymnastics_glow.png';
import style from './SportFilter.module.css'

const SportFilter = (props) => {

  const sport = props.sport
  const selectedSport = props.selectedSport
  const size = props.size //sm md lg
  const history = useHistory()

  const onIconClick = () => {
    history.push('/browse/' + sport)
  }

  const getSportIcon = () => {
    switch (sport) {
      case "basketball":
        return BasketballIcon
      case "baseball":
        return BaseballIcon
      case "football":
        return FootballIcon
      case "golf":
        return GolfIcon
      case "lacrosse":
        return LacrosseIcon
      case "gymnastics":
        return GymnasticsIcon
      default:
        return AllIcon
    }
  }

  const getFilterSize = () => {
    switch (size) {
      case "sm":
      case "xs":
        return "5rem"
      case "md":
        return "6rem"
      case "lg":
        return "7rem"
      default:
        return "7rem"
    }
  }

  const getImageSize = () => {
    switch (size) {
      case "sm":
      case "xs":
        return "4rem"
      case "md":
        return "5rem"
      case "lg":
        return "5rem"
      default:
        return "6rem"
    }
  }

  return (
    <div className={style.sportFilter} style={{ width: getFilterSize(), textAlign: "center" }} onClick={() => onIconClick()} >
      <img className={style.sportImage} src={getSportIcon()} style={{ width: getImageSize(), opacity: sport === selectedSport ? "1" : ".65" }} />
    </div>
  )
}

export default SportFilter