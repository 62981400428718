import React, { useEffect } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//state
import { selectUser } from '../../slices/UserSlice';
import { selectReviewerCompletedRequests, FetchRequests } from '../../slices/ReviewerCompletedRequestsSlice';
//components
import ReviewerCompletedRequest from '../reviewer-completed-request/ReviewerCompletedRequest';
import Loader from '../loader/Loader'
//styles
import styles from './ReviewerCompletedRequests.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import modal from '../../app-styles/Modal.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'


function ReviewerCompletedRequests() {

  const dispatch = useDispatch();
  const history = useHistory()
  const requestSlice = useSelector(selectReviewerCompletedRequests);
  const page = requestSlice.page
  const totalPages = requestSlice.totalPages
  const requests = requestSlice.requests;
  const status = requestSlice.status;

  useEffect(() => {
    dispatch(FetchRequests(page));
  }, []);

  const pageBack = () => {
    if (page > 1)
      dispatch(FetchRequests(page - 1));
  }

  const pageForward = () => {
    if (page < totalPages)
      dispatch(FetchRequests(page + 1));
  }

  const updateProfile = () => {
    history.push('/reviewer-profile/profile')
  }

  if (status === 'processing')
    return <Loader text="Loading Requests" />
  else if (status === 'error')
    return <div>Error retrieving requests. Try refreshing the page.</div>

  return (
    <div>
      <div className={rs.h1MediumLight}>Replays</div>
      {requests.length > 0 ?
        <div>
          {requests.map(request => (
            <ReviewerCompletedRequest key={request._id} request={request} />
          ))}
        </div> :
        <div className={layout.pageContent}>
          <div className={rs.textMediumLight}>You have not completed any Replays</div>
          {/* <div className={buttons.accentStretchButton} onClick={() => updateProfile()}>Update Profile</div> */}
        </div>}
    </div>
    // <div className={mjes.pageContainer}>
    //   <div className={mjes.pageLabel}>Completed Requests</div>
    //   {requests.length > 0 ?
    //     requests.map(request => (
    //       <ReviewerCompletedRequest key={request._id} request={request} />
    //     )) :
    //     <div>You have no completed requests</div>}
    // </div>
  );
}

export default ReviewerCompletedRequests;