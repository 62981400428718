import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectLogin } from '../../slices/LoginSlice'
import { getOnboardingLink } from '../../requests/PaymentRequests'
import Loader from '../loader/Loader'
import mjes from '../../mjes-styles/mjes.module.css'

const PaymentOnboardingRedirect = () => {

  const [status, setStatus] = useState('initializing')

  const history = useHistory()
  const login = useSelector(selectLogin)

  useEffect(() => {
    if (login.isLoggedIn)
      if (login.status === "setup" && login.setupStatus === "payment")
        getLink()
      else
        history.push("/reviewer-profile")
  }, [login])

  const getLink = async () => {
    if (login.isLoggedIn && login.role !== "reviewer")
      return history.push('/')

    const response = await getOnboardingLink()
    if (response.success) {
      const url = response.payload.link.url
      window.open(url, '_self')
    }
    else {
      setStatus('error')
    }
    setStatus('success')
  }

  if (status === "initializing")
    return (
      <div>
        <Loader message="Redirecting to Payment Platform" />
      </div>
    )
  else if (status === "success")
    return (
      <div className={mjes.label_m}>Payment Dashboard Opened In Separate Tab</div>
    )
  else
    return (
      <div>
        <div className={mjes.label_m}>Error Generating Payment Dashboard Link</div>
      </div>
    )

}

export default PaymentOnboardingRedirect