import React, { useRef } from "react";
import { useViewport } from '../../hooks/useViewport';
import { getScreenSize } from "../../helpers/ScreenSize";
import SportFilter from "../sport-filter/SportFilter";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import style from './SportFilterNav.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import ReplayDescription from "../replay-description/ReplayDescription";

const SportFilterNav = (props) => {

  const scrollRef = useRef()
  const { width, height } = useViewport();
  const screenSize = getScreenSize(width)
  const selectedSport = props.selectedSport

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  }

  const getNavClass = () => {
    if (screenSize === "sm" || screenSize === "xs") {
      return style.smGrid
    }
    else if (screenSize === "md") {
      return style.mdGrid
    }
    else {
      return style.lgGrid
    }
  }

  const getHeadline = () => {
    if (screenSize === "xs") {
      return (
        <div>
          <div>
            <div className={rs.h1S} style={{ textAlign: "center" }}>Choose Coach. Upload Video. Get Feedback.</div>
          </div>
          <div style={{ margin: "0rem auto 1rem auto", maxWidth: "30rem", textAlign: "center" }}>
            {/* <div className={rs.textS}>Select a sport to get started</div> */}
          </div>
        </div>
      )
    }
    else if (screenSize === "sm") {
      return (
        <div>
          <div>
            <div className={rs.h1XS} style={{ textAlign: "center" }}>Choose Coach. Upload Video. Get Feedback.</div>
          </div>
          <div style={{ margin: "0rem auto 1rem auto", maxWidth: "30rem", textAlign: "center" }}>
            {/* <div className={rs.textS}>Select a sport to get started</div> */}
          </div>
        </div>
      )
    }
    else if (screenSize === "md") {
      return (
        <div>
          <div>
            <div className={rs.h1M} style={{ textAlign: "center" }}>Choose Coach. Upload Video. Get Feedback.</div>
          </div>
          <div style={{ margin: "1rem auto 2rem auto", maxWidth: "30rem", textAlign: "center" }}>
            {/* <div className={rs.textM}>Select a sport to get started</div> */}
          </div>
        </div>
      )
    }
    else {
      return (
        <div style={{ maxWidth: "30rem", margin: "auto" }}>
          <ReplayDescription />
        </div>
      )
    }
  }

  const getScrollDescription = () => {
    if (screenSize === "xs" || screenSize === "sm")
      return (
        <div style={{ display: "flex", alignItems: "center", justifyItems: "center", justifyContent: "center", margin: ".5rem 0 0 0" }}>
          <div onClick={() => scroll(-150)}>
            <ArrowBackIos />
            <ArrowBackIos />
          </div>
          <div className={rs.labelSmallLight} style={{ width: "10rem", textAlign: "center" }}>Select a Sport</div>
          <div onClick={() => scroll(150)}>
            <ArrowForwardIos />
            <ArrowForwardIos />
          </div>
        </div>
      )
    else
      return null
  }

  return (
    <div>
      <div>
        {/* {getHeadline()} */}
      </div>
      <div className={getNavClass()} ref={scrollRef}>
        <SportFilter size={screenSize} sport="all" selectedSport={selectedSport} />
        <SportFilter size={screenSize} sport="golf" selectedSport={selectedSport} />
        <SportFilter size={screenSize} sport="baseball" selectedSport={selectedSport} />
        <SportFilter size={screenSize} sport="lacrosse" selectedSport={selectedSport} />
        <SportFilter size={screenSize} sport="gymnastics" selectedSport={selectedSport} />
        <SportFilter size={screenSize} sport="football" selectedSport={selectedSport} />
        <SportFilter size={screenSize} sport="basketball" selectedSport={selectedSport} />
      </div>
      <div>
        {getScrollDescription()}
      </div>
      {/* <div style={{ maxWidth: "30rem", margin: "auto" }}>
        <ReplayDescription />
      </div> */}
    </div>
  )
}

export default SportFilterNav