import React from 'react'
//libraries
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
//state
import { selectLogin } from '../../slices/LoginSlice';
//components
//styles
import styles from './Footer.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import facebookLogo from '../../images/third-party-logos/facebook.png';
import instagramLogo from '../../images/third-party-logos/instagram.png';
import twitterLogo from '../../images/third-party-logos/twitter.png';
import tiktokLogo from '../../images/third-party-logos/tiktok.png';
import BetaSignup from '../beta-signup/BetaSignup';
import rs from '../../styles/ReplayStyles.module.css'
import CopyrightIcon from '@mui/icons-material/Copyright';
import MissionStatement from '../home-page/MissionStatement';

function Footer(props) {

  //redux
  const login = useSelector(selectLogin);

  const history = useHistory()

  //media queries
  const isSmall = useMediaQuery({ query: '(max-width: 650px)' })

  const linkStyle = {
    cursor: "pointer",
    margin: ".25rem 1rem .25rem 0rem"
  }

  return (
    <div className={rs.mediumBackground} >
      <div style={{ padding: "1rem", maxWidth: "100rem", margin: "auto" }}>
        {/* <MissionStatement /> */}

        <div style={{ display: "flex", flexWrap: "wrap", textAlign: "left" }}>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/golf')}>Find Coach</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/')}>How It Works</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/contact-us')}>Contact Us</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/terms-of-service')}>Terms</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/privacy-policy')}>Privacy</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/signup-reviewer')}>Coach Application</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/browse')}>Other Sports</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/signup-user')}>Signup</div>
          <div className={rs.labelSmallLight} style={linkStyle} onClick={() => history.push('/login')}>Login</div>
        </div>
        <div style={{ borderBottom: ".1rem solid #3b3b3b", margin: "1rem 0" }}></div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ minWidth: "18rem", flexGrow: "1", marginBottom: "1rem" }}>
            <div className={rs.labelSmallLight}>Follow Us</div>
            <div style={{ display: "flex", marginTop: ".5rem" }}>
              <div style={{ marginRight: "1rem" }} onClick={() => window.open("https://instagram.com/thereplayapp?igshid=1myjn3i9yro8l", '_blank')}>
                <img className={styles.socialLink} src={instagramLogo} />
              </div>
              <div style={{ marginRight: "1rem" }} onClick={() => window.open("https://twitter.com/thereplayapp?s=11", '_blank')}>
                <img className={styles.socialLink} src={twitterLogo} />
              </div>
              <div style={{ marginRight: "1rem" }} onClick={() => window.open("https://www.facebook.com/thereplayapp", '_blank')}>
                <img className={styles.socialLink} src={facebookLogo} />
              </div>
              <div onClick={() => window.open("http://www.tiktok.com/@thereplayapp", '_blank')}>
                <img className={styles.socialLink} src={tiktokLogo} />
              </div>
            </div>
          </div>
          <div style={{ minWidth: "18rem", flexGrow: "1" }}>
            <div style={{ display: "flex" }}>
              <div className={rs.labelSmallLight}>The Replay App, Inc</div>
              <CopyrightIcon style={{ fontSize: "1rem", margin: "0 .25rem 0 .5rem" }} />
              <div className={rs.labelSmallLight}>2021</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Footer;