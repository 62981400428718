import React, { useState } from 'react';
//libraries
import { Link, useHistory } from 'react-router-dom';
import { getDateString, getDateTimeString } from '../../helpers/Date'
//state
//components
import SportIcon from '../icons/SportIcon';
import Icon from '../../icons/Icon';
//styles
import styles from './UserCompletedRequest.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import card from '../../app-styles/Card.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import rs from '../../styles/ReplayStyles.module.css'


function UserCompletedRequest(props) {

  const request = props.request;

  //router
  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getMenuLinks = () => {
    switch (request.status) {
      case "completed":
        return (
          <>
            <div className={mjes.textLink}>Report Issue</div>
          </>
        )
      default:
        return <></>
    }
  }

  const cardClick = () => {
    history.push('/user-download/' + request._id);
  }

  const getResponseTime = () => {
    const responseTime = Math.floor(request.responseTime);
    if (responseTime < 1)
      return "< 1 Hour"
    else if (responseTime === 1)
      return "1 Hour"
    else
      return responseTime + " Hours"
  }

  return (
    <div className={card.card} onClick={() => cardClick()} style={{ cursor: "pointer" }}>
      <div className={card.cardContent}>
        <div className={styles.topGrid}>
          <div className={rs.h1SmallLight}>{(request.reviewerFirstName + " " + request.reviewerLastName).substring(0, 25)}</div>
          <div className={styles.sportIconContainer}>
            <SportIcon icon={request.sport} />
          </div>
        </div>
        {/* <div className={styles.imageContainer}>
          <img className={styles.image} src={request.reviewerImage}></img>
        </div> */}
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Date:</div>
          <div className={rs.textSmallLight}>{getDateString(new Date(request.dateCreated))}</div>
        </div>
        <div className={card.textGroup}>
          <div className={rs.labelSmallLight}>Session #:</div>
          <div className={rs.textSmallLight}>{request.repeatCount + 1}</div>
        </div>        
        <div className={styles.followupGrid}>
          <div className={rs.labelSmallLight}>Follow up:</div>
          <div className={styles.followupGrid}>
            <div className={rs.labelSmallLight} style={{ color: request.question ? "white" : "gray" }}>Question</div>
            <div className={rs.labelSmallLight} style={{ color: request.response ? "white" : "gray" }}>Answer</div>
          </div>
        </div>
        <div className={styles.statsGrid}>
          <div className={styles.statGrid}>
            <Icon icon="star" width="2rem" height="1.5rem" color="white" />
            <div className={rs.labelSmallLight}>{request.rating ? request.rating.ratingValue + " Stars" : "-"}</div>
          </div>
          <div className={styles.statGrid}>
            <Icon icon="lightening" width="2rem" height="1.5rem" color="white" />
            <div className={rs.labelSmallLight}>{getResponseTime()}</div>
          </div>
        </div>
        <div className={card.statusContainer}>
          <div className={rs.labelSmallLight} style={{}}>Click To View</div>
          {/* <div className={card.buttonContainer} onClick={openMenu}>
            <Icon icon="gear" width="1rem" height="1rem" color="white" />
          </div> */}
        </div>
      </div>
      {isMenuOpen &&
        <div className={mjes.dropdownMenu}>
          <div>
            {getMenuLinks()}
          </div>
        </div>
      }
    </div>
  );
}

export default UserCompletedRequest;