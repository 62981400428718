import React, { useState } from 'react';
//libraries
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
//state
import { Logout } from '../../slices/LoginSlice';
//components
//styles
import styles from './DropdownMenuAdmin.module.css';
import mjes from '../../mjes-styles/mjes.module.css'

function DropdownMenuAdmin(props) {

  const firstName = props.firstName;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);

  function menuClick(event) {
    setShowMenu(!showMenu);
  }

  function outsideClick() {
    setShowMenu(false);
  }

  const switchUser = () => {
    setShowMenu(false);
    history.push('/switch-user')
  }

  const getColor = () => {
    return "#147efb"
  }

  const clickLink = (link) => {
    setShowMenu(false)
    history.push(link)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => outsideClick()} >
      <div>
        <div className={mjes.buttonPrimary} onClick={menuClick} style={{ backgroundColor: getColor(), height: "2.5rem", width: "2.5rem" }}>
          {"A"}
        </div>
        {showMenu &&
          <div className={mjes.dropdownMenu}>
            <div className={mjes.contentLabel} style={{ textAlign: "center" }}>{firstName}</div>
            <div style={{ textAlign: "left" }}>
              <div className={mjes.textLink} onClick={() => clickLink("/reviewer-applications")}>Applications</div>
              <div className={mjes.textLink} onClick={() => clickLink("/admin-reviewers")}>Reviewers</div>
              <div className={mjes.textLink} onClick={() => clickLink("/admin-requests")}>Requests</div>
              <div className={mjes.textLink} onClick={() => clickLink("/admin-stats")}>Stats</div>
              <div className={mjes.textLink} onClick={() => clickLink("/create-coach")}>Create Coach</div>
              <div className={mjes.textLink} style={{color:"blue"}} onClick={() => { dispatch(Logout()) }}>Log Out</div>
            </div>
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
}

export default DropdownMenuAdmin;