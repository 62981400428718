import React from 'react'
//libraries
import { useHistory } from 'react-router-dom';
import { useViewport } from '../../hooks/useViewport';
import { Helmet } from "react-helmet"
//state
//images
import Step1 from '../../images/assets/1.png'
import Step2 from '../../images/assets/2.png'
import Step3 from '../../images/assets/3.png'
import backgroundCover from '../../images/assets/landing-page-cover.png'
import GolfAcademy from '../../images/assets/golf-academy.png'
import GolfMagazine from '../../images/assets/golf-magazine.png'
import GolfDigest from '../../images/assets/golf-digest.png'


//styles
import rs from '../../styles/ReplayStyles.module.css'
import FeaturedCoaches from './FeaturedCoaches';
import PickYourSport from './PickYourSport';
import Testimonials from './Testimonials';
import HowItWorks from './HowItWorks';
import CommonQuestions from './CommonQuestions';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MissionStatement from './MissionStatement';
import FeaturedGolfCoaches from './FeaturedGolfCoaches';
import ExampleReplays from './ExampleReplays';
import { borderRadius } from '@mui/system';

const HomePage = () => {

  const history = useHistory();

  const { width, height } = useViewport();
  let screenSize;
  if (width <= 480)
    screenSize = "small"
  else if (width <= 900)
    screenSize = "medium"
  else
    screenSize = "large"

  const getHomepage = () => {
    switch (screenSize) {
      case "small":
        return <SmallHomePage />
      case "medium":
        return <MediumHomePage />
      default:
        return <LargeHomePage />
    }
  }

  return (
    <div>
      <Helmet>
        <title>The Replay App | Video Coaching From Professional Athletes</title>
        <meta name="description" content="Get 1-1 video coaching from professional and college athletes.  Upload a video and get back a Replay video review from a pro using high tech video analysis software.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
      </Helmet>
      <div>
        {getHomepage()}
      </div>

    </div >
  )
}

export default HomePage



const SmallHomePage = () => {
  const history = useHistory()
  return (
    <div style={{}}>
      <div style={{ margin: "2rem auto 2rem auto", padding: "0 1rem" }}>
        <div className={rs.h1LargeLight} style={{ fontSize: "2rem", textAlign: "center" }}>
          Play Your Best Golf
        </div>
        <div className={rs.textMediumLight} style={{ margin: "2rem 0 0 0", fontSize: "1.15rem", lineHeight: "1.75rem", textAlign: "center" }}>
          {"Send videos of your swing to America's best golf instructors for personalized feedback videos with 1:1 coaching"}
        </div>
        <div style={{ margin: "2rem 0 0 0" }}>
          {/* <div className={rs.textMediumLight} style={{}}>Coaches Seen In:</div> */}
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1rem", marginTop: "1rem" }}>
            <img src={GolfAcademy} style={{ width: "6rem" }} />
            <img src={GolfMagazine} style={{ width: "6rem" }} />
            <img src={GolfDigest} style={{ width: "6rem" }} />
          </div>
        </div>

        {/* <div style={{ marginTop: "5rem", marginBottom: "5rem" }}>
          <div className={rs.buttonSecondary} style={{ maxWidth: "18rem", margin: "auto", borderRadius: "1.5rem", padding: "1rem .5rem" }} onClick={() => history.push('/golf')}>
            <div className={rs.buttonTextLargeLight} >Pick Your Coach</div>
          </div>
        </div> */}
      </div>
      <div style={{ margin: "2rem 0 0rem 0" }}>
        <FeaturedGolfCoaches />
      </div>
      <div style={{ padding: ".5rem 1rem", border: ".2rem white solid", margin: "0rem auto 3rem auto", borderRadius: ".5rem", width: "12rem", textAlign: "center" }}>
        <div className={rs.textMediumLight} style={{ cursor: "pointer" }} onClick={() => history.push('/golf')}> View All Instructors </div>
      </div>
      <div style={{}}>
        <HowItWorks screenSize={"small"} />
      </div>
      <div>
        <ExampleReplays screenSize={"small"} />
      </div>
      <div style={{}}>
        <Testimonials screenSize="small" />
      </div>
      {/* <div style={{}}>
        <CommonQuestions />
      </div> */}
      <div>
        {/* <MissionStatement /> */}
      </div>
    </div>
  )
}

const MediumHomePage = () => {
  const history = useHistory()
  return (
    <div style={{ margin: "3rem 0rem 3rem 0" }}>
      <div style={{ maxWidth: "40rem", margin: "3rem auto 2rem auto", padding: "0 1rem" }}>
        <div className={rs.h1LargeLight} style={{ fontSize: "2.75rem", textAlign: "center" }}>
          Play Your Best Golf
        </div>
        <div className={rs.textMediumLight} style={{ margin: "2.25rem 0 0 0", fontSize: "1.5rem", lineHeight: "2.25rem", textAlign: "center" }}>
          {"Send videos of your swing to America's best golf instructors for personalized feedback videos with 1:1 coaching"}
        </div>
        <div style={{ margin: "3rem 0 0 0" }}>
          {/* <div className={rs.textMediumLight} style={{}}>Coaches Seen In:</div> */}
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1rem", marginTop: "1rem" }}>
            <img src={GolfAcademy} style={{ width: "7rem" }} />
            <img src={GolfMagazine} style={{ width: "7rem" }} />
            <img src={GolfDigest} style={{ width: "7rem" }} />
          </div>
        </div>
        {/* <div style={{ marginTop: "3rem", marginBottom: "5rem" }}>
          <div className={rs.buttonSecondary} style={{ maxWidth: "18rem", margin: "auto", borderRadius: "1.5rem", padding: "1rem .5rem" }} onClick={() => history.push('/golf')}>
            <div className={rs.buttonTextLargeLight} >Pick Your Coach</div>
          </div>
        </div> */}
      </div>
      <div style={{ margin: "4rem 0 0rem 0" }}>
        <FeaturedGolfCoaches />
      </div>
      <div style={{ padding: ".5rem 1rem", border: ".2rem white solid", margin: "0rem auto 3rem auto", borderRadius: ".5rem", width: "12rem", textAlign: "center" }}>
        <div className={rs.textMediumLight} style={{ cursor: "pointer" }} onClick={() => history.push('/golf')}> View All Instructors </div>
      </div>
      <div style={{}}>
        <HowItWorks screenSize={"medium"} />
      </div>
      <div>
        <ExampleReplays screenSize={"medium"} />
      </div>
      <div style={{}}>
        <Testimonials screenSize="medium" />
      </div>
      <div>
        {/* <MissionStatement /> */}
      </div>
    </div>
  )
}

const LargeHomePage = () => {
  const history = useHistory()
  return (
    <div className={rs.darkBackground}>

      <div style={{ maxWidth: "40rem", margin: "3rem auto 4rem auto" }}>
        <div className={rs.h1LargeLight} style={{ fontSize: "3rem", textAlign: "center" }}>
          Play Your Best Golf
        </div>
        <div className={rs.textMediumLight} style={{ marginTop: "2rem", fontSize: "1.5rem", lineHeight: "2.25rem", textAlign: "center" }}>
          {"Send videos of your swing to America's best golf instructors for personalized feedback videos with 1:1 coaching"}
        </div>

        <div style={{ margin: "2rem 0 0 0" }}>
          {/* <div className={rs.textMediumLight} style={{}}>Coaches Seen In:</div> */}
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1rem", marginTop: "1rem", alignItems: "center", justifyItems: "center" }}>
            <img src={GolfAcademy} />
            <img src={GolfMagazine} />
            <img src={GolfDigest} />
          </div>
        </div>
        {/* <div style={{ marginTop: "5rem", marginBottom: "5rem" }}>
          <div className={rs.buttonSecondary} style={{ maxWidth: "18rem", margin: "auto", borderRadius: "1.5rem", padding: "1rem .5rem" }} onClick={() => history.push('/golf')}>
            <div className={rs.buttonTextLargeLight} >Pick Your Coach</div>
          </div>
        </div> */}
      </div>
      <div style={{ margin: "2rem 0 1rem 0" }}>
        <FeaturedGolfCoaches />
      </div>
      <div style={{ padding: ".5rem 1rem", border: ".2rem white solid", margin: "0rem auto 5rem auto", borderRadius: ".5rem", width: "12rem", textAlign: "center" }}>
        <div className={rs.textMediumLight} style={{ cursor: "pointer" }} onClick={() => history.push('/golf')} > View All Instructors </div>
      </div>
      <div style={{}}>
        <HowItWorks screenSize={"large"} />
      </div>
      <div>
        <ExampleReplays screenSize={"large"} />
      </div>
      <div style={{}}>
        <Testimonials screenSize="large" />
      </div>
      {/* <div style={{}}>
        <FeaturedCoaches />
      </div> */}
      {/* <div style={{}}>
        <CommonQuestions />
      </div> */}
      <div>
        {/* <MissionStatement /> */}
      </div>
    </div>
  )
}