import React, { useEffect, useState } from 'react';
//libraries
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
//state
import { selectReviewerDownloadVideo, SubmitFollowupResponse, FetchRequest } from '../../slices/ReviewerDownloadVideoSlice';
import { selectLogin } from '../../slices/LoginSlice'
//hooks
import { useViewport } from '../../hooks/useViewport';
//helpers
import { getDimensions } from '../../helpers/Dimensions';
//components
import Loader from '../loader/Loader'
import Icon from '../../icons/Icon'
//styles
import styles from './ReviewerDownloadPage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import modal from '../../app-styles/Modal.module.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function ReviewerDownloadPage(props) {

  //state
  const [response, setResponse] = useState("");
  //params
  const params = useParams();
  const requestId = params.requestId;
  //redux
  const dispatch = useDispatch();
  const reviewerDownloadVideo = useSelector(selectReviewerDownloadVideo);
  const login = useSelector(selectLogin)
  //router
  const history = useHistory()
  //const rating = useSelector(selectRating);
  //const user = useSelector(selectUser);
  const request = reviewerDownloadVideo.request;
  const requestStatus = reviewerDownloadVideo.status;
  //screen size
  const { width, height } = useViewport();
  const remPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  //video size
  const videoHeight = height - 10 * remPx;
  const videoDimensions = getDimensions(videoHeight, width);
  const isTwoColumns = width > (videoDimensions.width * 2 + 3 * remPx)


  useEffect(() => {
    dispatch(FetchRequest(requestId));
  }, []);

  const submitResponse = () => {
    if (response === null || response === "")
      return;

    dispatch(SubmitFollowupResponse(request._id, response));
  }

  const getFormattedDate = (date) => {
    const newDate = new Date(date)
    var year = newDate.getFullYear();

    var month = (1 + newDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = newDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  const getResponse = () => {
    if (reviewerDownloadVideo.isUpdatingQuestion) {
      return (
        <Loader text="Submitting Question" />
      )
    }
    else if (request.response) {
      return (
        <div style={{ section }}>
          <div className={rs.labelSmallLight}>Answer:</div>
          <div className={rs.textSmallLight}>{request.response}</div>
        </div>
      )
    }
    else {
      return (
        <div style={{ section }}>
          <div className={rs.labelSmallLight}>Answer:</div>
          <textarea className={rs.textAreaInput} value={response} onChange={e => setResponse(e.target.value)} maxLength="999" />
          <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + response.length + "/999"}</div>
          <div className={rs.buttonSecondary} onClick={() => submitResponse()}>
            <div className={rs.buttonTextLargeLight}>Submit Response</div>
          </div>
        </div>
      )
    }
  }

  if (requestStatus === "processing")
    return <div>Processing...</div>
  else if (requestStatus === "error")
    return <div>Error: {reviewerDownloadVideo.error}</div>

  if (request.reviewerId !== login.accountId)
    history.push('/')

  const section = {
    margin: "1rem 0"
  }

  return (
    <div style={{ marginBottom: "2rem" }}>
      <div style={{ display: "flex", marginLeft: '1rem' }}>
        <div className={rs.buttonIcon} onClick={() => history.push('/reviewer-profile/videos')} >
          <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
        </div>
      </div>
      <div className={isTwoColumns ? styles.mainGrid : null} style={{ marginTop: "1rem", zIndex: 'inherit' }}>
        <div style={{ textAlign: "center" }}>
          <video width={videoDimensions.width} height={videoDimensions.height} crossOrigin="anonymous" controls >
            <source src={reviewerDownloadVideo.videoUrl} type="video/mp4" />
          </video>
        </div>
        <div className={mjes.pageContentContainer} style={{ margin: "0rem" }}>
          <div className={mjes.contentLabel} style={{ textAlign: "center", marginBottom: "1rem" }}>REPLAY REPORT</div>
          <div style={section}>
            <div className={rs.labelSmallLight}>Athlete</div>
            <div className={rs.textSmallLight}>{request.userFirstName + " " + request.userLastName}</div>
          </div>
          <div style={section}>
            <div className={rs.labelSmallLight}>Date</div>
            <div className={rs.textSmallLight}>{getFormattedDate(request.dateCompleted)}</div>
          </div>
          <div style={section}>
            <div className={rs.labelSmallLight}>Session</div>
            <div className={rs.textSmallLight}>{request.repeatCount + 1}</div>
          </div>
          <div style={section}>
            <div className={rs.labelSmallLight}>Price</div>
            <div className={rs.textSmallLight}>{"$" + request.originalBasePrice ? request.originalBasePrice : request.reviewerPrice}</div>
          </div>
          <div style={section}>
            <div className={rs.labelSmallLight}>Details</div>
            <div className={rs.textSmallLight}>{request.details}</div>
          </div>

          {request.question &&
            <div style={section}>
              <div className={rs.labelSmallLight}>Question:</div>
              <div className={rs.textSmallLight}>{request.question}</div>
            </div>
          }
          {request.question &&
            getResponse()
          }
          <div className={styles.buttonGrid}>
          </div>
        </div>
      </div>
    </div>
    // <div className={styles.pageContent}>
    //   <div className={styles.videoContainer}>
    //     <div className={styles.coachName}>{request.userFirstName}</div>
    //     <div className={styles.keyTakeaways}>{request.takeaways}</div>
    //     <div className={styles.videoContainer}>
    //       <video className={styles.video} src={reviewerDownloadVideo.videoUrl}
    //         height={videoDimensions.height} width={videoDimensions.width} controls></video>
    //     </div>
    //   </div>
    //   <div>
    //     {request.question &&
    //       <div className={styles.followupQuestion}>
    //         <div>
    //           <div>Question:</div>
    //           <div>{request.question}</div>
    //         </div>
    //         <div>
    //           <div>Response:</div>
    //           <div>
    //             {(request.response && !reviewerDownloadVideo.updatingResponse) ?
    //               <div>{request.followupResponse}</div> :
    //               <div>
    //                 <textarea className={elements.textAreaInput} value={response} maxLength="160" onChange={(e) => setResponse(e.target.value)} />
    //                 <div className={buttons.accentStretchButton} onClick={() => submitResponse()}>Submit</div>
    //               </div>
    //             }
    //           </div>
    //         </div>
    //       </div>
    //     }
    //   </div>
    // </div>
  );

}

export default ReviewerDownloadPage;