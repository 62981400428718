import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const updateStatus = async (status) => {
  let response = await fetch(getEndpoint() + "reviewer/update-status", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({status: status})
  });

  response = await response.json();
  return response
}
