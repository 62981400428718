import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
//state
//hooks
//components
//styles
import styles from './ReviewerResources.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'

function ReviewerResources() {

  //redux
  const history = useHistory()
  //state

  useEffect(() => {

  }, []);

  return (
    <div>
      <div className={rs.h1MediumLight}>Resources</div>
      <div className={mjes.pageContentContainer}>
        <div style={{ textAlign: "left" }}>
          <div className={rs.labelLargeLight}>Website Overview</div>
          <div className={rs.textSmallLight}>
            In this video I navigate through all the different sections of the “Coach Portal”. I briefly explain how you’ll interact with the site and the basic functionality. I hope this helps!
          </div>
          <div style={{ textAlign: "center" }}>
            <video src="https://d2vtlih1ycguzs.cloudfront.net/coach-resources/coach-profile-overview.mp4" style={{ maxWidth: "100%", margin: "1rem auto" }} controls />
          </div>
        </div>
        <div style={{ textAlign: "left" }}>
          <div className={rs.labelLargeLight}>Replay Video Tutorials</div>
          <div className={mjes.text_s}>
            In this video I show you guys how to record a Replay video. I walk you through the different tools you have at your disposal and how you can use each tool. Remember to have fun, be genuine and be both encouraging and critical. These athletes are excited to learn from you! Please also remember that (right now) Replays can only be created on laptops.
          </div>
          <div style={{ textAlign: "center" }}>
            <video src="https://d2vtlih1ycguzs.cloudfront.net/coach-resources/coach-tools-overview.mp4" style={{ maxWidth: "100%", margin: "1rem auto" }} controls />
          </div>
        </div>
        <div style={{ textAlign: "left", marginTop: "2rem" }}>
          <div className={rs.labelLargeLight}>Sample Replay Analysis</div>
          <div className={mjes.text_s}>
            This is a sample Replay video that I hope can be helpful. Here you’ll see how I see the tooling and speak to the athlete. Remember this review is only a sample and not a full review. We’d suggest that a complete review should be anywhere from 4-7 minutes.</div>
          <div style={{ textAlign: "center" }}>
            <video src="https://d2vtlih1ycguzs.cloudfront.net/shared-replays/mack-football-replay.mp4" style={{ maxWidth: "18rem", margin: "1rem auto" }} controls />
          </div>
        </div>
        <div style={{ textAlign: "left", marginTop: "2rem" }}>
          <div className={rs.labelLargeLight}>Completed Sample Replay Video</div>
          <div className={mjes.text_s}>
            This is an example of a completed Replay video.  This is here for you to learn from but we hope you make your own Replay's in your own unique coaching style.  That is why we chose you as a Replay coach.
          </div>
          <div style={{ textAlign: "center" }}>
            <video src="https://rw-finished-videos.s3.amazonaws.com/ed2ecf68-8a46-467a-97d7-009023d4b104.mp4" style={{ maxWidth: "18rem", margin: "1rem auto" }} controls />
          </div>
        </div>
        <div style={{ textAlign: "left", marginTop: "2rem" }}>
          <div className={rs.labelLargeLight}>Best Practices</div>
          <div style={{ marginTop: ".5rem" }}>
            <div>
              <div className={mjes.label_s}>1. Introduction</div>
              <div style={{ margin: ".25rem 0rem 0 1rem" }}>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  A. For new clients, please make sure to introduce yourself (your name and where you played). Be welcoming. For returning clients, provide a quick recap of where your last session left off. During your introduction (15-20 seconds) be sure to use the expanded/fullscreen webcam module to personally engage with your client to build rapport and trust so they’ll book recurring transactions with you in the future.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  B. Provide a quick overview of what you plan to breakdown in the review video. This will be helpful for both you as a coach and your client so they clearly understand what they should be focusing on.
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: ".5rem" }}>
            <div>
              <div className={mjes.label_s}>2. Analysis</div>
              <div style={{ margin: ".25rem 0rem 0 1rem" }}>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  A. Highlight a few areas of improvement but don’t overload the athlete (be focused and keep it simple). 3-4 coaching points are best. You may also need to address key points based on any specific request that the athlete provided with their upload. (Example: “Coach, can you please take a look at my footwork?)
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  <div>B. For each key point:</div>
                  <div style={{ marginLeft: "1rem" }}>
                    <div>(1) Describe what the client needs to fix or adjust (explain the impact of the adjustment)</div>
                    <div>(2) Coach the client on how they can fix it</div>
                    <div>(3) Provide and quickly explain any supplemental drills or action items you would like them to do to correct the issue.</div>
                  </div>
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  C. Make sure to use the provided tools to your advantage. (On screen drawing, laser pointer, slow motion functionality, and the ability to go frame by frame to see every part of an athletes motion.)
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  D. Don't forget to address the positives! Encourage the athlete and make sure they know what they’re doing well.
                </div>
              </div>
            </div>

          </div>
          <div style={{ marginTop: ".5rem" }}>
            <div>
              <div className={mjes.label_s}>3. Recap</div>
              <div style={{ margin: ".25rem 0rem 0 1rem" }}>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  A. Quick summary of the key coaching points.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  B. Thank the client for uploading their clip to you.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  C. Provide the athlete with the next steps they should take to prepare for their next  session. Example: "After you work through the drills we discussed and are ready for more feedback, I would love if you send me an another video of you doing x so we can work on xyz".
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  D. The tone of your response video should be constructive but in a positive manner. The feedback we’ve received is that clients prefer coaches who are authentic and energetic.
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: ".5rem" }}>
            <div>
              <div className={mjes.label_s}>4. Reminders</div>
              <div style={{ margin: ".25rem 0rem 0 1rem" }}>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  A. Make sure to bring energy.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  B. Be genuine!!! Honesty and authenticity is priceless.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  C. This goes without saying but we need you to be very intentional about the technical feedback you give. Make sure there is value and validity to the teach points.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  D. Be positive AND critical! These are not mutually exclusive. The best coaches in life can do both at the same time.
                </div>
                <div className={mjes.text_s} style={{ margin: ".25rem" }}>
                  E. Be relatable. Feel free to share what makes you great. They want to hear about you!
                </div>              
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ReviewerResources