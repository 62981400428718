import React, { useEffect, useState } from 'react'
import { getActiveRequests } from '../../../requests/AdminRequests'
import { useHistory } from 'react-router-dom'
import mjes from '../../../mjes-styles/mjes.module.css'

const AdminRequests = () => {

  const history = useHistory()
  const [requests, setRequests] = useState([])

  useEffect(() => {
    loadRequests()
  }, [])

  const loadRequests = async () => {
    const response = await getActiveRequests()
    if (response.success)
      setRequests(response.payload.requests)
  }

  return (
    <div>
      <div className={mjes.label_l} style={{ marginLeft: "1rem" }}>Active Requests</div>
      <div style={{ margin: "2em 1rem" }}>
        {requests.map((request) =>
          <div style={{ backgroundColor: "#333333", margin: ".5rem 0", padding: ".5rem", cursor: "pointer" }} onClick={() => history.push('/admin-request/' + request._id)}>
            <div className={mjes.text_m}>{"Id: " + request._id}</div>
            <div className={mjes.text_m}>{"User: " + request.userFirstName + " " + request.userLastName}</div>
            <div className={mjes.text_m}>{"Coach: " + request.reviewerFirstName + " " + request.reviewerLastName}</div>
            <div className={mjes.text_m}>{"Price: " + request.totalPrice}</div>
            <div className={mjes.text_m}>{"Status: " + request.status}</div>
            <div className={mjes.text_m}>{"Process: " + request.processStatus}</div>
            <div className={mjes.text_m}>{"Due: " + new Date(request.dateDue)}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminRequests