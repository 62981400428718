import React from 'react'
//libraries
import { useSelector } from 'react-redux';
import { Redirect, useParams, Route } from 'react-router-dom';
//state
import { selectLogin } from '../slices/LoginSlice';
//components
import Loader from '../components/loader/Loader'

const UserProtectedRoute = ({ component: Component, ...rest }) => {
  //const Component = props.component;
  const login = useSelector(selectLogin);

  if ((!login.isLoggedIn || login.role !== "user") && !login.isLoggingIn) {
    return <Redirect to="/login" />
  }
  else if (login.isLoggedIn && !login.isEmailVerified) {
    return <Redirect to="/verify-account?username=testcoach" />
  }

  if (login.isLoggingIn)
    return <div></div>
  //return <Loader message="Logging In" />

  return <Route {...rest} render={
    props => <Component {...rest} {...props} />
  } />

}


export default UserProtectedRoute;