import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const getFeaturedReviewers = async () => {
  let response = await fetch(getEndpoint() + "search/search", {
    method: "POST",
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({
      primaryFilter: "all"
    })
  });

  response = await response.json();
  return response
}

export const getReviewersByFilter = async (primaryFilter) => {
  let response = await fetch(getEndpoint() + "search/search", {
    method: "POST",
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify({
      primaryFilter: primaryFilter
    })
  });

  response = await response.json();
  return response
}