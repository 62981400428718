import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useRouteMatch, useParams, useHistory } from 'react-router-dom';
//state
//hooks
import { useViewport } from '../../hooks/useViewport';
//components
import ReviewerProfile from '../reviewer-profile/ReviewerProfile';
import ReviewerActiveRequests from '../reviewer-active-requests/ReviewerActiveRequests';
import ReviewerCompletedRequests from '../reviewer-completed-requests/ReviewerCompletedRequests';
import ReviewerDashboardPage from '../reviewer-dashboard-page/ReviewerDashboardPage';
import ReviewerPayments from '../reviewer-payments/ReviewerPayments';
import ReviewerResources from '../reviewer-resources/ReviewerResources';
import NotificationPage from '../notification-page/NotificationPage';
//images
import Request from '../../images/profile-icons/request.png'
import Replay from '../../images/profile-icons/replay.png'
import Notification from '../../images/profile-icons/notification.png'
import Profile from '../../images/profile-icons/profile.png'
import Payment from '../../images/profile-icons/payment.png'
import Resources from '../../images/profile-icons/resources.png'
import Users from '../../images/profile-icons/users.png'
import SportsIcon from '@mui/icons-material/Sports';
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ApprovalIcon from '@mui/icons-material/Approval';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import ArticleIcon from '@mui/icons-material/Article';
//styles
import styles from './ReviewerPage.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'


function ReviewerProfilePage(props) {

  //state
  //viewport
  const { width, height } = useViewport();
  const isSmall = width < 860
  //router
  const { page } = useParams();
  const history = useHistory();

  useEffect(() => {

  }, [])

  const getActiveComponent = () => {
    switch (page) {
      case "requests":
        return <ReviewerActiveRequests />
      case "videos":
        return <ReviewerCompletedRequests />
      case "messages":
        return <NotificationPage />
      case "profile":
        return <ReviewerProfile />
      case "payments":
        return <ReviewerPayments />
      case "resources":
        return <ReviewerResources />
      default:
        return <ReviewerActiveRequests />;
    }
  }

  const getActiveTab = () => {
    switch (page) {
      case "requests":
      case "videos":
      case "messages":
      case "profile":
      case "resources":
      case "payments":
        return page;
      default:
        return "requests";
    }
  }

  const getNavMenu = () => {
    return (
      <div>
        <div className={rs.darkbackground}>
          <div className={mjes.navMenuItems}>
            <div className={getActiveTab() === "requests" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/reviewer-profile/requests')}>
              <OndemandVideoIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Requests</div>
            </div>
            <div className={getActiveTab() === "videos" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/reviewer-profile/videos')}>
              <SlowMotionVideoIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Replays</div>
            </div>
            <div className={getActiveTab() === "profile" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/reviewer-profile/profile')}>
              <PersonIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Profile</div>
            </div>
            <div className={getActiveTab() === "resources" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/reviewer-profile/resources')}>
              <ArticleIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Resources</div>
            </div>
            <div className={getActiveTab() === "payments" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/reviewer-profile/payments')}>
              <PaidIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Payments</div>
            </div>
            <div className={getActiveTab() === "notifications" ? mjes.navMenuItemSelected : mjes.navMenuItem} onClick={() => history.push('/reviewer-profile/messages')}>
              <NotificationsIcon style={{ fontSize: "2.5rem" }} />
              <div className={rs.labelSmallLight}>Notifications</div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div>
      {!isSmall &&
        <div>{getNavMenu()}</div>
      }
      <div className={mjes.page}>{getActiveComponent()}</div>
    </div>
  );
}

export default ReviewerProfilePage;