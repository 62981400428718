import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const ReviewerDownloadVideoSlice = createSlice({
  name: 'ReviewerDownloadVideo',
  initialState: {
    request: null,
    videoUrl: "",
    status: 'processing',
    isUpdatingResponse: false,
    error: ''
  },
  reducers: {
    ReviewerDownloadVideoProcessing: (state) => {
      state.status = 'processing';
      state.request = null;
      state.error = '';
    },
    ReviewerDownloadVideoSuccess: (state, action) => {
      state.request = action.payload.request;
      state.videoUrl = action.payload.videoUrl
      state.status = 'success';
      state.isUpdatingResponse = false;
    },
    ReviewerDownloadVideoError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
      state.request = null;
    },
    ReviewerDownloadVideoReset: (state) => {
      state.status = 'processing';
      state.error = '';
      state.request = null;
    },
    UpdateResponseProcessing: (state) => {
      state.isUpdatingResponse = true
    },
    UpdateResponseSuccess: (state, action) => {
      state.request = action.payload.request
      state.isUpdatingResponse = false
    },
    UpdateQuestionError: (state, action) => {
      state.isUpdatingResponse = false
    },
  },
});

export const { ReviewerDownloadVideoProcessing, ReviewerDownloadVideoSuccess, SubmitFollowupResponseSuccess,
  ReviewerDownloadVideoError, UpdatingResponse,
  UpdateResponseProcessing, UpdateResponseSuccess, UpdateResponseError } = ReviewerDownloadVideoSlice.actions;


export const FetchRequest = (requestId) => async dispatch => {

  dispatch(ReviewerDownloadVideoProcessing());

  //validate inputs
  if (!requestId) {
    dispatch(ReviewerDownloadVideoError("Cannot find request"));
    return;
  }

  try {
    let requestResponse = await fetch(getEndpoint() +"request/get-request-response-video", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId: requestId })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(ReviewerDownloadVideoSuccess({request: request.payload.request, videoUrl: request.payload.videoUrl}));
      return;
    }
    dispatch(ReviewerDownloadVideoError(request.message));
  }
  catch (error) {
    dispatch(ReviewerDownloadVideoError(error.toString()));
    return;
  }
};

export const SubmitFollowupResponse = (requestId, response) => async dispatch => {

  dispatch(UpdateResponseProcessing())

  //validate inputs
  if (!requestId) {
    dispatch(ReviewerDownloadVideoError("Cannot find request"));
    return;
  }

  try {
    let requestResponse = await fetch(getEndpoint() +"request/update-response", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ requestId: requestId, response: response })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(UpdateResponseSuccess({request: request.payload}));
      return;
    }
    toast.error("Error updating response")
    dispatch(UpdateResponseError(request.message));
  }
  catch (error) {
    console.log(error);
    toast.error("Error updating response")
    dispatch(UpdateResponseError(error.toString()));
    return;
  }
};


export const selectReviewerDownloadVideo = state => state.reviewerDownloadVideo;

export default ReviewerDownloadVideoSlice.reducer;
