import React, { useState, useEffect } from 'react'
import { requestBetaAccess } from '../../requests/BetaRequests'
import { useMediaQuery } from 'react-responsive';
import mjes from '../../mjes-styles/mjes.module.css'
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import rs from '../../styles/ReplayStyles.module.css'
import { Redirect, useHistory } from 'react-router';


const BetaSignup = (props) => {

  const [email, setEmail] = useState("")
  const [honeypotPassword, setHoneypotPassword] = useState("")
  const [status, setStatus] = useState("awaiting")
  const [error, setError] = useState(null)

  const history = useHistory()

  const isSmall = true //useMediaQuery({ query: '(max-width: 28rem)' })

  useEffect(() => {
    const signupEmail = localStorage.beta_signup_email;
    if (signupEmail) {
      setEmail(signupEmail)
      setStatus("returning")
    }
  }, [])

  const submit = async () => {

    setStatus("processing")
    setError(null)

    if (honeypotPassword !== "") {
      console.log("ROBOT ALERT")
      setStatus("awaiting")
      setError("spam signup detected")
      return
    }

    try {

      if(localStorage.emailSignupStatus === "accepted" || localStorage.beta_signup_email)
        return history.push('/browse')

      const response = await requestBetaAccess(email)
      if (response.success) {
        localStorage.setItem('beta_signup_email', email);
        setStatus("success")
        //ReactPixel.trackCustom("email-signup", {})
        ReactGA.event({
          category: 'User Events',
          action: 'email_signup',
          label: 'Email Signup'
        });

        TagManager.dataLayer({
          dataLayer: {
            event: "email_signup"
          }
        })
        //props.acceptEmailSignup()
        return
      }
      else {
        setStatus("awaiting")
        setError(response.message)
        return
      }
    }
    catch (err) {
      setStatus("awaiting")
      setError(err.message)
      return
    }
  }

  const getSignupForm = () => {

    if (status === "success") {
      return (
        <Redirect to="/browse" />
      )
    }
    else if (status === "returning")
      return (
        <div className={rs.buttonSecondary} style={{ margin: ".25rem auto 0 auto", fontSize: "1.75rem", letterSpacing: ".05rem", fontFamily: "bebas_neueregular", maxWidth:"30rem" }} onClick={() => history.push("/browse")}>Connect With Pros</div>
      )
    else {
      return (
        <div style={{ maxWidth: "30rem", margin: "auto" }}>
          <div >
            <input className={rs.textInput} style={{ marginRight: ".5rem", marginTop: ".5rem", fontSize: "1rem" }} type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <input type="text" name="password" style={{ display: "none" }} tabIndex="-1" autoComplete="off" value={honeypotPassword} onChange={(e) => setHoneypotPassword(e.target.value)} />
          <div className={rs.buttonSecondary} style={{ margin: ".25rem auto 0 0", fontSize: "1.75rem", letterSpacing: ".05rem", fontFamily: "bebas_neueregular" }} onClick={() => submit()}>Connect With Pros</div>
          <div className={rs.textXS} style={{ margin: ".5rem 0" }}>
            <div>emails subject to <span style={{ cursor: "pointer" }} onClick={() => window.open('https://www.thereplayapp.com/privacy-policy')}><u>Privacy Policy</u></span></div>
          </div>
          <div className={mjes.labelError} style={{ margin: ".25rem 0" }} >{error}</div>
        </div>
      )
    }

  }

  return getSignupForm()
}

export default BetaSignup