import React, { useState } from 'react';
//libraries
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import EarlyAccess from '../../images/assets/earlyaccess.png'
//state
//components
import ProfileDropDownButton from '../profile-dropdown/ProfileDropDownButton';
import NavMenu from '../nav-menu/NavMenu';
//images
import Logo from '../../images/logo/logo2-01.png'
import MenuIcon from '@mui/icons-material/Menu';
//styles
import styles from './Header.module.css'
import rs from '../../styles/ReplayStyles.module.css'


function Header(props) {

  const user = props.user;
  const role = props.role;
  const status = props.status
  const isLoggedIn = props.isLoggedIn;
  const firstName = props.firstName;

  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const isSmall = useMediaQuery({ query: '(max-width: 40rem)' })
  const isMed = useMediaQuery({ query: '(max-width:1000px)' })

  const getLinks = () => {
    if (isLoggedIn) {
      if (role === "user") {
        return (<UserLinks user={user} />)
      }
      else if (role === "reviewer")
        return (<ReviewerLinks status={status} const firstName={firstName} />)
      else if (role === "admin")
        return (<AdminLinks />)
    }
    else {
      //return (<div></div>)
      return (<GuestLinks isSmall={isSmall} isMed={isMed} />)
    }
  }

  return (
    <div className={rs.darkBackground} style={{ padding: ".25rem" }}>
      <div style={{ display: "grid", gridTemplateColumns: "auto 1fr auto" }}>
        <div style={{ cursor: "pointer" }} onClick={() => history.push('/')}>
          <img src={Logo} style={{ height: "3rem" }} />
        </div>
        <div>
          {getLinks()}
        </div>
        {isMenuOpen ?
          <div style={{ cursor: "pointer" }} onClick={() => setIsMenuOpen(false)}>
            <MenuIcon style={{ fontSize: "3rem" }} />
            <NavMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} user={user} isLoggedIn={isLoggedIn} role={role} status={status} firstName={firstName} />
          </div> :
          <div style={{ cursor: "pointer" }} onClick={() => setIsMenuOpen(true)}>
            <MenuIcon style={{ fontSize: "3rem" }} />
            <NavMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} user={user} isLoggedIn={isLoggedIn} role={role} status={status} firstName={firstName} />
          </div>
        }
      </div>
    </div>
  );
}

export default Header;

const GuestLinks = (props) => {
  const history = useHistory()

  if (!props.isSmall && !props.isMed)
    return (
      <div style={{ display: "grid", gridTemplateColumns:"10rem 10rem", justifyContent: "right", alignItems: "center", height: "100%", marginRight: "2rem" }}>
        <div className={styles.headerLink} onClick={() => history.push('/golf')}>Find a Coach</div>
        <div className={styles.headerLink} onClick={() => history.push('/')}>How It Works</div>
      </div>
    )
  else
    return (
      <div></div>
    )
}

const UserLinks = (props) => {

  return (
    <div>

    </div>
  )
}

const ReviewerLinks = (props) => {

  return (
    <div>

    </div>
  )
}

const AdminLinks = (props) => {

  return (
    <div>

    </div>
  )
}