import React, { useState } from 'react';
//libraries
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
//state
//components
//styles
import styles from './ProfileDropDownButton.module.css';
import Buttons from '../../app-styles/Buttons.module.css';

function ProfileDropDownButton(props) {

  const profileName = props.profileName;
  const [showMenu, setShowMenu] = useState(false);


  function menuClick(event) {
    setShowMenu(!showMenu);
  }

  function outsideClick() {
    setShowMenu(false);
  }

  return (
    <OutsideClickHandler onOutsideClick={() => outsideClick()} >
      <div>
        <div className={Buttons.button} onClick={menuClick} style={{borderRadius:"50%", padding:".5rem"}}>
          {profileName}
        </div>
        {
          showMenu &&
          <div className={styles.menu}>
            {!props.isReviewer ?
              <div>
                <Link to="/user-page" onClick={menuClick} style={{ textDecoration: "none", color: "white" }}>
                  <div>User Portal</div>
                </Link>
              </div> :
              <div>
                <Link to="/reviewer-page" onClick={menuClick} style={{ textDecoration: "none", color: "white" }}>
                  <div>Coach Portal</div>
                </Link>
              </div>
            }
            <Link to="/notifications" onClick={menuClick} style={{ textDecoration: "none", color: "white" }}>
              <div>Notifications</div>
            </Link>
            <Link to="/logout" onClick={menuClick} style={{ textDecoration: "none", color: "white" }}>
              <div>Logout</div>
            </Link>
          </div>
        }
      </div>
    </OutsideClickHandler>
  );
}

export default ProfileDropDownButton;