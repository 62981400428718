import React from 'react'
import rs from '../../styles/ReplayStyles.module.css'
import CancelIcon from '@mui/icons-material/Cancel';


const UserUpload = (props) => {

  return (
    <div style={{ marginBottom: "1rem" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={rs.text_m} style={{ width: "2rem" }}>{props.number + ":"}</div>
        {props.videoFile ?
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div style={{ flexGrow: "1", maxWidth: "20rem" }}>{props.videoFile.name}</div>
            <CancelIcon style={{ color: "white", cursor: "pointer" }} onClick={() => props.setVideoFile(null)} />
          </div> :
          <label className={rs.buttonPrimary}>
            <input type="file" style={{ fontSize: "1.15rem", width: "16rem", display: "none" }} onChange={props.onVideoFileChanged} accept="video/mp4, video/quicktime" />
            <div>Select Video</div>
          </label>
        }
      </div>
      <div className={rs.error} style={{ marginTop: ".5rem", color:"darkred" }}>{props.videoError}</div>
    </div>
  )
}

export default UserUpload