import React from 'react'
//libraries
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
//state
import { selectLogin } from '../slices/LoginSlice';
//components
import Loader from '../components/loader/Loader'

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const login = useSelector(selectLogin);

  if ((!login.isLoggedIn || login.role !== "admin") && !login.isLoggingIn) {
    return <Redirect to="/login" />
  }

  if (login.isLoggingIn)
    return <div></div>
    //return <Loader message="Logging In" />

    return <Route {...rest} render={
      props => <Component {...rest} {...props} />
    } />

}

export default AdminProtectedRoute;