import React, { useEffect, useState } from 'react';
//libraries
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
//state
import { selectUserDownloadVideo, SubmitFollowupQuestion, FetchRequest, SubmitRating, UpdateRating, UpdateIsShareEnabledAsync, UpdateShareEnabled, UpdateShareEnabledAsync } from '../../slices/UserDownloadVideoSlice';
import { selectLogin } from '../../slices/LoginSlice'
//hooks
import { useViewport } from '../../hooks/useViewport';
//helpers
import { getDimensions } from '../../helpers/Dimensions';
//components
import Loader from '../loader/Loader'
import Icon from '../../icons/Icon'
//styles
import styles from './UserDownloadPage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import { toast } from 'react-toastify';
import IosShareIcon from '@mui/icons-material/IosShare';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function UserDownloadPage(props) {

  //state
  const [question, setQuestion] = useState("");
  const [ratingValue, setRatingValue] = useState(1);
  const [review, setReview] = useState("");
  const [showQuestion, setShowQuestion] = useState(false)
  //const [isPublic, setIsPublic] = useState(true)
  //params
  const params = useParams();
  const requestId = params.requestId;
  //redux
  const dispatch = useDispatch();
  const userDownloadSlice = useSelector(selectUserDownloadVideo);
  const login = useSelector(selectLogin)
  //router
  const history = useHistory()
  //const rating = useSelector(selectRating);
  //const user = useSelector(selectUser);
  const request = userDownloadSlice.request;
  const requestStatus = userDownloadSlice.status;
  const rating = userDownloadSlice.rating
  const shareLink = userDownloadSlice.shareLink
  const reviewerUsername = userDownloadSlice.reviewerUsername
  const isShareEnabled = userDownloadSlice.isShareEnabled
  //console.log(reviewerUsername)
  //screen size
  const { width, height } = useViewport();
  const remPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  //video size
  const videoHeight = height - 14 * remPx;
  const videoDimensions = getDimensions(videoHeight, width);
  const isTwoColumns = width > (videoDimensions.width * 2 + 3 * remPx)

  useEffect(() => {
    dispatch(FetchRequest(requestId));
  }, []);

  useEffect(() => {
    rating ? setRatingValue(rating.ratingValue) : setRatingValue(1)
    rating ? setReview(rating.review) : setReview("")
  }, [request, rating])

  const copyLink = () => {
    toast.info("Share Link Copied To Clipboard")
    navigator.clipboard.writeText(shareLink)
  }

  const onShareEnabledClick = () => {
    dispatch(UpdateShareEnabledAsync(requestId, !isShareEnabled))
  }

  const submitQuestion = () => {
    if (question === null || question === "")
      return;

    dispatch(SubmitFollowupQuestion(request._id, question));
  }

  const submitRating = () => {
    dispatch(SubmitRating(request._id, ratingValue, review))
  }

  const updateRating = () => {
    if (ratingValue <= rating.ratingValue)
      return
    dispatch(UpdateRating(rating._id, ratingValue, review))
  }

  const webshareOnClick = async () => {

    // const link = 'https://www.thereplayapp.com/results/' + reviewer.username
    // toast.info("Link Copied")
    // navigator.clipboard.writeText(link)
    const shareData = {
      title: 'Check Out My Replay',
      text: "Look at this Replay from " + request.reviewerFirstName + " " + request.reviewerLastName,
      url: 'https://www.thereplayapp.com/share/' + request._id
    }

    try {
      if (navigator.share) {
        await navigator.share(shareData)
      }
      else {
        const link = 'https://www.thereplayapp.com/share/' + request._id
        toast.info("Link Copied")
        navigator.clipboard.writeText(link)
      }
    } catch (err) {
      console.log(err)
    }
  }

  function forceDownload(blob, filename) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  // Current blob size limit is around 500MB for browsers
  function downloadResource() {
    const filename = "Replay";
    const url = userDownloadSlice.videoUrl
    fetch(url, {
      mode: 'cors'
    })
      .then(response => response.blob())
      .then(blob => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
      })
      .catch(e => console.error(e));
  }

  const setRating = (value) => {
    if (request.rating && rating.ratingValue > value)
      return
    setRatingValue(value)
  }

  const getFormattedDate = (date) => {
    const newDate = new Date(date)
    var year = newDate.getFullYear();

    var month = (1 + newDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = newDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  const getQuestion = () => {
    if (userDownloadSlice.isUpdatingQuestion) {
      return (
        <div>Updating Question...</div>
      )
    }
    else if (request.question) {
      return (
        <div style={{ margin: "0rem 0" }}>
          <div className={rs.textSmallLight}>{request.question}</div>
        </div>
      )
    }
    else {
      return (
        <div style={{ margin: "0rem 0" }}>
          <div className={styles.labelFlex}>
          </div>
          <textarea className={rs.textAreaInput} value={question} onChange={e => setQuestion(e.target.value)} maxLength="255" />
          <div className={mjes.subText} style={{ textAlign: "right", flexGrow: "1", alignSelf: "flex-end" }}>{question.length + "/255"}</div>
          <div className={rs.buttonSecondary} style={{ margin: ".25rem 0 .5rem 0" }} onClick={() => submitQuestion()}>Submit Question</div>
        </div>
      )
    }
  }

  const getRating = () => {
    if (userDownloadSlice.isUpdatingRating) {
      return (
        <div>Updating Rating...</div>
      )
    }

    if (!rating) {
      return (
        <div style={{ margin: "0rem 0" }}>
          <div className={styles.starGrid}>
            <div className={styles.starContainer} onClick={() => setRating(1)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 1 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(2)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 2 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(3)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 3 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(4)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 4 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(5)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 5 ? "white" : "gray"} />
            </div>
            <div className={rs.labelSmallLight}>{ratingValue + " star"}</div>
          </div>
          <textarea className={rs.textAreaInput} value={review} onChange={e => setReview(e.target.value)} maxLength="255" placeholder="Enter a review for you Replay Video" />
          <div className={mjes.subText} style={{ textAlign: "right", flexGrow: "1", alignSelf: "flex-end" }}>{review.length + "/255"}</div>
          <div className={rs.buttonSecondary} style={{ margin: ".25rem 0 .5rem 0" }} onClick={() => submitRating()} >Submit Rating</div>
        </div>
      )
    }
    else if (rating.ratingValue < ratingValue)
      return (
        <div style={{ margin: "0rem 0" }}>
          <div className={styles.starGrid}>
            <div className={styles.starContainer} onClick={() => setRating(1)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 1 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(2)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 2 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(3)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 3 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(4)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 4 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(5)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 5 ? "white" : "gray"} />
            </div>
            <div className={rs.labelSmallLight}>{ratingValue + " star"}</div>
          </div>
          <textarea className={rs.textAreaInput} value={review} onChange={e => setReview(e.target.value)} maxLength="255" placeholder="Enter a review for you Replay Video" />
          <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + review.length + "/255"}</div>
          <div className={rs.buttonSecondary} style={{ margin: ".25rem 0 .5rem 0" }} onClick={() => updateRating()} >Update Rating</div>
        </div>
      )
    else
      return (
        <div style={{ margin: "0rem 0" }}>
          <div className={styles.starGrid}>
            <div className={styles.starContainer} onClick={() => setRating(1)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 1 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(2)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 2 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(3)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 3 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(4)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 4 ? "white" : "gray"} />
            </div>
            <div className={styles.starContainer} onClick={() => setRating(5)}>
              <Icon icon="star" height="1.75rem" color={ratingValue >= 5 ? "white" : "gray"} />
            </div>
            <div className={rs.labelSmallLight}>{ratingValue + " star"}</div>
          </div>
          <div className={rs.textSmallLight} >{rating.review}</div>
        </div>
      )
  }

  if (requestStatus === "processing")
    return <Loader />
  else if (requestStatus === "error")
    return <div>Error: {userDownloadSlice.error}</div>

  if (request.userAccountId !== login.accountId) {
    history.push('/')
  }

  const section = {
    margin: "1rem 0"
  }

  return (
    <div className={mjes.pageContentContainer} style={{ maxWidth: "50rem", margin: "auto", paddingBottom: "3rem" }}>
      <div style={{ display: "flex" }}>
        <div>
          <div className={rs.buttonIcon} onClick={() => history.push('/profile/videos')} >
            <ArrowBackIcon style={{ fontSize: "1.5rem" }} />
          </div>
        </div>
        <div className={rs.h1MediumLight} style={{ textAlign: "center", margin: "1rem 0", flexGrow: "1" }}>Replay Video</div>
        <div>

        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
        {/* Video */}
        <div style={{ flexGrow: "1" }}>
          <div style={{ width: "18rem", margin: 'auto' }}>
            <video width="100%" crossOrigin="anonymous" controls controlsList="nodownload" preload="metadata">
              <source src={userDownloadSlice.videoUrl + "#t=0.0"} type="video/mp4" />
            </video>
          </div>
        </div>
        <div style={{ flexGrow: "1", maxWidth: "30rem" }}>
          <div>
            <div style={section}>
              <div className={rs.buttonSecondary} onClick={() => webshareOnClick()}>
                <div className={rs.buttonTextLargeLight}>Share with Friends</div>
                <div style={{ marginLeft: ".25rem" }}>
                  <IosShareIcon style={{ fontSize: "2rem" }} />
                </div>
              </div>
            </div>
            <div style={section}>
              <div className={rs.buttonPrimary} onClick={() => history.push('/results/' + reviewerUsername + '/request')}>
                <div className={rs.buttonTextLargeLight}>Book Follow-up</div>
              </div>
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Coach</div>
              <div className={rs.textSmallLight}>{request.reviewerFirstName + " " + request.reviewerLastName}</div>
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Date</div>
              <div className={rs.textSmallLight}>{getFormattedDate(request.dateCompleted)}</div>
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Session</div>
              <div className={rs.textSmallLight}>{"#" + (request.repeatCount + 1)}</div>
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Request Details</div>
              <div className={rs.textSmallLight}> {request.details}</div>
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Share Privacy</div>
              <div style={{ display: "flex", alignItems: "center", margin: ".5rem 0" }}>
                <div className={isShareEnabled ? mjes.switchEnabledLeft : mjes.switchDisabledLeft} onClick={() => onShareEnabledClick()}></div>
                <div className={!isShareEnabled ? mjes.switchEnabledRight : mjes.switchDisabledRight} onClick={() => onShareEnabledClick()}></div>
                <div className={mjes.text_m} style={{ marginLeft: "1rem" }} >{isShareEnabled ? "Sharing Enabled" : "Sharing Disabled"}</div>
              </div>
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Rating</div>
              {getRating()}
            </div>
            <div style={section}>
              <div className={rs.labelSmallLight}>Question</div>
              {getQuestion()}
            </div>
            {request.response &&
              <div style={section}>
                <div className={rs.labelSmallLight}>Answer</div>
                <div style={{ margin: "0rem 0" }}>
                  <div className={rs.textSmallLight}>{request.response}</div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>

    // <div style={{ marginBottom: "2rem" }}>
    //   <div className={styles.topGrid}>
    //     <div className={mjes.breadcrumbs} style={{ justifySelf: "left" }}>
    //       <div className={mjes.textLinkPrimary} onClick={() => history.push('/profile/videos')}>{"Replays"}</div>
    //       <div style={{ margin: "0 .5rem" }}>
    //         <Icon icon="right" height=".75rem" color="gray" />
    //       </div>
    //       <div>{request.userFirstName + " " + request.userLastName}</div>
    //     </div>
    //   </div>
    //   <div className={isTwoColumns ? styles.mainGrid : null} style={{ marginTop: "1rem", zIndex: 'ineherit' }}>
    //     <div style={{ textAlign: "center" }}>
    //       <video width={videoDimensions.width} height={videoDimensions.height} crossOrigin="anonymous" controls controlsList="nodownload" preload="metadata">
    //         <source src={userDownloadSlice.videoUrl+ "#t=0.05"} type="video/mp4" />
    //       </video>
    //       <div className={mjes.stretchButtonSecondary} style={{ maxWidth: videoDimensions.width, margin: "1rem auto 1.5rem auto" }} onClick={() => copyLink()}>Copy Share Link</div>
    //       <div className={mjes.stretchButtonPrimary} onClick={() => downloadResource()} style={{ maxWidth: videoDimensions.width, margin: "1rem auto 2rem auto" }}>Download Replay</div>
    //       {/* <Link to={userDownloadSlice.videoUrl} target="_blank" download>Download</Link> */}
    //       {/* <a href={userDownloadSlice.videoUrl} download="replay.mp4" title="replay">Click to download</a> */}
    //     </div>
    //     <div className={mjes.pageContentContainer} style={{ margin: "0rem" }}>
    //       <div className={mjes.contentLabel} style={{ textAlign: "center", marginBottom: "1rem" }}>REPLAY REPORT</div>
    //       <div className={styles.labelFlex} style={{ margin: ".5rem 0" }}>
    //         <div className={mjes.label_m} style={{ width: "6rem" }}>Athlete:</div>
    //         <div className={mjes.text_m} style={{ flexGrow: "1" }}>{request.userFirstName}</div>
    //       </div>
    //       <div className={styles.labelFlex} style={{ margin: ".5rem 0" }}>
    //         <div className={mjes.label_m} style={{ width: "6rem" }}>Coach:</div>
    //         <div className={mjes.text_m} style={{ flexGrow: "1" }}>{request.reviewerFirstName + " " + request.reviewerLastName}</div>
    //       </div>
    //       <div className={styles.labelFlex} style={{ margin: ".5rem 0" }}>
    //         <div className={mjes.label_m} style={{ width: "6rem" }}>Date:</div>
    //         <div className={mjes.text_m} style={{ flexGrow: "1" }}>{getFormattedDate(request.dateCompleted)}</div>
    //       </div>
    //       <div style={{ margin: ".2rem 0" }}>
    //         <div className={mjes.label_m}>Share Settings:</div>
    //         <div style={{ display: "flex", alignItems: "center", margin: ".5rem 0" }}>
    //           <div className={isShareEnabled ? mjes.switchEnabledLeft : mjes.switchDisabledLeft} onClick={() => onShareEnabledClick()}></div>
    //           <div className={!isShareEnabled ? mjes.switchEnabledRight : mjes.switchDisabledRight} onClick={() => onShareEnabledClick()}></div>
    //           <div className={mjes.text_m} style={{ marginLeft: "1rem" }} >{isShareEnabled ? "Sharing Enabled" : "Sharing Disabled"}</div>
    //         </div>
    //         <div style={{ margin: ".5rem 0", display: "grid", gridTemplateColumns: "1fr 3rem", alignItems: "center" }}>
    //           {/* <div className={mjes.text_m} style={{ color: "#147efb", wordWrap: "break-word" }} >{shareLink.substring(0, 20)}</div> */}
    //           {/* <div className={mjes.iconButton} onClick={() => copyLink()}>
    //             <Icon icon="copy" color="white" height="1.5rem" />
    //           </div> */}
    //         </div>
    //         {/* <div className={mjes.stretchButtonSecondary} style={{ width: "16rem" }} onClick={() => copyLink()}>Copy Share Link</div> */}
    //       </div>
    //       <div style={{ margin: ".5rem 0" }}>
    //         <div className={mjes.label_m}>Request Details:</div>
    //         <div className={mjes.text_m}>{request.details === "" ? "- No Specific Request" : request.details}</div>
    //       </div>
    //       {/* <div style={{ margin: "1rem 0" }}>
    //         <div className={mjes.label_m}>Key Takeaways:</div>
    //         <div className={mjes.text_m}>{request.takeaways ? request.takeaways : "- covered in video -"}</div>
    //       </div> */}
    //       <div>{getRating()}</div>
    //       <div style={{ margin: ".5rem 0" }}>
    //         <div className={mjes.label_m}>Next Steps:</div>
    //         <div className={mjes.stretchButtonSecondary} style={{ margin: ".5rem 0", width: "16rem" }} onClick={() => history.push('/results/' + reviewerUsername + '/request')}>Request Next Session</div>
    //       </div>
    //       <div>{getQuestion()}</div>
    //       {request.question &&
    //         <div style={{ margin: ".5rem 0" }}>
    //           <div className={mjes.label_m}>Coach Response:</div>
    //           <div className={mjes.text_m}>{request.response ? request.response : " - Awaiting Coach Response - "}</div>
    //         </div>
    //       }
    //     </div>
    //   </div>
    // </div>
  );

}

export default UserDownloadPage;