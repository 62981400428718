import { createSlice } from '@reduxjs/toolkit'
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint'
import { EditReviewerStatus } from './LoginSlice'

export const ReviewerSetupSlice = createSlice({
  name: 'reviewerSetup',
  initialState: {
    status: 'awaiting-input',
    error: ''
  },
  reducers: {
    Processing: (state) => {
      state.status = 'processing';
      state.error = '';
    },
    Success: (state) => {
      state.status = 'success';
    },
    Error: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
    Reset: (state) => {
      state.status = 'awaiting-input';
      state.error = '';
    }
  },
});

export const { Processing, Success, Error, Reset } = ReviewerSetupSlice.actions;


export const SubmitProfile = (price, title, aboutMe, feature1, feature2, feature3, videoGuidelines) => async dispatch => {

  dispatch(Processing());

  try {
    let response = await fetch(getEndpoint() + "reviewer/setup-profile", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        price,
        title,
        aboutMe,
        feature1,
        feature2,
        feature3,
        videoGuidelines
      })
    });

    response = await response.json();
    if (response.success) {
      const status = response.payload.status
      const setupStatus = response.payload.setupStatus
      dispatch(EditReviewerStatus({ status: status, setupStatus: setupStatus }))
    }
    else {
      dispatch(Error(response.error))
    }
  }
  catch (error) {
    console.log(error);
    dispatch(Error(error.toString()));
    return;
  }
};

export const SubmitPayment = (paymentId) => async dispatch => {

  dispatch(Processing());

  try {
    let response = await fetch(getEndpoint() + "reviewer/setup-payment", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        paymentId
      })
    });

    response = await response.json();
    if (response.success) {
      const status = response.payload.status
      const setupStatus = response.payload.setupStatus
      dispatch(EditReviewerStatus({ status: status, setupStatus: setupStatus }))
    }
    else {
      dispatch(Error(response.error))
    }
  }
  catch (error) {
    console.log(error);
    dispatch(Error(error.toString()));
    return;
  }
};

export const SubmitPicture = (image) => async dispatch => {

  dispatch(Processing());

  try {
    let response = await fetch(getEndpoint() + "reviewer/setup-picture", {
      method: "POST",
      headers: getAuthHeaders()
    });

    response = await response.json();
    if (response.success) {
      const uploadData = response.payload.uploadData;
      //upload image to s3 bucket
      let form = new FormData();
      Object.keys(uploadData.fields).forEach(key => form.append(key, uploadData.fields[key]));
      form.append('file', image);
      await fetch(uploadData.url, { method: 'POST', body: form });

      response = await fetch(getEndpoint() + "reviewer/setup-activate", {
        method: "POST",
        headers: getAuthHeaders()
      });

      response = await response.json();
      if (response.success) {
        const status = response.payload.status
        const setupStatus = response.payload.setupStatus
        dispatch(EditReviewerStatus({ status: status, setupStatus: setupStatus }))
      }
      else {
        return dispatch(Error(response.error))
      }
    }
    else {
      dispatch(Error(response.error))
    }
  }
  catch (error) {
    console.log(error);
    dispatch(Error(error.toString()));
    return;
  }
};


export const selectReviewerSetup = state => state.reviewerSetup;

export default ReviewerSetupSlice.reducer;
