import React from 'react'
import Step1 from '../../images/assets/step1.png'
import Step2 from '../../images/assets/2.png'
import Step3 from '../../images/assets/3.png'
import Value1 from '../../images/assets/1.jpg'
import Value2 from '../../images/assets/2.jpg'
import Value3 from '../../images/assets/3.jpg'
import rs from '../../styles/ReplayStyles.module.css'
import FeaturedCoaches from './FeaturedCoaches'

const HowItWorks = (props) => {
  const screenSize = props.screenSize

  const elementStyle = {

  }

  if (screenSize === "small") {
    return (
      <div style={{ padding: "2rem 1rem", borderRadius: "0rem", backgroundColor: "#F4F4F4" }}>
        <div>
          <div className={rs.h1MediumDark} style={{ textAlign: "center", marginBottom: "2rem" }}>How It Works</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 25rem))", justifyContent: "center", alignContent: "center", gap: "1rem", margin: "1rem 0" }}>
          <div style={{ textAlign: "center", marginBottom: "0rem" }}>
            <div style={{ marginTop: ".5rem", textAlign: "left" }}>
              <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                {/* <div className={rs.labelMediumDark}
                  style={{
                    display: "grid", border: "solid #4353FF .25rem", borderRadius: "50%", padding: "0rem", width: "2rem", height: "2rem",
                    justifyItems: 'center', alignItems: "center"
                  }}>1</div> */}
                <div className={rs.labelMediumDark} style={{ color: "#4353FF" }}>1. Choose Any Coach</div>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "1.25rem 0" }}>
                <img src={Value1} style={{ width: "14rem", margin: "auto", borderRadius: "1rem" }} />
              </div>
              <div className={rs.textSmallDark} style={{ marginTop: ".5rem", textAlign: "center" }}>
                Pick any coach and request a Replay Video by uploading videos of your swing directly through their profile. The price on each profile is for a ~10-minute Replay Video lesson.
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "0rem", marginTop: "2rem" }}>
            <div style={{ marginTop: ".5rem", textAlign: "left" }}>
              <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                {/* <div className={rs.labelMediumDark}
                  style={{
                    display: "grid", border: "solid #4353FF .25rem", borderRadius: "50%", padding: "0rem", width: "2rem", height: "2rem",
                    justifyItems: 'center', alignItems: "center"
                  }}>2</div> */}
                <div className={rs.labelMediumDark} style={{ color: "#4353FF" }}>2. Upload Your Video Clips</div>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "1.25rem 0" }}>
                <img src={Value2} style={{ width: "14rem", margin: "auto", borderRadius: "1rem" }} />
              </div>
              <div className={rs.textSmallDark} style={{ marginTop: ".5rem", textAlign: "center" }}>{"Upload video clips of your golf swing for your coach to analyze. Down-the-line / face-on camera angles work well. Include a chat message with any goals / background info."}</div>
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "0rem", marginTop: "2rem" }}>
            <div style={{ marginTop: ".5rem", textAlign: "left" }}>
              <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                {/* <div className={rs.labelMediumDark}
                  style={{
                    display: "grid", border: "solid #4353FF .25rem", borderRadius: "50%", padding: "0rem", width: "2rem", height: "2rem",
                    justifyItems: 'center', alignItems: "center"
                  }}>3</div> */}
                <div className={rs.labelMediumDark} style={{ color: "#4353FF" }}>3. Receive Replay Video</div>
              </div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "1.25rem 0" }}>
                <img src={Value3} style={{ width: "14rem", margin: "auto", borderRadius: "1rem" }} />
              </div>
              <div className={rs.textSmallDark} style={{ marginTop: ".5rem", textAlign: "center" }}>{"Coaches use our proprietary video analysis software to record a ~10 minute Replay Video for you with personalized coaching + drills. You'll receive it within a few days."}</div>
            </div>
          </div>
        </div>
        {/* <div style={{ margin: "4rem 0" }}>
        <ValueProp />
      </div> */}
      </div >
    )
  }

  else {
    return (
      <div style={{ padding: "3rem 1rem 1rem 1rem", borderRadius: "0rem", backgroundColor: "#F4F4F4" }}>
        <div>
          <div className={rs.h1MediumDark} style={{ textAlign: "center", marginBottom: "3rem" }}>How It Works</div>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(18rem, 25rem))", justifyContent: "center", gap: "3rem", margin: "1rem 0" }}>
          <div style={{ textAlign: "center", marginBottom: "2rem", padding: "2rem 1rem", borderRadius: "1.5rem", backgroundColor: "white" }}>
            <img src={Value1} style={{ width: "18rem", margin: "auto", borderRadius: "1rem" }} />
            <div style={{ marginTop: ".5rem", textAlign: "left" }}>
              <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "1rem", alignItems: "center" }}>
                {/* <div className={rs.labelMediumDark}
                  style={{
                    display: "grid", border: "solid #4353FF .25rem", borderRadius: "50%", padding: "0rem", width: "2rem", height: "2rem",
                    justifyItems: 'center', alignItems: "center"
                  }}>1</div> */}
                <div className={rs.labelMediumDark} style={{ marginTop: ".5rem", color: "#4353FF" }}>1. Choose Any Coach</div>
              </div>
              <div className={rs.textSmallDark} style={{ marginTop: ".5rem" }}>
                Pick any coach and request a Replay Video by uploading videos of your swing directly through their profile. The price on each profile is for a ~10-minute Replay Video lesson.
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "2rem", padding: "2rem 1rem", borderRadius: "1.5rem", backgroundColor: "white" }}>
            <img src={Value2} style={{ width: "18rem", margin: "auto", borderRadius: "1rem" }} />
            {/* <div className={mjes.pageLabel} style={{ margin: "-2rem -1rem 0 0" }}>2</div> */}
            <div style={{ marginTop: ".5rem", textAlign: "left" }}>
              <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "1rem", alignItems: "center" }}>
                {/* <div className={rs.labelMediumDark}
                  style={{
                    display: "grid", border: "solid #4353FF .25rem", borderRadius: "50%", padding: "0rem", width: "2rem", height: "2rem",
                    justifyItems: 'center', alignItems: "center"
                  }}>2</div> */}
                <div className={rs.labelMediumDark} style={{ marginTop: ".5rem", color: "#4353FF" }}>2. Upload Your Video Clips</div>
              </div>
              <div className={rs.textSmallDark} style={{ marginTop: ".5rem" }}>{"Upload video clips of your golf swing for your coach to analyze. Down-the-line / face-on camera angles work well. Include a chat message with any goals / background info."}</div>
            </div>
          </div>
          <div style={{ textAlign: "center", marginBottom: "2rem", padding: "2rem 1rem", borderRadius: "1.5rem", backgroundColor: "white" }}>
            <img src={Value3} style={{ width: "18rem", margin: "auto", borderRadius: "1rem" }} />
            {/* <div className={mjes.pageLabel} style={{ margin: "-2rem -1rem 0 0" }}>3</div> */}
            <div style={{ marginTop: ".5rem", textAlign: "left" }}>
              <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "1rem", alignItems: "center" }}>
                {/* <div className={rs.labelMediumDark}
                  style={{
                    display: "grid", border: "solid #4353FF .25rem", borderRadius: "50%", padding: "0rem", width: "2rem", height: "2rem",
                    justifyItems: 'center', alignItems: "center"
                  }}>3</div> */}
                <div className={rs.labelMediumDark} style={{ marginTop: ".5rem", color: "#4353FF" }}>3. Receive Replay Video</div>
              </div>
              <div className={rs.textSmallDark} style={{ marginTop: ".5rem" }}>{"Coaches use our proprietary video analysis software to record a ~10 minute Replay Video for you with personalized coaching + drills. You'll receive it within a few days."}</div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default HowItWorks


const ValueProp = () => {

  return (
    <div>
      <div className={rs.h1MediumDark} style={{ textAlign: "center", marginBottom: "1rem" }}>Train With Replay</div>
      <div style={{ display: "flex", flexWrap: "wrap", justifyItems: "center" }}>
        <div style={{ textAlign: "left", maxWidth: "30rem", minWidth: "18rem", flexGrow: "1", margin: "auto" }}>
          <div className={rs.labelLargeDark}>
            Access Top-Tier Coaches</div>
          <div className={rs.textMediumDark}>
            Replay gives you access to the highest quality coaches that include
            current professional players, top college athletes, and industry leading
            elite instructors.  All of our Replay coaches have made it to the highest
            levels of the game and know what it takes to get there.
          </div>
        </div>
        <img src={Value1} style={{ width: "18rem", margin: "auto" }} />
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", justifyItems: "center", marginTop: "2rem" }}>
        <div style={{ marginTop: ".5rem", textAlign: "left", maxWidth: "30rem", minWidth: "18rem", flexGrow: "1", margin: "auto" }}>
          <div className={rs.labelLargeDark}>
            Train like the pros</div>
          <div className={rs.textMediumDark}>
            Replay lets athletes of all skill levels train the same way as top college and
            professional players.  Video analysis has become a requirement for top
            athletes to work with their trainers to refine their skillsets.  Replay not only
            provides access to the same video analysis training methods but also provides
            access to the same quality trainers that the pros use.  Because each Replay is
            recorded as a video, they can be watched as many times as needed and
            removes the need to try to remember everything the coach recommended.
          </div>
        </div>
        <img src={Value1} style={{ width: "18rem", margin: "auto" }} />
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", justifyItems: "center", marginTop: "2rem" }}>
        <div style={{ marginTop: ".5rem", textAlign: "left", maxWidth: "30rem", minWidth: "18rem", flexGrow: "1", margin: "auto" }}>
          <div className={rs.labelLargeDark}>
            Own your training</div>
          <div className={rs.textMediumDark}>
            Replay allows you to train where you want, when you want.  You no longer have to travel
            long distances to find the only decent coach in your area.  No more scheduling your life
            around when a coach is able to fit you in.   Train on your own terms with no expensive
            packages or lock ins with a single coach.
          </div>
        </div>
        <img src={Value1} style={{ width: "18rem", margin: "auto" }} />
      </div>
    </div>
  )
}