import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const getOnboardingLink = async () => {
  let response = await fetch(getEndpoint() + "payment/get-payment-onboard-link", {
    method: "POST",
    headers: getAuthHeaders()
  });

  response = await response.json();
  return response
}

export const getDashboardLink = async () => {
  let response = await fetch(getEndpoint() + "payment/get-payment-dashboard-link", {
    method: "POST",
    headers: getAuthHeaders()
  });

  response = await response.json();
  return response
}

export const checkAccountStatus = async () => {
  let response = await fetch(getEndpoint() + "payment/check-account-status", {
    method: "POST",
    headers: getAuthHeaders()
  });

  response = await response.json();
  return response
}