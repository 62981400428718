import React from 'react'
//components
import Icon from '../../icons/Icon'
//styles
import styles from './ReviewerRating.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'

const ReviewerRating = (props) => {

  const rating = props.rating
  const ratingValue = rating.ratingValue
  const review = rating.review
  const firstName = rating.requesterFirstName

  const getFirstNameDisplay = () => {
    return firstName.substring(0, 1) + "*******"
  }

  return (
    <div className={styles.ratingContainer}>
      <div className={styles.title} style={{ alignItems: "center" }}>
        <div className={rs.labelMediumLight} style={{ marginRight: ".75rem" }}>{getFirstNameDisplay()}</div>
        <div className={mjes.contentText} >{" on " + new Date(rating.dateModified).toDateString()}</div>
      </div>
      <div>
        <div className={styles.starGrid}>
          <div  >
            <Icon icon="star" height="1.25rem" color={ratingValue >= 1 ? "white" : "gray"} />
          </div>
          <div  >
            <Icon icon="star" height="1.25rem" color={ratingValue >= 2 ? "white" : "gray"} />
          </div>
          <div  >
            <Icon icon="star" height="1.25rem" color={ratingValue >= 3 ? "white" : "gray"} />
          </div>
          <div >
            <Icon icon="star" height="1.25rem" color={ratingValue >= 4 ? "white" : "gray"} />
          </div>
          <div >
            <Icon icon="star" height="1.25rem" color={ratingValue >= 5 ? "white" : "gray"} />
          </div>
          <div className={rs.labelMediumLight}>{ratingValue + " star"}</div>
        </div>
      </div>
      {review.length > 0 &&
        <div className={rs.textSmallLight}>{review}</div>
      }
    </div>
  )
}

export default ReviewerRating