import React, { useState, useEffect, useCallback } from 'react';
//libraries
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
//state
import { selectSignup, ReviewerSignup, SignupReset } from '../../slices/SignupSlice';
import { selectUser } from '../../slices/UserSlice';
//components
import MaskedInput from 'react-text-mask';
import SignupReviewerExperience from '../signup-reviewer-experience/SignupReviewerExperience';
//styles
import styles from './SignupReviewer.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';


function SignupUserReviewer() {

  //redux
  const signup = useSelector(selectSignup);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  //state
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [price, setPrice] = useState(10);
  const [sport, setSport] = useState("basketball")
  const [playerBio, setPlayerBio] = useState("");
  const [playerExperience, setPlayerExperience] = useState([]);
  const [coachExperience, setCoachExperience] = useState([]);
  const [additionalExperience, setAdditionalExperience] = useState("");
  const [socialMediaLinks, setSocialMediaLinks] = useState("");

  useEffect(() => {
    dispatch(SignupReset());
  }, []);

  //react-dropzone
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 1)
      alert("Only accepts single files");

    const selectedFile = acceptedFiles[0];
    setImage(selectedFile);

    var reader = new FileReader();
    reader.onload = function (event) {
      setImageUrl(event.target.result);
    };
    reader.readAsDataURL(selectedFile);

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const submitSignup = () => {
    dispatch(ReviewerSignup(user._id, price, sport, playerBio, playerExperience, coachExperience,
      additionalExperience, socialMediaLinks, image));
  }

  const addPlayerExperience = () => {
    const experience = {
      id: uuidv4(),
      active: "inactive",
      level: 'd1',
      team: '',
      position: 'offense'
    }
    const experiences = [...playerExperience];
    experiences.push(experience);
    setPlayerExperience(experiences);
  }

  const addCoachExperience = () => {
    const experience = {
      id: uuidv4(),
      active: "inactive",
      level: 'd1',
      team: '',
      position: 'defense'
    }
    const experiences = [...coachExperience];
    experiences.push(experience);
    setCoachExperience(experiences);
  }

  const deletePlayerExperience = (id) => {
    const experiences = playerExperience.filter(e => e.id !== id);
    setPlayerExperience(experiences);
  }

  const deleteCoachExperience = (id) => {
    const experiences = coachExperience.filter(e => e.id !== id);
    setCoachExperience(experiences);
  }

  const updatePlayerExperience = (experience) => {
    const newExperiences = [...playerExperience];
    let newExperience = newExperiences.find(e => e.id === experience.id);
    if (newExperience) {
      newExperience.level = experience.level;
      newExperience.team = experience.team;
      newExperience.position = experience.position;
      newExperience.active = experience.active;
      setPlayerExperience(newExperiences);
    }
  }

  const updateCoachExperience = (experience) => {
    const newExperiences = [...coachExperience];
    let newExperience = newExperiences.find(e => e.id === experience.id);
    if (newExperience) {
      newExperience.level = experience.level;
      newExperience.team = experience.team;
      newExperience.position = experience.position;
      newExperience.active = experience.active;
      setCoachExperience(newExperiences);
    }
  }

  if (signup.status === 'success')
    return <Redirect to="/" />
  else if (signup.status === 'processing')
    return <div>Processing Request</div>

  return (
    <div className={layout.pageContainer}>
      <div className={layout.pageContent}>
        <div className={layout.pageHeader}>Welcome to Elevate</div>
        <div className={layout.pageHeaderContent}>Apply to become a private coach</div>
        <div className={layout.formContainer}>
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Coaching Profile Picture</div>
              <div className={styles.dragZoneContainer} {...getRootProps()}>
                <input {...getInputProps()} />
                <div className={styles.dropZoneLabel}>
                  {imageUrl ?
                    <img className={styles.image} src={imageUrl} /> :
                    <div>Drag image here</div>
                  }
                </div>
              </div>
            </div>
            <div>
              <div className={elements.label}>First Name *</div>
              <div className={elements.textLabel}>{user.firstName}</div>
              <div className={elements.label}>Last Name *</div>
              <div className={elements.textLabel}>{user.lastName}</div>
              <div className={elements.label}>Price *</div>
              <input className={elements.textInput} type='number' value={price}
                onChange={e => setPrice(parseInt(e.target.value))} />
            </div>
          </div>
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Sport *</div>
              <select className={elements.textInput} value={sport} onChange={e => setSport(e.target.value)}>
                <option value="basketball">Basketball</option>
                <option value="baseball">Baseball/Softball</option>
                <option value="football">Football</option>
                <option value="golf">Golf</option>
                <option value="lacrosse">Lacrosse</option>
                <option value="gymnastics">Gymnastics</option>
              </select>
            </div>
          </div>         
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Player Bio *</div>
              <textarea className={elements.textAreaInput} value={playerBio}
                onChange={e => setPlayerBio(e.target.value)}
                placeholder="Enter a personal bio that will be featured on your coaching profile"></textarea>
            </div>
          </div>
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Player Experience *</div>
              <div className={styles.experienceLabel}>Level | Team | Position | Status</div>
              <div className={styles.experienceContainer}>
                {playerExperience.map(experience =>
                  <SignupReviewerExperience key={experience.id} experience={experience}
                    updateExperience={updatePlayerExperience} deleteExperience={deletePlayerExperience} />
                )}
                <div>
                  <div className={buttons.stretchButton} style={{ backgroundColor: "#7899D4" }}
                    onClick={() => addPlayerExperience()}>Add Player Experience</div>
                </div>
              </div>
            </div>
          </div>
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Coaching Experience *</div>
              <div className={styles.experienceLabel}>Level | Team | Position | Status</div>
              <div className={styles.experienceContainer}>
                {coachExperience.map(experience =>
                  <SignupReviewerExperience key={experience.id} experience={experience}
                    updateExperience={updateCoachExperience} deleteExperience={deleteCoachExperience} />
                )}
                <div>
                  <div className={buttons.stretchButton} style={{ backgroundColor: "#7899D4" }}
                    onClick={() => addCoachExperience()}>Add Coach Experience</div>
                </div>
              </div>
            </div>
          </div>
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Additional Experience *</div>
              <textarea className={elements.textAreaInput} value={additionalExperience}
                onChange={e => setAdditionalExperience(e.target.value)}
                placeholder="Provide any additional relevant experience"></textarea>
            </div>
          </div>
          <div className={layout.columnGrid}>
            <div>
              <div className={elements.label}>Best place to see sports-specific credibility *</div>
              <textarea className={elements.textAreaInput} value={socialMediaLinks}
                onChange={e => setSocialMediaLinks(e.target.value)}
                placeholder="Provide links to social media pages or relevant content"></textarea>
            </div>
          </div>
          <div className={styles.buttonMargin}>
            <div className={buttons.stretchButton} onClick={() => submitSignup()}>Sign Up</div>
          </div>
          <div>{signup.error}</div>
        </div>
      </div>
    </div>
  );
}

export default SignupUserReviewer;