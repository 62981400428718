import React, { useState, useEffect } from 'react';
//libraries
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch, useParams, useHistory } from 'react-router-dom';
//state
import { selectUser } from '../../slices/UserSlice';
import { FetchDashboard, selectReviewerDashboard } from '../../slices/ReviewerDashboardSlice';
//components
import ReviewerSetup from '../reviewer-setup/ReviewerSetup';
import ReviewerDashboard from '../reviewer-dashboard/ReviewerDashboard';
//styles
import styles from './ReviewerDashboardPage.module.css';
import layout from '../../app-styles/Layout.module.css';
import buttons from '../../app-styles/Buttons.module.css';
import elements from '../../app-styles/Elements.module.css';
import menu from '../../app-styles/Menu.module.css';
import mjes from '../../mjes-styles/mjes.module.css';


function ReviewerDashboardPage(props) {

  //props
  //redux
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const dashboard = useSelector(selectReviewerDashboard);
  //router
  const history = useHistory();

  useEffect(() => {
    dispatch(FetchDashboard(user.reviewerId));
  }, []);

  if (dashboard.status === 'processing')
    return <div>Processing</div>
  else if (dashboard.status === 'error')
    return <div>Error fetching profile information</div>

  return (
    <div className={mjes.pageContainer}>
      <div className={mjes.pageLabel}>Dashboard</div>
      {dashboard.reviewer.status !== "active" ?
        <ReviewerSetup reviewer={dashboard.reviewer} /> :
        <ReviewerDashboard dashboard={dashboard} />
      }
    </div>
  );
}

export default ReviewerDashboardPage;