import { createSlice } from '@reduxjs/toolkit';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const SearchSlice = createSlice({
  name: 'search',
  initialState: {
    status: "processing",
    primaryFilter: "all",
    //categoryResults: [],
    results: [],
    bucket: "",
    error: ""
  },
  reducers: {
    SearchProcessing: (state) => {
      state.status = "processing";
    },
    SearchSuccess: (state, action) => {
      state.status = "success"
      //state.categoryResults = action.payload.categoryResults
      state.results = action.payload.results
      state.bucket = action.payload.bucket
    },
    SearchError: (state, action) => {
      state.status = "error"
      state.error = action.payload
      //state.categoryResults = []
      state.results = []
      state.bucket = ""
    },
    SearchFilterUpdate: (state, action) => {
      state.primaryFilter = action.payload.primaryFilter;
      state.status = "processing";
    }
  },
});

export const { SearchProcessing, SearchSuccess, SearchError, SearchFilterUpdate } = SearchSlice.actions;

export const Search = (primaryFilter, page) => async dispatch => {

  dispatch(SearchProcessing());

  try {
    let response = await fetch(getEndpoint() + "search/search", {
      method: "POST",
      mode: "cors",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        primaryFilter: primaryFilter
      })
    });

    response = await response.json();
    if (response.success) {
      //let categoryResults = response.payload.categoryResults
      let results = response.payload.results
      let bucket = response.payload.bucket;

      //sort list
      // allResults.sort(function (a, b) {
      //   var textA = a.lastName.toUpperCase();
      //   var textB = b.lastName.toUpperCase();
      //   return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      // });
      dispatch(SearchSuccess({ results, bucket }));
    }
    else {
      dispatch(SearchError(response.message));
    }
  }
  catch (error) {
    dispatch(SearchError(error));
  }
};

export const selectSearch = state => state.search;

export default SearchSlice.reducer;
