import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { getStats } from '../../../requests/AdminRequests'
import mjes from '../../../mjes-styles/mjes.module.css'

const AdminStats = () => {

  const [stats, setStats] = useState()
  const [status, setStatus] = useState("processing")

  const history = useHistory()

  useEffect(() => {
    loadStats()
  }, [])

  const loadStats = async () => {
    //
    const response = await getStats()
    if (response.success) {
      setStats(response.payload.stats)
      setStatus("loaded")
    }
  }

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "#66ff00"
      case "unavailable":
        return "blue"
      case "suspended":
        return "red"
      case "setup":
        return "yellow"
    }
  }

  if (status === "processing")
    return (
      <div>Loading</div>
    )

  return (
    <div>
      <div className={mjes.label_l} style={{ marginLeft: "1rem" }}>Stats</div>
      <div>
        <div style={{ marginTop: ".25rem", display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "18rem" }}>
            <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom: ".5rem" }}>Requests</div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Total - </div>
              <div className={mjes.text_s}>{stats.requests} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Completed - </div>
              <div className={mjes.text_s}>{stats.completedRequests} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Discarded - </div>
              <div className={mjes.text_s}>{stats.discardedRequests} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Expired - </div>
              <div className={mjes.text_s}>{stats.expiredRequests} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Errored Requests - </div>
              <div className={mjes.text_s}>{stats.erroredRequests} </div>
            </div>
          </div>
          <div style={{ width: "18rem" }}>
            <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom: ".5rem" }}>Users/Coaches</div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Users- </div>
              <div className={mjes.text_s}>{stats.users} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Verified Users - </div>
              <div className={mjes.text_s}>{stats.verifiedUsers} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Coaches - </div>
              <div className={mjes.text_s}>{stats.reviewers} </div>
            </div>
          </div>
          <div style={{ width: "18rem" }}>
            <div className={mjes.label_s} style={{ textDecoration: "underline", marginBottom: ".5rem" }}>Metrics</div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Total Revenue - </div>
              <div className={mjes.text_s}>{stats.totalRevenue} </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className={mjes.text_s} style={{ width: "10rem" }}>Share Views - </div>
              <div className={mjes.text_s}>{stats.totalShareViews} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminStats