import React, { useState, useEffect } from 'react';
//libraries
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom'
import queryString from "query-string"
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
//state 
import { selectSearch, Search, SearchProcessing } from '../../slices/SearchSlice';
import { selectLogin } from '../../slices/LoginSlice'
//components
import SearchResultsSection from '../search-results-section/SearchResultsSection';
import Loader from '../loader/Loader'
import Icon from '../../icons/Icon';
//images
import AllIcon from '../../images/sports-filter/all_glow.png';
import AllIconSelected from '../../images/sports-filter/all.png';
import BaseballIcon from '../../images/sports-filter/baseball_glow.png';
import BaseballIconSelected from '../../images/sports-filter/baseball.png';
import BasketballIcon from '../../images/sports-filter/basketball_glow.png';
import BasketballIconSelected from '../../images/sports-filter/basketball.png';
import FootballIcon from '../../images/sports-filter/football_glow.png';
import FootballIconSelected from '../../images/sports-filter/football.png';
import GolfIcon from '../../images/sports-filter/golf_glow.png';
import GolfIconSelected from '../../images/sports-filter/golf.png';
import LacrosseIcon from '../../images/sports-filter/lacrosse_glow.png';
import LacrosseIconSelected from '../../images/sports-filter/lacrosse.png';
import GymnasticsIcon from '../../images/sports-filter/gymnastics_glow.png';
import GymnasticsIconSelected from '../../images/sports-filter/gymnastics.png';
//styles
import styles from './SearchPage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import SportFilterNav from '../sport-filter-nav/SportFIlterNav';
import SearchResultsAll from '../search/SearchResultsAll';



function SearchPage() {

  //redux
  const search = useSelector(selectSearch)
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const isSmall = useMediaQuery({ query: '(max-width: 50rem)' })
  const isMed = useMediaQuery({ query: '(max-width:1000px)' })
  const login = useSelector(selectLogin)
  const [scrollY, setScrollY] = useState(0);
  const results = search.results
  const bucket = search.bucket;

  const query = queryString.parse(location.search)
  const sport = params.sport ? params.sport : "all"

  useEffect(() => {
    const newPage = query.page ? query.page : 1
    dispatch(Search(sport, newPage));
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  useEffect(() => {
    if (search.status === "success") {
      if (query.scrollY)
        window.scrollTo({ top: query.scrollY })
    }
  }, [search])

  const updateScroll = () => {
    setScrollY(window.pageYOffset)
  }

  const getResultsSection = () => {
    if (sport === "all") {
      return <SearchResultsAll results={results} bucket={bucket} />
    }
    else {
      const featuredReviewers = results.filter(r => r.isFeatured === true)
      const featuredResults = [{
        title: "Featured " + getSportName(),
        sport: "golf",
        reviewers: featuredReviewers
      }]
      console.log(featuredResults)
      return (
        <div>
          <SearchResultsAll results={featuredResults} bucket={bucket} />
          <SearchResultsSection result={results} bucket={bucket} title={"All " + getSportName()} sport={sport} scrollY={scrollY} />
        </div>
      )
    }
  }

  const getSportName = () => {
    if (!params.sport)
      return "Featured"
    switch (params.sport) {
      case "all":
        return "Featured"
      case "baseball":
        return "Baseball & Softball"
      default:
        return params.sport.charAt(0).toUpperCase() + params.sport.slice(1);
    }
  }

  return (
    <div className={mjes.pageContainer}>
      <Helmet>
        <title>The Replay App Coaches | Video Coaching From Professional Athletes</title>
        <meta name="description" content="Get 1-1 video coaching from professional and college athletes.  Choose from a selection of over 50 professional and college athletes.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
      </Helmet>
      <div className={styles.searchPage}>
        {/* {getHeadline()} */}
        <div style={{ margin: "2rem .5rem" }}>
          <div>
            <SportFilterNav selectedSport={sport} />
          </div>
          <div className={styles.results} style={{ marginTop: "1.5rem" }}>
            {(results !== null && search.status !== "processing") &&
              getResultsSection()
            }
          </div>
        </div>
        {scrollY > 0 &&
          <div className={rs.buttonPrimary} onClick={() => window.scrollTo({ top: "0" })} style={{
            position: "fixed", bottom: "2rem", right: "2rem", display: "flex", flexDirection: "column",
            borderRadius: "50%", padding: ".75rem", alignItems: "center", justifyItems: "center", cursor: "pointer"
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <polygon points="6,17.59 7.41,19 12,14.42 16.59,19 18,17.59 12,11.59" />
                  <polygon points="6,11 7.41,12.41 12,7.83 16.59,12.41 18,11 12,5" />
                </g>
              </g>
            </svg>
          </div>
        }
      </div>
    </div>
  );

}

export default SearchPage;