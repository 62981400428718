import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("localhost")) {
      console.log("dev - no analytics");
    } else {
      ReactGA.initialize("G-7B47CX4RSW");
      setInitialized(true);
    }    
  }, []);

  useEffect(() => {
    try{
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: (location.pathname + location.search) });
      //ReactGA.pageview(location.pathname + location.search); depricated
    }
  }
  catch(err){
    console.log(err)
  }
  }, [location]);
};

export default usePageTracking;
