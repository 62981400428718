import React, { useState, useEffect } from 'react';
//libraries
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validateText, validateInt, validatePrice } from '../../input-validation/InputValidation';
//state
import { EditReviewerStatus, ReviewerSignup, selectLogin } from '../../slices/LoginSlice'
import { Reset, SubmitPayment, SubmitProfile } from '../../slices/ReviewerSetupSlice'
//hooks
import { useInterval } from '../../hooks/useInterval';
//components
//styles
import styles from './SetupReviewerPayment.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import { checkAccountStatus } from '../../requests/PaymentRequests';
import Loader from '../loader/Loader';

function SetupReviewerPayment() {

  //redux
  const login = useSelector(selectLogin);
  const dispatch = useDispatch();
  const history = useHistory()
  //state
  const [status, setStatus] = useState('initializing')
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    dispatch(Reset())
  }, []);

  useInterval(() => {
    update()
  }, 3000);

  const update = async () => {
    try {
      const response = await checkAccountStatus()
      if (response.success) {
        dispatch(EditReviewerStatus({ status: response.payload.status, setupStatus: response.payload.setupStatus }))
      }
      else {
        setStatus('error')
        setErrorMessage(response.message)
      }
    }
    catch (err) {
      setErrorMessage(err.toString())
    }
    setStatus('success')
  }

  const submitPayment = () => {
    history.push('/payment-onboard-redirect')
    //dispatch(SubmitPayment("paymentId"));
  }

  if (status === "initializing")
    return <Loader message="Loading Payment Info" />

  if (status === "error")
    return (
      <div>{errorMessage}</div>
    )

  return (
    <div className={mjes.pageContainer}>
      <div className={mjes.pageContentContainer}>
        <div className={mjes.pageLabel} style={{ textAlign: "center" }}>Setup Payment Account</div>
        <div className={mjes.contentLabel} style={{ textAlign: "left" }}>Click the link to setup your stripe account to accept payments</div>
        <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Profile</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>2</div>
            <div className={mjes.label}>Profile Picture</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>3</div>
            <div className={mjes.label}>Payments</div>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <div className={mjes.stretchButtonSecondary} onClick={() => submitPayment()}>Setup Payment Account</div>
        </div>
      </div>
    </div>
  );
}

export default SetupReviewerPayment