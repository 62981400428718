import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const updateVideoNotes = async (requestId, notes) => {
  let response = await fetch(getEndpoint() + "request/update-video-notes", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId: requestId,
      notes: notes
    })
  });

  response = await response.json();
  return response
}

export const getVideoNotes = async (requestId) => {
  let response = await fetch(getEndpoint() + "request/get-video-notes", {
    method: "POST",
    headers: getAuthHeaders(),
    body: JSON.stringify({
      requestId: requestId
    })
  });

  response = await response.json();
  return response
}
