import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import {
  validateName,
  validateEmail,
  validatePassword,
  validatePrice,
  validateImage,
  validateVideo
} from '../input-validation/InputValidation';

export const RequestSlice = createSlice({
  name: 'request',
  initialState: {
    status: 'awaiting-input',
    error: ''
  },
  reducers: {
    RequestProcessing: (state) => {
      state.status = 'processing';
      state.error = '';
    },
    RequestSuccess: (state) => {
      state.status = 'success';
    },
    RequestError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
    RequestReset: (state) => {
      state.status = 'awaiting-input';
      state.error = '';
    }
  },
});

export const { RequestProcessing, RequestSuccess, RequestError, RequestReset } = RequestSlice.actions;


export const SubmitRequest = (reviewerId, details, isPublic, videoFile, price) => async dispatch => {

  dispatch(RequestProcessing());

  //create transaction - returns upload url
  const requestParams = {
    reviewerId: reviewerId,
    details: details,
    isPublic: isPublic,
    price: price
  };

  try {
    let requestResponse = await fetch(getEndpoint() + "request/submit-request", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify(requestParams)
    });

    const request = await requestResponse.json();
    if (!request.success) {
      dispatch(RequestError(request.message));
      return;
    }

    const requestId = request.payload.requestId;

    //get presigned URL
    const uploadData = request.payload.uploadData;

    try {
      //submit to s3 storage
      let form = new FormData();
      Object.keys(uploadData.fields).forEach(key => form.append(key, uploadData.fields[key]));
      form.append('file', videoFile);
      await fetch(uploadData.url, { method: 'POST', body: form });
    }
    catch (error) {
      //alert that upload had an error
      await fetch(getEndpoint() + "request/user-upload-error", {
        method: "POST",
        headers: getAuthHeaders(),
        body: JSON.stringify({ requestId: requestId })
      });
      dispatch(RequestSuccess());
      toast.error("Error uploading video - Navigate to Profile -> Requests to retry upload")
      return
    }

    //upload successfully finished
    await fetch(getEndpoint() + "request/user-upload-success", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ requestId: requestId })
    });

    dispatch(RequestSuccess());
  }
  catch (error) {
    console.log(error);
    dispatch(RequestError(error.toString()));
    return;
  }
};


export const selectRequest = state => state.request;

export default RequestSlice.reducer;
