export const getDimensions = (height, width) => {
  const reqHeight = (width * 16 / 9);
  const reqWidth = (height * 9 / 16);

  if(height > reqHeight) {
    return { width: width, height: reqHeight };
  }
  else {
    return { width: reqWidth, height: height };
  }
}