import React, { useEffect, useState } from 'react';
//libraries
import { useParams, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';
//state
import { selectUserDownloadVideo, SubmitFollowupQuestion, FetchRequest, SubmitRating, UpdateRating } from '../../slices/UserDownloadVideoSlice';
import { selectLogin } from '../../slices/LoginSlice'
//hooks
import { useViewport } from '../../hooks/useViewport';
//helpers
import { getDimensions } from '../../helpers/Dimensions';
//components
import Loader from '../loader/Loader'
import Icon from '../../icons/Icon'
//styles
import styles from './SharePage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import { getShareRequest } from '../../requests/ShareRequests';

function SharePage(props) {

  //state
  const [status, setStatus] = useState("loading")
  const [error, setError] = useState()
  const [videoUrl, setVideoUrl] = useState()
  const [isShareEnabled, setIsShareEnabled] = useState()
  const [reviewerFirstName, setReviewerFirstName] = useState()
  const [reviewerLastName, setReviewerLastName] = useState()
  const [reviewerUsername, setReviewerUsername] = useState()

  //params
  const params = useParams();
  const requestId = params.requestId;
  //redux
  //router
  const history = useHistory()
  //screen size
  const { width, height } = useViewport();
  const remPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  //video size
  const videoHeight = height - 15 * remPx
  const videoDimensions = getDimensions(videoHeight, width);

  useEffect(() => {
    loadRequest()
  }, []);

  const loadRequest = async () => {
    try {
      const response = await getShareRequest(requestId)
      if (response.success) {
        setVideoUrl(response.payload.videoUrl)
        setIsShareEnabled(response.payload.isShareEnabled)
        setReviewerFirstName(response.payload.reviewerFirstName)
        setReviewerLastName(response.payload.reviewerLastName)
        setReviewerUsername(response.payload.reviewerUsername)
        setStatus("loaded")
      }
      else {
        setStatus("error")
        setError(response.message)
      }
    }
    catch (err) {
      setStatus("error")
      setError("Could not connect to Replay video")
    }
  }

  if (status === "loading")
    return <Loader message="Loading Share Page" />
  else if (status === "error")
    return <div>Error: {error}</div>

  if (!isShareEnabled) {
    return (
      <div>
        <div className={rs.h1MediumLight} style={{ textAlign: "center" }}>Replay set to private</div>
      </div>
    )
  }

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div>
          <div className={rs.labelMediumLight}>Replay from</div>
          <div className={rs.h1MediumLight} style={{marginTop:".25rem"}}>{reviewerFirstName + " " + reviewerLastName }</div>
        </div>
        <div style={{ margin: "1rem 0" }}>
          <video width={videoDimensions.width} height={videoDimensions.height} style={{borderRadius:".5rem"}} crossOrigin="anonymous" controls controlsList="nodownload" preload="metadata" >
            <source src={videoUrl + "##t=0.5"} type="video/mp4" />
          </video>
        </div>
        {/* <div className={mjes.stretchButtonSecondary} style={{ width: videoDimensions.width, margin: "auto" }} onClick={() => history.push("/results/" + reviewerUsername)}>Request Now!</div> */}
        <div className={rs.buttonSecondary} style={{ width: videoDimensions.width, margin: "1rem auto", boxSizing:"border-box" }} onClick={() => history.push("/results/" + reviewerUsername)}>
          <div className={rs.buttonTextLargeLight}>View Coach</div>
        </div>
      </div>
    </div >
  );
}

export default SharePage;