import React, { useState, useEffect } from 'react';
//libraries
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { validateText, validateInt, validatePrice } from '../../input-validation/InputValidation';
//state
import { selectLogin } from '../../slices/LoginSlice'
import { selectReviewerSetup, SubmitProfile } from '../../slices/ReviewerSetupSlice'
//components
import Loader from '../loader/Loader'
//styles
import styles from './SetupReviewerProfile.module.css';
import mjes from '../../mjes-styles/mjes.module.css';

function SetupReviewerProfile() {

  //redux
  const login = useSelector(selectLogin);
  const reviewerSetup = useSelector(selectReviewerSetup)
  const dispatch = useDispatch();

  //state
  const [price, setPrice] = useState()
  const [priceStatus, setPriceStatus] = useState("awaiting")
  const [priceError, setPriceError] = useState("")
  const [title, setTitle] = useState("")
  const [titleStatus, setTitleStatus] = useState("awaiting")
  const [titleError, setTitleError] = useState("")
  const [aboutMe, setAboutMe] = useState("")
  const [aboutMeStatus, setAboutMeStatus] = useState("awaiting")
  const [aboutMeError, setAboutMeError] = useState("")
  const [feature1, setFeature1] = useState("")
  const [feature1Status, setFeature1Status] = useState("awaiting")
  const [feature1Error, setFeature1Error] = useState("")
  const [feature2, setFeature2] = useState("")
  const [feature2Status, setFeature2Status] = useState("awaiting")
  const [feature2Error, setFeature2Error] = useState("")
  const [feature3, setFeature3] = useState("")
  const [feature3Status, setFeature3Status] = useState("awaiting")
  const [feature3Error, setFeature3Error] = useState("")
  const [videoGuidelines, setVideoGuidelines] = useState("")
  const [videoGuidelinesStatus, setVideoGuidelinesStatus] = useState("awaiting")
  const [videoGuidelinesError, setVideoGuidelinesError] = useState("")


  useEffect(() => {
  }, []);

  const submitProfile = () => {
    dispatch(SubmitProfile(price, title, aboutMe, feature1, feature2, feature3, videoGuidelines));
  }

  const canSubmit = () => {
    return (priceStatus === "valid" && titleStatus === "valid" && aboutMeStatus === "valid" &&
      feature1Status === "valid" && feature2Status === "valid" && feature3Status === "valid" &&
      videoGuidelinesStatus === "valid");
  }

  const updatePrice = (price) => {
    setPrice(price)
    let validation = validateInt(price)
    if (validation.isValid) {
      const priceInt = parseInt(price, 10);
      const priceValidation = validatePrice(priceInt)
      if (priceValidation.isValid) {
        setPrice(priceInt)
        setPriceStatus("valid")
        setPriceError(null)
      }
      else {
        setPriceStatus("invalid")
        setPriceError(priceValidation.error)
      }
    }
    else {
      setPriceStatus("invalid")
      setPriceError("Not a valid number")
    }
  }

  const updateTitle = (title) => {
    setTitle(title);
    if (title.length < 3) {
      setTitleStatus("invalid");
      setTitleError("Must be at least 3 characters");
    }
    else if (title.length > 35) {
      setTitleStatus("invalid");
      setTitleError("Must be 35 characters or less");
    }
    else {
      setTitleStatus("valid")
      setTitleError(null);
    }
  }

  const updateAboutMe = (aboutMe) => {
    setAboutMe(aboutMe);

    const validation = validateText(aboutMe, 750);
    if (validation.isValid) {
      setAboutMeStatus("valid");
      setAboutMeError(null);
    }
    else {
      setAboutMeStatus("invalid")
      setAboutMeError(validation.error);
    }
  }

  const updateFeature1 = (feature) => {
    setFeature1(feature);

    const validation = validateText(feature, 60);
    if (validation.isValid) {
      setFeature1Status("valid");
      setFeature1Error(null);
    }
    else {
      setFeature1Status("invalid")
      setFeature1Error(validation.error);
    }
  }

  const updateFeature2 = (feature) => {
    setFeature2(feature);

    const validation = validateText(feature, 60);
    if (validation.isValid) {
      setFeature2Status("valid");
      setFeature2Error(null);
    }
    else {
      setFeature2Status("invalid")
      setFeature2Error(validation.error);
    }
  }

  const updateFeature3 = (feature) => {
    setFeature3(feature);

    const validation = validateText(feature, 60);
    if (validation.isValid) {
      setFeature3Status("valid");
      setFeature3Error(null);
    }
    else {
      setFeature3Status("invalid")
      setFeature3Error(validation.error);
    }
  }

  const updateVideoGuidelines = (guidelines) => {
    setVideoGuidelines(guidelines);

    const validation = validateText(guidelines, 500);
    if (validation.isValid) {
      setVideoGuidelinesStatus("valid");
      setVideoGuidelinesError(null);
    }
    else {
      setVideoGuidelinesStatus("invalid")
      setVideoGuidelinesError(validation.error);
    }
  }

  if (reviewerSetup.status === "processing")
    return <Loader text="Loading Setup Details" />

  return (
    <div className={mjes.pageContainer}>
      <div className={mjes.pageContentContainer}>
        <div className={mjes.pageLabel} style={{ textAlign: "center" }}>Setup Coach Profile</div>
        <div className={mjes.contentLabel} style={{ textAlign: "left" }}>The information you provide for your profile will be displayed to users looking at your profile.  This information can be changed in your profile at any time.</div>
        <div className={mjes.stepsGrid}>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumberSelected}>1</div>
            <div className={mjes.label}>Profile</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumber}>2</div>
            <div className={mjes.label}>Profile Picture</div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={mjes.stepNumber}>3</div>
            <div className={mjes.label}>Payments</div>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <div className={mjes.textGroupContainer}>
            <div className={mjes.label}>Price ($)</div>
            <div className={mjes.text_m}>$39 Minimum</div>
            <input className={priceStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              type='text' value={price} onChange={e => updatePrice(e.target.value)} maxLength="6"
              placeholder="$39 minimum price per transaction" />
            <div className={mjes.labelError}>{priceError}</div>
          </div>
          <div className={mjes.textGroupContainer}>
            <div className={mjes.label}>Positions/Specialties</div>
            <div className={mjes.text_m}>displayed on your coaching card and profile</div>
            <input className={titleStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              type='text' value={title} onChange={e => updateTitle(e.target.value)} maxLength="35" />
            <div className={mjes.labelError}>{titleError}</div>
            <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + title.length + "/35"}</div>
          </div>
          <div className={mjes.textGroupContainer} style={{ marginTop: "1.5rem" }}>
            <div className={mjes.label}>About Me *</div>
            <div className={mjes.text_m}>displayed on your coaching profile</div>
            <textarea className={mjes.textAreaInput} value={aboutMe} onChange={e => updateAboutMe(e.target.value)} maxLength="750" />
            <div className={mjes.labelError}>{aboutMeError}</div>
            <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + aboutMe.length + "/750"}</div>
          </div>
          <div className={mjes.textGroupContainer} style={{ marginTop: "1.5rem" }}>
            <div className={mjes.label}>Featured Highlights * (Accolades, Career Highlights, Record Stats, etc)</div>
            <div className={mjes.text_m}>displayed on your coaching card and profile</div>
            <div>
              <textarea className={mjes.textAreaInput} value={feature1} onChange={e => updateFeature1(e.target.value)} maxLength="60" />
              <div className={mjes.labelError}>{feature1Error}</div>
              <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + feature1.length + "/60"}</div>
            </div>
            <div>
              <textarea className={mjes.textAreaInput} value={feature2} onChange={e => updateFeature2(e.target.value)} maxLength="60" />
              <div className={mjes.labelError}>{feature2Error}</div>
              <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + feature2.length + "/60"}</div>
            </div>
            <div>
              <textarea className={mjes.textAreaInput} value={feature3} onChange={e => updateFeature3(e.target.value)} maxLength="60" />
              <div className={mjes.labelError}>{feature3Error}</div>
              <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + feature3.length + "/60"}</div>
            </div>
          </div>
          <div className={mjes.textGroupContainer} style={{ marginTop: "1.5rem" }}>
            <div className={mjes.label}>Video Guidelines *</div>
            <div className={mjes.text_m}>displayed on your coaching profile</div>
            <textarea className={mjes.textAreaInput} value={videoGuidelines} onChange={e => updateVideoGuidelines(e.target.value)} maxLength="500" />
            <div className={mjes.labelError}>{videoGuidelinesError}</div>
            <div className={mjes.subText} style={{ textAlign: "right" }}>{"Char " + videoGuidelines.length + "/500"}</div>
          </div>
          <div className={styles.buttonMargin} style={{ margin: "1rem 0" }}>
            {canSubmit() ?
              <div className={mjes.stretchButtonSecondary} onClick={() => submitProfile()}>Set Profile</div> :
              <div className={mjes.stretchButtonSecondaryDisabled} >Set Profile</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupReviewerProfile