import React, { useEffect, useState } from 'react'
//libraries
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import queryString from "query-string"
//state
import { selectLogin, LoginWithToken, LoginReset } from '../../slices/LoginSlice'
//hooks
import usePageTracking from '../../hooks/usePageTracking'
//components
import UserProtectedRoute from '../../protected-routes/UserProtectedRoute'
import HomePage from '../home-page/HomePage'
import HomePage2 from '../home-page/HomePage2'

import Header from '../header/Header'
import HeaderUser from '../header/HeaderUser'
import HeaderReviewer from '../header/HeaderReviewer'
import HeaderAdmin from '../header/HeaderAdmin'
import Footer from '../footer/Footer'
import Logout from '../logout/Logout'
import Login from '../login/Login'
import SignupUser from '../signup-user/SignupUser'
import SignupUserReviewer from '../signup-user-reviewer/SignupUserReviewer'
import VerifyAccount from '../verify-account/VerifyAccount'
import SwitchUser from '../switch-user/SwitchUser'
import AdminProtectedRoute from '../../protected-routes/AdminProtectedRoute'
import ReviewerProtectedRoute from '../../protected-routes/ReviewerProtectedRoute'
import ApplicationStatus from '../application-status/ApplicationStatus'
import ReviewerApplications from '../reviewer-applications/ReviewerApplications'
import SetupReviewerProfile from '../setup-reviewer-profile/SetupReviewerProfile'
import SignupReviewer from '../signup-reviewer/SignupReviewer'
import ReviewerPage from '../reviewer-page/ReviewerPage'
import SearchPage from '../search-page/SearchPage'
import SearchReviewerPage from '../search-reviewer-page/SearchReviewerPage'
import RequestReview from '../request-review/RequestReview'
import CreateVideoPage from '../create-video-page/CreateVideoPage'
import UserPage from '../user-page/UserPage'
import UserDownloadPage from '../user-download-page/UserDownloadPage'
import ReviewerDownloadPage from '../reviewer-download-page/ReviewerDownloadPage'
import UserNotifications from '../user-notifications/UserNotifications'
import SharePage from '../share-page/SharePage'

//styles
import styles from './App.module.css';
import 'react-toastify/dist/ReactToastify.css';
import SetupReviewerPayment from '../setup-reviewer-payment/SetupReviewerPayment'
import SetupReviewerPicture from '../setup-reviewer-picture/SetupReviewerPicture'
import ForgotPassword from '../forgot-password/ForgotPassword'
import UpdatePassword from '../update-password/UpdatePassword'
import PaymentOnboardingRedirect from '../payments/PaymentOnboardRedirect'
import PaymentRedirect from '../payments/PaymentRedirect'
import TermsOfService from '../terms-of-service/TermsOfService'
import PracticeVideoPage from '../practice-video-page/PracticeVideoPage'
import Faq from '../faq/Faq'
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy'
import ContactUs from '../contact-us/ContactUs'
import useFacebookPixel from '../../hooks/useFacebookPixel'
import AdminReviewers from '../admin/admin-reviewers/AdminReviewers'
import AdminReviewerPage from '../admin/admin-reviewers/AdminReviewerPage'
import AdminRequest from '../admin/admin-request/AdminRequest'
import AdminStats from '../admin/admin-stats/AdminStats'
import BetaSignup from '../beta-signup/BetaSignup'
import Icon from '../../icons/Icon'
import AdminRequests from '../admin/admin-requests/AdminRequests'
import ReplayContentPage from '../replay-content-page/ReplayContentPage'
import AdminCreateReviewer from '../admin/admin-create-reviewer/AdminCreateReviewer'
import HomePageBlackFriday from '../home-page/HomePage-Blackfriday'
import HomePageGolf from '../home-page/HomePageGolf'
import SearchPageGolf from '../search-page/SearchPageGolf'


function App() {

  const dispatch = useDispatch();
  const login = useSelector(selectLogin);
  const location = useLocation()
  usePageTracking()
  //useFacebookPixel()

  const [emailSignupStatus, setEmailSignupStatus] = useState("idle")
  const [showEmailSignup, setShowEmailSignup] = useState(false)

  useEffect(() => {
    document.title = "The Replay App"
    //try to log in if token exists
    const token = localStorage.account_token;
    if (token) {
      dispatch(LoginWithToken(token))
    }
    else {
      dispatch(LoginReset())
    }

    if (localStorage.emailSignupStatus)
      setEmailSignupStatus(localStorage.emailSignupStatus)

    //check for referral code
    saveReferralCode()

    const showEmailTimeout = setTimeout(() => {
      setShowEmailSignup(true)
    }, 3000)

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location]);

  const declineEmailSignup = () => {
    localStorage.setItem("emailSignupStatus", "declined")
    setEmailSignupStatus("declined")
  }

  const acceptEmailSignup = () => {
    localStorage.setItem("emailSignupStatus", "accepted")
    setEmailSignupStatus("accepted")
  }

  const saveReferralCode = () => {
    const query = queryString.parse(location.search)
    if (query.ref) {
      localStorage.setItem("referralCode", query.ref)
    }
  }

  const getHeader = () => {
    return <Header role={login.role} isLoggedIn={login.isLoggedIn} user={login.user} firstName={login.firstName} status={login.status} />;
  }

  return (
    <>
      <div className={styles.app}>
        <Switch>
          <Route path="/review-video/:requestId?" component={null} />
          <Route path="/practice-video/:sport?" component={null} />
          <Route path="/" >{getHeader()}</Route>
        </Switch>
        <div className={styles.page}>
          <Switch>
            <Route path="/signup-user" component={SignupUser} />
            <Route path="/signup-reviewer" component={SignupUserReviewer} />
            <Route path="/login" component={Login} />
            <Route path="/verify-account" component={VerifyAccount} />
            <Route path="/application-status" component={ApplicationStatus} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/update-password/:forgotPasswordId" component={UpdatePassword} />
            <Route path="/payment-onboard-redirect" component={PaymentOnboardingRedirect} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/share/:requestId" component={SharePage} />

            <UserProtectedRoute path="/switch-user" component={SwitchUser} />
            <UserProtectedRoute path="/results/:username/request" component={RequestReview} />
            <UserProtectedRoute path="/profile/:page?" component={UserPage} />
            <UserProtectedRoute path="/user-download/:requestId" component={UserDownloadPage} />
            {/* <UserProtectedRoute path="/content/:sport?" component={ReplayContentPage} /> */}

            <ReviewerProtectedRoute path="/setup-profile" component={SetupReviewerProfile} />
            <ReviewerProtectedRoute path="/setup-payment" component={SetupReviewerPayment} />
            <ReviewerProtectedRoute path="/setup-picture" component={SetupReviewerPicture} />
            <ReviewerProtectedRoute path="/reviewer-profile/:page?" component={ReviewerPage} />
            <ReviewerProtectedRoute path="/review-video/:requestId?" component={CreateVideoPage} />
            <ReviewerProtectedRoute path="/reviewer-replay/:requestId" component={ReviewerDownloadPage} />
            <ReviewerProtectedRoute path="/payment-redirect" component={PaymentRedirect} />
            <ReviewerProtectedRoute path="/practice-video/:sport?" component={PracticeVideoPage} />

            <AdminProtectedRoute path="/reviewer-applications" component={ReviewerApplications} />
            <AdminProtectedRoute path="/admin-reviewers" component={AdminReviewers} />
            <AdminProtectedRoute path="/admin-reviewer/:reviewerId" component={AdminReviewerPage} />
            <AdminProtectedRoute path="/admin-request/:requestId" component={AdminRequest} />
            <AdminProtectedRoute path="/admin-requests" component={AdminRequests} />
            <AdminProtectedRoute path="/admin-stats/" component={AdminStats} />
            <AdminProtectedRoute path="/create-coach/" component={AdminCreateReviewer} />

            <Route path="/browse/:sport?" component={SearchPage} />
            <Route path="/results/:username" component={SearchReviewerPage} />

            <Route default path="/lp1" component={HomePage2} />
            <Route default path="/bf" component={HomePageBlackFriday} />

            <Route default path="/golf" component={SearchPageGolf} />
            <Route default path="/" component={HomePage} />
            {/* <UserProtectedRoute path="/"/> */}
            {/* <Route default exact path="/" component={SearchPage} />
            <Route path="/results/:username/request" component={RequestReview} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/signup-options" component={SignupOptions} />
            <Route path="/signup-user" component={SignupUser} />
            <Route path="/signup-user-reviewer" component={SignupUserReviewer} />
            <Route path="/signup-reviewer" component={SignupReviewer} />
            <Route path="/user-page/:page?" component={UserPage} />
            <Route path="/user-download/:requestId" component={UserDownloadPage} />
            <Route path="/reviewer-page/:page?" component={ReviewerPage} />
            <Route path="/reviewer-download/:requestId" component={ReviewerDownloadPage} />
            <Route path="/create-video/:requestId" component={CreateVideoPage} />
            <Route path="/notifications" component={UserNotifications} />
            <Route path="/" component={SearchPage} /> */}
          </Switch>
        </div>
        <Switch>
          <Route path="/review-video/:requestId?" component={null} />
          <Route path="/practice-video/:sport?" component={null} />
          <Route path="/">
            <Footer acceptEmailSignup={acceptEmailSignup} />
          </Route>
        </Switch>
        {(emailSignupStatus === "idle" && !login.isLoggedIn && showEmailSignup) &&
          <div style={{ position: "fixed", bottom: "0rem", right: "0rem", backgroundColor: "#333333", width: "100%", padding: ".5rem 1rem", borderRadius: ".5rem", maxWidth: "30em", boxSizing: "border-box" }}>
            <div style={{ display: "flex", margin: "0rem 0 -1.5rem 0" }}>
              <div style={{ flexGrow: "1" }}></div>
              <div style={{ cursor: "pointer" }} onClick={() => declineEmailSignup()}>
                <Icon icon="x" height="1.25rem" color="gray" />
              </div>
            </div>
            <BetaSignup acceptEmailSignup={acceptEmailSignup} />
          </div>
        }
      </div>
      <ToastContainer
        position="bottom-center"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable />
    </>
  );
}

export default App;
