import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getAuthHeaders } from '../helpers/Authentication';
import { getEndpoint } from '../helpers/ServiceEndpoint';

export const ReviewApplicationSlice = createSlice({
  name: 'reviewApplication',
  initialState: {
    status: 'processing',
    applications: null,
    filter: "verified",
    error: ''
  },
  reducers: {
    ApplicationsProcessing: (state, action) => {
      state.status = 'processing';
      state.error = '';
      state.filter = action.payload.filter
    },
    ApplicationsSuccess: (state, action) => {
      state.applications = action.payload.applications
      state.status = 'success'
    },
    ApplicationsError: (state, action) => {
      state.applications = null;
      state.status = 'error'
      state.error = action.payload
    },
    AcceptApplicationSuccess: (state, action) => {
      const index = state.applications.findIndex(app => app._id === action.payload.applicationId)
      const apps = [...state.applications]
      apps.splice(index, 1)
      if (index > -1)
        state.applications = apps
    },
    RejectApplicationSuccess: (state, action) => {
      const index = state.applications.findIndex(app => app._id === action.payload.applicationId)
      const apps = [...state.applications]
      apps.splice(index, 1)
      if (index > -1)
        state.applications = apps
    }
  }
});

export const {
  ApplicationsProcessing,
  ApplicationsSuccess,
  ApplicationsError,
  AcceptApplicationSuccess,
  RejectApplicationSuccess } = ReviewApplicationSlice.actions;


export const FetchApplications = (filter) => async dispatch => {

  dispatch(ApplicationsProcessing({
    filter: filter
  }));

  try {
    let response = await fetch(getEndpoint() + "administrator/get-applications", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        filter: filter
      })
    });

    response = await response.json();
    if (response.success) {
      const applications = response.payload.reviewerApplications
      dispatch(ApplicationsSuccess({
        applications: applications
            }));
    }
    else
      dispatch(ApplicationsError(response.message));
  }
  catch (error) {
    console.log(error);
    dispatch(ApplicationsError(error.toString()));
    return;
  }
};

export const AcceptApplication = (applicationId) => async dispatch => {

  try {
    let requestResponse = await fetch(getEndpoint() + "administrator/accept-application", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        applicationId: applicationId
      })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(AcceptApplicationSuccess({
        applicationId: applicationId
      }));
      toast.success("Accepted the applicant")
    }
    else {
      console.log(request.message)
      toast.error("Could not accept the application")
    }
  }
  catch (error) {
    console.log(error)
    toast.error("Could not accept the application")
    return;
  }
};

export const RejectApplication = (applicationId) => async dispatch => {

  try {
    let requestResponse = await fetch(getEndpoint() + "reviewer-application/reject-application", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        applicationId: applicationId
      })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(RejectApplicationSuccess({
        applicationId: applicationId
      }));
      toast.success("Rejected the applicant")
    }
    else
      toast.error("Could not reject the application")
  }
  catch (error) {
    toast.error("Could not reject the application")
    return;
  }
};


export const selectReviewApplication = state => state.reviewApplication;

export default ReviewApplicationSlice.reducer;
