import React, { useState, useEffect, useReducer } from 'react';
//libraries
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import queryString from "query-string"
import { Helmet } from 'react-helmet';
//state
import { LoginAccount, LoginProcessing, selectLogin } from '../../slices/LoginSlice';
//components
//styles
import styles from './Login.module.css';
import mjes from '../../mjes-styles/mjes.module.css';
import rs from '../../styles/ReplayStyles.module.css'
import Loader from '../loader/Loader';

function Login(props) {

  //state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //redux
  const login = useSelector(selectLogin);
  const dispatch = useDispatch();
  //router
  const history = useHistory();
  const location = useLocation()
  const query = queryString.parse(location.search);

  //component did mount
  useEffect(() => {
    //document.addEventListener("keydown", keyPressed, false);
    //return () => {
    //document.removeEventListener("keydown", keyPressed, false);
    //}
  }, [])

  const keyPressed = (e) => {
    if (e.keyCode === 13)
      onSubmit();
  }

  const onSubmit = () => {
    dispatch(LoginAccount(email, password));
  }

  const signUp = () => {
    if (query.username)
      history.push('/signup-user?username=' + query.username)
    else
      history.push('signup-user')
  }

  if (login.isLoggedIn) {
    if (login.isEmailVerified) {
      if (login.role === "user") {
        if (login.autoLoginUser !== null && login.autoLoginUserId === login.user._id) {
          if (query.username)
            return <Redirect to={"/results/" + query.username + "/request"} />
          return <Redirect to="/golf" />
        }
        else
          return <Redirect to={"/switch-user" + location.search} />
      }
      else if (login.role === "reviewer") {
        switch (login.status) {
          case "application-pending":
          case "application-rejected":
            return <Redirect to="/application-status" />
          case "setup-profile":
            return <Redirect to="/setup-profile" />
          case "setup-payment":
            return <Redirect to="/setup-payment" />
          case "setup-picture":
            return <Redirect to="/setup-picture" />
          default:
            return <Redirect to="/reviewer-profile" />
        }
      }
      else if (login.role === "admin") {
        return <Redirect to="/" />
      }
    }
    else
      return <Redirect to={"/verify-account" + location.search} />;
  }

  if (login.isLoggingIn) {
    return <Loader message="Logging In" />
  }

  return (
    <div className={mjes.pageContainer} onKeyDown={keyPressed}>
      <Helmet>
        <title>Login | The Replay App - Video Coaching From Professional Athletes</title>
        <meta name="description" content="Log in to The Replay App. Get 1-1 video coaching from professional and college athletes.  Choose from a selection of over 50 professional and college athletes.  Basketball, Baseball, Softball, Football, Lacrosse, Gymnastics." />
      </Helmet>
      <div className={mjes.pageContentContainer}>
        <div className={rs.h1MediumLight} style={{ textAlign: "center", margin: "1rem 0" }}>Login</div>
        <div className={styles.settingContainer}>
          <div className={rs.labelSmallLight}>Email</div>
          <input className={rs.textInput} type='text' value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className={styles.settingContainer}>
          <div className={rs.labelSmallLight}>Password</div>
          <input className={rs.textInput} type='password' value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <div className={styles.settingContainer}>
          <div className={rs.buttonSecondary} onClick={() => onSubmit()}>
            <div className={rs.buttonTextLargeLight}>Login</div>
          </div>
        </div>
        <div className={mjes.labelError} style={{ marginBottom: ".5rem" }}>{login.error}</div>
        <div className={rs.labelSmallLight} style={{ color: "#147efb" }} onClick={() => history.push('/forgot-password')}>Forgot Password?</div>
        <div style={{ marginTop: ".5rem", display: "flex", flexWrap: "wrap", alignItems: "center", gap: ".5rem" }}>
          <div className={rs.textSmallLight}>Don't have an account?</div>
          <div className={rs.labelSmallLight} style={{ width: "8rem", cursor: "pointer" }} onClick={() => signUp()}>Signup</div>
        </div>
      </div>
    </div >
  );
}

export default Login;
