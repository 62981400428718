import React, { useState, useEffect } from 'react'
//libraries
import { useHistory, useParams } from 'react-router-dom'
//helpers
import { validatePassword } from '../../input-validation/InputValidation'
import { getEndpoint } from '../../helpers/ServiceEndpoint'
//styles
import styles from './UpdatePassword.module.css'
import mjes from '../../mjes-styles/mjes.module.css'
import Loader from '../loader/Loader'

const UpdatePassword = () => {

  const [forgotPassword, setForgotPassword] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordStatus, setPasswordStatus] = useState('idle')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordStatus, setConfirmPasswordStatus] = useState('idle')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [status, setStatus] = useState('initializing')
  const [error, setError] = useState()

  const history = useHistory()
  let { forgotPasswordId } = useParams();

  useEffect(() => {
    fetchForgotPassword()
  }, [])

  const fetchForgotPassword = async () => {

    //make request
    try {
      let response = await fetch(getEndpoint() + "authentication/get-forgot-password", {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ forgotPasswordId: forgotPasswordId })
      });

      response = await response.json();
      if (response.success) {
        setForgotPassword(response.payload)
        setStatus("idle")
      }
      else {
        setError(response.message)
        setStatus('error')
      }
    }
    catch (error) {
      setError("Error connecting to server")
      setStatus('error')
    }
  }

  const onSubmitHandler = async () => {
    if(password !== confirmPassword){
      setStatus("idle")
      setError("Passwords must match")
      return
    }
    setStatus("processing")
    try {
      let response = await fetch(getEndpoint() + "authentication/update-password", {
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ forgotPasswordId: forgotPasswordId, password: password })
      });

      response = await response.json();
      if (response.success) {
        setStatus("success")
      }
      else {
        setError(response.message)
        setStatus("idle")
      }
    }
    catch (error) {
      setError("Error connecting to server")
      setStatus(error.message)
    }
  }

  const onLoginHandler = () => {
    history.push("/login")
  }

  const onForgotPasswordHandler = () => {
    history.push("/forgot-password")
  }

  const onUpdatePasswordHandler = (password) => {
    setPassword(password);
    const validation = validatePassword(password);
    if (validation.isValid) {
      setPasswordStatus("valid");
      setPasswordError(null);
    }
    else {
      setPasswordStatus("invalid")
      setPasswordError(validation.error);
    }
  }

  const onUpdateConfirmPasswordHandler = (password) => {
    setConfirmPassword(password);

    const validation = validatePassword(password);
    if (validation.isValid) {
      setConfirmPasswordStatus("valid");
      setConfirmPasswordError(null);
    }
    else {
      setConfirmPasswordStatus("invalid")
      setConfirmPasswordError(validation.error);
    }
  }

  const isEntryValid = () => {
    return (passwordStatus === "valid" && confirmPasswordStatus === "valid");
  }

  if (status === 'initializing' || status === 'processing')
    return <Loader text="Loading" />
  else if (status === 'error')
    return (
      <div className={mjes.pageContainer}>
        <div className={mjes.pageContentContainer}>
          <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Error With Reset Link</div>
          <div className={mjes.contentLabel} style={{ textAlign: "center", margin: "1rem 0" }}>There is an issue with this link.  Please attempt to reset password again</div>
          <div className={mjes.stretchButtonPrimary} style={{ margin: ".5rem 0" }} onClick={() => onForgotPasswordHandler()}>Reset Password</div>
        </div>
      </div>
    )
  else if (status === "success")
    return (
      <div className={mjes.pageContainer}>
        <div className={mjes.pageContentContainer}>
          <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Password Was Updated</div>
          <div className={mjes.contentLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Your password has been updated</div>
          <div className={mjes.stretchButtonPrimary} style={{ margin: ".5rem 0" }} onClick={() => onLoginHandler()}>Login</div>
        </div>
      </div>
    )
  else
    return (
      <div className={mjes.pageContainer}>
        <div className={mjes.pageContentContainer}>
          <div className={mjes.pageLabel} style={{ textAlign: "center", margin: "1rem 0" }}>Update Password</div>
          <div className={styles.settingContainer}>
            <div className={mjes.label}>Email:</div>
            <div>{forgotPassword.email}</div>
          </div>
          <div className={mjes.textGroupContainer}>
            <div className={mjes.label}>Password *</div>
            <input className={passwordStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              value={password} onChange={e => onUpdatePasswordHandler(e.target.value)} maxLength="13" />
            <div className={mjes.labelError}>{passwordError}</div>
          </div>
          <div className={mjes.textGroupContainer}>
            <div className={mjes.label}>Confirm Password *</div>
            <input className={confirmPasswordStatus === "invalid" ? mjes.textInputError : mjes.textInput}
              value={confirmPassword} onChange={e => onUpdateConfirmPasswordHandler(e.target.value)} maxLength="13" />
            <div className={mjes.labelError}>{confirmPasswordError}</div>
          </div>
          <div className={styles.buttonMargin}>
            {isEntryValid() ?
              <div className={mjes.stretchButtonSecondary} onClick={() => onSubmitHandler()}>Update Password</div> :
              <div className={mjes.stretchButtonSecondaryDisabled} >Update Password</div>
            }
          </div>
          <div className={mjes.labelError}>{error}</div>
        </div>
      </div >
    )

}

export default UpdatePassword