import React, { useEffect, useState } from 'react';
//libraries
import { useParams } from 'react-router-dom';
//state
//components
import CreateVideo from '../create-video/CreateVideo';
import ReviewVideo from '../review-video/ReviewVideo';
//styles
import styles from './PracticeVideoPage.module.css';

function PracticeVideoPage() {


  //params
  const params = useParams();
  const sport = params.sport;
  const request = {
    userFirstName: "Johnny",
    userLastName: "Smith",
    details: "Please help me get better!",
    userFileUploadCount: 1
  }

  //state
  const [reviewState, setReviewState] = useState("create");
  const [videoUrl, setVideoUrl] = useState(null);
  const [duration, setDuration] = useState(0)

  useEffect(() => {
  }, []);

  function videoFinished(file, videoUrl, duration) {
    setReviewState("review");
    setVideoUrl(videoUrl);
    setDuration(duration)
  }

  function reject() {
    setReviewState("create");
    setVideoUrl(null);
  }

  function uploadVideo() {
  }

  const updateVideoNotes = () => {
    //dont do anything because practice
  }

  const getNotes = async () => {
    return []
  }

  const getStreamUrl = () => {
    let baseUrl = "https://d2vtlih1ycguzs.cloudfront.net/sample-videos/"
    switch (sport) {
      case "basketball":
        return baseUrl + "basketball-sample.mp4"
      case "baseball":
        return baseUrl + "baseball-sample.mp4"
      case "golf":
        return baseUrl + "golf-sample.mp4"
      case "lacrosse":
        return baseUrl + "lacrosse-sample.mp4"
      case "football":
        return baseUrl + "football-sample.mp4"
      case "gymnastics":
        return baseUrl + "gymnastics-sample.mp4"
      default:
        return baseUrl + "golf-sample.mp4"
    }
  }

  const requestFiles = [
    {
      position: 1,
      videoUrl: getStreamUrl()
    }
  ]

  return (
    <div className={styles.container}>
      {reviewState === "create"
        ? <CreateVideo videoFinished={videoFinished} requestFiles={requestFiles} request={request} updateNotes={updateVideoNotes} getNotes={getNotes}></CreateVideo>
        : <ReviewVideo videoUrl={videoUrl} reject={reject} uploadVideo={uploadVideo} request={request} duration={duration} mode="practice"></ReviewVideo>
      }
    </div>
  );
}

export default PracticeVideoPage;