import React, { useState, useEffect } from 'react';
//libraries
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom'
import queryString from "query-string"
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
//state 
import { selectSearch, Search, SearchProcessing } from '../../slices/SearchSlice';
import { selectLogin } from '../../slices/LoginSlice'
//components
import SearchResultsSection from '../search-results-section/SearchResultsSection';
import Loader from '../loader/Loader'
import Icon from '../../icons/Icon';
//images
import GolfIcon from '../../images/sports-filter/golf_glow.png';
import GolfIconSelected from '../../images/sports-filter/golf.png';
//styles
import styles from './SearchPage.module.css';
import mjes from '../../mjes-styles/mjes.module.css'
import rs from '../../styles/ReplayStyles.module.css'
import SportFilterNav from '../sport-filter-nav/SportFIlterNav';
import SearchResultsAll from '../search/SearchResultsAll';
import { useViewport } from '../../hooks/useViewport';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import PersonIcon from '@mui/icons-material/Person';



function SearchPageGolf() {

  //redux
  const search = useSelector(selectSearch)
  const dispatch = useDispatch()
  const location = useLocation()
  const [scrollY, setScrollY] = useState(0);
  const results = search.results
  const bucket = search.bucket;

  const query = queryString.parse(location.search)
  const sport = "golf"

  useEffect(() => {
    const newPage = query.page ? query.page : 1
    dispatch(Search(sport, newPage));
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  useEffect(() => {
    if (search.status === "success") {
      if (query.scrollY)
        window.scrollTo({ top: query.scrollY })
    }
  }, [search])

  const updateScroll = () => {
    setScrollY(window.pageYOffset)
  }

  const getResultsSection = () => {

    const featuredReviewers = results.filter(r => r.isFeatured === true)
    const featuredResults = [{
      title: "Featured Golf",
      sport: "golf",
      reviewers: featuredReviewers
    }]
    return (
      <div>
        <SearchResultsAll results={featuredResults} bucket={bucket} />
        <SearchResultsSection result={results} bucket={bucket} title={"All Golf"} sport={sport} scrollY={scrollY} />
      </div>
    )
  }

  return (
    <div className={mjes.pageContainer}>
      <Helmet>
        <title>The Replay App Coaches | Video Golf Lessons</title>
        <meta name="description" content="Video Golf Lessons" />
      </Helmet>
      <div className={styles.searchPage}>
        <div style={{ margin: "1.5rem .5rem" }}>
          <div className={rs.h1LargeLight} style={{ fontSize: "1.75rem", textAlign: "center", margin: "1rem 0" }}>
            Play Your Best Golf
          </div>
          <Headline />
          <div className={styles.results} style={{ marginTop: "2.5rem" }}>
            {(results !== null && search.status !== "processing") &&
              getResultsSection()
            }
          </div>
        </div>
        {scrollY > 0 &&
          <div className={rs.buttonPrimary} onClick={() => window.scrollTo({ top: "0" })} style={{
            position: "fixed", bottom: "2rem", right: "2rem", display: "flex", flexDirection: "column",
            borderRadius: "50%", padding: ".75rem", alignItems: "center", justifyItems: "center", cursor: "pointer"
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <polygon points="6,17.59 7.41,19 12,14.42 16.59,19 18,17.59 12,11.59" />
                  <polygon points="6,11 7.41,12.41 12,7.83 16.59,12.41 18,11 12,5" />
                </g>
              </g>
            </svg>
          </div>
        }
      </div>
    </div>
  );

}

export default SearchPageGolf;


const Headline = (props) => {

  const size = props.size
  const { width, height } = useViewport();


  if (width > 750) {
    return (
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1rem", margin: "1rem 0 0 0", textAlign: "center", backgroundColor: "#1a1a1a", borderRadius: "1rem", padding: "1rem 0", maxWidth: "45rem", margin: "auto" }}>
          <div className={rs.textMediumLight} style={{}}>
            <PersonIcon fontSize="large" />
            <div>Pick a</div>
            <div>coach</div>
          </div>
          <div className={rs.textMediumLight} style={{}}>
            <FileUploadIcon fontSize="large" />
            <div>Send in</div>
            <div>your swings</div>
          </div>
          <div className={rs.textMediumLight} style={{}}>
            <SlowMotionVideoIcon fontSize="large" />
            <div>Get Replay</div>
            <div>Video lesson</div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div>
        {/* <div style={{ alignItems: "center" }}>
          <div style={{ display: "flex", gap: width > 375 ? "1.5rem" : ".5rem", alignItems: 'center', justifyContent: "center" }}>
            <img src={GolfIcon} style={{ width: "4rem" }} />
            <div className={rs.labelLargeLight} style={{ fontSize: "2.5rem", textAlign: "center" }}>Golf Coaches</div>
          </div>
        </div> */}
        <div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "1rem", margin: "1rem 0 0 0", textAlign: "center", backgroundColor: "#1a1a1a", borderRadius: "1rem", padding: "1rem 0" }}>
            <div className={rs.textSmallLight} style={{}}>
              <PersonIcon />
              <div>Pick a</div>
              <div>coach</div>
            </div>
            <div className={rs.textSmallLight} style={{}}>
              <FileUploadIcon />
              <div>Send in</div>
              <div>your swings</div>
            </div>
            <div className={rs.textSmallLight} style={{}}>
              <SlowMotionVideoIcon />
              <div>Get Replay</div>
              <div>Video lesson</div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}