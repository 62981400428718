import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getEndpoint } from '../helpers/ServiceEndpoint';
import { getAuthHeaders } from '../helpers/Authentication';

export const UserDownloadVideoSlice = createSlice({
  name: 'userDownloadVideo',
  initialState: {
    status: 'processing',
    request: null,
    streamUrl: "",
    shareLink: "",
    reviewerUsername: "",
    isShareEnabled: false,
    rating: null,
    isUpdatingQuestion: false,
    isUpdatingRating: false,
    error: ''
  },
  reducers: {
    UserDownloadVideoProcessing: (state) => {
      state.status = 'processing'
      state.request = null
      state.rating = null
      state.error = ''
    },
    UserDownloadVideoSuccess: (state, action) => {
      state.request = action.payload.request
      state.videoUrl = action.payload.videoUrl
      state.rating = action.payload.rating
      state.shareLink = action.payload.shareLink
      state.reviewerUsername = action.payload.reviewerUsername
      state.isShareEnabled = action.payload.request.isShareEnabled
      state.status = 'success'
    },
    UserDownloadVideoError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
      state.request = null;
    },
    UserDownloadVideoReset: (state) => {
      state.status = 'processing';
      state.error = '';
      state.request = null;
    },
    UpdateQuestionProcessing: (state) => {
      state.isUpdatingQuestion = true
    },
    UpdateQuestionSuccess: (state, action) => {
      state.request = action.payload.request
      state.isUpdatingQuestion = false
    },
    UpdateQuestionError: (state, action) => {
      state.isUpdatingQuestion = false
    },
    UpdateRatingProcessing: (state) => {
      state.isUpdatingRating = true
    },
    UpdateRatingSuccess: (state, action) => {
      state.rating = action.payload.rating
      state.isUpdatingRating = false
    },
    UpdateRatingError: (state, action) => {
      state.isUpdatingRating = false
    },
    UpdateShareEnabled: (state, action) => {
      state.isShareEnabled = action.payload.isShareEnabled
    }
  },
});

export const { UserDownloadVideoProcessing, UserDownloadVideoSuccess,
  UserDownloadVideoError, UserDownloadVideoReset,
  UpdateQuestionProcessing, UpdateQuestionSuccess, UpdateQuestionError,
  UpdateRatingProcessing, UpdateRatingSuccess, UpdateRatingError,
  UpdateShareEnabled } = UserDownloadVideoSlice.actions;


export const FetchRequest = (requestId) => async dispatch => {

  dispatch(UserDownloadVideoProcessing());

  //validate inputs
  if (!requestId) {
    dispatch(UserDownloadVideoError("Cannot find request"));
    return;
  }

  try {
    let response = await fetch(getEndpoint() + "request/get-request-response-video", {
      method: "POST",
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify({ requestId: requestId })
    });

    response = await response.json();
    console.log(response)
    if (response.success) {
      dispatch(UserDownloadVideoSuccess({
        request: response.payload.request,
        rating: response.payload.request.rating,
        videoUrl: response.payload.videoUrl,
        shareLink: response.payload.shareLink,
        reviewerUsername: response.payload.reviewerUsername
      }));
      return;
    }
    dispatch(UserDownloadVideoError(response.message));
  }
  catch (error) {
    console.log(error);
    dispatch(UserDownloadVideoError(error.toString()));
    return;
  }
};

export const SubmitFollowupQuestion = (requestId, question) => async dispatch => {

  dispatch(UpdateQuestionProcessing());

  try {
    let requestResponse = await fetch(getEndpoint() + "request/update-question", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ requestId: requestId, question: question })
    });

    const request = await requestResponse.json();
    if (request.success) {
      dispatch(UpdateQuestionSuccess({ request: request.payload }));
      return;
    }
    toast.error("Could not submit question")
    dispatch(UpdateQuestionError());
  }
  catch (error) {
    console.log(error);
    toast.error("Could not submit question")
    dispatch(UpdateQuestionError());
    return;
  }
};

export const SubmitRating = (requestId, ratingValue, review) => async dispatch => {

  dispatch(UpdateRatingProcessing());

  try {
    let response = await fetch(getEndpoint() + "rating/submit-rating", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ requestId: requestId, ratingValue: ratingValue, review: review })
    });

    response = await response.json();
    if (response.success) {
      const newRating = response.payload.rating
      dispatch(UpdateRatingSuccess({ rating: newRating }));
      return;
    }
    toast.error("Could not submit rating")
    dispatch(UpdateRatingError());
  }
  catch (error) {
    console.log(error);
    toast.error("Could not submit rating")
    dispatch(UpdateRatingError());
    return;
  }
};

export const UpdateRating = (ratingId, ratingValue, review) => async dispatch => {

  dispatch(UpdateRatingProcessing());

  try {
    let response = await fetch(getEndpoint() + "rating/update-rating", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({ ratingId: ratingId, ratingValue: ratingValue, review: review })
    });

    response = await response.json();
    if (response.success) {
      const newRating = response.payload.rating
      dispatch(UpdateRatingSuccess({ rating: newRating }));
      return;
    }
    toast.error("Could not submit rating")
    dispatch(UpdateRatingError());
  }
  catch (error) {
    console.log(error);
    toast.error("Could not submit rating")
    dispatch(UpdateRatingError());
    return;
  }
};

export const UpdateShareEnabledAsync = (requestId, isShareEnabled) => async dispatch => {

  try {
    let response = await fetch(getEndpoint() + "request/update-share-enabled", {
      method: "POST",
      headers: getAuthHeaders(),
      body: JSON.stringify({
        requestId: requestId,
        isShareEnabled: isShareEnabled
      })
    });

    response = await response.json();
    if (response.success) {
      dispatch(UpdateShareEnabled({ isShareEnabled: isShareEnabled }));
      return;
    }
    toast.error("Could not update share privacy")
  }
  catch (error) {
    console.log(error);
    toast.error("Could not update share privacy")
    return;
  }
};


export const selectUserDownloadVideo = state => state.userDownloadVideo;

export default UserDownloadVideoSlice.reducer;
